import useUnitReportModel from "../../model/Units/useUnitReportModel";
import { transformUnitReport } from "../../transformers/unitReport/transformUnitReport";

const useUnitReportViewModel = () => {
  const { unitReportData } = useUnitReportModel();

  const transformedUnitReportData = transformUnitReport(unitReportData);

  return {
    transformedUnitReportData,
  };
};

export default useUnitReportViewModel;
