import LinearProgress from "../UI/LinearProgress/LinearProgress";
import Table from "../../Components/Table/Table";
import { formatHygieneStarsData } from "../../utils/index";
import { TopPerformers } from "../../types/types";

type StarsProps = {
  topPerformers: TopPerformers;
};

function Stars(props: StarsProps) {
  const columns = [
    {
      name: "staff_name",
      label: "Staff Name",
    },
    {
      name: "group_name",
      label: "Group Name",
    },
    {
      name: "opportunities",
      label: "Opportunities",
    },
    {
      name: "performance",
      label: "Performance",
      options: {
        customBodyRender: (value: number) => {
          return (
            <LinearProgress
              percentage={value}
              data-testid="linear-progress"
            ></LinearProgress>
          );
        },
      },
    },
  ];

  const options = {
    sortOrder: {
      name: "performance",
      direction: "desc",
    },
    rowsPerPageOptions: [5],
  };

  return (
    <Table
      title={"Hand Hygiene Stars"}
      data={formatHygieneStarsData(props.topPerformers)}
      columns={columns}
      optionOverrides={options}
    ></Table>
  );
}

export default Stars;
