import { useModuleTheme } from "../../contexts/ModuleTheme/moduleThemes";

function Footer() {
  const { theme } = useModuleTheme();
  return (
    <div
      data-testid={"footer-container"}
      className={"footer-container " + theme}
    >
      <div className="footer-copyright">
        <p className="no-margin">
          &#169; SwipeSense {new Date().getFullYear()}
        </p>
      </div>
      <div className="footer-links">
        <a href={`${process.env.REACT_APP_AUTH_DOMAIN}/termsOfUse`}>
          Terms Of Use
        </a>
        <a href={`${process.env.REACT_APP_AUTH_DOMAIN}/privacyPolicy`}>
          Privacy Policy
        </a>
        <a href={`${process.env.REACT_APP_AUTH_DOMAIN}/supportIssue`}>
          Contact Support
        </a>
      </div>
    </div>
  );
}

export default Footer;
