import { ExecutiveReport, Filter } from "../../types/types";
import { transformFilters } from "../utils";

type FacilityData = {
  opportunities: number;
  performance: number;
  labels: string;
};

export function transformExecutiveReport(responseJson): ExecutiveReport {
  const handHygienePlot = responseJson?.handHygienePlot?.dataSeries;

  const excelUrl = responseJson?._export?.url;

  // we only want to show the customers filter on the executive dashboard
  const filters = transformFilters(responseJson._params).filter(
    (filter) => filter.name === "customers"
  );

  const response = {
    excelUrl: excelUrl,
    lateStartFiscalYear: [],
    groupTypeDataCurrentM: [] as any[],
    unitTypeDataCurrentM: [] as any[],
    groupTypeDataPreviousM: [] as any[],
    unitTypeDataPreviousM: [] as any[],
    filters: [] as Filter[],
    error: null,
    uuid: "",

    heatmapData: [],
    heatmapXLabels: [] as string[],
    heatmapYLabels: [] as { name: string; id: string }[],
    sparkLineReport: [] as any[],
    subtitle: "",
  };

  response.filters = filters as Filter[];

  response.uuid = responseJson?._report?.uuid;

  // bar graph data
  function extractBarGraphData(
    data: Record<string, any>,
    dataKey: string,
    monthKey: "currentM" | "previousM" | "currentFy" | "previousFy"
  ): FacilityData[] {
    if (!data[dataKey]) {
      return [];
    }

    const combinedData = Object.keys(data[dataKey]).reduce((acc, key) => {
      const facilities = data[dataKey][key].map((facility: any) => ({
        labels: facility.name,
        performance: facility[monthKey]?.performance || 0,
        opportunities: parseInt(facility[monthKey]?.total_opportunities) || 0,
      }));
      return acc.concat(facilities);
    }, [] as FacilityData[]);

    combinedData.sort((a, b) => b.opportunities - a.opportunities);

    return combinedData;
  }

  function getMonthlyOppsData(
    responseJson: any,
    monthKey: "currentM" | "previousM" | "currentFy" | "previousFy"
  ): FacilityData[] {
    const dataKey =
      responseJson?._params?.facility_ids?.value === null
        ? "facilityHandHygieneDataBySystem"
        : "unitHandHygieneDataByFacility";

    return extractBarGraphData(responseJson, dataKey, monthKey);
  }

  const extractedFacilitiesMonthlyOppsData = getMonthlyOppsData(
    responseJson,
    "currentM"
  );

  if (extractedFacilitiesMonthlyOppsData.length === 0) {
    return response;
  }

  response.heatmapData = handHygienePlot;

  response.heatmapYLabels = Array.from(
    new Set(
      handHygienePlot.map((ds) =>
        JSON.stringify({ name: ds.name, facility_id: ds.facility_id })
      )
    )
  ).map((item) => JSON.parse(item as any));

  const longestArray = handHygienePlot.reduce((longest, ds) => {
    return ds.data.length > longest.length ? ds.data : longest;
  }, []);

  const longestArrayLabels = longestArray.map(
    (item: { name: string }) => item.name
  );

  const labelOrderMap = new Map(
    longestArrayLabels.map((label, index) => [label, index])
  );

  const xLabels = Array.from(
    new Set(
      handHygienePlot.flatMap((ds: { data: { name: string }[] }) =>
        ds.data.map((item: { name: string }) => item.name)
      )
    )
  );

  response.heatmapXLabels = xLabels.sort((a, b) => {
    const indexA: any = labelOrderMap.has(a) ? labelOrderMap.get(a) : Infinity;
    const indexB: any = labelOrderMap.has(b) ? labelOrderMap.get(b) : Infinity;
    return indexA - indexB;
  }) as string[];

  const getPercentagesWithFacilityNames = (facilitiesArray: any[]) => {
    return facilitiesArray.map((facility) => ({
      facility_name: facility.name,
      facility_id: facility.facility_id,
      percentages: facility.data.map(
        (item: { percentage: number }) => item.percentage
      ),
    }));
  };

  const percentagesWithFacilityNames = getPercentagesWithFacilityNames(
    responseJson.handHygienePlot.dataSeries
  );

  response.sparkLineReport = percentagesWithFacilityNames.filter(
    (facility) => facility.facility_id !== null
  );

  response.subtitle = responseJson.reportSubtitle;

  function createPerformanceMatrix(
    typeDataByFacilityMonth: { [x: string]: { [x: string]: never[] } },
    monthKey: string
  ) {
    const facilitiesListByMonth = Object.keys(typeDataByFacilityMonth);

    const unitGroupType = Array.from(
      new Set(
        facilitiesListByMonth.flatMap((facility) =>
          Object.keys(typeDataByFacilityMonth[facility] || {})
        )
      )
    );

    const performanceMatrix = unitGroupType.map((type) => {
      const row = { type };

      facilitiesListByMonth.forEach((facility) => {
        const typeData = typeDataByFacilityMonth[facility]?.[type] || [];

        let arrayItem;
        if (monthKey === "currentM") {
          arrayItem = typeData.slice(-1)[0];
        } else if (monthKey === "previousM") {
          arrayItem = typeData.slice(-2)[0];
        }
        row[facility] = arrayItem ? parseFloat(arrayItem.performance) : null;
      });

      return row;
    });

    return performanceMatrix;
  }

  if (responseJson.length !== 0) {
    response.groupTypeDataCurrentM = createPerformanceMatrix(
      responseJson.groupTypeDataByFacilityMonth,
      "currentM"
    );
    response.unitTypeDataCurrentM = createPerformanceMatrix(
      responseJson.unitTypeDataByFacilityMonth,
      "currentM"
    );
    response.groupTypeDataPreviousM = createPerformanceMatrix(
      responseJson.groupTypeDataByFacilityMonth,
      "previousM"
    );
    response.unitTypeDataPreviousM = createPerformanceMatrix(
      responseJson.unitTypeDataByFacilityMonth,
      "previousM"
    );
  }

  return response;
}
