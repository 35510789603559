export default function mockUserReportData() {
  return {
    //   reportSubtitle: {
    //     endDate: "2023-04-26T14:29:53.055510Z",
    //     startDate: "2023-02-13T05:00:00.000000Z",
    //   },
    //   _params: {
    //     customer_ids: {
    //       _filter_component_class: "CustomerIdsFilter",
    //       authorized: true,
    //       visible: true,
    //       value: [32, 1],
    //       valueDisplay:
    //         "[Barometers Healthcare (32), Claxon Suds Healthcare (1)]",
    //       default: null,
    //       initial: [1],
    //       options: {
    //         12: "Afib Navigators Health Center (12)",
    //         32: "Barometers Healthcare (32)",
    //         31: "Caffeinated Meditation Center (31)",
    //         16: "Changing Appetites Filling Co. (16)",
    //         1: "Claxon Suds Healthcare (1)",
    //         33: "Covalent Points Pediatric (33)",
    //         19: "Dunblane Regional Medical Center (19)",
    //         10: "Ebenezer Health System (10)",
    //         28: "Enigmatic Inventor Conventional (28)",
    //         15: "Floral University Health Care (15)",
    //         17: "Fourth Valence Vineyards Medical (17)",
    //         25: "Franny May Health Bureau (25)",
    //         20: "Healthy McGrasslands Medical Corporation (20)",
    //         18: "Monsters Inc Ministry (18)",
    //         2: "North Life Hospital System (2)",
    //         24: "Philharmonic Plato Health (24)",
    //         34: "Piece de Resistance Health (34)",
    //         29: "Preeminent Christian Charitable (29)",
    //         11: "Saintly Joan Medical (11)",
    //         27: "Salient Foliage Health (27)",
    //         26: "Serenading Seas Psychological (26)",
    //         13: "Tamaflu Medical (13)",
    //         22: "Triceratops Healthcare (22)",
    //         21: "Uplifting Radiance Healthcare (21)",
    //         23: "Varnon Association of Westerly Plages (23)",
    //       },
    //       reloading: {
    //         peer_filters: [],
    //         url: "https://local.swipesense.test/ajax/filter/getOptions/customer_ids_2177c0e9c23bea7e419ee627b10f9c9d?expires=1682635540&signature=535e30be8f5362fb9b1092dec82e88fc5f1526340c5a68ec76b9206b6626ca2d",
    //       },
    //     },
    //     facility_ids: {
    //       _filter_component_class: "FacilityIdsFilter",
    //       authorized: true,
    //       visible: true,
    //       value: [12585, 12588, 12589, 12591],
    //       valueDisplay:
    //         "[Facility-12585, Facility-12588, Facility-12589, Facility-12591]",
    //       default: null,
    //       initial: null,
    //       options: {
    //         1: "Facility-1",
    //         6932: "Facility-6932",
    //         12585: "Facility-12585",
    //         12586: "Facility-12586",
    //         12587: "Facility-12587",
    //         12588: "Facility-12588",
    //         12589: "Facility-12589",
    //         12590: "Facility-12590",
    //         12591: "Facility-12591",
    //         12592: "Facility-12592",
    //         14473: "Facility-14473",
    //       },
    //       reloading: {
    //         peer_filters: ["customer_ids"],
    //         url: "https://local.swipesense.test/ajax/filter/getOptions/facility_ids_1412725da3c3cbe9ab878f6564b1c720?expires=1682635540&signature=a41cce04ff0e9a15da7b90caf6d4dbc935b50c21e846d1c43793d522b033b5ea",
    //       },
    //     },
    //     unit_ids: {
    //       _filter_component_class: "UnitIdsFilter",
    //       authorized: true,
    //       visible: true,
    //       value: null,
    //       valueDisplay: "All",
    //       default: null,
    //       initial: null,
    //       options: {
    //         "Facility-12585": {
    //           12740: "ED",
    //         },
    //         "Facility-12588": {
    //           12596: "ED",
    //           16653: "Endoscopy",
    //           16604: "ICU",
    //           16600: "LDRP",
    //           16603: "Med Surg",
    //           16599: "NICU",
    //           16654: "PACU",
    //           16652: "PCU",
    //           12598: "Pre-Op/Endo",
    //         },
    //         "Facility-12589": {
    //           14670: "ASU (Bay)",
    //           12733: "CCU",
    //           12728: "ED",
    //           14671: "Endo (Bay)",
    //           12729: "LDRP",
    //           12735: "MSU",
    //           12731: "PACU",
    //           12734: "PCU",
    //           12736: "STU",
    //         },
    //         "Facility-12591": {
    //           12605: "AAU",
    //           12609: "ASU/PACU",
    //           12608: "ED",
    //           12607: "LDRP Suite",
    //           12611: "MU2",
    //           12606: "SU1",
    //         },
    //       },
    //       reloading: {
    //         peer_filters: ["customer_ids", "facility_ids"],
    //         url: "https://local.swipesense.test/ajax/filter/getOptions/unit_ids_cf3ca128f211dad96e86c734dfbc783a?expires=1682635540&signature=d353ae42c38301cb98baaedb3aa3a2d02cdd7ec9b316fd8ccc58372f68e64871",
    //       },
    //     },
    //     group_ids: {
    //       _filter_component_class: "GroupIdsFilter",
    //       authorized: true,
    //       visible: true,
    //       value: [3803],
    //       valueDisplay: "Group-3803 (All)",
    //       default: null,
    //       initial: null,
    //       options: {
    //         "Facility-12585": {
    //           3803: "Group-3803 (All)",
    //           3804: "Group-3804 (All)",
    //           3810: "Group-3804 — Administration-3810",
    //           3811: "Group-3804 — Administration-3811",
    //           3805: "Group-3805 (All)",
    //           3815: "Group-3805 — Administration-3815",
    //           3816: "Group-3805 — Environmental Services (EVS)-3816",
    //           3818: "Group-3805 — Group-3818",
    //           3807: "Group-3807 (All)",
    //           3819: "Group-3807 — Group-3819",
    //           3820: "Group-3807 — Radiology-3820",
    //           3821: "Group-3807 — Radiology-3821",
    //           3806: "Nurses (RN)-3806 (All)",
    //           3808: "Techs-3808 (All)",
    //           3809: "Therapists-3809 (All)",
    //           3822: "Therapists-3809 — Therapists-3822",
    //         },
    //         "Facility-12585: ED": {
    //           3812: "Nurses (RN)-3812",
    //           3813: "Nurses (RN)-3813",
    //           3814: "Techs-3814",
    //         },
    //         "Facility-12588": {
    //           3864: "Administration-3864 (All)",
    //           3870: "Administration-3864 — Administration-3870",
    //           3871: "Administration-3864 — Administration-3871",
    //           4630: "Administration-3864 — Administration-4630",
    //           4631: "Administration-3864 — Administration-4631",
    //           3863: "Group-3863 (All)",
    //           3865: "Group-3865 (All)",
    //           3876: "Group-3865 — Environmental Services (EVS)-3876",
    //           4577: "Group-3865 — Food Services-4577",
    //           3875: "Group-3865 — Group-3875",
    //           4576: "Group-3865 — Spiritual Services-4576",
    //           3878: "Group-3865 — Transport-3878",
    //           3867: "Group-3867 (All)",
    //           3879: "Group-3867 — Group-3879",
    //           4595: "Group-3867 — Group-4595",
    //           4596: "Group-3867 — Group-4596",
    //           4634: "Group-3867 — Group-4634",
    //           4635: "Group-3867 — Group-4635",
    //           4597: "Group-3867 — Pharmacists-4597",
    //           3880: "Group-3867 — Radiology-3880",
    //           3881: "Group-3867 — Radiology-3881",
    //           3866: "Nurses (RN)-3866 (All)",
    //           4632: "Nurses (RN)-3866 — Nurses (RN)-4632",
    //           3868: "Techs-3868 (All)",
    //           4633: "Techs-3868 — Techs-4633",
    //           3869: "Therapists-3869 (All)",
    //           4579: "Therapists-3869 — Group-4579",
    //           4580: "Therapists-3869 — Group-4580",
    //           4581: "Therapists-3869 — Group-4581",
    //           4582: "Therapists-3869 — Group-4582",
    //           3882: "Therapists-3869 — Respiratory Therapists (RT)-3882",
    //         },
    //         "Facility-12588: ED": {
    //           3872: "Administration-3872",
    //           3873: "Nurses (RN)-3873",
    //           3874: "Techs-3874",
    //         },
    //         "Facility-12588: ICU": {
    //           4607: "Administration-4607",
    //           4608: "Nurses (RN)-4608",
    //           4609: "Techs-4609",
    //         },
    //         "Facility-12588: LDRP": {
    //           4575: "Administration-4575",
    //           4592: "Nurses (RN)-4592",
    //           4584: "Techs-4584",
    //         },
    //         "Facility-12588: NICU": {
    //           4612: "Administration-4612",
    //           4613: "Nurses (RN)-4613",
    //           4614: "Techs-4614",
    //         },
    //         "Facility-12588: PCU": {
    //           4601: "Administration-4601",
    //           4602: "Nurses (RN)-4602",
    //           4603: "Techs-4603",
    //         },
    //         "Facility-12588: Pre-Op/Endo": {
    //           4573: "Administration-4573",
    //           4610: "Nurses (RN)-4610",
    //           4611: "Techs-4611",
    //         },
    //         "Facility-12589": {
    //           3756: "Administration-3756 (All)",
    //           3762: "Administration-3756 — Administration-3762",
    //           3763: "Administration-3756 — Administration-3763",
    //           3708: "Group-3708 (All)",
    //           3757: "Group-3757 (All)",
    //           3766: "Group-3757 — Environmental Services (EVS)-3766",
    //           3765: "Group-3757 — Food Services-3765",
    //           3764: "Group-3757 — Group-3764",
    //           3768: "Group-3757 — Group-3768",
    //           3769: "Group-3757 — Group-3769",
    //           3759: "Group-3759 (All)",
    //           3791: "Group-3759 — Group-3791",
    //           3792: "Group-3759 — Group-3792",
    //           3793: "Group-3759 — Group-3793",
    //           3794: "Group-3759 — Pharmacists-3794",
    //           3796: "Group-3759 — Radiology-3796",
    //           3758: "Nurses (RN)-3758 (All)",
    //           3760: "Techs-3760 (All)",
    //           3761: "Therapists-3761 (All)",
    //           3801: "Therapists-3761 — Techs-3801",
    //           3798: "Therapists-3761 — Therapists-3798",
    //           3799: "Therapists-3761 — Therapists-3799",
    //           3800: "Therapists-3761 — Therapists-3800",
    //           3802: "Therapists-3761 — Therapists-3802",
    //         },
    //         "Facility-12589: ASU (Bay)": {
    //           4004: "Administration-4004",
    //           4005: "Nurses (RN)-4005",
    //           4006: "Techs-4006",
    //         },
    //         "Facility-12589: CCU": {
    //           3770: "Nurses (RN)-3770",
    //           3777: "Nurses (RN)-3777",
    //           3784: "Techs-3784",
    //         },
    //         "Facility-12589: ED": {
    //           3771: "Nurses (RN)-3771",
    //           3778: "Nurses (RN)-3778",
    //           3785: "Techs-3785",
    //         },
    //         "Facility-12589: Endo (Bay)": {
    //           4001: "Administration-4001",
    //           4002: "Nurses (RN)-4002",
    //           4003: "Techs-4003",
    //         },
    //         "Facility-12589: LDRP": {
    //           3772: "Nurses (RN)-3772",
    //           3779: "Nurses (RN)-3779",
    //           3786: "Techs-3786",
    //         },
    //         "Facility-12589: MSU": {
    //           3773: "Nurses (RN)-3773",
    //           3780: "Nurses (RN)-3780",
    //           3787: "Techs-3787",
    //         },
    //         "Facility-12589: PACU": {
    //           3775: "Nurses (RN)-3775",
    //           3782: "Nurses (RN)-3782",
    //           3789: "Techs-3789",
    //         },
    //         "Facility-12589: PCU": {
    //           3774: "Nurses (RN)-3774",
    //           3781: "Nurses (RN)-3781",
    //           3788: "Techs-3788",
    //         },
    //         "Facility-12589: STU": {
    //           3776: "Nurses (RN)-3776",
    //           3783: "Nurses (RN)-3783",
    //           3790: "Techs-3790",
    //         },
    //         "Facility-12591": {
    //           3712: "Administration-3712 (All)",
    //           3718: "Administration-3712 — Administration-3718",
    //           3719: "Administration-3712 — Administration-3719",
    //           3900: "Administration-3712 — Administration-3900",
    //           3711: "Group-3711 (All)",
    //           3713: "Group-3713 (All)",
    //           3722: "Group-3713 — Environmental Services (EVS)-3722",
    //           3721: "Group-3713 — Food Services-3721",
    //           3720: "Group-3713 — Group-3720",
    //           3724: "Group-3713 — Group-3724",
    //           3725: "Group-3713 — Group-3725",
    //           3715: "Group-3715 (All)",
    //           3749: "Group-3715 — Group-3749",
    //           3751: "Group-3715 — Group-3751",
    //           3754: "Group-3715 — Pharmacists-3754",
    //           3755: "Group-3715 — Phlebotomy-3755",
    //           3752: "Group-3715 — Radiology-3752",
    //           3753: "Group-3715 — Radiology-3753",
    //           3714: "Nurses (RN)-3714 (All)",
    //           3716: "Techs-3716 (All)",
    //           3717: "Therapists-3717 (All)",
    //           3747: "Therapists-3717 — Respiratory Therapists (RT)-3747",
    //           3744: "Therapists-3717 — Therapists-3744",
    //           3745: "Therapists-3717 — Therapists-3745",
    //           3746: "Therapists-3717 — Therapists-3746",
    //           3748: "Therapists-3717 — Therapists-3748",
    //         },
    //         "Facility-12591: AAU": {
    //           3726: "Nurses (RN)-3726",
    //           3732: "Nurses (RN)-3732",
    //           3738: "Techs-3738",
    //         },
    //         "Facility-12591: ASU/PACU": {
    //           3727: "Nurses (RN)-3727",
    //           3733: "Nurses (RN)-3733",
    //           3739: "Techs-3739",
    //         },
    //         "Facility-12591: ED": {
    //           3728: "Nurses (RN)-3728",
    //           3734: "Nurses (RN)-3734",
    //           3740: "Techs-3740",
    //         },
    //         "Facility-12591: LDRP Suite": {
    //           3729: "Nurses (RN)-3729",
    //           3735: "Nurses (RN)-3735",
    //           3741: "Techs-3741",
    //         },
    //         "Facility-12591: MU2": {
    //           3730: "Nurses (RN)-3730",
    //           3736: "Nurses (RN)-3736",
    //           3742: "Techs-3742",
    //         },
    //         "Facility-12591: SU1": {
    //           3731: "Nurses (RN)-3731",
    //           3737: "Nurses (RN)-3737",
    //           3743: "Techs-3743",
    //         },
    //       },
    //       reloading: {
    //         peer_filters: ["customer_ids", "facility_ids", "unit_ids"],
    //         url: "https://local.swipesense.test/ajax/filter/getOptions/group_ids_8d26e9cde770e1c947fc83ad7c36f902?expires=1682635540&signature=b69917487b0e404696ff92886262a5c0d04cf65d2a189668b891a61be1182e63",
    //       },
    //     },
    //     group_type_ids: {
    //       _filter_component_class: "GroupTypeIdsFilter",
    //       authorized: true,
    //       visible: true,
    //       value: null,
    //       valueDisplay: "All",
    //       default: null,
    //       initial: null,
    //       options: {
    //         11: "Administration",
    //         5: "Environmental Services (EVS)",
    //         3: "Facility Services",
    //         10: "Food Services",
    //         4: "Guests",
    //         8: "Midlevel Providers (MID/PA/NP)",
    //         0: "Nurses (RN)",
    //         12: "Pharmacists",
    //         14: "Phlebotomy",
    //         1: "Physicians",
    //         13: "Radiology",
    //         9: "Respiratory Therapists (RT)",
    //         6: "Social Workers",
    //         16: "Spiritual Services",
    //         2: "Techs",
    //         7: "Therapists",
    //         17: "Transport",
    //         15: "Visitors",
    //       },
    //       reloading: {
    //         peer_filters: [],
    //         url: "https://local.swipesense.test/ajax/filter/getOptions/group_type_ids_9996b2f56d84b0b76c0687c8f00e909f?expires=1682635540&signature=f3dfde0e9585b0f1f3cfb13c9d2642541cdb415a8c973debe88a00a5493c29a0",
    //       },
    //     },
    //     user_ids: {
    //       _filter_component_class: "UserIdsFilter",
    //       authorized: true,
    //       visible: true,
    //       value: null,
    //       valueDisplay: "All",
    //       default: null,
    //       initial: null,
    //       options: {
    //         73028: "Wendy Altenwerth [73028]",
    //         73000: "Lenka Bashirian [73000]",
    //         73024: "Cassidy Baumbach [73024]",
    //         75311: "David Becker [75311]",
    //         73072: "Jessica Beier [73072]",
    //         73079: "Anya Brakus [73079]",
    //         73025: "Laurrie Buckridge [73025]",
    //         73099: "Curshonda Cartwright [73099]",
    //         73008: "Matthew Cole [73008]",
    //         73044: "Alison Corwin [73044]",
    //         72996: "Amber Cremin [72996]",
    //         73073: "Kristen Dare [73073]",
    //         73084: "Charles Doyle [73084]",
    //         73098: "Robin Effertz [73098]",
    //         77055: "Jessica Fahey [77055]",
    //         73056: "Jayde Fisher [73056]",
    //         73017: "Andrew Frami [73017]",
    //         73050: "Marisa Franecki [73050]",
    //         73042: "Karen Friesen [73042]",
    //         73040: "Lillian Fritsch [73040]",
    //         73038: "Kayla Funk [73038]",
    //         76984: "Morgan Gerhold [76984]",
    //         73014: "Karen Gutkowski [73014]",
    //         73065: "Travis Gutkowski [73065]",
    //         73047: "Shea Haag [73047]",
    //         77091: "Sophia Haley [77091]",
    //         73093: "Nicole Hauck [73093]",
    //         79451: "Jazmyn Hegmann [79451]",
    //         73022: "Carrie Hirthe [73022]",
    //         73009: "Kevin Hoeger [73009]",
    //         73071: "Calandra Jakubowski [73071]",
    //         72994: "Kimberly Jast [72994]",
    //         73067: "Jonathan Johns [73067]",
    //         73082: "Richard Johns [73082]",
    //         73035: "Mackenzie Keebler [73035]",
    //         73091: "Tambra Kiehn [73091]",
    //         73041: "Whitney Kiehn [73041]",
    //         73060: "Rachel Koss [73060]",
    //         73046: "Traci Lehner [73046]",
    //         75312: "Sherrie Luettgen [75312]",
    //         73012: "Teresa Lynch [73012]",
    //         73045: "Serena McDermott [73045]",
    //         73020: "Amber McLaughlin [73020]",
    //         73097: "Launa Morissette [73097]",
    //         72999: "Brett Nader [72999]",
    //         73048: "Melba Nader [73048]",
    //         73011: "Nicole Nicolas [73011]",
    //         73074: "Nickeela O'Reilly [73074]",
    //         80648: "Nicole Padberg [80648]",
    //         73023: "Sanela Pfannerstill [73023]",
    //         73078: "Ashley Pouros [73078]",
    //         73070: "Thomas Purdy [73070]",
    //         72997: "Malia Ratke [72997]",
    //         73029: "Brooke Reichert [73029]",
    //         80014: "Linda Reilly [80014]",
    //         73049: "Michelle Reinger [73049]",
    //         73018: "Janie Rice [73018]",
    //         73002: "Ashley Robel [73002]",
    //         73032: "Courtney Rohan [73032]",
    //         79738: "Brandi Rolfson [79738]",
    //         73081: "Amy Roob [73081]",
    //         73096: "Destiny Ruecker [73096]",
    //         73100: "Graciela Sauer [73100]",
    //         72998: "Patricia Sawayn [72998]",
    //         73016: "Nichole Schmidt [73016]",
    //         93809: "Brooke Schultz [93809]",
    //         72993: "Keith Schuster [72993]",
    //         77053: "Nitza Schuster [77053]",
    //         73015: "Jody Spencer [73015]",
    //         73007: "Amber Stanton [73007]",
    //         73034: "Cherelle Swift [73034]",
    //         73019: "Henrietta Upton [73019]",
    //         73104: "Tina Von [73104]",
    //         73026: "Samantha Waters [73026]",
    //         73064: "Tracy Weber [73064]",
    //         73027: "Catherine Weissnat [73027]",
    //         79591: "Jax Weissnat [79591]",
    //         73066: "Angel Welch [73066]",
    //         73095: "Mario Wiegand [73095]",
    //         73052: "Matthew Wilkinson [73052]",
    //         92618: "Nadia Wilkinson [92618]",
    //         73033: "Sophia Wiza [73033]",
    //       },
    //       reloading: {
    //         peer_filters: [
    //           "customer_ids",
    //           "facility_ids",
    //           "unit_ids",
    //           "group_ids",
    //         ],
    //         url: "https://local.swipesense.test/ajax/filter/getOptions/user_ids_81cdbec92893c8bfb0dc1d2c6e489ea4?expires=1682635540&signature=6ba5a1fc892ccd8d3d0c070dea35d7e5c3a8ca466f4a958ff15aa25f8f1339a6",
    //       },
    //     },
    //     include_excluded_data: {
    //       _filter_component_class: "ReportIncludeExcludedDataFilter",
    //       authorized: true,
    //       visible: true,
    //       value: false,
    //       valueDisplay: "Exclude",
    //       default: false,
    //       initial: null,
    //       options: {
    //         1: "Include",
    //         0: "Exclude",
    //       },
    //       reloading: {
    //         peer_filters: [],
    //         url: "https://local.swipesense.test/ajax/filter/getOptions/include_excluded_data_4fc3a41231b894cd1f79add2b8c91bfd?expires=1682635540&signature=8808b812b59cbd88e5062edde67aad6205ee8f1cd8e982a25cc7f879a66fe9d9",
    //       },
    //     },
    //     include_partial_periods: {
    //       _filter_component_class: "ReportIncludePartialPeriodsFilter",
    //       authorized: true,
    //       visible: true,
    //       value: true,
    //       valueDisplay: "Yes (include the current partial day/week/month)",
    //       default: true,
    //       initial: null,
    //       options: {
    //         1: "Yes (include the current partial day/week/month)",
    //         0: "No (only include full days/weeks/months)",
    //       },
    //       reloading: {
    //         peer_filters: [],
    //         url: "https://local.swipesense.test/ajax/filter/getOptions/include_partial_periods_318184f2d036043802857ba8c07184ed?expires=1682635540&signature=65f8b019c7d8ee82c3ec2ac7ca7a005b1f1dda8a49935d3949142ca3afd169e3",
    //       },
    //     },
    //     sum_over_time_range: {
    //       _filter_component_class: "ReportSumOverTimeRangeFilter",
    //       authorized: true,
    //       visible: true,
    //       value: true,
    //       valueDisplay: "Yes, only one data point per Group",
    //       default: false,
    //       initial: null,
    //       options: {
    //         1: "Yes, only one data point per Group",
    //         0: "No, keep by day/week/month",
    //       },
    //       reloading: {
    //         peer_filters: [],
    //         url: "https://local.swipesense.test/ajax/filter/getOptions/sum_over_time_range_fe7ef2f93a92c3971db0e0d1949cde08?expires=1682635540&signature=0e2f3f80618421579329abbd8c91e0b2d2bcbd6cfe70ac5ba95417080a34ecff",
    //       },
    //     },
    //     aggregation: {
    //       _filter_component_class: "ReportAggregationFilter",
    //       authorized: true,
    //       visible: true,
    //       value: "monthly",
    //       valueDisplay: "Monthly",
    //       default: "weekly",
    //       initial: null,
    //       options: {
    //         daily: "Daily",
    //         weekly: "Weekly",
    //         monthly: "Monthly",
    //       },
    //       reloading: {
    //         peer_filters: [],
    //         url: "https://local.swipesense.test/ajax/filter/getOptions/aggregation_2f892ab3890bdb82d2295a61fe43fbbe?expires=1682635540&signature=630692ea3abd3dd9c584d79e0acd4e15355abdb99e719963dff0bad77246ce19",
    //       },
    //     },
    //     date_range: {
    //       _filter_component_class: "ReportDateRangeFilter",
    //       authorized: true,
    //       visible: true,
    //       value: ["DD", "2023-04-01", "2023-04-27"],
    //       valueDisplay: "4/1/2023–4/27/2023",
    //       default: ["DD", "2023-02-16", "2023-04-27"],
    //       initial: null,
    //       options: {
    //         "Days (rolling)": {
    //           "DR|offset_day|": "Last 1 Day",
    //           "DR|offset_day*2|": "Last 2 Days",
    //           "DR|offset_day*3|": "Last 3 Days",
    //           "DR|offset_day*4|": "Last 4 Days",
    //           "DR|offset_day*5|": "Last 5 Days",
    //           "DR|offset_day*6|": "Last 6 Days",
    //           "DR|offset_day*7|": "Last 7 Days",
    //           "DR|offset_day*14|": "Last 14 Days",
    //           "DR|offset_day*30|": "Last 30 Days",
    //           "DR|offset_day*90|": "Last 90 Days",
    //           "DR|offset_day*180|": "Last 180 Days",
    //         },
    //         "Weeks (rolling)": {
    //           "DR|offset_week|": "Last 1 Week",
    //           "DR|offset_week*2|": "Last 2 Weeks",
    //           "DR|offset_week*3|": "Last 3 Weeks",
    //           "DR|offset_week*4|": "Last 4 Weeks",
    //           "DR|offset_week*5|": "Last 5 Weeks",
    //           "DR|offset_week*6|": "Last 6 Weeks",
    //           "DR|offset_week*7|": "Last 7 Weeks",
    //           "DR|offset_week*8|": "Last 8 Weeks",
    //           "DR|offset_week*9|": "Last 9 Weeks",
    //           "DR|offset_week*10|": "Last 10 Weeks",
    //           "DR|offset_week*11|": "Last 11 Weeks",
    //           "DR|offset_week*12|": "Last 12 Weeks",
    //           "DR|offset_week*13|": "Last 13 Weeks",
    //           "DR|offset_week*14|": "Last 14 Weeks",
    //           "DR|offset_week*15|": "Last 15 Weeks",
    //           "DR|offset_week*16|": "Last 16 Weeks",
    //           "DR|offset_week*17|": "Last 17 Weeks",
    //           "DR|offset_week*18|": "Last 18 Weeks",
    //           "DR|offset_week*19|": "Last 19 Weeks",
    //           "DR|offset_week*20|": "Last 20 Weeks",
    //         },
    //         "Months (rolling)": {
    //           "DR|offset_month|": "Last 1 Month",
    //           "DR|offset_month*2|": "Last 2 Months",
    //           "DR|offset_month*3|": "Last 3 Months",
    //           "DR|offset_month*4|": "Last 4 Months",
    //           "DR|offset_month*5|": "Last 5 Months",
    //           "DR|offset_month*6|": "Last 6 Months",
    //           "DR|offset_month*7|": "Last 7 Months",
    //           "DR|offset_month*8|": "Last 8 Months",
    //           "DR|offset_month*9|": "Last 9 Months",
    //           "DR|offset_month*10|": "Last 10 Months",
    //           "DR|offset_month*11|": "Last 11 Months",
    //           "DR|offset_month*12|": "Last 12 Months",
    //         },
    //         "Years (rolling)": {
    //           "DR|offset_year|": "Last 1 Year",
    //           "DR|offset_year*2|": "Last 2 Years",
    //           "DR|offset_year*3|": "Last 3 Years",
    //           "DR|offset_year*4|": "Last 4 Years",
    //           "DR|offset_year*5|": "Last 5 Years",
    //           "DR|offset_year*6|": "Last 6 Years",
    //           "DR|offset_year*7|": "Last 7 Years",
    //           "DR|offset_year*8|": "Last 8 Years",
    //           "DR|offset_year*9|": "Last 9 Years",
    //           "DR|offset_year*10|": "Last 10 Years",
    //         },
    //         "Current-To-Date": {
    //           "DR|offset_S+day*0|": "Start of Day to Now",
    //           "DR|offset_S+week*0|": "Start of Week to Now (WTD)",
    //           "DR|offset_S+month*0|": "Start of Month to Now (MTD)",
    //           "DR|offset_S+quarter*0|": "Start of Quarter to Now (QTD)",
    //           "DR|offset_S+semester*0|":
    //             "Start of Semester (Half-year) to Now (SmTD)",
    //           "DR|offset_S+year*0|": "Start of Year to Now (YTD)",
    //         },
    //         "Previous-To-Date": {
    //           "DR|offset_S+day*1|": "Start of Previous Day to Now",
    //           "DR|offset_S+week*1|": "Start of Previous Week to Now",
    //           "DR|offset_S+month*1|": "Start of Previous Month to Now",
    //           "DR|offset_S+quarter*1|": "Start of Previous Quarter to Now",
    //           "DR|offset_S+semester*1|":
    //             "Start of Previous Semester (Half-year) to Now",
    //           "DR|offset_S+year*1|": "Start of Previous Year to Now",
    //         },
    //         "From-Date": {
    //           "DR|set_-01-01_00:00:00|": "Last January 1st to Now",
    //           "DR|set_-02-01_00:00:00|": "Last February 1st to Now",
    //           "DR|set_-03-01_00:00:00|": "Last March 1st to Now",
    //           "DR|set_-04-01_00:00:00|": "Last April 1st to Now",
    //           "DR|set_-05-01_00:00:00|": "Last May 1st to Now",
    //           "DR|set_-06-01_00:00:00|": "Last June 1st to Now",
    //           "DR|set_-07-01_00:00:00|": "Last July 1st to Now",
    //           "DR|set_-08-01_00:00:00|": "Last August 1st to Now",
    //           "DR|set_-09-01_00:00:00|": "Last September 1st to Now",
    //           "DR|set_-10-01_00:00:00|": "Last October 1st to Now",
    //           "DR|set_-11-01_00:00:00|": "Last November 1st to Now",
    //           "DR|set_-12-01_00:00:00|": "Last December 1st to Now",
    //         },
    //         "Previous Period": {
    //           "DR|offset_S+day*1|offset_E+day*1": "Previous Complete Day",
    //           "DR|offset_S+week*1|offset_E+week*1": "Previous Complete Week",
    //           "DR|offset_S+month*1|offset_E+month*1": "Previous Complete Month",
    //           "DR|offset_S+quarter*1|offset_E+quarter*1":
    //             "Previous Complete Quarter",
    //           "DR|offset_S+semester*1|offset_E+semester*1":
    //             "Previous Complete Semester (Half-year)",
    //           "DR|offset_S+year*1|offset_E+year*1": "Previous Complete Year",
    //         },
    //       },
    //       reloading: {
    //         peer_filters: [],
    //         url: "https://local.swipesense.test/ajax/filter/getOptions/date_range_d20b80b2223df33994890dff52483a1c?expires=1682635540&signature=4769f9a85422d09b6ff4b395896a5f5b14e316e2517bf70ea548090748f27b95",
    //       },
    //     },
    //   },
    //   handHygienePlot: {
    //     dataSeries: [
    //       {
    //         name: "<i>Selected Providers Combined</i>",
    //         data: [
    //           {
    //             x: 1677456000000,
    //             y: 0.6419294990723562,
    //             percentage: 64,
    //             opportunities: 539,
    //             name: "2/27/2023–4/26/2023<br>Opportunities: 539",
    //             performance_delta: 0.452054794520548,
    //           },
    //         ],
    //         showInLegend: true,
    //         visible: true,
    //         legendType: "selectedCombined",
    //       },
    //       {
    //         name: "<i>Selected Providers Individually</i>",
    //         data: [],
    //         showInLegend: true,
    //         visible: false,
    //         groupTag: "_chshReport_individualItems_users",
    //         onToggle: "toggleGroup",
    //         legendType: "selectedIndividualToggleAll",
    //       },
    //       {
    //         name: "Cheryl Abernathy",
    //         data: [
    //           {
    //             x: 1677456000000,
    //             y: 0.6528599605522682,
    //             percentage: 65,
    //             opportunities: 507,
    //             name: "2/27/2023–4/26/2023<br>Opportunities: 507",
    //             performance_delta: 0.15205479452054793,
    //           },
    //         ],
    //         showInLegend: true,
    //         visible: true,
    //         groupTag: "_chshReport_individualItems_users",
    //         legendType: "selectedIndividual",
    //       },
    //       {
    //         name: "Dakota Abbott",
    //         data: [
    //           {
    //             x: 1677456000000,
    //             y: 0.46875,
    //             percentage: 47,
    //             opportunities: 32,
    //             name: "2/27/2023–4/26/2023<br>Opportunities: 32",
    //             performance_delta: 0,
    //           },
    //         ],
    //         showInLegend: true,
    //         visible: true,
    //         groupTag: "_chshReport_individualItems_users",
    //         legendType: "selectedIndividual",
    //       },
    //       {
    //         name: "Sandra Abernathy",
    //         data: [
    //           {
    //             x: 1677456000000,
    //             y: 0,
    //             percentage: 0,
    //             opportunities: 0,
    //             name: "2/27/2023–4/26/2023<br>Opportunities: 0",
    //             performance_delta: null,
    //           },
    //         ],
    //         showInLegend: true,
    //         visible: true,
    //         groupTag: "_chshReport_individualItems_users",
    //         legendType: "selectedIndividual",
    //       },
    //       {
    //         name: "Tabitha Abernathy",
    //         data: [
    //           {
    //             x: 1677456000000,
    //             y: 0,
    //             percentage: 0,
    //             opportunities: 0,
    //             name: "2/27/2023–4/26/2023<br>Opportunities: 0",
    //             performance_delta: null,
    //           },
    //         ],
    //         showInLegend: true,
    //         visible: true,
    //         groupTag: "_chshReport_individualItems_users",
    //         legendType: "selectedIndividual",
    //       },
    //     ],
    //     plotOptions: null,
    //     helpText: "This graph shows Providers' Hand Hygiene (HH) in percent.",
    //     extraJavascript: "",
    //     isExportable: true,
    //   },
    // };
    _report: {
      uuid: "990a2916-cae0-4c8c-a40b-faf90cfd804c",
      title: "Individual Trend Report",
    },
    _export: {
      url: "https://local.swipesense.test/report/de/990a2916-cae0-4c8c-a40b-faf90cfd804c?expires=1682776524&signature=454559ad32aa93b0ce892d90411a556a46e201564e47ce315644c1a1a64e5b14",
    },
    _user: {
      id: 95801,
      is_admin: true,
    },
    _error: [],
    _request: {
      is_signed: false,
      is_spa: false,
    },
    _params: {
      customer_ids: {
        _filter_component_class: "CustomerIdsFilter",
        authorized: true,
        visible: true,
        value: [32, 16, 1],
        valueDisplay:
          "[Barometers Healthcare (32), Changing Appetites Filling Co. (16), Claxon Suds Healthcare (1)]",
        default: null,
        initial: [1],
        options: {
          12: "Afib Navigators Health Center (12)",
          32: "Barometers Healthcare (32)",
          31: "Caffeinated Meditation Center (31)",
          16: "Changing Appetites Filling Co. (16)",
          1: "Claxon Suds Healthcare (1)",
          33: "Covalent Points Pediatric (33)",
          19: "Dunblane Regional Medical Center (19)",
          10: "Ebenezer Health System (10)",
          28: "Enigmatic Inventor Conventional (28)",
          15: "Floral University Health Care (15)",
          17: "Fourth Valence Vineyards Medical (17)",
          25: "Franny May Health Bureau (25)",
          20: "Healthy McGrasslands Medical Corporation (20)",
          18: "Monsters Inc Ministry (18)",
          2: "North Life Hospital System (2)",
          24: "Philharmonic Plato Health (24)",
          34: "Piece de Resistance Health (34)",
          29: "Preeminent Christian Charitable (29)",
          11: "Saintly Joan Medical (11)",
          27: "Salient Foliage Health (27)",
          26: "Serenading Seas Psychological (26)",
          13: "Tamaflu Medical (13)",
          22: "Triceratops Healthcare (22)",
          21: "Uplifting Radiance Healthcare (21)",
          23: "Varnon Association of Westerly Plages (23)",
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/customer_ids_2177c0e9c23bea7e419ee627b10f9c9d?expires=1682700924&signature=c8ad9f703745e5ad20276f8a563f069e048cb945bfaf0446e952ce134a265536",
        },
      },
      facility_ids: {
        _filter_component_class: "FacilityIdsFilter",
        authorized: true,
        visible: true,
        value: [6932, 12585],
        valueDisplay: "[Facility-6932, Facility-12585]",
        default: null,
        initial: null,
        options: {
          1: "Facility-1",
          1876: "Facility-1876",
          6932: "Facility-6932",
          12585: "Facility-12585",
          12586: "Facility-12586",
          12587: "Facility-12587",
          12588: "Facility-12588",
          12589: "Facility-12589",
          12590: "Facility-12590",
          12591: "Facility-12591",
          12592: "Facility-12592",
          14473: "Facility-14473",
        },
        reloading: {
          peer_filters: ["customer_ids"],
          url: "https://local.swipesense.test/ajax/filter/getOptions/facility_ids_7368b177a1280392344e67fd47c8dfdd?expires=1682700924&signature=da790d4029a33eab4279467e9b8e4a05d46d005c16c8147be93f10158bc82f68",
        },
      },
      unit_ids: {
        _filter_component_class: "UnitIdsFilter",
        authorized: true,
        visible: true,
        value: null,
        valueDisplay: "All",
        default: null,
        initial: null,
        options: {
          "Barometers Healthcare (32): Facility-12585": {
            12740: "ED",
          },
          "Claxon Suds Healthcare (1): Facility-6932": {
            6933: "Speedy's Office",
          },
        },
        reloading: {
          peer_filters: ["customer_ids", "facility_ids"],
          url: "https://local.swipesense.test/ajax/filter/getOptions/unit_ids_883864499025cf0469e8cf1d68642796?expires=1682700924&signature=a7966d51025e4fe9fe4294b8aaba52ed9170f3e403a2ab001840b11a5fe9b964",
        },
      },
      group_ids: {
        _filter_component_class: "GroupIdsFilter",
        authorized: true,
        visible: true,
        value: null,
        valueDisplay: "All",
        default: null,
        initial: null,
        options: {
          "Facility-12585": {
            3803: "Group-3803 (All)",
            3804: "Group-3804 (All)",
            3810: "Group-3804 — Administration-3810",
            3811: "Group-3804 — Administration-3811",
            3805: "Group-3805 (All)",
            3815: "Group-3805 — Administration-3815",
            3816: "Group-3805 — Environmental Services (EVS)-3816",
            3818: "Group-3805 — Group-3818",
            3807: "Group-3807 (All)",
            3819: "Group-3807 — Group-3819",
            3820: "Group-3807 — Radiology-3820",
            3821: "Group-3807 — Radiology-3821",
            3806: "Nurses (RN)-3806 (All)",
            3808: "Techs-3808 (All)",
            3809: "Therapists-3809 (All)",
            3822: "Therapists-3809 — Therapists-3822",
          },
          "Facility-12585: ED": {
            3812: "Nurses (RN)-3812",
            3813: "Nurses (RN)-3813",
            3814: "Techs-3814",
          },
        },
        reloading: {
          peer_filters: ["customer_ids", "facility_ids", "unit_ids"],
          url: "https://local.swipesense.test/ajax/filter/getOptions/group_ids_63c6a2cb4615f3c3a5e692b511898d6c?expires=1682700924&signature=03e4c930cfcf2e3a9d932e6742efee308757c256d20689e88b7e925056d1e2c5",
        },
      },
      group_type_ids: {
        _filter_component_class: "GroupTypeIdsFilter",
        authorized: true,
        visible: true,
        value: null,
        valueDisplay: "All",
        default: null,
        initial: null,
        options: {
          11: "Administration",
          5: "Environmental Services (EVS)",
          3: "Facility Services",
          10: "Food Services",
          4: "Guests",
          8: "Midlevel Providers (MID/PA/NP)",
          0: "Nurses (RN)",
          12: "Pharmacists",
          14: "Phlebotomy",
          1: "Physicians",
          13: "Radiology",
          9: "Respiratory Therapists (RT)",
          6: "Social Workers",
          16: "Spiritual Services",
          2: "Techs",
          7: "Therapists",
          17: "Transport",
          15: "Visitors",
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/group_type_ids_9996b2f56d84b0b76c0687c8f00e909f?expires=1682700924&signature=0254f5231dcf96274e353f6a99294941c671aedae2515a71d826b131309a4e46",
        },
      },
      user_ids: {
        _filter_component_class: "UserIdsFilter",
        authorized: true,
        visible: true,
        value: null,
        valueDisplay: "All",
        default: null,
        initial: null,
        options: {
          73028: "Wendy Altenwerth [73028]",
          73000: "Lenka Bashirian [73000]",
          73024: "Cassidy Baumbach [73024]",
          75311: "David Becker [75311]",
          73072: "Jessica Beier [73072]",
          73079: "Anya Brakus [73079]",
          73025: "Laurrie Buckridge [73025]",
          73099: "Curshonda Cartwright [73099]",
          73008: "Matthew Cole [73008]",
          73044: "Alison Corwin [73044]",
          72996: "Amber Cremin [72996]",
          73073: "Kristen Dare [73073]",
          73084: "Charles Doyle [73084]",
          73098: "Robin Effertz [73098]",
          77055: "Jessica Fahey [77055]",
          73056: "Jayde Fisher [73056]",
          73017: "Andrew Frami [73017]",
          73050: "Marisa Franecki [73050]",
          73042: "Karen Friesen [73042]",
          73040: "Lillian Fritsch [73040]",
          73038: "Kayla Funk [73038]",
          76984: "Morgan Gerhold [76984]",
          73014: "Karen Gutkowski [73014]",
          73065: "Travis Gutkowski [73065]",
          73047: "Shea Haag [73047]",
          77091: "Sophia Haley [77091]",
          73093: "Nicole Hauck [73093]",
          79451: "Jazmyn Hegmann [79451]",
          73022: "Carrie Hirthe [73022]",
          73009: "Kevin Hoeger [73009]",
          73071: "Calandra Jakubowski [73071]",
          72994: "Kimberly Jast [72994]",
          73067: "Jonathan Johns [73067]",
          73082: "Richard Johns [73082]",
          73035: "Mackenzie Keebler [73035]",
          73091: "Tambra Kiehn [73091]",
          73041: "Whitney Kiehn [73041]",
          73060: "Rachel Koss [73060]",
          73046: "Traci Lehner [73046]",
          75312: "Sherrie Luettgen [75312]",
          73012: "Teresa Lynch [73012]",
          73045: "Serena McDermott [73045]",
          73020: "Amber McLaughlin [73020]",
          73097: "Launa Morissette [73097]",
          72999: "Brett Nader [72999]",
          73048: "Melba Nader [73048]",
          73011: "Nicole Nicolas [73011]",
          73074: "Nickeela O'Reilly [73074]",
          80648: "Nicole Padberg [80648]",
          73023: "Sanela Pfannerstill [73023]",
          73078: "Ashley Pouros [73078]",
          73070: "Thomas Purdy [73070]",
          72997: "Malia Ratke [72997]",
          73029: "Brooke Reichert [73029]",
          80014: "Linda Reilly [80014]",
          73049: "Michelle Reinger [73049]",
          73018: "Janie Rice [73018]",
          73002: "Ashley Robel [73002]",
          73032: "Courtney Rohan [73032]",
          79738: "Brandi Rolfson [79738]",
          73081: "Amy Roob [73081]",
          73096: "Destiny Ruecker [73096]",
          73100: "Graciela Sauer [73100]",
          72998: "Patricia Sawayn [72998]",
          73016: "Nichole Schmidt [73016]",
          93809: "Brooke Schultz [93809]",
          72993: "Keith Schuster [72993]",
          77053: "Nitza Schuster [77053]",
          73015: "Jody Spencer [73015]",
          73007: "Amber Stanton [73007]",
          73034: "Cherelle Swift [73034]",
          73019: "Henrietta Upton [73019]",
          73104: "Tina Von [73104]",
          73026: "Samantha Waters [73026]",
          73064: "Tracy Weber [73064]",
          73027: "Catherine Weissnat [73027]",
          79591: "Jax Weissnat [79591]",
          73066: "Angel Welch [73066]",
          73095: "Mario Wiegand [73095]",
          73052: "Matthew Wilkinson [73052]",
          92618: "Nadia Wilkinson [92618]",
          73033: "Sophia Wiza [73033]",
        },
        reloading: {
          peer_filters: [
            "customer_ids",
            "facility_ids",
            "unit_ids",
            "group_ids",
          ],
          url: "https://local.swipesense.test/ajax/filter/getOptions/user_ids_c4c15bc8b36943030626fc2affb47e7a?expires=1682700924&signature=559cca4aafdd4efbde2444854a1e433c6bf52e86a4e337e2f8d9797c478d5acc",
        },
      },
      include_excluded_data: {
        _filter_component_class: "ReportIncludeExcludedDataFilter",
        authorized: true,
        visible: true,
        value: false,
        valueDisplay: "Exclude",
        default: false,
        initial: null,
        options: {
          1: "Include",
          0: "Exclude",
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/include_excluded_data_4fc3a41231b894cd1f79add2b8c91bfd?expires=1682700924&signature=0a4147ca57cf0567cdff9bbbf64e185cd4f0b2c738a20224cb2450fd43e5e4f0",
        },
      },
      include_partial_periods: {
        _filter_component_class: "ReportIncludePartialPeriodsFilter",
        authorized: true,
        visible: true,
        value: true,
        valueDisplay: "Yes (include the current partial day/week/month)",
        default: true,
        initial: null,
        options: {
          1: "Yes (include the current partial day/week/month)",
          0: "No (only include full days/weeks/months)",
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/include_partial_periods_318184f2d036043802857ba8c07184ed?expires=1682700924&signature=635763b72bb525ffdaac88e0b2c6f4d65a104c1cbb2a48498d600ac6e247c34a",
        },
      },
      sum_over_time_range: {
        _filter_component_class: "ReportSumOverTimeRangeFilter",
        authorized: true,
        visible: true,
        value: true,
        valueDisplay: "Yes, only one data point per Group",
        default: false,
        initial: null,
        options: {
          1: "Yes, only one data point per Group",
          0: "No, keep by day/week/month",
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/sum_over_time_range_fe7ef2f93a92c3971db0e0d1949cde08?expires=1682700924&signature=c9294d0b6522862c656ab1f57b416d8aee5b09a9c490f23ab5f1fb9af7183d7f",
        },
      },
      aggregation: {
        _filter_component_class: "ReportAggregationFilter",
        authorized: true,
        visible: true,
        value: "weekly",
        valueDisplay: "Weekly",
        default: "weekly",
        initial: null,
        options: {
          daily: "Daily",
          weekly: "Weekly",
          monthly: "Monthly",
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/aggregation_2f892ab3890bdb82d2295a61fe43fbbe?expires=1682700924&signature=5e881b5d85876c690b569962aefc40a2ee3aa393c2a14ab45187549ddb11a7c8",
        },
      },
      date_range: {
        _filter_component_class: "ReportDateRangeFilter",
        authorized: true,
        visible: true,
        value: ["DD", "2023-02-13", "2023-04-28"],
        valueDisplay: "2/13/2023–4/28/2023",
        default: ["DD", "2023-02-17", "2023-04-28"],
        initial: null,
        options: {
          "Days (rolling)": {
            "DR|offset_day|": "Last 1 Day",
            "DR|offset_day*2|": "Last 2 Days",
            "DR|offset_day*3|": "Last 3 Days",
            "DR|offset_day*4|": "Last 4 Days",
            "DR|offset_day*5|": "Last 5 Days",
            "DR|offset_day*6|": "Last 6 Days",
            "DR|offset_day*7|": "Last 7 Days",
            "DR|offset_day*14|": "Last 14 Days",
            "DR|offset_day*30|": "Last 30 Days",
            "DR|offset_day*90|": "Last 90 Days",
            "DR|offset_day*180|": "Last 180 Days",
          },
          "Weeks (rolling)": {
            "DR|offset_week|": "Last 1 Week",
            "DR|offset_week*2|": "Last 2 Weeks",
            "DR|offset_week*3|": "Last 3 Weeks",
            "DR|offset_week*4|": "Last 4 Weeks",
            "DR|offset_week*5|": "Last 5 Weeks",
            "DR|offset_week*6|": "Last 6 Weeks",
            "DR|offset_week*7|": "Last 7 Weeks",
            "DR|offset_week*8|": "Last 8 Weeks",
            "DR|offset_week*9|": "Last 9 Weeks",
            "DR|offset_week*10|": "Last 10 Weeks",
            "DR|offset_week*11|": "Last 11 Weeks",
            "DR|offset_week*12|": "Last 12 Weeks",
            "DR|offset_week*13|": "Last 13 Weeks",
            "DR|offset_week*14|": "Last 14 Weeks",
            "DR|offset_week*15|": "Last 15 Weeks",
            "DR|offset_week*16|": "Last 16 Weeks",
            "DR|offset_week*17|": "Last 17 Weeks",
            "DR|offset_week*18|": "Last 18 Weeks",
            "DR|offset_week*19|": "Last 19 Weeks",
            "DR|offset_week*20|": "Last 20 Weeks",
          },
          "Months (rolling)": {
            "DR|offset_month|": "Last 1 Month",
            "DR|offset_month*2|": "Last 2 Months",
            "DR|offset_month*3|": "Last 3 Months",
            "DR|offset_month*4|": "Last 4 Months",
            "DR|offset_month*5|": "Last 5 Months",
            "DR|offset_month*6|": "Last 6 Months",
            "DR|offset_month*7|": "Last 7 Months",
            "DR|offset_month*8|": "Last 8 Months",
            "DR|offset_month*9|": "Last 9 Months",
            "DR|offset_month*10|": "Last 10 Months",
            "DR|offset_month*11|": "Last 11 Months",
            "DR|offset_month*12|": "Last 12 Months",
          },
          "Years (rolling)": {
            "DR|offset_year|": "Last 1 Year",
            "DR|offset_year*2|": "Last 2 Years",
            "DR|offset_year*3|": "Last 3 Years",
            "DR|offset_year*4|": "Last 4 Years",
            "DR|offset_year*5|": "Last 5 Years",
            "DR|offset_year*6|": "Last 6 Years",
            "DR|offset_year*7|": "Last 7 Years",
            "DR|offset_year*8|": "Last 8 Years",
            "DR|offset_year*9|": "Last 9 Years",
            "DR|offset_year*10|": "Last 10 Years",
          },
          "Current-To-Date": {
            "DR|offset_S+day*0|": "Start of Day to Now",
            "DR|offset_S+week*0|": "Start of Week to Now (WTD)",
            "DR|offset_S+month*0|": "Start of Month to Now (MTD)",
            "DR|offset_S+quarter*0|": "Start of Quarter to Now (QTD)",
            "DR|offset_S+semester*0|":
              "Start of Semester (Half-year) to Now (SmTD)",
            "DR|offset_S+year*0|": "Start of Year to Now (YTD)",
          },
          "Previous-To-Date": {
            "DR|offset_S+day*1|": "Start of Previous Day to Now",
            "DR|offset_S+week*1|": "Start of Previous Week to Now",
            "DR|offset_S+month*1|": "Start of Previous Month to Now",
            "DR|offset_S+quarter*1|": "Start of Previous Quarter to Now",
            "DR|offset_S+semester*1|":
              "Start of Previous Semester (Half-year) to Now",
            "DR|offset_S+year*1|": "Start of Previous Year to Now",
          },
          "From-Date": {
            "DR|set_-01-01_00:00:00|": "Last January 1st to Now",
            "DR|set_-02-01_00:00:00|": "Last February 1st to Now",
            "DR|set_-03-01_00:00:00|": "Last March 1st to Now",
            "DR|set_-04-01_00:00:00|": "Last April 1st to Now",
            "DR|set_-05-01_00:00:00|": "Last May 1st to Now",
            "DR|set_-06-01_00:00:00|": "Last June 1st to Now",
            "DR|set_-07-01_00:00:00|": "Last July 1st to Now",
            "DR|set_-08-01_00:00:00|": "Last August 1st to Now",
            "DR|set_-09-01_00:00:00|": "Last September 1st to Now",
            "DR|set_-10-01_00:00:00|": "Last October 1st to Now",
            "DR|set_-11-01_00:00:00|": "Last November 1st to Now",
            "DR|set_-12-01_00:00:00|": "Last December 1st to Now",
          },
          "Previous Period": {
            "DR|offset_S+day*1|offset_E+day*1": "Previous Complete Day",
            "DR|offset_S+week*1|offset_E+week*1": "Previous Complete Week",
            "DR|offset_S+month*1|offset_E+month*1": "Previous Complete Month",
            "DR|offset_S+quarter*1|offset_E+quarter*1":
              "Previous Complete Quarter",
            "DR|offset_S+semester*1|offset_E+semester*1":
              "Previous Complete Semester (Half-year)",
            "DR|offset_S+year*1|offset_E+year*1": "Previous Complete Year",
          },
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/date_range_ffb5dfd47714d2097ad36eebf5f6886c?expires=1682700924&signature=f428de033c153d2a461835ee075a43dc8d8d111df328d5d5f0f22feef306bd88",
        },
      },
    },
    reportSubtitle: {
      _component_class: "ReportSubtitle",
      displayTime: false,
      endDate: "2023-04-28T13:55:24.223732Z",
      lastEtlAtMessage: null,
      startDate: "2023-02-13T05:00:00.000000Z",
      text: "Alison Corwin, Amber Cremin, Amber McLaughlin, Amber Stanton, Amy Roob, Andrew Frami, Angel Welch, Anya Brakus, Ashley Pouros, Ashley Robel, Brandi Rolfson, Brett Nader, Brooke Reichert, Brooke Schultz, Calandra Jakubowski, Carrie Hirthe, Cassidy Baumbach, Catherine Weissnat, Charles Doyle, Cherelle Swift, Courtney Rohan, Curshonda Cartwright, David Becker, Destiny Ruecker, Graciela Sauer, Henrietta Upton, Janie Rice, Jax Weissnat, Jayde Fisher, Jazmyn Hegmann, Jessica Beier, Jessica Fahey, Jody Spencer, Jonathan Johns, Karen Friesen, Karen Gutkowski, Kayla Funk, Keith Schuster, Kevin Hoeger, Kimberly Jast, Kristen Dare, Launa Morissette, Laurrie Buckridge, Lenka Bashirian, Lillian Fritsch, Linda Reilly, Mackenzie Keebler, Malia Ratke, Mario Wiegand, Marisa Franecki, Matthew Cole, Matthew Wilkinson, Melba Nader, Michelle Reinger, Morgan Gerhold, Nadia Wilkinson, Nichole Schmidt, Nickeela O'Reilly, Nicole Hauck, Nicole Nicolas, Nicole Padberg, Nitza Schuster, Patricia Sawayn, Rachel Koss, Richard Johns, Robin Effertz, Samantha Waters, Sanela Pfannerstill, Serena McDermott, Shea Haag, Sherrie Luettgen, Sophia Haley, Sophia Wiza, Tambra Kiehn, Teresa Lynch, Thomas Purdy, Tina Von, Traci Lehner, Tracy Weber, Travis Gutkowski, Wendy Altenwerth, and Whitney Kiehn",
      componentName: null,
      attributes: {},
      setGroupNames: {},
      setLastEtlAtDate: {},
      jsonSerialize: {},
      resolve: {},
      flushCache: {},
      forgetFactory: {},
      forgetComponentsResolver: {},
      resolveComponentsUsing: {},
    },
    summaryBlocks: {
      _component_class: "SummaryTiles",
      tiles: [
        {
          _component_class: "PerformanceOverall",
          performance: 0.3364563635321758,
          subtitle: "In This Time Period",
          panelType: "warning",
          panelWidth: 4,
          componentName: null,
          attributes: {},
          getPanelTypeFromPerformance: {},
          jsonSerialize: {},
          resolve: {},
          flushCache: {},
          forgetFactory: {},
          forgetComponentsResolver: {},
          resolveComponentsUsing: {},
        },
        {
          _component_class: "PerformanceChanged",
          icon: "exclamation-triangle",
          percentChanged: -0.13095074455899197,
          percentChangedFormatted: "-13%",
          subtitle: "2/13/2023 to 4/28/2023",
          panelType: "warning",
          panelWidth: 4,
          componentName: null,
          attributes: {},
          formatPercentChanged: {},
          formatSubtitle: {},
          setIcon: {},
          getPanelTypeFromPerformance: {},
          jsonSerialize: {},
          resolve: {},
          flushCache: {},
          forgetFactory: {},
          forgetComponentsResolver: {},
          resolveComponentsUsing: {},
        },
        {
          _component_class: "EventCounts",
          events: 11743,
          opportunities: 34902,
          panelType: "warning",
          panelWidth: 4,
          componentName: null,
          attributes: {},
          getPanelTypeFromPerformance: {},
          jsonSerialize: {},
          resolve: {},
          flushCache: {},
          forgetFactory: {},
          forgetComponentsResolver: {},
          resolveComponentsUsing: {},
        },
      ],
      componentName: null,
      attributes: {},
      addSummaryTile: {},
      jsonSerialize: {},
      resolve: {},
      flushCache: {},
      forgetFactory: {},
      forgetComponentsResolver: {},
      resolveComponentsUsing: {},
    },
    handHygienePlot: {
      _graph_class: "PercentageColumnGraph",
      chartId: "chart_e114633a03",
      chartTestKey: "Providers' Hand Hygiene Performance Overall",
      chartType: "column",
      titleText: "Providers' Hand Hygiene Performance Overall",
      subtitle: null,
      xAxis: {
        _plot_component_class: "AxisWithoutTicks",
        title: {
          text: "",
          style: {
            fontSize: "15px",
          },
        },
        tickPositions: [],
      },
      yAxis: {
        _plot_component_class: "PercentageAxis",
        title: {
          text: "Performance (%)",
          style: {
            fontSize: "15px",
          },
        },
        min: 0,
        max: 1,
        floor: 0,
        ceiling: 1,
        labels:
          "{@JS}{'formatter': function() { return (this.value * 100) + \" % \"; }}",
        tickPositions:
          "{@JS}window.HighchartsHelper.getTickPositions(1.0, true)",
      },
      xAxisPlotlines: "[]",
      yAxisPlotlines:
        '[{"series":{"name":"Baseline: F12585","data":[],"visible":false,"showInLegend":true,"legendIndex":-10,"type":"area","color":"red"}, "lines": [{"value":0.34,"label":{"align":"left","style":{"color":"red"},"text":"<i> Baseline:<\\/i> 34%","x":2,"y":15,"rotation":0},"zIndex":2.1,"color":"red","dashStyle":"longdashdot","width":1.5}]},{"series":{"name":"Hand Hygiene Goal","data":[],"visible":true,"showInLegend":true,"legendIndex":-99,"type":"area","color":"gold"}, "lines": []}]',
      showYearBoundaries: false,
      showColorBands: true,
      tooltip: {
        _plot_component_class: "PercentageTooltip",
        pointFormat: "{@JS}window.HighchartsPointFormats.specifiedPercentage",
      },
      dataSeries: [
        {
          name: "<i>Selected Providers Combined</i>",
          data: [
            {
              x: 1676246400000,
              y: 0.3364563635321758,
              percentage: 34,
              opportunities: 34902,
              name: "2/13/2023–4/28/2023<br>Opportunities: 34,902",
              performance_delta: -0.13095074455899197,
            },
          ],
          showInLegend: true,
          visible: true,
          legendType: "selectedCombined",
        },
        {
          name: "<i>Selected Providers Individually</i>",
          data: [],
          showInLegend: true,
          visible: false,
          groupTag: "_chshReport_individualItems_users",
          onToggle: "toggleGroup",
          legendType: "selectedIndividualToggleAll",
        },
        {
          name: "Someone who has more than 1 set of data",
          data: [
            {
              x: 1676246400000,
              y: 0.48154657293497366,
              percentage: 48.32432432,
              opportunities: 569,
              name: "2/13/2023–4/28/2023<br>Opportunities: 569",
              performance_delta: 0.0444444444444444,
            },
            {
              x: 1676246400000,
              y: 0.48154657293497366,
              percentage: 98.4444,
              opportunities: 569.555,
              name: "2/13/2023–4/28/2023<br>Opportunities: 569",
              performance_delta: 0.0444444444444444,
            },
            {
              x: 1676246400000,
              y: 0.48154657293497366,
              percentage: 48.9999,
              opportunities: 569,
              name: "2/13/2023–4/28/2023<br>Opportunities: 569",
              performance_delta: 0.0444444444444444,
            },
            {
              x: 1676246400000,
              y: 0.48154657293497366,
              percentage: 48.66662332,
              opportunities: 569,
              name: "2/13/2023–4/28/2023<br>Opportunities: 569",
              performance_delta: 0.0444444444444444,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Alison Corwin",
          data: [
            {
              x: 1676246400000,
              y: 0.48154657293497366,
              percentage: 48,
              opportunities: 569,
              name: "2/13/2023–4/28/2023<br>Opportunities: 569",
              performance_delta: 0.0444444444444444,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Amber Cremin",
          data: [
            {
              x: 1676246400000,
              y: 0.4521857923497268,
              percentage: 45,
              opportunities: 732,
              name: "2/13/2023–4/28/2023<br>Opportunities: 732",
              performance_delta: -0.0773955773955774,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Amber McLaughlin",
          data: [
            {
              x: 1676246400000,
              y: 0.34236804564907275,
              percentage: 34,
              opportunities: 701,
              name: "2/13/2023–4/28/2023<br>Opportunities: 701",
              performance_delta: -0.19361121932216593,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Amber Stanton",
          data: [
            {
              x: 1676246400000,
              y: 0.3157894736842105,
              percentage: 32,
              opportunities: 38,
              name: "2/13/2023–4/28/2023<br>Opportunities: 38",
              performance_delta: -0.13824884792626724,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Amy Roob",
          data: [
            {
              x: 1676246400000,
              y: 0.4444444444444444,
              percentage: 44,
              opportunities: 9,
              name: "2/13/2023–4/28/2023<br>Opportunities: 9",
              performance_delta: 1,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Andrew Frami",
          data: [
            {
              x: 1676246400000,
              y: 0.7570093457943925,
              percentage: 76,
              opportunities: 321,
              name: "2/13/2023–4/28/2023<br>Opportunities: 321",
              performance_delta: -0.015151515151515138,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Angel Welch",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 0,
              name: "2/13/2023–4/28/2023<br>Opportunities: 0",
              performance_delta: null,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Anya Brakus",
          data: [
            {
              x: 1676246400000,
              y: 0.23721881390593047,
              percentage: 24,
              opportunities: 489,
              name: "2/13/2023–4/28/2023<br>Opportunities: 489",
              performance_delta: 0.0037878787878787845,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Ashley Pouros",
          data: [
            {
              x: 1676246400000,
              y: 0.18430034129692832,
              percentage: 18,
              opportunities: 879,
              name: "2/13/2023–4/28/2023<br>Opportunities: 879",
              performance_delta: 0.09924812030075186,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Ashley Robel",
          data: [
            {
              x: 1676246400000,
              y: 0.3130841121495327,
              percentage: 31,
              opportunities: 428,
              name: "2/13/2023–4/28/2023<br>Opportunities: 428",
              performance_delta: -0.15268817204301077,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Brandi Rolfson",
          data: [
            {
              x: 1676246400000,
              y: 0.19183965640658554,
              percentage: 19,
              opportunities: 1397,
              name: "2/13/2023–4/28/2023<br>Opportunities: 1,397",
              performance_delta: -0.05061475409836064,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Brett Nader",
          data: [
            {
              x: 1676246400000,
              y: 0.48484848484848486,
              percentage: 48,
              opportunities: 429,
              name: "2/13/2023–4/28/2023<br>Opportunities: 429",
              performance_delta: -0.3416666666666667,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Brooke Reichert",
          data: [
            {
              x: 1676246400000,
              y: 0.19854132901134522,
              percentage: 20,
              opportunities: 1234,
              name: "2/13/2023–4/28/2023<br>Opportunities: 1,234",
              performance_delta: -0.3264002620373403,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Brooke Schultz",
          data: [
            {
              x: 1676246400000,
              y: 0.46255506607929514,
              percentage: 46,
              opportunities: 227,
              name: "2/13/2023–4/28/2023<br>Opportunities: 227",
              performance_delta: 0.13157894736842107,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Calandra Jakubowski",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 0,
              name: "2/13/2023–4/28/2023<br>Opportunities: 0",
              performance_delta: null,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Carrie Hirthe",
          data: [
            {
              x: 1676246400000,
              y: 0.4396551724137931,
              percentage: 44,
              opportunities: 232,
              name: "2/13/2023–4/28/2023<br>Opportunities: 232",
              performance_delta: -0.18315018315018317,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Cassidy Baumbach",
          data: [
            {
              x: 1676246400000,
              y: 0.26953748006379585,
              percentage: 27,
              opportunities: 627,
              name: "2/13/2023–4/28/2023<br>Opportunities: 627",
              performance_delta: 0.09839357429718873,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Catherine Weissnat",
          data: [
            {
              x: 1676246400000,
              y: 0.6575246132208158,
              percentage: 66,
              opportunities: 1422,
              name: "2/13/2023–4/28/2023<br>Opportunities: 1,422",
              performance_delta: -0.10498673153471916,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Charles Doyle",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 0,
              name: "2/13/2023–4/28/2023<br>Opportunities: 0",
              performance_delta: null,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Cherelle Swift",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 0,
              name: "2/13/2023–4/28/2023<br>Opportunities: 0",
              performance_delta: null,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Courtney Rohan",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 0,
              name: "2/13/2023–4/28/2023<br>Opportunities: 0",
              performance_delta: null,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Curshonda Cartwright",
          data: [
            {
              x: 1676246400000,
              y: 0.1592920353982301,
              percentage: 16,
              opportunities: 339,
              name: "2/13/2023–4/28/2023<br>Opportunities: 339",
              performance_delta: 0.1111111111111111,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "David Becker",
          data: [
            {
              x: 1676246400000,
              y: 0.21159420289855072,
              percentage: 21,
              opportunities: 345,
              name: "2/13/2023–4/28/2023<br>Opportunities: 345",
              performance_delta: 0.10877719429857463,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Destiny Ruecker",
          data: [
            {
              x: 1676246400000,
              y: 0.562992125984252,
              percentage: 56,
              opportunities: 254,
              name: "2/13/2023–4/28/2023<br>Opportunities: 254",
              performance_delta: -0.7397260273972602,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Graciela Sauer",
          data: [
            {
              x: 1676246400000,
              y: 0.1566265060240964,
              percentage: 16,
              opportunities: 1162,
              name: "2/13/2023–4/28/2023<br>Opportunities: 1,162",
              performance_delta: -0.038805970149253716,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Henrietta Upton",
          data: [
            {
              x: 1676246400000,
              y: 0.2962962962962963,
              percentage: 30,
              opportunities: 135,
              name: "2/13/2023–4/28/2023<br>Opportunities: 135",
              performance_delta: -0.20833333333333331,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Janie Rice",
          data: [
            {
              x: 1676246400000,
              y: 0.45940959409594095,
              percentage: 46,
              opportunities: 542,
              name: "2/13/2023–4/28/2023<br>Opportunities: 542",
              performance_delta: -0.08333333333333326,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Jax Weissnat",
          data: [
            {
              x: 1676246400000,
              y: 0.2644970414201183,
              percentage: 26,
              opportunities: 1690,
              name: "2/13/2023–4/28/2023<br>Opportunities: 1,690",
              performance_delta: -0.014880952380952384,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Jayde Fisher",
          data: [
            {
              x: 1676246400000,
              y: 0.1766329346826127,
              percentage: 18,
              opportunities: 1087,
              name: "2/13/2023–4/28/2023<br>Opportunities: 1,087",
              performance_delta: -0.03595768487437698,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Jazmyn Hegmann",
          data: [
            {
              x: 1676246400000,
              y: 0.44214876033057854,
              percentage: 44,
              opportunities: 242,
              name: "2/13/2023–4/28/2023<br>Opportunities: 242",
              performance_delta: 0.10367892976588627,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Jessica Beier",
          data: [
            {
              x: 1676246400000,
              y: 0.13636363636363635,
              percentage: 14,
              opportunities: 44,
              name: "2/13/2023–4/28/2023<br>Opportunities: 44",
              performance_delta: 0.20879120879120877,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Jessica Fahey",
          data: [
            {
              x: 1676246400000,
              y: 0.4968152866242038,
              percentage: 50,
              opportunities: 157,
              name: "2/13/2023–4/28/2023<br>Opportunities: 157",
              performance_delta: -0.4610389610389611,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Jody Spencer",
          data: [
            {
              x: 1676246400000,
              y: 0.7293064876957495,
              percentage: 73,
              opportunities: 447,
              name: "2/13/2023–4/28/2023<br>Opportunities: 447",
              performance_delta: -0.001240694789081953,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Jonathan Johns",
          data: [
            {
              x: 1676246400000,
              y: 0.12530712530712532,
              percentage: 13,
              opportunities: 1221,
              name: "2/13/2023–4/28/2023<br>Opportunities: 1,221",
              performance_delta: -0.4103448275862069,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Karen Friesen",
          data: [
            {
              x: 1676246400000,
              y: 0.1895424836601307,
              percentage: 19,
              opportunities: 459,
              name: "2/13/2023–4/28/2023<br>Opportunities: 459",
              performance_delta: -0.2112676056338028,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Karen Gutkowski",
          data: [
            {
              x: 1676246400000,
              y: 0.34210526315789475,
              percentage: 34,
              opportunities: 38,
              name: "2/13/2023–4/28/2023<br>Opportunities: 38",
              performance_delta: 0.8333333333333334,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Kayla Funk",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 3,
              name: "2/13/2023–4/28/2023<br>Opportunities: 3",
              performance_delta: 0,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Keith Schuster",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 0,
              name: "2/13/2023–4/28/2023<br>Opportunities: 0",
              performance_delta: null,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Kevin Hoeger",
          data: [
            {
              x: 1676246400000,
              y: 0.21686746987951808,
              percentage: 22,
              opportunities: 83,
              name: "2/13/2023–4/28/2023<br>Opportunities: 83",
              performance_delta: -0.125,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Kimberly Jast",
          data: [
            {
              x: 1676246400000,
              y: 0.48065173116089616,
              percentage: 48,
              opportunities: 982,
              name: "2/13/2023–4/28/2023<br>Opportunities: 982",
              performance_delta: -0.05463081519419544,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Kristen Dare",
          data: [
            {
              x: 1676246400000,
              y: 0.3824884792626728,
              percentage: 38,
              opportunities: 868,
              name: "2/13/2023–4/28/2023<br>Opportunities: 868",
              performance_delta: 0.3695652173913043,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Launa Morissette",
          data: [
            {
              x: 1676246400000,
              y: 0.3488372093023256,
              percentage: 35,
              opportunities: 43,
              name: "2/13/2023–4/28/2023<br>Opportunities: 43",
              performance_delta: -1,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Laurrie Buckridge",
          data: [
            {
              x: 1676246400000,
              y: 0.36678200692041524,
              percentage: 37,
              opportunities: 289,
              name: "2/13/2023–4/28/2023<br>Opportunities: 289",
              performance_delta: -0.02307692307692305,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Lenka Bashirian",
          data: [
            {
              x: 1676246400000,
              y: 0.125,
              percentage: 13,
              opportunities: 656,
              name: "2/13/2023–4/28/2023<br>Opportunities: 656",
              performance_delta: -0.03666666666666667,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Lillian Fritsch",
          data: [
            {
              x: 1676246400000,
              y: 0.22839506172839505,
              percentage: 23,
              opportunities: 162,
              name: "2/13/2023–4/28/2023<br>Opportunities: 162",
              performance_delta: -0.1684981684981685,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Linda Reilly",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 1,
              name: "2/13/2023–4/28/2023<br>Opportunities: 1",
              performance_delta: 0,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Mackenzie Keebler",
          data: [
            {
              x: 1676246400000,
              y: 0.3453887884267631,
              percentage: 35,
              opportunities: 553,
              name: "2/13/2023–4/28/2023<br>Opportunities: 553",
              performance_delta: 0.010864841373315948,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Malia Ratke",
          data: [
            {
              x: 1676246400000,
              y: 0.3031496062992126,
              percentage: 30,
              opportunities: 508,
              name: "2/13/2023–4/28/2023<br>Opportunities: 508",
              performance_delta: 0.14437689969604864,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Mario Wiegand",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 0,
              name: "2/13/2023–4/28/2023<br>Opportunities: 0",
              performance_delta: null,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Marisa Franecki",
          data: [
            {
              x: 1676246400000,
              y: 0.23333333333333334,
              percentage: 23,
              opportunities: 210,
              name: "2/13/2023–4/28/2023<br>Opportunities: 210",
              performance_delta: 0.17777777777777778,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Matthew Cole",
          data: [
            {
              x: 1676246400000,
              y: 0.5662650602409639,
              percentage: 57,
              opportunities: 498,
              name: "2/13/2023–4/28/2023<br>Opportunities: 498",
              performance_delta: 0.06736842105263163,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Matthew Wilkinson",
          data: [
            {
              x: 1676246400000,
              y: 0.21145894861193149,
              percentage: 21,
              opportunities: 1693,
              name: "2/13/2023–4/28/2023<br>Opportunities: 1,693",
              performance_delta: -0.015384615384615385,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Melba Nader",
          data: [
            {
              x: 1676246400000,
              y: 0.3157894736842105,
              percentage: 32,
              opportunities: 133,
              name: "2/13/2023–4/28/2023<br>Opportunities: 133",
              performance_delta: -0.14932126696832582,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Michelle Reinger",
          data: [
            {
              x: 1676246400000,
              y: 0.4864649681528662,
              percentage: 49,
              opportunities: 1256,
              name: "2/13/2023–4/28/2023<br>Opportunities: 1,256",
              performance_delta: -0.5306122448979591,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Morgan Gerhold",
          data: [
            {
              x: 1676246400000,
              y: 0.4846335697399527,
              percentage: 48,
              opportunities: 423,
              name: "2/13/2023–4/28/2023<br>Opportunities: 423",
              performance_delta: -0.5,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Nadia Wilkinson",
          data: [
            {
              x: 1676246400000,
              y: 0.14035087719298245,
              percentage: 14,
              opportunities: 1653,
              name: "2/13/2023–4/28/2023<br>Opportunities: 1,653",
              performance_delta: -0.059368191721132904,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Nichole Schmidt",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 1,
              name: "2/13/2023–4/28/2023<br>Opportunities: 1",
              performance_delta: 0,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Nickeela O'Reilly",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 0,
              name: "2/13/2023–4/28/2023<br>Opportunities: 0",
              performance_delta: null,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Nicole Hauck",
          data: [
            {
              x: 1676246400000,
              y: 0.4,
              percentage: 40,
              opportunities: 20,
              name: "2/13/2023–4/28/2023<br>Opportunities: 20",
              performance_delta: -0.6666666666666667,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Nicole Nicolas",
          data: [
            {
              x: 1676246400000,
              y: 0.5714285714285714,
              percentage: 57,
              opportunities: 35,
              name: "2/13/2023–4/28/2023<br>Opportunities: 35",
              performance_delta: -0.19780219780219788,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Nicole Padberg",
          data: [
            {
              x: 1676246400000,
              y: 0.5,
              percentage: 50,
              opportunities: 16,
              name: "2/13/2023–4/28/2023<br>Opportunities: 16",
              performance_delta: 0.75,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Nitza Schuster",
          data: [
            {
              x: 1676246400000,
              y: 0.4,
              percentage: 40,
              opportunities: 30,
              name: "2/13/2023–4/28/2023<br>Opportunities: 30",
              performance_delta: 0,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Patricia Sawayn",
          data: [
            {
              x: 1676246400000,
              y: 0.2687224669603524,
              percentage: 27,
              opportunities: 454,
              name: "2/13/2023–4/28/2023<br>Opportunities: 454",
              performance_delta: -0.22431633407243162,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Rachel Koss",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 0,
              name: "2/13/2023–4/28/2023<br>Opportunities: 0",
              performance_delta: null,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Richard Johns",
          data: [
            {
              x: 1676246400000,
              y: 0.5533596837944664,
              percentage: 55,
              opportunities: 1012,
              name: "2/13/2023–4/28/2023<br>Opportunities: 1,012",
              performance_delta: -0.026755852842809347,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Robin Effertz",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 0,
              name: "2/13/2023–4/28/2023<br>Opportunities: 0",
              performance_delta: null,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Samantha Waters",
          data: [
            {
              x: 1676246400000,
              y: 0.18181818181818182,
              percentage: 18,
              opportunities: 11,
              name: "2/13/2023–4/28/2023<br>Opportunities: 11",
              performance_delta: 0,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Sanela Pfannerstill",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 0,
              name: "2/13/2023–4/28/2023<br>Opportunities: 0",
              performance_delta: null,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Serena McDermott",
          data: [
            {
              x: 1676246400000,
              y: 0.2838150289017341,
              percentage: 28,
              opportunities: 1730,
              name: "2/13/2023–4/28/2023<br>Opportunities: 1,730",
              performance_delta: -0.0502283105022831,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Shea Haag",
          data: [
            {
              x: 1676246400000,
              y: 0.14285714285714285,
              percentage: 14,
              opportunities: 7,
              name: "2/13/2023–4/28/2023<br>Opportunities: 7",
              performance_delta: 0,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Sherrie Luettgen",
          data: [
            {
              x: 1676246400000,
              y: 0.3333333333333333,
              percentage: 33,
              opportunities: 3,
              name: "2/13/2023–4/28/2023<br>Opportunities: 3",
              performance_delta: 1,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Sophia Haley",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 0,
              name: "2/13/2023–4/28/2023<br>Opportunities: 0",
              performance_delta: null,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Sophia Wiza",
          data: [
            {
              x: 1676246400000,
              y: 0.6908212560386473,
              percentage: 69,
              opportunities: 1035,
              name: "2/13/2023–4/28/2023<br>Opportunities: 1,035",
              performance_delta: 0.02662037037037035,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Tambra Kiehn",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 0,
              name: "2/13/2023–4/28/2023<br>Opportunities: 0",
              performance_delta: null,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Teresa Lynch",
          data: [
            {
              x: 1676246400000,
              y: 0.18333333333333332,
              percentage: 18,
              opportunities: 60,
              name: "2/13/2023–4/28/2023<br>Opportunities: 60",
              performance_delta: 0.3112745098039216,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Thomas Purdy",
          data: [
            {
              x: 1676246400000,
              y: 0.31645569620253167,
              percentage: 32,
              opportunities: 237,
              name: "2/13/2023–4/28/2023<br>Opportunities: 237",
              performance_delta: 0.04444444444444445,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Tina Von",
          data: [
            {
              x: 1676246400000,
              y: 0.41843971631205673,
              percentage: 42,
              opportunities: 705,
              name: "2/13/2023–4/28/2023<br>Opportunities: 705",
              performance_delta: -0.040454130236199926,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Traci Lehner",
          data: [
            {
              x: 1676246400000,
              y: 0.31547619047619047,
              percentage: 32,
              opportunities: 168,
              name: "2/13/2023–4/28/2023<br>Opportunities: 168",
              performance_delta: -0.05942028985507247,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Tracy Weber",
          data: [
            {
              x: 1676246400000,
              y: 0.40756302521008403,
              percentage: 41,
              opportunities: 238,
              name: "2/13/2023–4/28/2023<br>Opportunities: 238",
              performance_delta: 0.10942760942760937,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Travis Gutkowski",
          data: [
            {
              x: 1676246400000,
              y: 0.24420677361853832,
              percentage: 24,
              opportunities: 561,
              name: "2/13/2023–4/28/2023<br>Opportunities: 561",
              performance_delta: 0.05246913580246915,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Wendy Altenwerth",
          data: [
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 2,
              name: "2/13/2023–4/28/2023<br>Opportunities: 2",
              performance_delta: 0,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
        {
          name: "Whitney Kiehn",
          data: [
            {
              x: 1676246400000,
              y: 0.37631184407796103,
              percentage: 38,
              opportunities: 667,
              name: "2/13/2023–4/28/2023<br>Opportunities: 667",
              performance_delta: 0.08333333333333333,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
      ],
      plotOptions: null,
      helpText: "This graph shows Providers' Hand Hygiene (HH) in percent.",
      extraJavascript: "",
      isExportable: true,
    },
    groupHandHygienePlot: {
      _graph_class: "PercentageColumnGraph",
      chartId: "chart_8c361cf9ac",
      chartTestKey: "Providers' Groups's Hand Hygiene Performance Overall",
      chartType: "column",
      titleText: "Providers' Groups's Hand Hygiene Performance Overall",
      subtitle: null,
      xAxis: {
        _plot_component_class: "AxisWithoutTicks",
        title: {
          text: "",
          style: {
            fontSize: "15px",
          },
        },
        tickPositions: [],
      },
      yAxis: {
        _plot_component_class: "PercentageAxis",
        title: {
          text: "Performance (%)",
          style: {
            fontSize: "15px",
          },
        },
        min: 0,
        max: 1,
        floor: 0,
        ceiling: 1,
        labels:
          "{@JS}{'formatter': function() { return (this.value * 100) + \" % \"; }}",
        tickPositions:
          "{@JS}window.HighchartsHelper.getTickPositions(1.0, true)",
      },
      xAxisPlotlines: "[]",
      yAxisPlotlines:
        '[{"series":{"name":"Baseline: F12585","data":[],"visible":false,"showInLegend":true,"legendIndex":-10,"type":"area","color":"red"}, "lines": [{"value":0.34,"label":{"align":"left","style":{"color":"red"},"text":"<i> Baseline:<\\/i> 34%","x":2,"y":15,"rotation":0},"zIndex":2.1,"color":"red","dashStyle":"longdashdot","width":1.5}]},{"series":{"name":"Hand Hygiene Goal","data":[],"visible":true,"showInLegend":true,"legendIndex":-99,"type":"area","color":"gold"}, "lines": []}]',
      showYearBoundaries: false,
      showColorBands: true,
      tooltip: {
        _plot_component_class: "PercentageTooltip",
        pointFormat: "{@JS}window.HighchartsPointFormats.specifiedPercentage",
      },
      dataSeries: [
        {
          name: "S12584 Health System",
          data: [
            {
              x: 1676246400000,
              y: 0.5223806041454666,
              percentage: 52,
              opportunities: 2640523,
              name: "2/13/2023–4/28/2023<br>Opportunities: 2,640,523",
              performance_delta: 0.015107799268400557,
            },
          ],
          showInLegend: true,
          visible: false,
          legendType: "nodeAncestor",
          nodeType_id: 8,
        },
        {
          name: "F12585 Hospital",
          data: [
            {
              x: 1676246400000,
              y: 0.328128078817734,
              percentage: 33,
              opportunities: 40600,
              name: "2/13/2023–4/28/2023<br>Opportunities: 40,600",
              performance_delta: -0.08366768940287939,
            },
          ],
          showInLegend: true,
          visible: false,
          legendType: "nodeAncestor",
          nodeType_id: 0,
        },
        {
          name: "Group-3803",
          data: [
            {
              x: 1676246400000,
              y: 0.33749125996364143,
              percentage: 34,
              opportunities: 35755,
              name: "2/13/2023–4/28/2023<br>Opportunities: 35,755",
              performance_delta: -0.13095074455899197,
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_groups",
          legendType: "selectedIndividual",
        },
      ],
      plotOptions: null,
      helpText:
        "This graph shows Providers' Groups Hand Hygiene (HH) in percent.",
      extraJavascript: "",
      isExportable: true,
    },
    item_count: 34902,
    item_count_label: "Opportunities",
  };
}
