import DatePicker from "../../Components/DatePicker/DatePicker";
import MissingData from "../../Components/MissingData/MissingData";
import StaffTable from "../../Components/StaffTable/StaffTable";
import Performance from "../../Components/Performance/Performance";
import Filters from "../../Components/Filters/Filters";
import {
  defaultDateRange,
  maxDayFilter,
  maxWeekFilter,
  missingDataAction,
  missingDataMessage,
} from "../../constants";
import Table from "@untitled-ui/icons-react/build/cjs/LayoutAlt03";
import Bubbles from "@untitled-ui/icons-react/build/cjs/IntersectCircle";

import ApiError from "../../Components/ApiError/ApiError";
import useStaffReportViewController from "../../viewcontroller/Staff/useStaffReportViewController";
import TabsList from "../../Components/UI/Tabs/Tabs";
import BubbleChart from "../../Components/BubbleChart/BubbleChart";
import MetricsCard from "../../Components/MetricsCard/MetricsCard";
import Cards from "../../Components/UI/Cards/Cards";
import { tooltipContent } from "../../content";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
import { useUUIDWithLocation } from "../../Hooks/useUuid";
import { useEffect } from "react";
import DatePickerDropdown from "../../Components/DatePickerDropdown/DatePickerDropdown";
import usePdfDownload from "../../Hooks/usePdfDownload";
import { staffElementIds, staffTitles } from "../../constants/pdfs";
import DownloadButton from "../../Components/DownloadButton/DownloadButton";

function StaffView() {
  const {
    transformedStaffReportData: staffReportData,
    handleDateChange,
    filters,
    onFilterChange,
    applyFilters,
    toggleButton,
    disableExcelDoc,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
    pdfTables,
  } = useStaffReportViewController();

  const { updateUUID } = useUUIDWithLocation();

  useEffect(() => {
    updateUUID(staffReportData?.uuid);
  }, [staffReportData?.uuid, updateUUID]);

  const apiError = staffReportData.error;
  const { total, performant } = staffReportData.tiles;

  const { downloadPdf, loading } = usePdfDownload(
    staffElementIds,
    staffTitles,
    pdfTables,
    "Staff-Report.pdf",
    "Staff Report PDF"
  );

  if (!staffReportData) {
    return (
      <MissingData message={missingDataMessage} action={missingDataAction} />
    );
  }

  return (
    <div>
      <div className="page-header">
        <h1>Staff</h1>
        <div className="buttons-container">
          <ExcelDownload
            link={staffReportData?.excelUrl}
            disabled={disableExcelDoc}
          />
          <DownloadButton click={downloadPdf} loading={loading} cta={"PDF"} />
        </div>
      </div>
      {apiError && <ApiError message={staffReportData?.error?.message} />}
      <div className="margin-bottom">
        <Cards>
          <div className="filters filters-margin-top">
            <div>
              <DatePickerDropdown
                startDateLabel={
                  staffReportData.startDate ?? defaultDateRange.startDate
                }
                endDateLabel={
                  staffReportData.endDate ?? defaultDateRange.endDate
                }
                onChange={handleDatePickerChange}
              />
              {isDatePickerVisible && (
                <DatePicker
                  handleDatePickerChange={() => handleDatePickerChange}
                  visible={isDatePickerVisible}
                  maxNumDays={maxDayFilter}
                  maxNumWeeks={maxWeekFilter}
                  aggregate={"weekly"}
                  startDate={
                    staffReportData.startDate ?? defaultDateRange.startDate
                  }
                  endDate={staffReportData.endDate ?? defaultDateRange.endDate}
                  onChange={handleDateChange}
                  handleClick={handleDatePickerButtonClick}
                />
              )}
            </div>
            <Filters
              filters={filters}
              onChange={onFilterChange}
              toggleButton={toggleButton}
              applyFilters={applyFilters}
            />
          </div>
        </Cards>
      </div>
      <div className="staff-pages-grid">
        <div className="performance-card">
          <Performance reportData={staffReportData.tiles} />
        </div>
        <div className="metrics-card">
          <MetricsCard total={total} performant={performant} />
        </div>
        <div className="graph-card">
          <TabsList
            forceRenderTabPanel={true}
            showTooltip
            tooltipContent={tooltipContent.staffGraphsToggle["en-US"]!}
            tooltipId={"staffViewGraphsToggle"!}
            tabs={["Table", "Bubble"]}
            content={[
              <StaffTable
                userInfo={staffReportData.userInfo}
                data={staffReportData.averageCompliance}
              />,
              <BubbleChart
                title="Hand Hygiene Performance By Staff"
                userInfo={staffReportData.userInfo}
                data={staffReportData.averageCompliance}
              />,
            ]}
            icons={[
              <Table className="tab-icon" />,
              <Bubbles className="tab-icon" />,
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default StaffView;
