import { useAsyncValue } from "react-router";
import { StaffOverviewStaffTableDTO } from "../../model/types";
import Table from "../Table/Table";
import { transformStaffOverview } from "../../transformers/visitOverview/transformStaffOverview";
import { convertSecondsToHoursMinutesSeconds } from "../../utils";

function StaffTable() {
  const tableData = useAsyncValue() as StaffOverviewStaffTableDTO;
  const { staffOverviews } = tableData;

  const columns = [
    {
      name: "staffName",
      label: "Staff Name",
    },
    {
      name: "visitLengthAvg",
      label: "Average Length of Visit",
      options: {
        customBodyRender: (value: number | null) => {
          return value !== null
            ? convertSecondsToHoursMinutesSeconds(value)
            : "N/A";
        },
      },
    },
    {
      name: "numberOfVisits",
      label: "Number of Visits",
    },
    {
      name: "longestVisit",
      label: "Longest Visit",
      options: {
        customBodyRender: (value: number | null) => {
          return value !== null
            ? convertSecondsToHoursMinutesSeconds(value)
            : "N/A";
        },
      },
    },
    {
      name: "mostVisitedRoom",
      label: "Most Visited Room",
    },
  ];

  const data = staffOverviews.map(transformStaffOverview);

  return <Table title={"Staff Overview"} data={data} columns={columns}></Table>;
}

export default StaffTable;
