export const colorLines = [
  "#E15759",
  "#6d529e",
  "#4E79A8",
  "#758556",
  "#FF9D9A",
  "#B6992D",
  "#A0CBE8",
  "#8c564b",
  "#59A14F",
  "#F28E2B",
  "#86BCB6",
  "#D47295",
  "#244d32",
  "#B07AA2",
  "#499894",
  "#4d1975",
  "#FABFD2",
  "#DED717",
  "#FCA4FA",
  "#09DAFC",
  "#D86606",
  "#03883F",
  "#153CFD",
  "#AB0344",
  "#00FBB4",
  "#5D4C01",
  "#7D7B86",
  "#0D5252",
  "#188EF7",
  "#370257",
  "#F4717C",
  "#D90DCB",
];

// if we have more colors than listed above, we will generate random colors
for (let i = 0; i < 100; i++) {
  const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
  colorLines.push(randomColor);
}

export const goalLineColor = "#000000";
export const baseLineColor = "#707070";

// chart js plugin to create html legend buttons
const getOrCreateLegendList = (_chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.style.display = "flex";
    listContainer.style.justifyContent = "flex-start";
    listContainer.style.flexDirection = "row";
    listContainer.style.flexWrap = "wrap";
    listContainer.style.padding = "0";
    listContainer.style.borderBottom = "none";

    legendContainer?.appendChild(listContainer);
  }

  return listContainer;
};

const createList = () => {
  const li = document.createElement("li");
  li.style.alignItems = "center";
  li.style.cursor = "pointer";
  li.style.display = "flex";
  li.style.flexDirection = "row";
  li.style.marginLeft = "10px";
  li.style.marginBottom = "10px";
  li.style.padding = "0";
  return li;
};

const createLegendColorKey = (item) => {
  const legendKey = document.createElement("span");
  legendKey.style.display = "inline-block";
  legendKey.style.marginRight = "10px";

  if (item.pointStyle === "triangle" || item.pointStyle === "star") {
    legendKey.classList.add(item.pointStyle);
  } else {
    Object.assign(legendKey.style, {
      background: item.fillStyle,
      borderColor: item.strokeStyle,
      borderWidth: item.lineWidth + "px",
      display: "inline-block",
      flexShrink: "0",
      height: "16px",
      width: "16px",
    });

    if (item.pointStyle === "rectRot") {
      legendKey.classList.add("rectRot");
    } else {
      legendKey.style.borderRadius = "50%";
    }
  }

  return legendKey;
};

export const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart, _args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item) => {
      const li = createList();
      const legendKey = createLegendColorKey(item);

      const buttonText = document.createElement("span");
      buttonText.textContent = item.text;

      const button = document.createElement("button");
      button.setAttribute("id", item.text);
      button.appendChild(legendKey);
      button.appendChild(buttonText);
      button.onclick = () => {
        const isVisible = chart.isDatasetVisible(item.datasetIndex);
        if (isVisible) {
          button.classList.add("active-legend-button");
        } else {
          button.classList.remove("active-legend-button");
        }
        chart.setDatasetVisibility(item.datasetIndex, !isVisible);
        chart.update();
      };

      if (chart.isDatasetVisible(item.datasetIndex)) {
        button.classList.add("active-legend-button");
      }

      li.appendChild(button);
      ul.appendChild(li);
    });
  },
};
