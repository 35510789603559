import { Outlet, useNavigation } from "react-router-dom";
import Loading from "../../Components/UI/Loading/Loading";
import { useUserInfo } from "../../contexts/UserInfo/userInfo";
import { useEffect } from "react";
import Hotjar from "@hotjar/browser";
import Footer from "../../Components/Footer/Footer";
import TopNavigation from "../../Components/TopNavigation/TopNavigation";
import { useModuleTheme } from "../../contexts/ModuleTheme/moduleThemes";
import { fetchUserInfo } from "../../api/laravelApi";

function initHotJar({
  userId,
  facilityId,
}: {
  userId: string;
  facilityId: number;
}) {
  const SITE_ID = 3222212;
  const HOTJAR_VERSION = 6;

  Hotjar.init(SITE_ID, HOTJAR_VERSION, {
    debug: false, // change this to true to get helpful logs in console.
  });

  Hotjar.identify(userId, {
    facility_id: facilityId,
  });
}

function RootLayout() {
  const navigation = useNavigation();
  const { updateUserInfo, userInfo } = useUserInfo();
  const { theme } = useModuleTheme();

  function isObjEmpty(obj) {
    return JSON.stringify(obj) === "{}";
  }

  useEffect(() => {
    if (isObjEmpty(userInfo) && !window.location.href.includes("signature")) {
      fetchUserInfo().then((userInfo) => {
        if (userInfo) {
          updateUserInfo(userInfo);
        }

        if (process.env.REACT_APP_DEPLOYED_ENVIRONMENT !== "development") {
          const userId = userInfo?.user?.id;
          const facilityId = userInfo?.user?.hierarchy?.facility_id;
          if (userId && facilityId) {
            initHotJar({ userId: userId.toString(), facilityId });
          }
        }
      });
    }
  });

  return (
    <>
      <TopNavigation displayHamburgerButton={false} />
      <main className={theme + " overview-layout position-relative"}>
        {navigation.state === "loading" ? <Loading /> : <Outlet />}
      </main>
      <footer className="overview-layout-footer">
        <Footer />
      </footer>
    </>
  );
}

export default RootLayout;
