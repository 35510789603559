import { Await, useLoaderData } from "react-router";
import { Suspense, useEffect } from "react";
import Loading from "../../Components/UI/Loading/Loading";
import Cards from "../../Components/UI/Cards/Cards";
import { RoomsOverviewDTO } from "../../model/types";
import FiltersWithDatePicker from "../../CompositeComponents/FiltersWithDatePicker";
import RoomsTable from "../../Components/VisitOverview/RoomOverviewTable";
import Summary from "../../Components/VisitOverview/RoomOverviewSummary";
import { useOutletContext } from "react-router-dom";
import { VisitOverviewContext } from "../VisitOverview/contexts/VisitOverviewContext";

function RoomsOverviewView() {
  const { summary, filters, roomsTable } = useLoaderData() as RoomsOverviewDTO;
  const { setExcelDownloadUrl, setPdfProps } =
    useOutletContext<VisitOverviewContext>();

  // handle excel download url
  useEffect(() => {
    const resolveData = async () => {
      setExcelDownloadUrl((await filters).export);
    };
    resolveData().then();
  }, [filters, setExcelDownloadUrl]);

  // handle pdf attributes
  useEffect(() => {
    const resolveData = async () => {
      const [summaryData, roomsTableData] = await Promise.all([
        summary,
        roomsTable,
      ]);
      setPdfProps({
        type: "rooms",
        roomsProps: {
          summary: summaryData,
          rooms: roomsTableData,
        },
        staffProps: null,
      });
    };
    resolveData().then();
  }, [summary, roomsTable, setPdfProps]);
  return (
    <>
      <div className="margin-bottom">
        <Cards>
          <div className="filters filters-margin-top">
            <Suspense
              fallback={
                <div className="fallback-loader">
                  <Loading />
                </div>
              }
            >
              <Await resolve={filters}>
                <FiltersWithDatePicker />
              </Await>
            </Suspense>
          </div>
        </Cards>
      </div>
      <div className="display-flex-visit-summary">
        <Suspense
          fallback={
            <div className="fallback-loader">
              <Loading />
            </div>
          }
        >
          <Await resolve={summary}>
            <Summary />
          </Await>
        </Suspense>
      </div>
      <div className="display-flex-visit-table">
        <div>
          <Suspense>
            <Await resolve={roomsTable}>
              <RoomsTable />
            </Await>
          </Suspense>
        </div>
      </div>
    </>
  );
}

export default RoomsOverviewView;
