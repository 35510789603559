import usePositiveStaffCaseModel from "../../model/PositiveStaffCase/usePositiveStaffCaseModel";
import { transformCaseDetails } from "../../transformers/positiveCase/transformCaseDetails";

const usePositiveStaffCaseViewModel = () => {
  const { positiveStaffCaseData } = usePositiveStaffCaseModel();

  const transformedCaseDetails = transformCaseDetails(
    positiveStaffCaseData.case
  );

  return {
    positiveStaffCaseData,
    transformedCaseDetails,
  };
};

export default usePositiveStaffCaseViewModel;
