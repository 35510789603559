import { get } from "../../api/laravelApi";
import { caseEndpoint } from "../../constants";

export const CasesLoader = async ({ request }) => {
  const customerIds = new URL(request.url).searchParams.getAll("customersIds");
  const facilityIds = new URL(request.url).searchParams.getAll("facilitiesIds");
  const exposureType = new URL(request.url).searchParams.getAll(
    "Exposure TypeIds"
  );

  const data = await get(caseEndpoint, {
    customerIds,
    facilityIds,
    exposureType,
  });

  return data;
};
