import React, { ReactNode } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Tooltips from "../Tooltip/Tooltip";

type TabsListProps = {
  tabs: string[];
  icons?: React.ReactNode[];
  content: React.ReactNode[];
  className?: string;
  tooltipId?: string;
  tooltipContent?: string;
  select?: (index: number) => void;
  forceRenderTabPanel?: boolean;
  showTooltip?: boolean;
};

function TabsList(props: TabsListProps) {
  // selectableClass is included with ReactTabs but if you define things with ClassNames it automatically goes to the parent div so we need this if check instead.
  function getSelectableClass() {
    if (props.className === "pills-tabs") {
      return "pills-tabs";
    } else {
      return "themed-tabs";
    }
  }

  return (
    <div className="Tabs">
      <div className={getSelectableClass() + "-container"}>
        <Tabs
          forceRenderTabPanel={props.forceRenderTabPanel}
          className="right-align"
          focusTabOnClick={false}
          selectedTabClassName={getSelectableClass()}
          onSelect={props.select}
        >
          <TabList>
            <div className="display-flex flex-wrap">
              {props.tabs.map((tab: string, index: number) => {
                return (
                  <>
                    <Tab key={index}>
                      {props.icons && props.icons[index]}
                      {tab}
                    </Tab>
                  </>
                );
              })}
            </div>
            {props.showTooltip && (
              <div className="display-flex-center">
                <Tooltips
                  id={props.tooltipId!}
                  content={props.tooltipContent!}
                />
              </div>
            )}
          </TabList>
          {props.content.map((content: ReactNode, index: number) => (
            <TabPanel key={index}>{content}</TabPanel>
          ))}
        </Tabs>
      </div>
    </div>
  );
}

export default TabsList;
