import { get } from "../../api/laravelApi";
import { caseOptionsEndpoint } from "../../constants";

export const CaseOptionsLoader = async ({ request }) => {
  const customerIds = new URL(request.url).searchParams.getAll("customersIds");
  const facilityIds = new URL(request.url).searchParams.getAll("facilitiesIds");

  const data = await get(caseOptionsEndpoint, {
    customerIds,
    facilityIds,
  });

  return data;
};
