import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable, { MUIDataTableColumn } from "mui-datatables";
import { missingDataMessage, missingDataAction } from "../../constants";
import MissingData from "../MissingData/MissingData";

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

interface TableProps<T> {
  columns: MUIDataTableColumn[];
  title?: string;
  data: T[];
  optionOverrides?: any;
}

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
});

const theme = createTheme({
  typography: {
    fontFamily: "Inter",
    h6: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.5,
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        footer: {
          borderBottom: "none",
        },
        root: {
          paddingTop: "8px",
          paddingBottom: "8px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow:
            "0 1px 2px 0 rgba(16, 24, 40, 0.06), 0 1px 3px 0 rgba(16, 24, 40, 0.1);",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          height: "10px",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          padding: 0,
          minWidth: 0,
          boxShadow: "none",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      // sets mobile breakpoint for DT
      md: 1270,
      // we need medium to be slightly larger than our typical breakpoints, LG and XL are set to our current LG breakpoint
      lg: 1401,
      xl: 1401,
    },
  },
});

const defaultOptions = {
  rowHover: false,
  selectableRows: "none",
  responsive: "simple",
  textLabels: {
    body: {
      noMatch: (
        <MissingData message={missingDataMessage} action={missingDataAction} />
      ),
    },
  },
  filter: false,
  print: false,
  download: false,
  viewColumns: false,
};

function Table<T>(props: TableProps<T>) {
  const { columns, title, data, optionOverrides } = props;
  const options = {
    ...defaultOptions,
    ...optionOverrides,
  };

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <MUIDataTable
          title={title}
          columns={columns}
          data={data}
          options={options}
        />
      </ThemeProvider>
    </CacheProvider>
  );
}

export default Table;
