// import StaffTable from "../../Components/StaffTable/StaffTable";
import Archive from "@untitled-ui/icons-react/build/cjs/Pin02";
import DeleteButton from "../../Components/DeleteButton/DeleteButton";
import usePositiveStaffCaseViewController from "../../viewcontroller/PositiveStaffCase/usePositiveStaffCaseViewController";
import Cards from "../../Components/UI/Cards/Cards";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
import MissingData from "../../Components/MissingData/MissingData";
import { missingDataMessage, missingDataAction } from "../../constants";
import BackwardsButton from "../../Components/UI/BackwardsButton/BackwardsButton";
import Table from "../../Components/Table/Table";
import TabsList from "../../Components/UI/Tabs/Tabs";

function PositiveStaffCaseView() {
  const {
    positiveStaffCaseData,
    caseDetails,
    tableColumnsStaff,
    tableColumnsRooms,
    staffTableData,
    tableOptions,
    roomTableData,
    disableExcelDoc,
    returnToAllCases,
    isDisabled,
    archiveAction,
    deleteAction,
  } = usePositiveStaffCaseViewController();

  return (
    <div>
      <div className="page-header">
        <h1>Case Details</h1>
        <div className="buttons-container">
          <ExcelDownload
            link={positiveStaffCaseData.export}
            disabled={disableExcelDoc}
          />
        </div>
      </div>

      {!positiveStaffCaseData && (
        <MissingData
          message={missingDataMessage}
          action={missingDataAction}
          className="missing-data-container"
        />
      )}

      {positiveStaffCaseData && (
        <>
          <div className="margin-bottom display-flex-space-between negative-margin-top-half">
            <div>
              <button
                onClick={archiveAction}
                className={
                  isDisabled ? "disabled cta margin-right" : "cta margin-right"
                }
              >
                <Archive /> Archive
              </button>
              <DeleteButton
                onClickYes={deleteAction}
                message={"Are you sure you want to delete this case?"}
              />
            </div>
            <div>
              <BackwardsButton
                click={returnToAllCases}
                text={"Back To All Cases"}
              />
            </div>
          </div>
          <Cards className="full-height">
            <div className="display-flex-positive-staff-container">
              {Object.entries(caseDetails).map(([field, val]) => {
                return (
                  <div className="no-margin">
                    <h2 className="no-margin">{field}:</h2>
                    <p className="no-margin">{val}</p>
                  </div>
                );
              })}
            </div>
          </Cards>
        </>
      )}

      <TabsList
        className="pills-tabs"
        tabs={["Staff", "Rooms"]}
        content={[
          <div className="ct-rooms-history-table">
            <Table
              columns={tableColumnsStaff}
              optionOverrides={tableOptions}
              data={staffTableData}
              title={"Staff Exposures"}
            ></Table>
          </div>,
          <div className="ct-rooms-history-table">
            <Table
              columns={tableColumnsRooms}
              optionOverrides={tableOptions}
              data={roomTableData}
              title={"Room Exposures"}
            ></Table>
          </div>,
        ]}
      />
    </div>
  );
}

export default PositiveStaffCaseView;
