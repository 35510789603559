import Alert from "@untitled-ui/icons-react/build/cjs/AlertCircle";

type ApiErrorProps = {
  message?: string;
};

function MissingData(props: ApiErrorProps) {
  return (
    <>
      <div className="api-error">
        <div className="error-icon">
          <Alert />
        </div>
        <h2 className="error-message">{props.message}</h2>
      </div>
    </>
  );
}

export default MissingData;
