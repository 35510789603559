import Cards from "../../Components/UI/Cards/Cards";
import useRtibViewController from "../../viewcontroller/Rtibs/useRtibsViewController";
import Filters from "../../Components/Filters/Filters";
import NodeMap from "../../Components/NodeMap/NodeMap";
import { useUUIDWithLocation } from "../../Hooks/useUuid";
import { useEffect } from "react";

function Rtib() {
  const {
    transformedRtib,
    filters,
    onFilterChange,
    applyFilters,
    toggleButton,
  } = useRtibViewController();

  const { updateUUID } = useUUIDWithLocation();

  useEffect(() => {
    updateUUID(transformedRtib?.uuid);
  }, [transformedRtib?.uuid, updateUUID]);

  return (
    <div className="content-container">
      <div className="page-header">
        <div>
          <h1>Real-Time Intervention Blueprint</h1>
        </div>
      </div>
      <div className="margin-bottom">
        <Cards>
          <div className="filters filters-margin-top">
            <Filters
              filters={filters}
              onChange={onFilterChange}
              toggleButton={toggleButton}
              applyFilters={applyFilters}
            />
          </div>
        </Cards>
      </div>
      <NodeMap transformedData={transformedRtib} />
    </div>
  );
}

export default Rtib;
