import { useLoaderData } from "react-router-dom";
import { PositiveStaffCaseDTO } from "../types";

const usePositiveStaffCaseModel = () => {
  const positiveStaffCaseData = useLoaderData() as PositiveStaffCaseDTO;

  return {
    positiveStaffCaseData,
  };
};

export default usePositiveStaffCaseModel;
