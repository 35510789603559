import { SetStateAction, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  formatDate,
  lastMonth,
  lastSixMonths,
  lastThreeMonths,
  lastWeek,
  pastFourteenDays,
  pastSevenDays,
  pastThirtyDays,
  stripWhiteSpace,
} from "../../utils";
import { useUserInfo } from "../../contexts/UserInfo/userInfo";
import { useFilterState } from "../../Components/Filters/hooks/useFilterState";
import {
  DependencyValues,
  RawOptionsObject,
} from "../../Components/Filters/types/types";
import { post, fetchUserInfo } from "../../api/laravelApi";
import { Option } from "../../types/types";
import { useModuleTheme } from "../../contexts/ModuleTheme/moduleThemes";
import { transformFilters } from "../../transformers/utils";

// todo: add types
const useSharedFunctionsForFilters = (resolvedFilters) => {
  const { userInfo } = useUserInfo();
  const filtersStartDate = resolvedFilters?.params?.date_range?.value[1];
  const filtersEndDate = resolvedFilters?.params?.date_range?.value[2];

  const [startDate, setStartDate] = useState<string>(filtersStartDate || "");
  const [endDate, setEndDate] = useState<string>(filtersEndDate || "");

  const transformedFilters = transformFilters(resolvedFilters?.params);

  const [, setDateValue] = useState<string>("defaultView");

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

  const toggleDatePickerVisibility = () => {
    setIsDatePickerVisible((prev) => !prev);
  };

  const [, setSearchParams] = useSearchParams({
    aggregation: "weekly",
    startDate,
    endDate,
  });

  const { updateUserInfo } = useUserInfo();

  const fetchOptions: (
    reloadingUrl: string,
    dependencyValues: DependencyValues
  ) => Promise<RawOptionsObject> = async (reloadingUrl, dependencyValues) => {
    let csrfToken = userInfo?.tokens?.csrf;
    if (!csrfToken) {
      const userInfo = await fetchUserInfo();
      updateUserInfo(userInfo);
      csrfToken = userInfo?.tokens?.csrf;
    }

    const additionalHeaders = {
      "X-CSRF-TOKEN": csrfToken,
    };

    try {
      const data = await post({
        body: { dependencyValues },
        additionalHeaders,
        url: reloadingUrl,
      });

      if (!data) return [];

      return data;
    } catch (error) {
      console.error("Error fetching options:", error);
      return [];
    }
  };

  const { filters, setSelected, setPeerFilters } = useFilterState(
    fetchOptions,
    {
      initialFilters: transformedFilters,
    }
  );

  function getSelectedOptions(options: Option[]) {
    if (options.length === 0) return ["null"];
    return options.map((option) => option.value);
  }

  let selectedIdsArray: any = [];
  if (!transformedFilters) {
    selectedIdsArray = [];
  } else {
    selectedIdsArray = filters?.map((filter) => {
      const filterName = `${stripWhiteSpace(filter.name)}Ids`;
      const selectedOptions = getSelectedOptions(filter.selection);

      return {
        [filterName]: selectedOptions,
      };
    });
  }

  const [toggleButton, setToggleButton] = useState(true);

  const handleDateChange = (startDate: string, endDate: string) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setTimeout(() => {
      setToggleButton(false);
    }, 1000);
    setToggleButton(true);
  };

  const handleDatePickerButtonClick = () => {
    toggleDatePickerVisibility();
  };

  const handleDatePickerChange = (value: SetStateAction<string>) => {
    setDateValue(value);
    let startDate = new Date();
    let endDate = new Date();
    const predefinedRanges = {
      sevenDays: () => pastSevenDays(startDate, endDate),
      fourteenDays: () => pastFourteenDays(startDate, endDate),
      thirtyDays: () => pastThirtyDays(startDate, endDate),
      lastWeek: () => {
        const { startDate: lastWeekStart, endDate: lastWeekEnd } = lastWeek();
        startDate = lastWeekStart;
        endDate = lastWeekEnd;
      },
      lastMonth: () => {
        const { startDate: lastMonthStart, endDate: lastMonthEnd } =
          lastMonth();
        startDate = lastMonthStart;
        endDate = lastMonthEnd;
      },
      lastThreeMonths: () => {
        const {
          startDate: firstDayOfThreeMonthsAgo,
          endDate: lastDayOfOneMonthAgo,
        } = lastThreeMonths();
        startDate = firstDayOfThreeMonthsAgo;
        endDate = lastDayOfOneMonthAgo;
      },
      lastSixMonths: () => {
        const {
          startDate: firstDayOfSixMonthsAgo,
          endDate: lastDayOfOneMonthAgo,
        } = lastSixMonths();
        startDate = firstDayOfSixMonthsAgo;
        endDate = lastDayOfOneMonthAgo;
      },
      custom: () => toggleDatePickerVisibility(),
    };

    const predefinedRangeFunction =
      predefinedRanges[value as keyof typeof predefinedRanges];
    if (predefinedRangeFunction) {
      predefinedRangeFunction();
      setStartDate(formatDate(startDate));
      setEndDate(formatDate(endDate));
    }
  };

  const onFilterChange = (
    filterName: string,
    updatedSelected: readonly Option[]
  ) => {
    setTimeout(() => {
      setToggleButton(false);
    }, 1000);
    const updatedSelectedValues = [...updatedSelected];
    setSelected(filterName, updatedSelectedValues);
    setToggleButton(true);
  };

  const selectedIds = selectedIdsArray.reduce((acc, curr) => {
    return { ...acc, ...curr };
  }, {});

  const { theme } = useModuleTheme();

  const applyFilters = () => {
    const includesDynamicUrl = /assets\/\d+/;
    if (theme === "asset-tracking") {
      if (includesDynamicUrl.test(window.location.pathname)) {
        setSearchParams({
          startDate: startDate,
          endDate: endDate,
          ...selectedIds,
        });
      } else {
        setSearchParams({
          ...selectedIds,
        });
      }
    } else if (theme === "contact-tracing") {
      setSearchParams({
        ...selectedIds,
      });
    } else {
      setSearchParams({
        startDate: startDate,
        endDate: endDate,
        ...selectedIds,
      });
    }
  };

  return {
    startDate,
    endDate,
    handleDateChange,
    onFilterChange,
    filters,
    applyFilters,
    setSelected,
    setPeerFilters,
    toggleButton,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
  };
};

export default useSharedFunctionsForFilters;
