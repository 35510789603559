export default function mockUnitReport() {
  return {
    _report: {
      uuid: "98f43b87-4b06-4aad-adfb-f70759d06540",
      title: "Unit Report",
    },
    _export: {
      url: "https://local.swipesense.test/report/de/98f43b87-4b06-4aad-adfb-f70759d06540?expires=1681834249&signature=fb1e2549e7c3b267e8e7e1e226bfc9ff4bdba99a28832f6b1a1c5f4084e11c14",
    },
    _user: {
      id: 61407,
      is_admin: false,
    },
    _error: [],
    _isSignedRoute: false,
    _params: {
      customer_ids: {
        _filter_component_class: "CustomerIdsFilter",
        authorized: false,
        visible: true,
        value: [18],
        valueDisplay: "Monsters Inc Ministry (18)",
        default: [18],
        initial: [18],
        options: {
          18: "Monsters Inc Ministry (18)",
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/customer_ids_4f6813f738ccd69e2418421b538a9600?expires=1681751449&signature=3a9994efd1f2be8ae2633044bf085a105abbbd1ed724842b0bf1a4d3cb38a4c6",
        },
      },
      facility_ids: {
        _filter_component_class: "FacilityIdsFilter",
        authorized: true,
        visible: true,
        value: [1949],
        valueDisplay: "Facility-1949",
        default: null,
        initial: [1949],
        options: {
          1949: "Facility-1949",
          5221: "Facility-5221",
          6028: "Facility-6028",
          6773: "Facility-6773",
          7152: "Facility-7152",
          7899: "Facility-7899",
          8747: "Facility-8747",
          9050: "Facility-9050",
          9693: "Facility-9693",
          9859: "Facility-9859",
          9883: "Facility-9883",
          10127: "Facility-10127",
        },
        reloading: {
          peer_filters: ["customer_ids"],
          url: "https://local.swipesense.test/ajax/filter/getOptions/facility_ids_5f0ef280e5bb770123553b137e8641c0?expires=1681751449&signature=d02f77713d3bf2738b263d9a1afe5249313b115b5782588278587ace130247f1",
        },
      },
      unit_ids: {
        _filter_component_class: "UnitIdsFilter",
        authorized: true,
        visible: true,
        value: null,
        valueDisplay: "All",
        default: null,
        initial: null,
        options: {
          3458: "Burn",
          3423: "CPCU",
          3426: "CVICU",
          3428: "ED 1st",
          3427: "ED 2nd",
          3459: "L&B 6th",
          3460: "L&B 7th",
          3424: "MPCU",
          3441: "Med Surg 6",
          3446: "Med Surg 7",
          3457: "Med Surg ICU",
          3438: "Med Surg Stepdown",
          3443: "Mother/Baby 5th",
          3442: "Mother/Baby 6th",
          3448: "Mother/Baby 7th",
          3447: "Mother/Baby 7th South",
          3455: "NICU",
          3437: "Neuro",
          3444: "Oncology",
          3435: "Ortho 2nd",
          5194: "Peds/PICU Green",
          3454: "Peds/PICU Yellow",
          3425: "SPCU",
          3440: "TN ICU 1 (475)",
          3557: "TN ICU 2 (474)",
          3436: "Tele 2",
          3556: "Trauma Neuro Stepdown",
          3439: "Trauma Surg",
          3445: "Women's Health",
        },
        reloading: {
          peer_filters: ["customer_ids", "facility_ids"],
          url: "https://local.swipesense.test/ajax/filter/getOptions/unit_ids_3f85f23f384e96ec40093dfbc1457c61?expires=1681751449&signature=2e75f4f62dc5babad1e45a3a1ae8b6370124865b26f9ab20d9b89470e34d6359",
        },
      },
      room_config_ids: {
        _filter_component_class: "RoomConfigIdsFilter",
        authorized: false,
        visible: true,
        value: null,
        valueDisplay: "All",
        default: null,
        initial: null,
        options: {
          3448: "Room 1",
          3500: "Room 1",
          3946: "Room 1",
          11308: "Room 1",
          3449: "Room 2",
          3498: "Room 2",
          3510: "Room 2",
          3948: "Room 2",
          3451: "Room 3",
          3496: "Room 3",
          3950: "Room 3",
          3513: "Room 3",
          3453: "Room 4",
          3494: "Room 4",
          3952: "Room 4",
          3515: "Room 4",
          3474: "Room 5",
          3491: "Room 5",
          3516: "Room 5",
          3954: "Room 5",
          3472: "Room 6",
          3490: "Room 6",
          3517: "Room 6",
          3955: "Room 6",
          3469: "Room 7",
          3488: "Room 7",
          3518: "Room 7",
          3956: "Room 7",
          3468: "Room 8",
          3486: "Room 8",
          3953: "Room 8",
          3519: "Room 8",
          3467: "Room 9",
          3483: "Room 9",
          3951: "Room 9",
          3527: "Room 9",
          3466: "Room 10",
          3482: "Room 10",
          3949: "Room 10",
          3528: "Room 10",
          3463: "Room 11",
          3947: "Room 11",
          3523: "Room 11",
          3460: "Room 12",
          3957: "Room 12",
          3520: "Room 12",
          3459: "Room 13",
          3959: "Room 13",
          3458: "Room 14",
          3962: "Room 14",
          3456: "Room 15",
          3963: "Room 15",
          3455: "Room 16",
          3965: "Room 16",
          3966: "Room 17",
          3964: "Room 18",
          3961: "Room 19",
          3960: "Room 20",
          3958: "Room 21",
          3977: "Room 22",
          3974: "Room 23",
          3971: "Room 24",
          3967: "Room 25",
          3968: "Room 26",
          3969: "Room 27",
          3970: "Room 28",
          3972: "Room 29",
          3973: "Room 30",
          3975: "Room 31",
          3976: "Room 32",
          3987: "Room 33",
          3985: "Room 34",
          3983: "Room 35",
          3981: "Room 36",
          3978: "Room 37",
          3979: "Room 38",
          3980: "Room 39",
          3982: "Room 40",
          3984: "Room 41",
          3986: "Room 42",
          4042: "Room 47",
          4041: "Room 48",
          4038: "Room 49",
          4040: "Room 50",
          4037: "Room 51",
          4033: "Room 52",
          4032: "Room 53",
          4030: "Room 54",
          4029: "Room 55",
          4026: "Room 56",
          4025: "Room 57",
          4024: "Room 58",
          4023: "Room 59",
          4022: "Room 60",
          4021: "Room 61",
          4020: "Room 62",
          4019: "Room 63",
          4018: "Room 64",
          4016: "Room 65",
          4017: "Room 66",
          4015: "Room 67",
          4014: "Room 68",
          4012: "Room 69",
          4013: "Room 70",
          4011: "Room 71",
          4009: "Room 72",
          4010: "Room 73",
          4007: "Room 74",
          4008: "Room 75",
          4005: "Room 76",
          4006: "Room 77",
          4004: "Room 78",
          4003: "Room 79",
          4002: "Room 80",
          4001: "Room 81",
          4000: "Room 82",
          3999: "Room 83",
          3998: "Room 84",
          3997: "Room 85",
          3996: "Room 86",
          3995: "Room 87",
          3994: "Room 88",
          3993: "Room 89",
          3992: "Room 90",
          3990: "Room 91",
          3991: "Room 92",
          3989: "Room 93",
          3988: "Room 94",
          3538: "Room 490-1",
          3540: "Room 490-2",
          3542: "Room 490-3",
          3543: "Room 490-4",
          3544: "Room 490-5",
          3547: "Room 490-6",
          3548: "Room 490-7",
          3549: "Room 490-8",
          3551: "Room 490-9",
          3552: "Room 490-10",
          3554: "Room 490-11",
          3556: "Room 490-12",
          3557: "Room 490-13",
          3559: "Room 490-14",
          3561: "Room 490-15",
          3563: "Room 490-16",
          3779: "Room 2301",
          3780: "Room 2302",
          3782: "Room 2303",
          3784: "Room 2304",
          3794: "Room 2305",
          3786: "Room 2306",
          3789: "Room 2307",
          3797: "Room 2315",
          3799: "Room 2316",
          3801: "Room 2317",
          3803: "Room 2318",
          3802: "Room 2319",
          3805: "Room 2320",
          3804: "Room 2321",
          3806: "Room 2323",
          3807: "Room 2325",
          3809: "Room 2326",
          3808: "Room 2327",
          3810: "Room 2335",
          3811: "Room 2336",
          3812: "Room 2337",
          3814: "Room 2353",
          3815: "Room 2354",
          3816: "Room 2355",
          3817: "Room 2356",
          3818: "Room 2357",
          3819: "Room 2358",
          3820: "Room 2360",
          3822: "Room 2361",
          3821: "Room 2362",
          3824: "Room 2364",
          3826: "Room 2366",
          3827: "Room 2368",
          3829: "Room 2369",
          3830: "Room 2370",
          3834: "Room 2371",
          3832: "Room 2372",
          3021: "Room 2401",
          3022: "Room 2402",
          3023: "Room 2403",
          3024: "Room 2404",
          3025: "Room 2405",
          3027: "Room 2406",
          3028: "Room 2407",
          3029: "Room 2408",
          3030: "Room 2409",
          3031: "Room 2410",
          3033: "Room 2411",
          3034: "Room 2412",
          3036: "Room 2413",
          3037: "Room 2414",
          3039: "Room 2415",
          3040: "Room 2416",
          3041: "Room 2417",
          3043: "Room 2418",
          2966: "Room 3064",
          2971: "Room 3065",
          2973: "Room 3066",
          2975: "Room 3067",
          2976: "Room 3068",
          2977: "Room 3069",
          2978: "Room 3070",
          2979: "Room 3071",
          2980: "Room 3072",
          2981: "Room 3073",
          2982: "Room 3074",
          2983: "Room 3075",
          2984: "Room 3076",
          2985: "Room 3077",
          2986: "Room 3078",
          2987: "Room 3079",
          2990: "Room 3080",
          2992: "Room 3081",
          2994: "Room 3082",
          2993: "Room 3083",
          2996: "Room 3084",
          2997: "Room 3085",
          2998: "Room 3086",
          3000: "Room 3087",
          3001: "Room 3088",
          3003: "Room 3089",
          3004: "Room 3090",
          3005: "Room 3091",
          3006: "Room 3092",
          3007: "Room 3093",
          3009: "Room 3094",
          3010: "Room 3095",
          4114: "Room 3101",
          4115: "Room 3102",
          4116: "Room 3103",
          4117: "Room 3104",
          4118: "Room 3105",
          4119: "Room 3106",
          4120: "Room 3107",
          4121: "Room 3108",
          4122: "Room 3109",
          4123: "Room 3110",
          4127: "Room 3111",
          4129: "Room 3112",
          4133: "Room 3113",
          4136: "Room 3129",
          4139: "Room 3130",
          4154: "Room 3131",
          4155: "Room 3132",
          4156: "Room 3133",
          3297: "Room 3201",
          3299: "Room 3202",
          3300: "Room 3203",
          3304: "Room 3204",
          3307: "Room 3205",
          3310: "Room 3206",
          3225: "Room 3207",
          3224: "Room 3208",
          3223: "Room 3209",
          3221: "Room 3210",
          3220: "Room 3211",
          3219: "Room 3212",
          3218: "Room 3213",
          3217: "Room 3214",
          3176: "Room 3215",
          3175: "Room 3216",
          3174: "Room 3217",
          3173: "Room 3218",
          3172: "Room 3219",
          3171: "Room 3220",
          3226: "Room 3221",
          3227: "Room 3222",
          3228: "Room 3223",
          3229: "Room 3224",
          3278: "Room 3225",
          3279: "Room 3226",
          3280: "Room 3227",
          3281: "Room 3228",
          3282: "Room 3229",
          3283: "Room 3230",
          3284: "Room 3231",
          3285: "Room 3232",
          3286: "Room 3233",
          3287: "Room 3234",
          3291: "Room 3235",
          3294: "Room 3236",
          5111: "Room 3301",
          5104: "Room 3302",
          5106: "Room 3303",
          5109: "Room 3305",
          5112: "Room 3306",
          5114: "Room 3307",
          5118: "Room 3308",
          5117: "Room 3309",
          5120: "Room 3310",
          5121: "Room 3311",
          5124: "Room 3312",
          5191: "Room 3315",
          5190: "Room 3316",
          5189: "Room 3317",
          5187: "Room 3319",
          5188: "Room 3320",
          5186: "Room 3321",
          5184: "Room 3322",
          5185: "Room 3323",
          5183: "Room 3324",
          4289: "Room 3325",
          4291: "Room 3326",
          4288: "Room 3327",
          4290: "Room 3328",
          4287: "Room 3329",
          4286: "Room 3330",
          4285: "Room 3335",
          4284: "Room 3336",
          4283: "Room 3337",
          4282: "Room 3338",
          4281: "Room 3340",
          4278: "Room 3341",
          4280: "Room 3342",
          4277: "Room 3343",
          4279: "Room 3344",
          4274: "Room 3345",
          4276: "Room 3346",
          4273: "Room 3347",
          4275: "Room 3348",
          4272: "Room 3349",
          4271: "Room 3350",
          4270: "Room 3356",
          4269: "Room 3357",
          4268: "Room 3358",
          4266: "Room 3359",
          4265: "Room 3361",
          4262: "Room 3363",
          4264: "Room 3364",
          4261: "Room 3365",
          4263: "Room 3366",
          4258: "Room 3367",
          4260: "Room 3368",
          4257: "Room 3369",
          4259: "Room 3370",
          4256: "Room 3372",
          2541: "Room 4064",
          2542: "Room 4065",
          2543: "Room 4066",
          2544: "Room 4067",
          2545: "Room 4068",
          2546: "Room 4069",
          2547: "Room 4070",
          2548: "Room 4071",
          2549: "Room 4072",
          2550: "Room 4073",
          2551: "Room 4074",
          2552: "Room 4075",
          2553: "Room 4076",
          2554: "Room 4077",
          2555: "Room 4078",
          2556: "Room 4079",
          2793: "Room 4080",
          2792: "Room 4081",
          2791: "Room 4082",
          2790: "Room 4083",
          2789: "Room 4084",
          2788: "Room 4085",
          2787: "Room 4086",
          2786: "Room 4087",
          2785: "Room 4088",
          2784: "Room 4089",
          2783: "Room 4090",
          2782: "Room 4091",
          2781: "Room 4092",
          2780: "Room 4093",
          2779: "Room 4094",
          2778: "Room 4095",
          4157: "Room 4201",
          4158: "Room 4202",
          4159: "Room 4203",
          4160: "Room 4204",
          4161: "Room 4205",
          4162: "Room 4206",
          4163: "Room 4207",
          4164: "Room 4208",
          4165: "Room 4209",
          4166: "Room 4210",
          4167: "Room 4211",
          4168: "Room 4212",
          4169: "Room 4213",
          4170: "Room 4214",
          4171: "Room 4215",
          4172: "Room 4216",
          4173: "Room 4217",
          4174: "Room 4218",
          4175: "Room 4219",
          4176: "Room 4220",
          4177: "Room 4221",
          4178: "Room 4222",
          4179: "Room 4223",
          4180: "Room 4224",
          4181: "Room 4225",
          4182: "Room 4226",
          4183: "Room 4227",
          4184: "Room 4228",
          4185: "Room 4229",
          4186: "Room 4230",
          4187: "Room 4231",
          4188: "Room 4232",
          4189: "Room 4233",
          4190: "Room 4234",
          4191: "Room 4235",
          4192: "Room 4236",
          3868: "Room 4301",
          3869: "Room 4302",
          3870: "Room 4303",
          3871: "Room 4304",
          3872: "Room 4306",
          3873: "Room 4308",
          3874: "Room 4310",
          3877: "Room 4311",
          3875: "Room 4312",
          3878: "Room 4313",
          3876: "Room 4314",
          3879: "Room 4315",
          3880: "Room 4316",
          3867: "Room 4352",
          3866: "Room 4353",
          3865: "Room 4354",
          3864: "Room 4355",
          3858: "Room 4356",
          3863: "Room 4357",
          3862: "Room 4358",
          3861: "Room 4359",
          3860: "Room 4361",
          3859: "Room 4363",
          3857: "Room 4365",
          3856: "Room 4366",
          3851: "Room 4367",
          3853: "Room 4368",
          3850: "Room 4369",
          3852: "Room 4370",
          3847: "Room 4371",
          3848: "Room 4372",
          2460: "Room 5064",
          2461: "Room 5065",
          2462: "Room 5066",
          2463: "Room 5067",
          2464: "Room 5068",
          2465: "Room 5069",
          2466: "Room 5070",
          2467: "Room 5071",
          2468: "Room 5072",
          2469: "Room 5073",
          2470: "Room 5074",
          2471: "Room 5075",
          2472: "Room 5076",
          2473: "Room 5077",
          2474: "Room 5078",
          2475: "Room 5079",
          2476: "Room 5080",
          2479: "Room 5081",
          2482: "Room 5082",
          2483: "Room 5083",
          2485: "Room 5084",
          2488: "Room 5085",
          2489: "Room 5086",
          2490: "Room 5087",
          2491: "Room 5088",
          2493: "Room 5089",
          2494: "Room 5090",
          2496: "Room 5091",
          2500: "Room 5092",
          2501: "Room 5093",
          2502: "Room 5094",
          2498: "Room 5095",
          3169: "Room 5108",
          3168: "Room 5109",
          3167: "Room 5110",
          3165: "Room 5111",
          3163: "Room 5118",
          3159: "Room 5120",
          3158: "Room 5124",
          3156: "Room 5131",
          3153: "Room 5133",
          3151: "Room 5135",
          3148: "Room 5137",
          3146: "Room 5139",
          3144: "Room 5141",
          3141: "Room 5143",
          3139: "Room 5145",
          3136: "Room 5147",
          3132: "Room 5149",
          3129: "Room 5151",
          3126: "Room 5153",
          3122: "Room 5155",
          3119: "Room 5159",
          3116: "Room 5161",
          3114: "Room 5163",
          3112: "Room 5165",
          3111: "Room 5167",
          3106: "Room 5169",
          3109: "Room 5170",
          3105: "Room 5171",
          3108: "Room 5172",
          3170: "Room 5173",
          2854: "Room 5201",
          2855: "Room 5202",
          2856: "Room 5203",
          2857: "Room 5204",
          2859: "Room 5205",
          2860: "Room 5206",
          2861: "Room 5207",
          2863: "Room 5208",
          2864: "Room 5209",
          2866: "Room 5210",
          2869: "Room 5211",
          2870: "Room 5212",
          2871: "Room 5213",
          2872: "Room 5214",
          2873: "Room 5215",
          2874: "Room 5216",
          2875: "Room 5217",
          2876: "Room 5218",
          2877: "Room 5219",
          2878: "Room 5220",
          2879: "Room 5221",
          2880: "Room 5222",
          2881: "Room 5223",
          2882: "Room 5224",
          2940: "Room 5225",
          2935: "Room 5226",
          2892: "Room 5227",
          2891: "Room 5228",
          2890: "Room 5229",
          2889: "Room 5230",
          2888: "Room 5231",
          2887: "Room 5232",
          2886: "Room 5233",
          2885: "Room 5234",
          2884: "Room 5235",
          2883: "Room 5236",
          2850: "Room 5301",
          2849: "Room 5302",
          2847: "Room 5303",
          2846: "Room 5304",
          2843: "Room 5305",
          2841: "Room 5306",
          2839: "Room 5307",
          2836: "Room 5308",
          2835: "Room 5309",
          2833: "Room 5310",
          2834: "Room 5311",
          2837: "Room 5312",
          2838: "Room 5314",
          2831: "Room 5319",
          2832: "Room 5320",
          2830: "Room 5321",
          2829: "Room 5322",
          2828: "Room 5323",
          2827: "Room 5324",
          2826: "Room 5325",
          2825: "Room 5326",
          2821: "Room 5327",
          2820: "Room 5328",
          2819: "Room 5329",
          2818: "Room 5330",
          2822: "Room 5331",
          2823: "Room 5332",
          2824: "Room 5333",
          2815: "Room 5357",
          2817: "Room 5358",
          2814: "Room 5359",
          2810: "Room 5360",
          2812: "Room 5361",
          2808: "Room 5362",
          2807: "Room 5366",
          2805: "Room 5367",
          2800: "Room 5368",
          2803: "Room 5369",
          2798: "Room 5370",
          2795: "Room 5371",
          2794: "Room 5372",
          3401: "Room 6107",
          3402: "Room 6110",
          3403: "Room 6111",
          3404: "Room 6114",
          3405: "Room 6117",
          3370: "Room 6118",
          3371: "Room 6120",
          3373: "Room 6121",
          3374: "Room 6122",
          3375: "Room 6123",
          3376: "Room 6125",
          3378: "Room 6126",
          3379: "Room 6127",
          3380: "Room 6132",
          3382: "Room 6133",
          3383: "Room 6138",
          3384: "Room 6139",
          3386: "Room 6141",
          3387: "Room 6143",
          3388: "Room 6144",
          3389: "Room 6145",
          3390: "Room 6150",
          3391: "Room 6154",
          3392: "Room 6157",
          3393: "Room 6158",
          3394: "Room 6159",
          3395: "Room 6161",
          3397: "Room 6163",
          3396: "Room 6164",
          3399: "Room 6166",
          3398: "Room 6167",
          3400: "Room 6170",
          3723: "Room 6201",
          3729: "Room 6202",
          3735: "Room 6203",
          3740: "Room 6204",
          3742: "Room 6205",
          3745: "Room 6206",
          3748: "Room 6207",
          3750: "Room 6208",
          3755: "Room 6209",
          3758: "Room 6210",
          3760: "Room 6211",
          3765: "Room 6212",
          3767: "Room 6213",
          3772: "Room 6215",
          3774: "Room 6216",
          3775: "Room 6217",
          3776: "Room 6218",
          3777: "Room 6219",
          3778: "Room 6220",
          4069: "Room 6301",
          2777: "Room 6302",
          2775: "Room 6303",
          2776: "Room 6304",
          2772: "Room 6305",
          2774: "Room 6306",
          2773: "Room 6308",
          2769: "Room 6309",
          2770: "Room 6310",
          2768: "Room 6311",
          2771: "Room 6312",
          2767: "Room 6313",
          2766: "Room 6314",
          2765: "Room 6316",
          2764: "Room 6317",
          2758: "Room 6318",
          2761: "Room 6319",
          2754: "Room 6320",
          2763: "Room 6321",
          2751: "Room 6322",
          2749: "Room 6323",
          2747: "Room 6325",
          2746: "Room 6326",
          2744: "Room 6327",
          2745: "Room 6328",
          2743: "Room 6329",
          2741: "Room 6330",
          2742: "Room 6331",
          2740: "Room 6335",
          2739: "Room 6336",
          2738: "Room 6337",
          2737: "Room 6338",
          2736: "Room 6339",
          2735: "Room 6340",
          2734: "Room 6341",
          2732: "Room 6342",
          2733: "Room 6343",
          2730: "Room 6344",
          2731: "Room 6345",
          2726: "Room 6346",
          2728: "Room 6347",
          2719: "Room 6352",
          2717: "Room 6353",
          2715: "Room 6354",
          2714: "Room 6355",
          2711: "Room 6356",
          2713: "Room 6357",
          2708: "Room 6358",
          2703: "Room 6359",
          2701: "Room 6360",
          2705: "Room 6361",
          2695: "Room 6362",
          2698: "Room 6363",
          2692: "Room 6364",
          2697: "Room 6365",
          2688: "Room 6366",
          2690: "Room 6367",
          3359: "Room 7107",
          3360: "Room 7110",
          3361: "Room 7111",
          3363: "Room 7114",
          3364: "Room 7117",
          3319: "Room 7118",
          3322: "Room 7120",
          3323: "Room 7121",
          3324: "Room 7122",
          3327: "Room 7123",
          3328: "Room 7125",
          3332: "Room 7126",
          3333: "Room 7127",
          3335: "Room 7132",
          3336: "Room 7133",
          3339: "Room 7138",
          3341: "Room 7139",
          3344: "Room 7141",
          3345: "Room 7143",
          3347: "Room 7144",
          3348: "Room 7145",
          3349: "Room 7150",
          3350: "Room 7154",
          3352: "Room 7157",
          3353: "Room 7158",
          3354: "Room 7159",
          3355: "Room 7163",
          3356: "Room 7166",
          3357: "Room 7169",
          3358: "Room 7170",
          3685: "Room 7201",
          3687: "Room 7202",
          3692: "Room 7203",
          3694: "Room 7204",
          3697: "Room 7205",
          3698: "Room 7206",
          3701: "Room 7216",
          3703: "Room 7217",
          3706: "Room 7218",
          3710: "Room 7219",
          3707: "Room 7220",
          3711: "Room 7221",
          3712: "Room 7222",
          3713: "Room 7223",
          3714: "Room 7224",
          3715: "Room 7225",
          3716: "Room 7226",
          3717: "Room 7227",
          3718: "Room 7228",
          3719: "Room 7229",
          3720: "Room 7230",
          3721: "Room 7231",
          3722: "Room 7232",
          3921: "Room 7301",
          3922: "Room 7302",
          3923: "Room 7303",
          3925: "Room 7304",
          3924: "Room 7305",
          3926: "Room 7306",
          3929: "Room 7307",
          3927: "Room 7308",
          3930: "Room 7309",
          3928: "Room 7310",
          3931: "Room 7311",
          3932: "Room 7312",
          7002: "Room 7315",
          7000: "Room 7316",
          7005: "Room 7317",
          7007: "Room 7318",
          7008: "Room 7319",
          7009: "Room 7335",
          7010: "Room 7336",
          8638: "Room 7339",
          8639: "Room 7340",
          8640: "Room 7341",
          8641: "Room 7342",
          8642: "Room 7343",
          8643: "Room 7344",
          8644: "Room 7345",
          8645: "Room 7346",
          8646: "Room 7347",
          8647: "Room 7348",
          8648: "Room 7349",
          8649: "Room 7350",
          8650: "Room 7351",
          2530: "Room 7380",
          2533: "Room 7381",
          2534: "Room 7382",
          2535: "Room 7383",
          2536: "Room 7384",
          2537: "Room 7386",
          2538: "Room 7388",
          2539: "Room 7390",
          2540: "Room 7392",
          12371: "Room A5083",
        },
        reloading: {
          peer_filters: ["customer_ids", "facility_ids", "unit_ids"],
          url: "https://local.swipesense.test/ajax/filter/getOptions/room_config_ids_9ce60f62798756bdfe82a9028152b743?expires=1681751449&signature=c5e513b308c159a981a2b2a4f6ecae4b2c4617ce600bb183a0d48df95023bbdd",
        },
      },
      node_type_ids: {
        _filter_component_class: "NodeTypeIdsFilter",
        authorized: false,
        visible: false,
        value: null,
        valueDisplay: "All",
        default: null,
        initial: null,
        options: {
          8: "System",
          9: "Region",
          0: "Facility",
          1: "Building",
          3: "Floor",
          4: "Unit",
          6: "Room",
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/node_type_ids_9c36b3a58e2ebd7a87e12cf028856ee1?expires=1681751449&signature=2f631f89b87e809a4c292d2f52ceafe009cdc9b770603f61384995dad3858693",
        },
      },
      tag_ids: {
        _filter_component_class: "TagIdsFilter",
        authorized: false,
        visible: false,
        value: null,
        valueDisplay: "All",
        default: null,
        initial: null,
        options: {
          4: "Burn",
          5: "Cardiac",
          1: "Critical Care",
          6: "Emergency",
          2: "Med/Surg",
          7: "Neuro",
          8: "Oncology",
          9: "Orthopedics",
          10: "Peds/NICU",
          12: "Procedural",
          11: "Rehab",
          13: "Step-down/Intermediate Care",
          3: "Women's Services",
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/tag_ids_e97f110910fdad667e46a81a61c19fb3?expires=1681751449&signature=74b04984a02c024624a55afce53ff4c95ac82847901001bbb7e9a76b0b0ebee0",
        },
      },
      histogram_y_axis_max: {
        _filter_component_class: "ReportHistogramYAxisMaxFilter",
        authorized: false,
        visible: false,
        value: null,
        valueDisplay: "Auto-Scale",
        default: null,
        initial: null,
        options: {
          "": "Auto-Scale",
          5: "5%",
          10: "10%",
          15: "15%",
          20: "20%",
          25: "25%",
          30: "30%",
          35: "35%",
          40: "40%",
          45: "45%",
          50: "50%",
          55: "55%",
          60: "60%",
          65: "65%",
          70: "70%",
          75: "75%",
          80: "80%",
          85: "85%",
          90: "90%",
          95: "95%",
          100: "100%",
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/histogram_y_axis_max_b5044f474a1dc93daec4168188a8a06d?expires=1681751449&signature=326e23024af231ce158ae3c81a9a5e15d6e6617c47a7b96bba362cba4bc24b29",
        },
      },
      include_excluded_data: {
        _filter_component_class: "ReportIncludeExcludedDataFilter",
        authorized: false,
        visible: false,
        value: false,
        valueDisplay: "Exclude",
        default: false,
        initial: null,
        options: {
          1: "Include",
          0: "Exclude",
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/include_excluded_data_abb578fb662b64ad4460228dcbd74cbe?expires=1681751449&signature=1a1018399c4a68d016747374527353a96880647f35cca110738738bfe492db35",
        },
      },
      include_partial_periods: {
        _filter_component_class: "ReportIncludePartialPeriodsFilter",
        authorized: false,
        visible: false,
        value: true,
        valueDisplay: "Yes (include the current partial day/week/month)",
        default: true,
        initial: null,
        options: {
          1: "Yes (include the current partial day/week/month)",
          0: "No (only include full days/weeks/months)",
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/include_partial_periods_4eecb8345dc30a2cfd78c1ddc8a6b08c?expires=1681751449&signature=600821b958bcd7e1643a174c4247b508621bae9bb1614fcd4c887bfff0e7d9b0",
        },
      },
      sum_over_time_range: {
        _filter_component_class: "ReportSumOverTimeRangeFilter",
        authorized: false,
        visible: false,
        value: false,
        valueDisplay: "No, keep by day/week/month",
        default: false,
        initial: null,
        options: {
          1: "Yes, only one data point per Group",
          0: "No, keep by day/week/month",
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/sum_over_time_range_df9ad0efd6ba7f31ce349743c30bca12?expires=1681751449&signature=def8ea59565a52022e5ee5c2e8e31e0db5d74012bb974ca4ebfcd71afff7a89f",
        },
      },
      limit_histogram_length: {
        _filter_component_class: "ReportLimitHistogramLengthFilter",
        authorized: true,
        visible: true,
        value: true,
        valueDisplay: "Yes (limit to 4 weeks)",
        default: true,
        initial: null,
        options: {
          1: "Yes (limit to 4 weeks)",
          0: "No (full length)",
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/limit_histogram_length_d25d64caf13ad8e2e76acec8a42cfb39?expires=1681751449&signature=b0765dfede09fdb5eaf7e495423b146d79f8c43e0260e3330a86fc096d1826a7",
        },
      },
      aggregation: {
        _filter_component_class: "ReportAggregationFilter",
        authorized: true,
        visible: true,
        value: "weekly",
        valueDisplay: "Weekly",
        default: "weekly",
        initial: null,
        options: {
          daily: "Daily",
          weekly: "Weekly",
          monthly: "Monthly",
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/aggregation_f06b050829d96ff4a17054974123e217?expires=1681751449&signature=23f62beaf622095a243ae38eb653357febe68e14295d0088e515de697595808f",
        },
      },
      date_range: {
        _filter_component_class: "ReportDateRangeFilter",
        authorized: true,
        visible: true,
        value: ["DD", "2023-02-06", "2023-04-17"],
        valueDisplay: "2/6/2023–4/17/2023",
        default: ["DD", "2023-02-06", "2023-04-17"],
        initial: null,
        options: {
          "Days (rolling)": {
            "DR|offset_day|": "Last 1 Day",
            "DR|offset_day*2|": "Last 2 Days",
            "DR|offset_day*3|": "Last 3 Days",
            "DR|offset_day*4|": "Last 4 Days",
            "DR|offset_day*5|": "Last 5 Days",
            "DR|offset_day*6|": "Last 6 Days",
            "DR|offset_day*7|": "Last 7 Days",
            "DR|offset_day*14|": "Last 14 Days",
            "DR|offset_day*30|": "Last 30 Days",
            "DR|offset_day*90|": "Last 90 Days",
            "DR|offset_day*180|": "Last 180 Days",
          },
          "Weeks (rolling)": {
            "DR|offset_week|": "Last 1 Week",
            "DR|offset_week*2|": "Last 2 Weeks",
            "DR|offset_week*3|": "Last 3 Weeks",
            "DR|offset_week*4|": "Last 4 Weeks",
            "DR|offset_week*5|": "Last 5 Weeks",
            "DR|offset_week*6|": "Last 6 Weeks",
            "DR|offset_week*7|": "Last 7 Weeks",
            "DR|offset_week*8|": "Last 8 Weeks",
            "DR|offset_week*9|": "Last 9 Weeks",
            "DR|offset_week*10|": "Last 10 Weeks",
            "DR|offset_week*11|": "Last 11 Weeks",
            "DR|offset_week*12|": "Last 12 Weeks",
            "DR|offset_week*13|": "Last 13 Weeks",
            "DR|offset_week*14|": "Last 14 Weeks",
            "DR|offset_week*15|": "Last 15 Weeks",
            "DR|offset_week*16|": "Last 16 Weeks",
            "DR|offset_week*17|": "Last 17 Weeks",
            "DR|offset_week*18|": "Last 18 Weeks",
            "DR|offset_week*19|": "Last 19 Weeks",
            "DR|offset_week*20|": "Last 20 Weeks",
          },
          "Months (rolling)": {
            "DR|offset_month|": "Last 1 Month",
            "DR|offset_month*2|": "Last 2 Months",
            "DR|offset_month*3|": "Last 3 Months",
            "DR|offset_month*4|": "Last 4 Months",
            "DR|offset_month*5|": "Last 5 Months",
            "DR|offset_month*6|": "Last 6 Months",
            "DR|offset_month*7|": "Last 7 Months",
            "DR|offset_month*8|": "Last 8 Months",
            "DR|offset_month*9|": "Last 9 Months",
            "DR|offset_month*10|": "Last 10 Months",
            "DR|offset_month*11|": "Last 11 Months",
            "DR|offset_month*12|": "Last 12 Months",
          },
          "Years (rolling)": {
            "DR|offset_year|": "Last 1 Year",
            "DR|offset_year*2|": "Last 2 Years",
            "DR|offset_year*3|": "Last 3 Years",
            "DR|offset_year*4|": "Last 4 Years",
            "DR|offset_year*5|": "Last 5 Years",
            "DR|offset_year*6|": "Last 6 Years",
            "DR|offset_year*7|": "Last 7 Years",
            "DR|offset_year*8|": "Last 8 Years",
            "DR|offset_year*9|": "Last 9 Years",
            "DR|offset_year*10|": "Last 10 Years",
          },
          "Current-To-Date": {
            "DR|offset_S+day*0|": "Start of Day to Now",
            "DR|offset_S+week*0|": "Start of Week to Now (WTD)",
            "DR|offset_S+month*0|": "Start of Month to Now (MTD)",
            "DR|offset_S+quarter*0|": "Start of Quarter to Now (QTD)",
            "DR|offset_S+semester*0|":
              "Start of Semester (Half-year) to Now (SmTD)",
            "DR|offset_S+year*0|": "Start of Year to Now (YTD)",
          },
          "Previous-To-Date": {
            "DR|offset_S+day*1|": "Start of Previous Day to Now",
            "DR|offset_S+week*1|": "Start of Previous Week to Now",
            "DR|offset_S+month*1|": "Start of Previous Month to Now",
            "DR|offset_S+quarter*1|": "Start of Previous Quarter to Now",
            "DR|offset_S+semester*1|":
              "Start of Previous Semester (Half-year) to Now",
            "DR|offset_S+year*1|": "Start of Previous Year to Now",
          },
          "From-Date": {
            "DR|set_-01-01_00:00:00|": "Last January 1st to Now",
            "DR|set_-02-01_00:00:00|": "Last February 1st to Now",
            "DR|set_-03-01_00:00:00|": "Last March 1st to Now",
            "DR|set_-04-01_00:00:00|": "Last April 1st to Now",
            "DR|set_-05-01_00:00:00|": "Last May 1st to Now",
            "DR|set_-06-01_00:00:00|": "Last June 1st to Now",
            "DR|set_-07-01_00:00:00|": "Last July 1st to Now",
            "DR|set_-08-01_00:00:00|": "Last August 1st to Now",
            "DR|set_-09-01_00:00:00|": "Last September 1st to Now",
            "DR|set_-10-01_00:00:00|": "Last October 1st to Now",
            "DR|set_-11-01_00:00:00|": "Last November 1st to Now",
            "DR|set_-12-01_00:00:00|": "Last December 1st to Now",
          },
          "Previous Period": {
            "DR|offset_S+day*1|offset_E+day*1": "Previous Complete Day",
            "DR|offset_S+week*1|offset_E+week*1": "Previous Complete Week",
            "DR|offset_S+month*1|offset_E+month*1": "Previous Complete Month",
            "DR|offset_S+quarter*1|offset_E+quarter*1":
              "Previous Complete Quarter",
            "DR|offset_S+semester*1|offset_E+semester*1":
              "Previous Complete Semester (Half-year)",
            "DR|offset_S+year*1|offset_E+year*1": "Previous Complete Year",
          },
        },
        reloading: {
          peer_filters: [],
          url: "https://local.swipesense.test/ajax/filter/getOptions/date_range_199cb3af7185861b90b25d4fe7d4ef4e?expires=1681751449&signature=af5c1e577856e076f69d1186c7f32d6b121194e33cb951dda8b4661dc1980565",
        },
      },
    },
    subtitle: {
      _component_class: "ReportSubtitle",
      displayTime: false,
      endDate: "2023-04-17T16:10:49.073038Z",
      lastEtlAtMessage: null,
      startDate: "2023-02-06T06:00:00.000000Z",
      text: "Facility-1949",
      componentName: null,
      attributes: {},
      setGroupNames: {},
      setLastEtlAtDate: {},
      jsonSerialize: {},
      resolve: {},
      flushCache: {},
      forgetFactory: {},
      forgetComponentsResolver: {},
      resolveComponentsUsing: {},
    },
    summaryBlocks: {
      _component_class: "SummaryTiles",
      tiles: [
        {
          _component_class: "PerformanceOverall",
          performance: 0.8450073198734565,
          subtitle: "In This Time Period",
          panelType: "info",
          panelWidth: 4,
          componentName: null,
          attributes: {},
          getPanelTypeFromPerformance: {},
          jsonSerialize: {},
          resolve: {},
          flushCache: {},
          forgetFactory: {},
          forgetComponentsResolver: {},
          resolveComponentsUsing: {},
        },
        {
          _component_class: "PerformanceChanged",
          icon: "arrow-up",
          percentChanged: 0.0028925891992694908,
          percentChangedFormatted: "0%",
          subtitle: "2/6/2023 to 4/17/2023",
          panelType: "success",
          panelWidth: 4,
          componentName: null,
          attributes: {},
          formatPercentChanged: {},
          formatSubtitle: {},
          setIcon: {},
          getPanelTypeFromPerformance: {},
          jsonSerialize: {},
          resolve: {},
          flushCache: {},
          forgetFactory: {},
          forgetComponentsResolver: {},
          resolveComponentsUsing: {},
        },
        {
          _component_class: "EventCounts",
          events: 1348341,
          opportunities: 1595656,
          panelType: "info",
          panelWidth: 4,
          componentName: null,
          attributes: {},
          getPanelTypeFromPerformance: {},
          jsonSerialize: {},
          resolve: {},
          flushCache: {},
          forgetFactory: {},
          forgetComponentsResolver: {},
          resolveComponentsUsing: {},
        },
      ],
      componentName: null,
      attributes: {},
      addSummaryTile: {},
      jsonSerialize: {},
      resolve: {},
      flushCache: {},
      forgetFactory: {},
      forgetComponentsResolver: {},
      resolveComponentsUsing: {},
    },
    handHygienePlot: {
      _graph_class: "PercentageLineGraph",
      chartId: "chart_d536d334ec",
      chartTestKey: "Hand Hygiene Performance Over Time",
      chartType: "line",
      titleText: "Hand Hygiene Performance Over Time",
      subtitle: null,
      xAxis: {
        _plot_component_class: "DateTimeAxis",
        type: "datetime",
        units:
          '{@JS}window.HighchartsHelper.getAxisDataIntervalUnits("weekly")',
        minorTickInterval:
          '{@JS}window.HighchartsHelper.getAxisDataIntervalMinorTickInterval("weekly")',
        title: {
          text: '{@JS}window.HighchartsHelper.getAxisDataIntervalLabel("weekly")',
          style: {
            fontSize: "15px",
          },
        },
        labels:
          "{@JS}{'formatter': function () { return Highcharts.dateFormat(window.HighchartsHelper.getAxisDataIntervalFormat(\"weekly\"), this.value);}}",
      },
      yAxis: {
        _plot_component_class: "PercentageAxis",
        title: {
          text: "Performance (%)",
          style: {
            fontSize: "15px",
          },
        },
        min: 0,
        max: 1,
        floor: 0,
        ceiling: 1,
        labels:
          "{@JS}{'formatter': function() { return (this.value * 100) + \" % \"; }}",
        tickPositions:
          "{@JS}window.HighchartsHelper.getTickPositions(1.0, true)",
      },
      xAxisPlotlines:
        '[{"series":{"name":"Phase Markers","data":[],"visible":true,"showInLegend":true,"legendIndex":-99,"type":"area","color":"#808080"}, "lines": []}]',
      yAxisPlotlines:
        '[{"series":{"name":"Hand Hygiene Goal","data":[],"visible":false,"showInLegend":true,"legendIndex":-99,"type":"area","color":"gold"}, "lines": [{"value":0.8,"label":{"align":"left","style":{"color":"grey"},"text":"<i>Goal:<\\/i> 80%","x":2,"y":15,"rotation":0},"zIndex":2.1,"color":"gold","dashStyle":null,"width":1.5}]}]',
      showYearBoundaries: true,
      showColorBands: true,
      tooltip: {
        _plot_component_class: "PercentageTooltip",
        pointFormat: "{@JS}window.HighchartsPointFormats.specifiedPercentage",
      },
      dataSeries: [
        {
          name: "System-1948 Health System",
          data: [
            {
              x: 1676246400000,
              y: 0.8430166575734567,
              percentage: 84,
              opportunities: 617677,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 617,677",
            },
            {
              x: 1676851200000,
              y: 0.8423990676465802,
              percentage: 84,
              opportunities: 608353,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 608,353",
            },
            {
              x: 1677456000000,
              y: 0.8428874364468563,
              percentage: 84,
              opportunities: 630968,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 630,968",
            },
            {
              x: 1678060800000,
              y: 0.8464264502078797,
              percentage: 85,
              opportunities: 606120,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 606,120",
            },
            {
              x: 1678665600000,
              y: 0.8428646567230083,
              percentage: 84,
              opportunities: 616004,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 616,004",
            },
            {
              x: 1679270400000,
              y: 0.8438942012383011,
              percentage: 84,
              opportunities: 590971,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 590,971",
            },
            {
              x: 1679875200000,
              y: 0.8460984512183092,
              percentage: 85,
              opportunities: 608220,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 608,220",
            },
            {
              x: 1680480000000,
              y: 0.8435278061757845,
              percentage: 84,
              opportunities: 607437,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 607,437",
            },
            {
              x: 1681084800000,
              y: 0.8453350288500716,
              percentage: 85,
              opportunities: 608664,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 608,664",
            },
            {
              x: 1681689600000,
              y: 0.8347949080622348,
              percentage: 83,
              opportunities: 3535,
              name: "April 17, 2023–April 23, 2023<br>Opportunities: 3,535",
            },
          ],
          showInLegend: true,
          visible: false,
          legendType: "nodeAncestor",
          nodeType_id: 8,
        },
        {
          name: "Region-3413",
          data: [
            {
              x: 1676246400000,
              y: 0.83846483563586,
              percentage: 84,
              opportunities: 303290,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 303,290",
            },
            {
              x: 1676851200000,
              y: 0.8378331388525747,
              percentage: 84,
              opportunities: 299087,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 299,087",
            },
            {
              x: 1677456000000,
              y: 0.8378623010815933,
              percentage: 84,
              opportunities: 306030,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 306,030",
            },
            {
              x: 1678060800000,
              y: 0.8422013201431676,
              percentage: 84,
              opportunities: 295877,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 295,877",
            },
            {
              x: 1678665600000,
              y: 0.8358416521871264,
              percentage: 84,
              opportunities: 302726,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 302,726",
            },
            {
              x: 1679270400000,
              y: 0.8340802112695328,
              percentage: 83,
              opportunities: 292328,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 292,328",
            },
            {
              x: 1679875200000,
              y: 0.8355136845729115,
              percentage: 84,
              opportunities: 302092,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 302,092",
            },
            {
              x: 1680480000000,
              y: 0.8293031135155813,
              percentage: 83,
              opportunities: 292788,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 292,788",
            },
            {
              x: 1681084800000,
              y: 0.8300788461206673,
              percentage: 83,
              opportunities: 289805,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 289,805",
            },
            {
              x: 1681689600000,
              y: 0.8376113148245155,
              percentage: 84,
              opportunities: 1909,
              name: "April 17, 2023–April 23, 2023<br>Opportunities: 1,909",
            },
          ],
          showInLegend: true,
          visible: false,
          legendType: "nodeAncestor",
          nodeType_id: 9,
        },
        {
          name: "Facility-1949",
          data: [
            {
              x: 1676246400000,
              y: 0.8482804831894973,
              percentage: 85,
              opportunities: 161179,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 161,179",
            },
            {
              x: 1676851200000,
              y: 0.8505436285747651,
              percentage: 85,
              opportunities: 162703,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 162,703",
            },
            {
              x: 1677456000000,
              y: 0.8465832215945978,
              percentage: 85,
              opportunities: 164819,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 164,819",
            },
            {
              x: 1678060800000,
              y: 0.8508516931864545,
              percentage: 85,
              opportunities: 156864,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 156,864",
            },
            {
              x: 1678665600000,
              y: 0.8460834289512129,
              percentage: 85,
              opportunities: 159489,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 159,489",
            },
            {
              x: 1679270400000,
              y: 0.8443835544255773,
              percentage: 84,
              opportunities: 151822,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 151,822",
            },
            {
              x: 1679875200000,
              y: 0.8470149253731343,
              percentage: 85,
              opportunities: 161336,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 161,336",
            },
            {
              x: 1680480000000,
              y: 0.8383287745116924,
              percentage: 84,
              opportunities: 155742,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 155,742",
            },
            {
              x: 1681084800000,
              y: 0.8381527048559413,
              percentage: 84,
              opportunities: 153132,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 153,132",
            },
            {
              x: 1681689600000,
              y: 0.8424396442185514,
              percentage: 84,
              opportunities: 1574,
              name: "April 17, 2023–April 23, 2023<br>Opportunities: 1,574",
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_nodes",
          legendType: "selectedIndividual",
          nodeType_id: 0,
        },
      ],
      plotOptions: null,
      helpText: "This graph shows Hand Hygiene (HH) in percent.",
      extraJavascript: "",
      isExportable: true,
    },
    handHygieneByGroupPlot: {
      _graph_class: "PercentageLineGraph",
      chartId: "chart_36e06c93",
      chartTestKey: "Hand Hygiene Performance By Group Over Time",
      chartType: "line",
      titleText: "Hand Hygiene Performance By Group Over Time",
      subtitle: null,
      xAxis: {
        _plot_component_class: "DateTimeAxis",
        type: "datetime",
        units:
          '{@JS}window.HighchartsHelper.getAxisDataIntervalUnits("weekly")',
        minorTickInterval:
          '{@JS}window.HighchartsHelper.getAxisDataIntervalMinorTickInterval("weekly")',
        title: {
          text: '{@JS}window.HighchartsHelper.getAxisDataIntervalLabel("weekly")',
          style: {
            fontSize: "15px",
          },
        },
        labels:
          "{@JS}{'formatter': function () { return Highcharts.dateFormat(window.HighchartsHelper.getAxisDataIntervalFormat(\"weekly\"), this.value);}}",
      },
      yAxis: {
        _plot_component_class: "PercentageAxis",
        title: {
          text: "Performance (%)",
          style: {
            fontSize: "15px",
          },
        },
        min: 0,
        max: 1,
        floor: 0,
        ceiling: 1,
        labels:
          "{@JS}{'formatter': function() { return (this.value * 100) + \" % \"; }}",
        tickPositions:
          "{@JS}window.HighchartsHelper.getTickPositions(1.0, true)",
      },
      xAxisPlotlines: "[]",
      yAxisPlotlines: "[]",
      showYearBoundaries: true,
      showColorBands: true,
      tooltip: {
        _plot_component_class: "PercentageTooltip",
        pointFormat: "{@JS}window.HighchartsPointFormats.specifiedPercentage",
      },
      dataSeries: [
        {
          name: "Nurses (RN)-952",
          data: [
            {
              x: 1675641600000,
              y: 0.9126231302444363,
              percentage: 91,
              opportunities: 16446,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 16,446",
            },
            {
              x: 1676246400000,
              y: 0.9216755558218973,
              percentage: 92,
              opportunities: 16687,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 16,687",
            },
            {
              x: 1676851200000,
              y: 0.9231592720352088,
              percentage: 92,
              opportunities: 16814,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 16,814",
            },
            {
              x: 1677456000000,
              y: 0.9243426359090176,
              percentage: 92,
              opportunities: 18597,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 18,597",
            },
            {
              x: 1678060800000,
              y: 0.9167877427024171,
              percentage: 92,
              opportunities: 15142,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 15,142",
            },
            {
              x: 1678665600000,
              y: 0.9235735473739313,
              percentage: 92,
              opportunities: 17193,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 17,193",
            },
            {
              x: 1679270400000,
              y: 0.9259235086803289,
              percentage: 93,
              opportunities: 15322,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 15,322",
            },
            {
              x: 1679875200000,
              y: 0.933921664760037,
              percentage: 93,
              opportunities: 18357,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 18,357",
            },
            {
              x: 1680480000000,
              y: 0.9236305477808876,
              percentage: 92,
              opportunities: 15006,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 15,006",
            },
            {
              x: 1681084800000,
              y: 0.925930663937572,
              percentage: 93,
              opportunities: 15634,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 15,634",
            },
            {
              x: 1681689600000,
              y: 0.9234234234234234,
              percentage: 92,
              opportunities: 222,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 222",
            },
          ],
          visible: true,
          opportunities: 165420,
          successful_opportunities: 152734,
          performance: 0.9233103615040503,
        },
        {
          name: "Nurses (RN)-967",
          data: [
            {
              x: 1675641600000,
              y: 0.8518411413148256,
              percentage: 85,
              opportunities: 10374,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 10,374",
            },
            {
              x: 1676246400000,
              y: 0.8405747126436781,
              percentage: 84,
              opportunities: 8700,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 8,700",
            },
            {
              x: 1676851200000,
              y: 0.8402107111501317,
              percentage: 84,
              opportunities: 9112,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 9,112",
            },
            {
              x: 1677456000000,
              y: 0.8276545657291537,
              percentage: 83,
              opportunities: 8071,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 8,071",
            },
            {
              x: 1678060800000,
              y: 0.8291517323775388,
              percentage: 83,
              opportunities: 8370,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 8,370",
            },
            {
              x: 1678665600000,
              y: 0.8108310456700774,
              percentage: 81,
              opportunities: 8014,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 8,014",
            },
            {
              x: 1679270400000,
              y: 0.8258957444056396,
              percentage: 83,
              opportunities: 7731,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 7,731",
            },
            {
              x: 1679875200000,
              y: 0.8420735382760699,
              percentage: 84,
              opportunities: 8295,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 8,295",
            },
            {
              x: 1680480000000,
              y: 0.8218233840551437,
              percentage: 82,
              opportunities: 7689,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 7,689",
            },
            {
              x: 1681084800000,
              y: 0.8004590080326406,
              percentage: 80,
              opportunities: 7843,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 7,843",
            },
            {
              x: 1681689600000,
              y: 0.7559055118110236,
              percentage: 76,
              opportunities: 127,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 127",
            },
          ],
          visible: true,
          opportunities: 84326,
          successful_opportunities: 69987,
          performance: 0.8299575457154377,
        },
        {
          name: "Nurses (RN)-965",
          data: [
            {
              x: 1675641600000,
              y: 0.8610263119639483,
              percentage: 86,
              opportunities: 6879,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 6,879",
            },
            {
              x: 1676246400000,
              y: 0.8753501400560224,
              percentage: 88,
              opportunities: 7140,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 7,140",
            },
            {
              x: 1676851200000,
              y: 0.8581818181818182,
              percentage: 86,
              opportunities: 6050,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 6,050",
            },
            {
              x: 1677456000000,
              y: 0.8475747320924986,
              percentage: 85,
              opportunities: 7092,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 7,092",
            },
            {
              x: 1678060800000,
              y: 0.858701041150223,
              percentage: 86,
              opportunities: 6051,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 6,051",
            },
            {
              x: 1678665600000,
              y: 0.8596573208722741,
              percentage: 86,
              opportunities: 6420,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 6,420",
            },
            {
              x: 1679270400000,
              y: 0.8528455284552846,
              percentage: 85,
              opportunities: 6150,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 6,150",
            },
            {
              x: 1679875200000,
              y: 0.8576358930112166,
              percentage: 86,
              opportunities: 6954,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 6,954",
            },
            {
              x: 1680480000000,
              y: 0.8488726386349786,
              percentage: 85,
              opportunities: 6564,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 6,564",
            },
            {
              x: 1681084800000,
              y: 0.8533685601056803,
              percentage: 85,
              opportunities: 6813,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 6,813",
            },
            {
              x: 1681689600000,
              y: 0.725,
              percentage: 73,
              opportunities: 40,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 40",
            },
          ],
          visible: true,
          opportunities: 66153,
          successful_opportunities: 56715,
          performance: 0.8573307333000771,
        },
        {
          name: "Nurses (RN)-964",
          data: [
            {
              x: 1675641600000,
              y: 0.8110441439973937,
              percentage: 81,
              opportunities: 6139,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 6,139",
            },
            {
              x: 1676246400000,
              y: 0.8451704545454546,
              percentage: 85,
              opportunities: 6336,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 6,336",
            },
            {
              x: 1676851200000,
              y: 0.8472121650977552,
              percentage: 85,
              opportunities: 5524,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 5,524",
            },
            {
              x: 1677456000000,
              y: 0.826605504587156,
              percentage: 83,
              opportunities: 5450,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 5,450",
            },
            {
              x: 1678060800000,
              y: 0.8527555024739806,
              percentage: 85,
              opportunities: 5861,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 5,861",
            },
            {
              x: 1678665600000,
              y: 0.8381578947368421,
              percentage: 84,
              opportunities: 5320,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 5,320",
            },
            {
              x: 1679270400000,
              y: 0.8164944843284889,
              percentage: 82,
              opportunities: 5711,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 5,711",
            },
            {
              x: 1679875200000,
              y: 0.8208049718851731,
              percentage: 82,
              opportunities: 6758,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 6,758",
            },
            {
              x: 1680480000000,
              y: 0.8240448147084172,
              percentage: 82,
              opportunities: 6962,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 6,962",
            },
            {
              x: 1681084800000,
              y: 0.8525944993903265,
              percentage: 85,
              opportunities: 7381,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 7,381",
            },
            {
              x: 1681689600000,
              y: 0.9310344827586207,
              percentage: 93,
              opportunities: 58,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 58",
            },
          ],
          visible: false,
          opportunities: 61500,
          successful_opportunities: 51270,
          performance: 0.8336585365853658,
        },
        {
          name: "Nurses (RN)-950",
          data: [
            {
              x: 1675641600000,
              y: 0.8158719683220591,
              percentage: 82,
              opportunities: 6061,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 6,061",
            },
            {
              x: 1676246400000,
              y: 0.8283298826777088,
              percentage: 83,
              opportunities: 5796,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 5,796",
            },
            {
              x: 1676851200000,
              y: 0.8425954198473282,
              percentage: 84,
              opportunities: 6550,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 6,550",
            },
            {
              x: 1677456000000,
              y: 0.8197217180883243,
              percentage: 82,
              opportunities: 6612,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 6,612",
            },
            {
              x: 1678060800000,
              y: 0.8272290048094709,
              percentage: 83,
              opportunities: 5406,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 5,406",
            },
            {
              x: 1678665600000,
              y: 0.8387755102040816,
              percentage: 84,
              opportunities: 5880,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 5,880",
            },
            {
              x: 1679270400000,
              y: 0.8179531824337396,
              percentage: 82,
              opportunities: 5169,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 5,169",
            },
            {
              x: 1679875200000,
              y: 0.8394253790901836,
              percentage: 84,
              opportunities: 6265,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 6,265",
            },
            {
              x: 1680480000000,
              y: 0.8356004663816556,
              percentage: 84,
              opportunities: 5146,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 5,146",
            },
            {
              x: 1681084800000,
              y: 0.8320501342882721,
              percentage: 83,
              opportunities: 5585,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 5,585",
            },
            {
              x: 1681689600000,
              y: 0.8888888888888888,
              percentage: 89,
              opportunities: 54,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 54",
            },
          ],
          visible: false,
          opportunities: 58524,
          successful_opportunities: 48571,
          performance: 0.8299330189324038,
        },
        {
          name: "<i>Unassigned Badges</i>",
          data: [
            {
              x: 1675641600000,
              y: 0.7835392128698649,
              percentage: 78,
              opportunities: 6962,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 6,962",
            },
            {
              x: 1676246400000,
              y: 0.8503388189738625,
              percentage: 85,
              opportunities: 5165,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 5,165",
            },
            {
              x: 1676851200000,
              y: 0.8115183246073299,
              percentage: 81,
              opportunities: 6112,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 6,112",
            },
            {
              x: 1677456000000,
              y: 0.832903463522476,
              percentage: 83,
              opportunities: 5428,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 5,428",
            },
            {
              x: 1678060800000,
              y: 0.8413409824309789,
              percentage: 84,
              opportunities: 5578,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 5,578",
            },
            {
              x: 1678665600000,
              y: 0.8388064971751412,
              percentage: 84,
              opportunities: 5664,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 5,664",
            },
            {
              x: 1679270400000,
              y: 0.8249005116543491,
              percentage: 82,
              opportunities: 5277,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 5,277",
            },
            {
              x: 1679875200000,
              y: 0.8230670809723999,
              percentage: 82,
              opportunities: 5471,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 5,471",
            },
            {
              x: 1680480000000,
              y: 0.8155323998697492,
              percentage: 82,
              opportunities: 6142,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 6,142",
            },
            {
              x: 1681084800000,
              y: 0.7937971500419112,
              percentage: 79,
              opportunities: 5965,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 5,965",
            },
            {
              x: 1681689600000,
              y: 0.8571428571428571,
              percentage: 86,
              opportunities: 28,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 28",
            },
          ],
          visible: false,
          opportunities: 57792,
          successful_opportunities: 47396,
          performance: 0.8201135105204873,
        },
        {
          name: "Techs-1002",
          data: [
            {
              x: 1675641600000,
              y: 0.802179222243096,
              percentage: 80,
              opportunities: 5323,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 5,323",
            },
            {
              x: 1676246400000,
              y: 0.8123308851952068,
              percentage: 81,
              opportunities: 5174,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 5,174",
            },
            {
              x: 1676851200000,
              y: 0.8121897955132023,
              percentage: 81,
              opportunities: 5037,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 5,037",
            },
            {
              x: 1677456000000,
              y: 0.7941487486781812,
              percentage: 79,
              opportunities: 5674,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 5,674",
            },
            {
              x: 1678060800000,
              y: 0.813172338090011,
              percentage: 81,
              opportunities: 4555,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 4,555",
            },
            {
              x: 1678665600000,
              y: 0.8009615384615385,
              percentage: 80,
              opportunities: 6240,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 6,240",
            },
            {
              x: 1679270400000,
              y: 0.8127870010596963,
              percentage: 81,
              opportunities: 5662,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 5,662",
            },
            {
              x: 1679875200000,
              y: 0.8043942630454685,
              percentage: 80,
              opportunities: 6554,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 6,554",
            },
            {
              x: 1680480000000,
              y: 0.7822537710736468,
              percentage: 78,
              opportunities: 5635,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 5,635",
            },
            {
              x: 1681084800000,
              y: 0.7529937444146559,
              percentage: 75,
              opportunities: 5595,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 5,595",
            },
            {
              x: 1681689600000,
              y: 0.873015873015873,
              percentage: 87,
              opportunities: 63,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 63",
            },
          ],
          visible: false,
          opportunities: 55512,
          successful_opportunities: 44322,
          performance: 0.79842196281885,
        },
        {
          name: "Nurses (RN)-1001",
          data: [
            {
              x: 1675641600000,
              y: 0.801063829787234,
              percentage: 80,
              opportunities: 4700,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 4,700",
            },
            {
              x: 1676246400000,
              y: 0.7804011700793982,
              percentage: 78,
              opportunities: 4786,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 4,786",
            },
            {
              x: 1676851200000,
              y: 0.7852392190517057,
              percentage: 79,
              opportunities: 4661,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 4,661",
            },
            {
              x: 1677456000000,
              y: 0.8115495668912416,
              percentage: 81,
              opportunities: 5195,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 5,195",
            },
            {
              x: 1678060800000,
              y: 0.8228090317642557,
              percentage: 82,
              opportunities: 5226,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 5,226",
            },
            {
              x: 1678665600000,
              y: 0.8261292834890965,
              percentage: 83,
              opportunities: 5136,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 5,136",
            },
            {
              x: 1679270400000,
              y: 0.8516129032258064,
              percentage: 85,
              opportunities: 5580,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 5,580",
            },
            {
              x: 1679875200000,
              y: 0.8564916119620715,
              percentage: 86,
              opportunities: 5484,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 5,484",
            },
            {
              x: 1680480000000,
              y: 0.8189605939463164,
              percentage: 82,
              opportunities: 5253,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 5,253",
            },
            {
              x: 1681084800000,
              y: 0.8022017045454546,
              percentage: 80,
              opportunities: 5632,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 5,632",
            },
            {
              x: 1681689600000,
              y: 0.7764705882352941,
              percentage: 78,
              opportunities: 170,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 170",
            },
          ],
          visible: false,
          opportunities: 51823,
          successful_opportunities: 42320,
          performance: 0.8166258225112402,
        },
        {
          name: "Nurses (RN)-966",
          data: [
            {
              x: 1675641600000,
              y: 0.8783982365907421,
              percentage: 88,
              opportunities: 5444,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 5,444",
            },
            {
              x: 1676246400000,
              y: 0.8748015873015873,
              percentage: 87,
              opportunities: 5040,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 5,040",
            },
            {
              x: 1676851200000,
              y: 0.8868421052631579,
              percentage: 89,
              opportunities: 4560,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 4,560",
            },
            {
              x: 1677456000000,
              y: 0.8653958373857227,
              percentage: 87,
              opportunities: 5141,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 5,141",
            },
            {
              x: 1678060800000,
              y: 0.8654121010004764,
              percentage: 87,
              opportunities: 4198,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 4,198",
            },
            {
              x: 1678665600000,
              y: 0.8580557707203718,
              percentage: 86,
              opportunities: 5164,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 5,164",
            },
            {
              x: 1679270400000,
              y: 0.8599177330895795,
              percentage: 86,
              opportunities: 4376,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 4,376",
            },
            {
              x: 1679875200000,
              y: 0.8764932172504556,
              percentage: 88,
              opportunities: 4939,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 4,939",
            },
            {
              x: 1680480000000,
              y: 0.8622024385523515,
              percentage: 86,
              opportunities: 5167,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 5,167",
            },
            {
              x: 1681084800000,
              y: 0.8555619156649494,
              percentage: 86,
              opportunities: 5241,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 5,241",
            },
            {
              x: 1681689600000,
              y: 0.9666666666666667,
              percentage: 97,
              opportunities: 60,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 60",
            },
          ],
          visible: false,
          opportunities: 49330,
          successful_opportunities: 42837,
          performance: 0.8683762416379485,
        },
        {
          name: "Nurses (RN)-963",
          data: [
            {
              x: 1675641600000,
              y: 0.8680385431977788,
              percentage: 87,
              opportunities: 6123,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 6,123",
            },
            {
              x: 1676246400000,
              y: 0.8811151079136691,
              percentage: 88,
              opportunities: 5560,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 5,560",
            },
            {
              x: 1676851200000,
              y: 0.887381036305957,
              percentage: 89,
              opportunities: 5674,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 5,674",
            },
            {
              x: 1677456000000,
              y: 0.8758595056680915,
              percentage: 88,
              opportunities: 5381,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 5,381",
            },
            {
              x: 1678060800000,
              y: 0.865173116089613,
              percentage: 87,
              opportunities: 4910,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 4,910",
            },
            {
              x: 1678665600000,
              y: 0.8649131767109295,
              percentage: 86,
              opportunities: 3916,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 3,916",
            },
            {
              x: 1679270400000,
              y: 0.8501362397820164,
              percentage: 85,
              opportunities: 4771,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 4,771",
            },
            {
              x: 1679875200000,
              y: 0.8525267249757046,
              percentage: 85,
              opportunities: 4116,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 4,116",
            },
            {
              x: 1680480000000,
              y: 0.8295994568906992,
              percentage: 83,
              opportunities: 4419,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 4,419",
            },
            {
              x: 1681084800000,
              y: 0.8602074493163602,
              percentage: 86,
              opportunities: 4242,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 4,242",
            },
            {
              x: 1681689600000,
              y: 0.9178082191780822,
              percentage: 92,
              opportunities: 73,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 73",
            },
          ],
          visible: false,
          opportunities: 49185,
          successful_opportunities: 42544,
          performance: 0.8649791603131036,
        },
        {
          name: "Environmental Services (EVS)-935",
          data: [
            {
              x: 1675641600000,
              y: 0.7926344423519565,
              percentage: 79,
              opportunities: 4779,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 4,779",
            },
            {
              x: 1676246400000,
              y: 0.7977552816901409,
              percentage: 80,
              opportunities: 4544,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 4,544",
            },
            {
              x: 1676851200000,
              y: 0.7748123436196831,
              percentage: 77,
              opportunities: 4796,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 4,796",
            },
            {
              x: 1677456000000,
              y: 0.7716013263116832,
              percentage: 77,
              opportunities: 5127,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 5,127",
            },
            {
              x: 1678060800000,
              y: 0.8005176557589203,
              percentage: 80,
              opportunities: 5409,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 5,409",
            },
            {
              x: 1678665600000,
              y: 0.7569535187605003,
              percentage: 76,
              opportunities: 5357,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 5,357",
            },
            {
              x: 1679270400000,
              y: 0.7292967124427798,
              percentage: 73,
              opportunities: 4806,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 4,806",
            },
            {
              x: 1679875200000,
              y: 0.7485975483066695,
              percentage: 75,
              opportunities: 4813,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 4,813",
            },
            {
              x: 1680480000000,
              y: 0.7003197807217908,
              percentage: 70,
              opportunities: 4378,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 4,378",
            },
            {
              x: 1681084800000,
              y: 0.6995271867612293,
              percentage: 70,
              opportunities: 4230,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 4,230",
            },
            {
              x: 1681689600000,
              y: 0.46153846153846156,
              percentage: 46,
              opportunities: 26,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 26",
            },
          ],
          visible: false,
          opportunities: 48265,
          successful_opportunities: 36615,
          performance: 0.7586242618874961,
        },
        {
          name: "Nurses (RN)-953",
          data: [
            {
              x: 1675641600000,
              y: 0.9012413793103449,
              percentage: 90,
              opportunities: 3625,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 3,625",
            },
            {
              x: 1676246400000,
              y: 0.9014754878629224,
              percentage: 90,
              opportunities: 4202,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 4,202",
            },
            {
              x: 1676851200000,
              y: 0.9028861154446178,
              percentage: 90,
              opportunities: 5128,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 5,128",
            },
            {
              x: 1677456000000,
              y: 0.9023630701593699,
              percentage: 90,
              opportunities: 5459,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 5,459",
            },
            {
              x: 1678060800000,
              y: 0.8896578239138793,
              percentage: 89,
              opportunities: 5202,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 5,202",
            },
            {
              x: 1678665600000,
              y: 0.8760744985673352,
              percentage: 88,
              opportunities: 5584,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 5,584",
            },
            {
              x: 1679270400000,
              y: 0.8686010851615947,
              percentage: 87,
              opportunities: 4239,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 4,239",
            },
            {
              x: 1679875200000,
              y: 0.9128591615638247,
              percentage: 91,
              opportunities: 4246,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 4,246",
            },
            {
              x: 1680480000000,
              y: 0.8883161512027491,
              percentage: 89,
              opportunities: 4074,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 4,074",
            },
            {
              x: 1681084800000,
              y: 0.917546362339515,
              percentage: 92,
              opportunities: 3505,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 3,505",
            },
            {
              x: 1681689600000,
              y: 0.9482758620689655,
              percentage: 95,
              opportunities: 58,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 58",
            },
          ],
          visible: false,
          opportunities: 45322,
          successful_opportunities: 40579,
          performance: 0.8953488372093024,
        },
        {
          name: "Nurses (RN)-962",
          data: [
            {
              x: 1675641600000,
              y: 0.8450669310857709,
              percentage: 85,
              opportunities: 4034,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 4,034",
            },
            {
              x: 1676246400000,
              y: 0.8632458233890214,
              percentage: 86,
              opportunities: 4190,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 4,190",
            },
            {
              x: 1676851200000,
              y: 0.8506592292089249,
              percentage: 85,
              opportunities: 3944,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3,944",
            },
            {
              x: 1677456000000,
              y: 0.8640145605824233,
              percentage: 86,
              opportunities: 3846,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 3,846",
            },
            {
              x: 1678060800000,
              y: 0.8744174638214374,
              percentage: 87,
              opportunities: 4077,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 4,077",
            },
            {
              x: 1678665600000,
              y: 0.8711090615632926,
              percentage: 87,
              opportunities: 4337,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 4,337",
            },
            {
              x: 1679270400000,
              y: 0.8477157360406091,
              percentage: 85,
              opportunities: 4334,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 4,334",
            },
            {
              x: 1679875200000,
              y: 0.8702737940026075,
              percentage: 87,
              opportunities: 4602,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 4,602",
            },
            {
              x: 1680480000000,
              y: 0.8494668618022162,
              percentage: 85,
              opportunities: 4783,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 4,783",
            },
            {
              x: 1681084800000,
              y: 0.8662361623616236,
              percentage: 87,
              opportunities: 4336,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 4,336",
            },
            {
              x: 1681689600000,
              y: 0.925,
              percentage: 93,
              opportunities: 40,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 40",
            },
          ],
          visible: false,
          opportunities: 42523,
          successful_opportunities: 36582,
          performance: 0.8602873738917762,
        },
        {
          name: "Nurses (RN)-961",
          data: [
            {
              x: 1675641600000,
              y: 0.7838616714697406,
              percentage: 78,
              opportunities: 4164,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 4,164",
            },
            {
              x: 1676246400000,
              y: 0.7934453318645002,
              percentage: 79,
              opportunities: 3631,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3,631",
            },
            {
              x: 1676851200000,
              y: 0.8105534105534106,
              percentage: 81,
              opportunities: 3885,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3,885",
            },
            {
              x: 1677456000000,
              y: 0.8168353492964701,
              percentage: 82,
              opportunities: 4051,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 4,051",
            },
            {
              x: 1678060800000,
              y: 0.8560573165436387,
              percentage: 86,
              opportunities: 4606,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 4,606",
            },
            {
              x: 1678665600000,
              y: 0.8444444444444444,
              percentage: 84,
              opportunities: 3465,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 3,465",
            },
            {
              x: 1679270400000,
              y: 0.8582089552238806,
              percentage: 86,
              opportunities: 4020,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 4,020",
            },
            {
              x: 1679875200000,
              y: 0.8458100558659218,
              percentage: 85,
              opportunities: 4475,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 4,475",
            },
            {
              x: 1680480000000,
              y: 0.8613821138211382,
              percentage: 86,
              opportunities: 4920,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 4,920",
            },
            {
              x: 1681084800000,
              y: 0.8678404279028304,
              percentage: 87,
              opportunities: 4487,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 4,487",
            },
            {
              x: 1681689600000,
              y: 0.8518518518518519,
              percentage: 85,
              opportunities: 54,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 54",
            },
          ],
          visible: false,
          opportunities: 41758,
          successful_opportunities: 34885,
          performance: 0.8354087839455913,
        },
        {
          name: "Techs-981",
          data: [
            {
              x: 1675641600000,
              y: 0.8555930568948891,
              percentage: 86,
              opportunities: 4148,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 4,148",
            },
            {
              x: 1676246400000,
              y: 0.8688147295742232,
              percentage: 87,
              opportunities: 4345,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 4,345",
            },
            {
              x: 1676851200000,
              y: 0.8579387186629527,
              percentage: 86,
              opportunities: 3949,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3,949",
            },
            {
              x: 1677456000000,
              y: 0.8184782608695652,
              percentage: 82,
              opportunities: 4600,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 4,600",
            },
            {
              x: 1678060800000,
              y: 0.8495772140631954,
              percentage: 85,
              opportunities: 4494,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 4,494",
            },
            {
              x: 1678665600000,
              y: 0.8405704555129843,
              percentage: 84,
              opportunities: 4698,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 4,698",
            },
            {
              x: 1679270400000,
              y: 0.8484679665738162,
              percentage: 85,
              opportunities: 3590,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 3,590",
            },
            {
              x: 1679875200000,
              y: 0.837396265560166,
              percentage: 84,
              opportunities: 3856,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 3,856",
            },
            {
              x: 1680480000000,
              y: 0.803921568627451,
              percentage: 80,
              opportunities: 3723,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 3,723",
            },
            {
              x: 1681084800000,
              y: 0.8210821847881572,
              percentage: 82,
              opportunities: 3918,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 3,918",
            },
            {
              x: 1681689600000,
              y: 1,
              percentage: 100,
              opportunities: 38,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 38",
            },
          ],
          visible: false,
          opportunities: 41359,
          successful_opportunities: 34767,
          performance: 0.8406151019125221,
        },
        {
          name: "Nurses (RN)-954",
          data: [
            {
              x: 1675641600000,
              y: 0.8043130990415336,
              percentage: 80,
              opportunities: 3756,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 3,756",
            },
            {
              x: 1676246400000,
              y: 0.8163368848300355,
              percentage: 82,
              opportunities: 3942,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3,942",
            },
            {
              x: 1676851200000,
              y: 0.8664332166083042,
              percentage: 87,
              opportunities: 3998,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3,998",
            },
            {
              x: 1677456000000,
              y: 0.8610917537746806,
              percentage: 86,
              opportunities: 4305,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 4,305",
            },
            {
              x: 1678060800000,
              y: 0.8625239923224568,
              percentage: 86,
              opportunities: 4168,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 4,168",
            },
            {
              x: 1678665600000,
              y: 0.8395895220091818,
              percentage: 84,
              opportunities: 3703,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 3,703",
            },
            {
              x: 1679270400000,
              y: 0.8865403304178814,
              percentage: 89,
              opportunities: 4116,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 4,116",
            },
            {
              x: 1679875200000,
              y: 0.8590240123934935,
              percentage: 86,
              opportunities: 3873,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 3,873",
            },
            {
              x: 1680480000000,
              y: 0.8572866851245909,
              percentage: 86,
              opportunities: 3973,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 3,973",
            },
            {
              x: 1681084800000,
              y: 0.8599248377177998,
              percentage: 86,
              opportunities: 2927,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 2,927",
            },
            {
              x: 1681689600000,
              y: 0.875,
              percentage: 88,
              opportunities: 16,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 16",
            },
          ],
          visible: false,
          opportunities: 38777,
          successful_opportunities: 33027,
          performance: 0.8517162235345694,
        },
        {
          name: "Respiratory Therapists (RT)-944",
          data: [
            {
              x: 1675641600000,
              y: 0.8801229508196722,
              percentage: 88,
              opportunities: 3904,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 3,904",
            },
            {
              x: 1676246400000,
              y: 0.8810182623132263,
              percentage: 88,
              opportunities: 3614,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3,614",
            },
            {
              x: 1676851200000,
              y: 0.8893431452711911,
              percentage: 89,
              opportunities: 3669,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3,669",
            },
            {
              x: 1677456000000,
              y: 0.8747422680412371,
              percentage: 87,
              opportunities: 3880,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 3,880",
            },
            {
              x: 1678060800000,
              y: 0.8886378308586185,
              percentage: 89,
              opportunities: 3098,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 3,098",
            },
            {
              x: 1678665600000,
              y: 0.8833981121599112,
              percentage: 88,
              opportunities: 3602,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 3,602",
            },
            {
              x: 1679270400000,
              y: 0.8747060799462546,
              percentage: 87,
              opportunities: 2977,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 2,977",
            },
            {
              x: 1679875200000,
              y: 0.8598589389757743,
              percentage: 86,
              opportunities: 3261,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 3,261",
            },
            {
              x: 1680480000000,
              y: 0.8694704049844236,
              percentage: 87,
              opportunities: 3210,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 3,210",
            },
            {
              x: 1681084800000,
              y: 0.8745632424877708,
              percentage: 87,
              opportunities: 2862,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 2,862",
            },
            {
              x: 1681689600000,
              y: 0.8387096774193549,
              percentage: 84,
              opportunities: 31,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 31",
            },
          ],
          visible: false,
          opportunities: 34108,
          successful_opportunities: 29940,
          performance: 0.8777999296352762,
        },
        {
          name: "Techs-984",
          data: [
            {
              x: 1675641600000,
              y: 0.8411507191994997,
              percentage: 84,
              opportunities: 3198,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 3,198",
            },
            {
              x: 1676246400000,
              y: 0.840194489465154,
              percentage: 84,
              opportunities: 3085,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3,085",
            },
            {
              x: 1676851200000,
              y: 0.8338720103425986,
              percentage: 83,
              opportunities: 3094,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3,094",
            },
            {
              x: 1677456000000,
              y: 0.8240796620398311,
              percentage: 82,
              opportunities: 3314,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 3,314",
            },
            {
              x: 1678060800000,
              y: 0.8388528508023216,
              percentage: 84,
              opportunities: 2929,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 2,929",
            },
            {
              x: 1678665600000,
              y: 0.8219575016097875,
              percentage: 82,
              opportunities: 3106,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 3,106",
            },
            {
              x: 1679270400000,
              y: 0.8266480641785838,
              percentage: 83,
              opportunities: 2867,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 2,867",
            },
            {
              x: 1679875200000,
              y: 0.8355868036776636,
              percentage: 84,
              opportunities: 3698,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 3,698",
            },
            {
              x: 1680480000000,
              y: 0.8319209039548022,
              percentage: 83,
              opportunities: 3540,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 3,540",
            },
            {
              x: 1681084800000,
              y: 0.8237169270003187,
              percentage: 82,
              opportunities: 3137,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 3,137",
            },
            {
              x: 1681689600000,
              y: 0.75,
              percentage: 75,
              opportunities: 32,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 32",
            },
          ],
          visible: false,
          opportunities: 32000,
          successful_opportunities: 26616,
          performance: 0.83175,
        },
        {
          name: "Nurses (RN)-956",
          data: [
            {
              x: 1675641600000,
              y: 0.84375,
              percentage: 84,
              opportunities: 3392,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 3,392",
            },
            {
              x: 1676246400000,
              y: 0.8260336906584992,
              percentage: 83,
              opportunities: 3265,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3,265",
            },
            {
              x: 1676851200000,
              y: 0.838477366255144,
              percentage: 84,
              opportunities: 2916,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2,916",
            },
            {
              x: 1677456000000,
              y: 0.8427807486631016,
              percentage: 84,
              opportunities: 2805,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 2,805",
            },
            {
              x: 1678060800000,
              y: 0.827810818465024,
              percentage: 83,
              opportunities: 3531,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 3,531",
            },
            {
              x: 1678665600000,
              y: 0.8374628344895937,
              percentage: 84,
              opportunities: 3027,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 3,027",
            },
            {
              x: 1679270400000,
              y: 0.8557332425995237,
              percentage: 86,
              opportunities: 2939,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 2,939",
            },
            {
              x: 1679875200000,
              y: 0.8268208815254767,
              percentage: 83,
              opportunities: 3199,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 3,199",
            },
            {
              x: 1680480000000,
              y: 0.8529330762875241,
              percentage: 85,
              opportunities: 3631,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 3,631",
            },
            {
              x: 1681084800000,
              y: 0.8330440819160014,
              percentage: 83,
              opportunities: 2881,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 2,881",
            },
            {
              x: 1681689600000,
              y: 0.7692307692307693,
              percentage: 77,
              opportunities: 65,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 65",
            },
          ],
          visible: false,
          opportunities: 31651,
          successful_opportunities: 26533,
          performance: 0.8382989479005403,
        },
        {
          name: "Techs-983",
          data: [
            {
              x: 1675641600000,
              y: 0.7378087397086763,
              percentage: 74,
              opportunities: 3158,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 3,158",
            },
            {
              x: 1676246400000,
              y: 0.7595907928388747,
              percentage: 76,
              opportunities: 2737,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2,737",
            },
            {
              x: 1676851200000,
              y: 0.7449014863463532,
              percentage: 74,
              opportunities: 2893,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2,893",
            },
            {
              x: 1677456000000,
              y: 0.756152125279642,
              percentage: 76,
              opportunities: 2682,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 2,682",
            },
            {
              x: 1678060800000,
              y: 0.8266572137539879,
              percentage: 83,
              opportunities: 2821,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 2,821",
            },
            {
              x: 1678665600000,
              y: 0.8364758313335618,
              percentage: 84,
              opportunities: 2917,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 2,917",
            },
            {
              x: 1679270400000,
              y: 0.8377942599161561,
              percentage: 84,
              opportunities: 3101,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 3,101",
            },
            {
              x: 1679875200000,
              y: 0.8245309242529535,
              percentage: 82,
              opportunities: 2878,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 2,878",
            },
            {
              x: 1680480000000,
              y: 0.8353365384615384,
              percentage: 84,
              opportunities: 3328,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 3,328",
            },
            {
              x: 1681084800000,
              y: 0.8620293187697614,
              percentage: 86,
              opportunities: 3479,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 3,479",
            },
            {
              x: 1681689600000,
              y: 0.7954545454545454,
              percentage: 80,
              opportunities: 44,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 44",
            },
          ],
          visible: false,
          opportunities: 30038,
          successful_opportunities: 24149,
          performance: 0.8039483321126573,
        },
        {
          name: "Techs-1003",
          data: [
            {
              x: 1675641600000,
              y: 0.8218390804597702,
              percentage: 82,
              opportunities: 3132,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 3,132",
            },
            {
              x: 1676246400000,
              y: 0.8450020234722785,
              percentage: 85,
              opportunities: 2471,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2,471",
            },
            {
              x: 1676851200000,
              y: 0.8579249931563099,
              percentage: 86,
              opportunities: 3653,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3,653",
            },
            {
              x: 1677456000000,
              y: 0.8671303074670571,
              percentage: 87,
              opportunities: 2732,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 2,732",
            },
            {
              x: 1678060800000,
              y: 0.8607554417413572,
              percentage: 86,
              opportunities: 3124,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 3,124",
            },
            {
              x: 1678665600000,
              y: 0.8510165184243964,
              percentage: 85,
              opportunities: 3148,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 3,148",
            },
            {
              x: 1679270400000,
              y: 0.842057761732852,
              percentage: 84,
              opportunities: 3324,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 3,324",
            },
            {
              x: 1679875200000,
              y: 0.8433485288023208,
              percentage: 84,
              opportunities: 2413,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 2,413",
            },
            {
              x: 1680480000000,
              y: 0.8509700176366843,
              percentage: 85,
              opportunities: 2268,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 2,268",
            },
            {
              x: 1681084800000,
              y: 0.8461259956553222,
              percentage: 85,
              opportunities: 2762,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 2,762",
            },
            {
              x: 1681689600000,
              y: 1,
              percentage: 100,
              opportunities: 18,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 18",
            },
          ],
          visible: false,
          opportunities: 29045,
          successful_opportunities: 24652,
          performance: 0.8487519366500258,
        },
        {
          name: "Nurses (RN)-955",
          data: [
            {
              x: 1675641600000,
              y: 0.8054718875502008,
              percentage: 81,
              opportunities: 3984,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 3,984",
            },
            {
              x: 1676246400000,
              y: 0.7900882939333523,
              percentage: 79,
              opportunities: 3511,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3,511",
            },
            {
              x: 1676851200000,
              y: 0.813881107998755,
              percentage: 81,
              opportunities: 3213,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3,213",
            },
            {
              x: 1677456000000,
              y: 0.8044377585558481,
              percentage: 80,
              opportunities: 2659,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 2,659",
            },
            {
              x: 1678060800000,
              y: 0.7550351288056206,
              percentage: 76,
              opportunities: 2135,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 2,135",
            },
            {
              x: 1678665600000,
              y: 0.7768421052631579,
              percentage: 78,
              opportunities: 2375,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 2,375",
            },
            {
              x: 1679270400000,
              y: 0.7820267686424475,
              percentage: 78,
              opportunities: 2092,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 2,092",
            },
            {
              x: 1679875200000,
              y: 0.8164365106096328,
              percentage: 82,
              opportunities: 2969,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 2,969",
            },
            {
              x: 1680480000000,
              y: 0.7547419497132775,
              percentage: 75,
              opportunities: 2267,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 2,267",
            },
            {
              x: 1681084800000,
              y: 0.8200179533213644,
              percentage: 82,
              opportunities: 2228,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 2,228",
            },
            {
              x: 1681689600000,
              y: 0.5714285714285714,
              percentage: 57,
              opportunities: 35,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 35",
            },
          ],
          visible: false,
          opportunities: 27468,
          successful_opportunities: 21812,
          performance: 0.7940876656472987,
        },
        {
          name: "Nurses (RN)-968",
          data: [
            {
              x: 1675641600000,
              y: 0.8451327433628318,
              percentage: 85,
              opportunities: 2486,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 2,486",
            },
            {
              x: 1676246400000,
              y: 0.8480786416443253,
              percentage: 85,
              opportunities: 2238,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2,238",
            },
            {
              x: 1676851200000,
              y: 0.849830636055702,
              percentage: 85,
              opportunities: 2657,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2,657",
            },
            {
              x: 1677456000000,
              y: 0.8388791593695272,
              percentage: 84,
              opportunities: 2855,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 2,855",
            },
            {
              x: 1678060800000,
              y: 0.8180540920266948,
              percentage: 82,
              opportunities: 2847,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 2,847",
            },
            {
              x: 1678665600000,
              y: 0.8448017950635752,
              percentage: 84,
              opportunities: 2674,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 2,674",
            },
            {
              x: 1679270400000,
              y: 0.8496986884083658,
              percentage: 85,
              opportunities: 2821,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 2,821",
            },
            {
              x: 1679875200000,
              y: 0.8490497076023392,
              percentage: 85,
              opportunities: 2736,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 2,736",
            },
            {
              x: 1680480000000,
              y: 0.8289770893836722,
              percentage: 83,
              opportunities: 3099,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 3,099",
            },
            {
              x: 1681084800000,
              y: 0.8205035971223021,
              percentage: 82,
              opportunities: 2780,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 2,780",
            },
            {
              x: 1681689600000,
              y: 0.8,
              percentage: 80,
              opportunities: 30,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 30",
            },
          ],
          visible: false,
          opportunities: 27223,
          successful_opportunities: 22834,
          performance: 0.8387760349704294,
        },
        {
          name: "Group-941",
          data: [
            {
              x: 1675641600000,
              y: 0.8360044726052925,
              percentage: 84,
              opportunities: 2683,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 2,683",
            },
            {
              x: 1676246400000,
              y: 0.8374685364976627,
              percentage: 84,
              opportunities: 2781,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2,781",
            },
            {
              x: 1676851200000,
              y: 0.8194444444444444,
              percentage: 82,
              opportunities: 2736,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2,736",
            },
            {
              x: 1677456000000,
              y: 0.8329194749911316,
              percentage: 83,
              opportunities: 2819,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 2,819",
            },
            {
              x: 1678060800000,
              y: 0.8461815561959655,
              percentage: 85,
              opportunities: 2776,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 2,776",
            },
            {
              x: 1678665600000,
              y: 0.8243191407748369,
              percentage: 82,
              opportunities: 2607,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 2,607",
            },
            {
              x: 1679270400000,
              y: 0.8142633228840125,
              percentage: 81,
              opportunities: 2552,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 2,552",
            },
            {
              x: 1679875200000,
              y: 0.8211792086889061,
              percentage: 82,
              opportunities: 2578,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 2,578",
            },
            {
              x: 1680480000000,
              y: 0.8508946322067594,
              percentage: 85,
              opportunities: 2515,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 2,515",
            },
            {
              x: 1681084800000,
              y: 0.8448142414860681,
              percentage: 84,
              opportunities: 2584,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 2,584",
            },
            {
              x: 1681689600000,
              y: 0.9,
              percentage: 90,
              opportunities: 10,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 10",
            },
          ],
          visible: false,
          opportunities: 26641,
          successful_opportunities: 22187,
          performance: 0.8328140835554221,
        },
        {
          name: "Nurses (RN)-969",
          data: [
            {
              x: 1675641600000,
              y: 0.8055790363482671,
              percentage: 81,
              opportunities: 2366,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 2,366",
            },
            {
              x: 1676246400000,
              y: 0.8220081135902637,
              percentage: 82,
              opportunities: 1972,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1,972",
            },
            {
              x: 1676851200000,
              y: 0.8640350877192983,
              percentage: 86,
              opportunities: 2508,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2,508",
            },
            {
              x: 1677456000000,
              y: 0.8423190546528804,
              percentage: 84,
              opportunities: 2708,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 2,708",
            },
            {
              x: 1678060800000,
              y: 0.8486956521739131,
              percentage: 85,
              opportunities: 2300,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 2,300",
            },
            {
              x: 1678665600000,
              y: 0.8492221779018747,
              percentage: 85,
              opportunities: 2507,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 2,507",
            },
            {
              x: 1679270400000,
              y: 0.8172231985940246,
              percentage: 82,
              opportunities: 2845,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 2,845",
            },
            {
              x: 1679875200000,
              y: 0.8189081225033289,
              percentage: 82,
              opportunities: 2253,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 2,253",
            },
            {
              x: 1680480000000,
              y: 0.802970297029703,
              percentage: 80,
              opportunities: 3030,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 3,030",
            },
            {
              x: 1681084800000,
              y: 0.7995753715498939,
              percentage: 80,
              opportunities: 2355,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 2,355",
            },
            {
              x: 1681689600000,
              y: 0.5,
              percentage: 50,
              opportunities: 6,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 6",
            },
          ],
          visible: false,
          opportunities: 24850,
          successful_opportunities: 20545,
          performance: 0.8267605633802817,
        },
        {
          name: "Techs-986",
          data: [
            {
              x: 1675641600000,
              y: 0.8974744437763079,
              percentage: 90,
              opportunities: 3326,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 3,326",
            },
            {
              x: 1676246400000,
              y: 0.8995884773662551,
              percentage: 90,
              opportunities: 2430,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2,430",
            },
            {
              x: 1676851200000,
              y: 0.8895591647331786,
              percentage: 89,
              opportunities: 2155,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2,155",
            },
            {
              x: 1677456000000,
              y: 0.9052910052910053,
              percentage: 91,
              opportunities: 1890,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 1,890",
            },
            {
              x: 1678060800000,
              y: 0.8590272052761748,
              percentage: 86,
              opportunities: 2426,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 2,426",
            },
            {
              x: 1678665600000,
              y: 0.8717948717948718,
              percentage: 87,
              opportunities: 3120,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 3,120",
            },
            {
              x: 1679270400000,
              y: 0.8722627737226277,
              percentage: 87,
              opportunities: 2192,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 2,192",
            },
            {
              x: 1679875200000,
              y: 0.8762575452716298,
              percentage: 88,
              opportunities: 1988,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 1,988",
            },
            {
              x: 1680480000000,
              y: 0.8785120696478037,
              percentage: 88,
              opportunities: 2527,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 2,527",
            },
            {
              x: 1681084800000,
              y: 0.8629848229342327,
              percentage: 86,
              opportunities: 2372,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 2,372",
            },
            {
              x: 1681689600000,
              y: 0.9444444444444444,
              percentage: 94,
              opportunities: 18,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 18",
            },
          ],
          visible: false,
          opportunities: 24444,
          successful_opportunities: 21541,
          performance: 0.8812387497954508,
        },
        {
          name: "Nurses (RN)-958",
          data: [
            {
              x: 1675641600000,
              y: 0.763986835919135,
              percentage: 76,
              opportunities: 2127,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 2,127",
            },
            {
              x: 1676246400000,
              y: 0.7925925925925926,
              percentage: 79,
              opportunities: 3105,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3,105",
            },
            {
              x: 1676851200000,
              y: 0.796023311621529,
              percentage: 80,
              opportunities: 2917,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2,917",
            },
            {
              x: 1677456000000,
              y: 0.7944983818770227,
              percentage: 79,
              opportunities: 2472,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 2,472",
            },
            {
              x: 1678060800000,
              y: 0.781478102189781,
              percentage: 78,
              opportunities: 2192,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 2,192",
            },
            {
              x: 1678665600000,
              y: 0.7788018433179723,
              percentage: 78,
              opportunities: 1736,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 1,736",
            },
            {
              x: 1679270400000,
              y: 0.7391944990176817,
              percentage: 74,
              opportunities: 2036,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 2,036",
            },
            {
              x: 1679875200000,
              y: 0.7785630153121319,
              percentage: 78,
              opportunities: 2547,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 2,547",
            },
            {
              x: 1680480000000,
              y: 0.775236200895077,
              percentage: 78,
              opportunities: 2011,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 2,011",
            },
            {
              x: 1681084800000,
              y: 0.8288494562106469,
              percentage: 83,
              opportunities: 1747,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 1,747",
            },
            {
              x: 1681689600000,
              y: 0.8,
              percentage: 80,
              opportunities: 25,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 25",
            },
          ],
          visible: false,
          opportunities: 22915,
          successful_opportunities: 17952,
          performance: 0.7834169757800568,
        },
        {
          name: "Nurses (RN)-959",
          data: [
            {
              x: 1675641600000,
              y: 0.781305114638448,
              percentage: 78,
              opportunities: 2268,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 2,268",
            },
            {
              x: 1676246400000,
              y: 0.8254374733247972,
              percentage: 83,
              opportunities: 2343,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2,343",
            },
            {
              x: 1676851200000,
              y: 0.8633968382650993,
              percentage: 86,
              opportunities: 2467,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2,467",
            },
            {
              x: 1677456000000,
              y: 0.8393977415307403,
              percentage: 84,
              opportunities: 2391,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 2,391",
            },
            {
              x: 1678060800000,
              y: 0.8612159329140461,
              percentage: 86,
              opportunities: 2385,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 2,385",
            },
            {
              x: 1678665600000,
              y: 0.8277467411545624,
              percentage: 83,
              opportunities: 2148,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 2,148",
            },
            {
              x: 1679270400000,
              y: 0.8524509803921568,
              percentage: 85,
              opportunities: 2040,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 2,040",
            },
            {
              x: 1679875200000,
              y: 0.7901578458681523,
              percentage: 79,
              opportunities: 1077,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 1,077",
            },
            {
              x: 1680480000000,
              y: 0.8405797101449275,
              percentage: 84,
              opportunities: 2622,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 2,622",
            },
            {
              x: 1681084800000,
              y: 0.8590021691973969,
              percentage: 86,
              opportunities: 2766,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 2,766",
            },
            {
              x: 1681689600000,
              y: 0.9459459459459459,
              percentage: 95,
              opportunities: 37,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 37",
            },
          ],
          visible: false,
          opportunities: 22544,
          successful_opportunities: 18880,
          performance: 0.837473385379702,
        },
        {
          name: "Nurses (RN)-2779",
          data: [
            {
              x: 1675641600000,
              y: 0.8923792902850495,
              percentage: 89,
              opportunities: 1719,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 1,719",
            },
            {
              x: 1676246400000,
              y: 0.8706780422092502,
              percentage: 87,
              opportunities: 2227,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2,227",
            },
            {
              x: 1676851200000,
              y: 0.8620253164556962,
              percentage: 86,
              opportunities: 2370,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2,370",
            },
            {
              x: 1677456000000,
              y: 0.8517934568387859,
              percentage: 85,
              opportunities: 2537,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 2,537",
            },
            {
              x: 1678060800000,
              y: 0.8850467289719626,
              percentage: 89,
              opportunities: 2140,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 2,140",
            },
            {
              x: 1678665600000,
              y: 0.8370846730975349,
              percentage: 84,
              opportunities: 1866,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 1,866",
            },
            {
              x: 1679270400000,
              y: 0.8616885871871023,
              percentage: 86,
              opportunities: 2357,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 2,357",
            },
            {
              x: 1679875200000,
              y: 0.8601941747572815,
              percentage: 86,
              opportunities: 2060,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 2,060",
            },
            {
              x: 1680480000000,
              y: 0.8921694480102695,
              percentage: 89,
              opportunities: 779,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 779",
            },
            {
              x: 1681084800000,
              y: 0.8142957252978276,
              percentage: 81,
              opportunities: 1427,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 1,427",
            },
            {
              x: 1681689600000,
              y: 1,
              percentage: 100,
              opportunities: 2,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 2",
            },
          ],
          visible: false,
          opportunities: 19484,
          successful_opportunities: 16795,
          performance: 0.8619893245740095,
        },
        {
          name: "Therapists-942",
          data: [
            {
              x: 1675641600000,
              y: 0.7946708463949843,
              percentage: 79,
              opportunities: 1914,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 1,914",
            },
            {
              x: 1676246400000,
              y: 0.8149202733485194,
              percentage: 81,
              opportunities: 1756,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1,756",
            },
            {
              x: 1676851200000,
              y: 0.8029014507253627,
              percentage: 80,
              opportunities: 1999,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 1,999",
            },
            {
              x: 1677456000000,
              y: 0.8211610486891385,
              percentage: 82,
              opportunities: 2136,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 2,136",
            },
            {
              x: 1678060800000,
              y: 0.8066896212493851,
              percentage: 81,
              opportunities: 2033,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 2,033",
            },
            {
              x: 1678665600000,
              y: 0.8271971496437055,
              percentage: 83,
              opportunities: 1684,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 1,684",
            },
            {
              x: 1679270400000,
              y: 0.8155124653739613,
              percentage: 82,
              opportunities: 1805,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 1,805",
            },
            {
              x: 1679875200000,
              y: 0.8001060445387063,
              percentage: 80,
              opportunities: 1886,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 1,886",
            },
            {
              x: 1680480000000,
              y: 0.8123608017817372,
              percentage: 81,
              opportunities: 1796,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 1,796",
            },
            {
              x: 1681084800000,
              y: 0.7806553911205074,
              percentage: 78,
              opportunities: 1892,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 1,892",
            },
          ],
          visible: false,
          opportunities: 18901,
          successful_opportunities: 15261,
          performance: 0.8074175969525422,
        },
        {
          name: "Therapists-943",
          data: [
            {
              x: 1675641600000,
              y: 0.8101711983887211,
              percentage: 81,
              opportunities: 1986,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 1,986",
            },
            {
              x: 1676246400000,
              y: 0.8093264248704664,
              percentage: 81,
              opportunities: 1930,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1,930",
            },
            {
              x: 1676851200000,
              y: 0.823906083244397,
              percentage: 82,
              opportunities: 1874,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 1,874",
            },
            {
              x: 1677456000000,
              y: 0.8228180862250263,
              percentage: 82,
              opportunities: 1902,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 1,902",
            },
            {
              x: 1678060800000,
              y: 0.7986501687289089,
              percentage: 80,
              opportunities: 1778,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 1,778",
            },
            {
              x: 1678665600000,
              y: 0.8103638368246968,
              percentage: 81,
              opportunities: 1814,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 1,814",
            },
            {
              x: 1679270400000,
              y: 0.7886486486486487,
              percentage: 79,
              opportunities: 1850,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 1,850",
            },
            {
              x: 1679875200000,
              y: 0.8051668460710442,
              percentage: 81,
              opportunities: 1858,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 1,858",
            },
            {
              x: 1680480000000,
              y: 0.8303425774877651,
              percentage: 83,
              opportunities: 1839,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 1,839",
            },
            {
              x: 1681084800000,
              y: 0.8147220831246871,
              percentage: 81,
              opportunities: 1997,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 1,997",
            },
          ],
          visible: false,
          opportunities: 18828,
          successful_opportunities: 15279,
          performance: 0.8115041427660931,
        },
        {
          name: "Physicians-930",
          data: [
            {
              x: 1675641600000,
              y: 0.8658057271195957,
              percentage: 87,
              opportunities: 1781,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 1,781",
            },
            {
              x: 1676246400000,
              y: 0.8324396782841823,
              percentage: 83,
              opportunities: 1492,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1,492",
            },
            {
              x: 1676851200000,
              y: 0.8472622478386167,
              percentage: 85,
              opportunities: 1735,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 1,735",
            },
            {
              x: 1677456000000,
              y: 0.8465256797583082,
              percentage: 85,
              opportunities: 1655,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 1,655",
            },
            {
              x: 1678060800000,
              y: 0.8425110132158591,
              percentage: 84,
              opportunities: 1816,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 1,816",
            },
            {
              x: 1678665600000,
              y: 0.8206826286296485,
              percentage: 82,
              opportunities: 1963,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 1,963",
            },
            {
              x: 1679270400000,
              y: 0.8604757548032936,
              percentage: 86,
              opportunities: 2186,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 2,186",
            },
            {
              x: 1679875200000,
              y: 0.8298695021749638,
              percentage: 83,
              opportunities: 2069,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 2,069",
            },
            {
              x: 1680480000000,
              y: 0.8303393213572854,
              percentage: 83,
              opportunities: 2004,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 2,004",
            },
            {
              x: 1681084800000,
              y: 0.832171581769437,
              percentage: 83,
              opportunities: 1865,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 1,865",
            },
            {
              x: 1681689600000,
              y: 1,
              percentage: 100,
              opportunities: 1,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 1",
            },
          ],
          visible: false,
          opportunities: 18567,
          successful_opportunities: 15611,
          performance: 0.8407928044379813,
        },
        {
          name: "Group-1109",
          data: [
            {
              x: 1675641600000,
              y: 0.7466918714555766,
              percentage: 75,
              opportunities: 2116,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 2,116",
            },
            {
              x: 1676246400000,
              y: 0.783484390735146,
              percentage: 78,
              opportunities: 1986,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1,986",
            },
            {
              x: 1676851200000,
              y: 0.8171875,
              percentage: 82,
              opportunities: 1920,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 1,920",
            },
            {
              x: 1677456000000,
              y: 0.8003264417845484,
              percentage: 80,
              opportunities: 1838,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 1,838",
            },
            {
              x: 1678060800000,
              y: 0.8467501490757304,
              percentage: 85,
              opportunities: 1677,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 1,677",
            },
            {
              x: 1678665600000,
              y: 0.8241573033707865,
              percentage: 82,
              opportunities: 1780,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 1,780",
            },
            {
              x: 1679270400000,
              y: 0.7942821628340584,
              percentage: 79,
              opportunities: 1609,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 1,609",
            },
            {
              x: 1679875200000,
              y: 0.7952069716775599,
              percentage: 80,
              opportunities: 1836,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 1,836",
            },
            {
              x: 1680480000000,
              y: 0.7964449541284404,
              percentage: 80,
              opportunities: 1744,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 1,744",
            },
            {
              x: 1681084800000,
              y: 0.756774193548387,
              percentage: 76,
              opportunities: 1550,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 1,550",
            },
            {
              x: 1681689600000,
              y: 0.6666666666666666,
              percentage: 67,
              opportunities: 6,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 6",
            },
          ],
          visible: false,
          opportunities: 18062,
          successful_opportunities: 14367,
          performance: 0.7954268630273502,
        },
        {
          name: "Techs-971",
          data: [
            {
              x: 1675641600000,
              y: 0.8601493550577054,
              percentage: 86,
              opportunities: 1473,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 1,473",
            },
            {
              x: 1676246400000,
              y: 0.8580171358629131,
              percentage: 86,
              opportunities: 1634,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1,634",
            },
            {
              x: 1676851200000,
              y: 0.861228813559322,
              percentage: 86,
              opportunities: 1888,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 1,888",
            },
            {
              x: 1677456000000,
              y: 0.8804679552390641,
              percentage: 88,
              opportunities: 1966,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 1,966",
            },
            {
              x: 1678060800000,
              y: 0.8752424046541694,
              percentage: 88,
              opportunities: 1547,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 1,547",
            },
            {
              x: 1678665600000,
              y: 0.8683473389355743,
              percentage: 87,
              opportunities: 1785,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 1,785",
            },
            {
              x: 1679270400000,
              y: 0.8540540540540541,
              percentage: 85,
              opportunities: 1295,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 1,295",
            },
            {
              x: 1679875200000,
              y: 0.8634857521537442,
              percentage: 86,
              opportunities: 1509,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 1,509",
            },
            {
              x: 1680480000000,
              y: 0.8520467836257309,
              percentage: 85,
              opportunities: 1710,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 1,710",
            },
            {
              x: 1681084800000,
              y: 0.8852364475201846,
              percentage: 89,
              opportunities: 1734,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 1,734",
            },
          ],
          visible: false,
          opportunities: 16541,
          successful_opportunities: 14331,
          performance: 0.8663926002055499,
        },
        {
          name: "Techs-2780",
          data: [
            {
              x: 1675641600000,
              y: 0.9321372854914196,
              percentage: 93,
              opportunities: 1282,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 1,282",
            },
            {
              x: 1676246400000,
              y: 0.902107409925221,
              percentage: 90,
              opportunities: 1471,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1,471",
            },
            {
              x: 1676851200000,
              y: 0.8679896462467644,
              percentage: 87,
              opportunities: 1159,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 1,159",
            },
            {
              x: 1677456000000,
              y: 0.8887147335423198,
              percentage: 89,
              opportunities: 1276,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 1,276",
            },
            {
              x: 1678060800000,
              y: 0.9324644549763034,
              percentage: 93,
              opportunities: 1688,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 1,688",
            },
            {
              x: 1678665600000,
              y: 0.8953951108584423,
              percentage: 90,
              opportunities: 1759,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 1,759",
            },
            {
              x: 1679270400000,
              y: 0.8922155688622755,
              percentage: 89,
              opportunities: 1837,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 1,837",
            },
            {
              x: 1679875200000,
              y: 0.8697857948139797,
              percentage: 87,
              opportunities: 1774,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 1,774",
            },
            {
              x: 1680480000000,
              y: 0.8501400560224089,
              percentage: 85,
              opportunities: 714,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 714",
            },
            {
              x: 1681084800000,
              y: 0.8887744593202883,
              percentage: 89,
              opportunities: 971,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 971",
            },
            {
              x: 1681689600000,
              y: 1,
              percentage: 100,
              opportunities: 12,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 12",
            },
          ],
          visible: false,
          opportunities: 13943,
          successful_opportunities: 12475,
          performance: 0.8947141935021158,
        },
        {
          name: "Techs-975",
          data: [
            {
              x: 1675641600000,
              y: 0.8138359891177613,
              percentage: 81,
              opportunities: 2573,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 2,573",
            },
            {
              x: 1676246400000,
              y: 0.7802120141342757,
              percentage: 78,
              opportunities: 1415,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1,415",
            },
            {
              x: 1676851200000,
              y: 0.7769679300291545,
              percentage: 78,
              opportunities: 1372,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 1,372",
            },
            {
              x: 1677456000000,
              y: 0.7709570957095709,
              percentage: 77,
              opportunities: 1515,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 1,515",
            },
            {
              x: 1678060800000,
              y: 0.7042735042735043,
              percentage: 70,
              opportunities: 1170,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 1,170",
            },
            {
              x: 1678665600000,
              y: 0.7345932621199671,
              percentage: 73,
              opportunities: 1217,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 1,217",
            },
            {
              x: 1679270400000,
              y: 0.75625,
              percentage: 76,
              opportunities: 1440,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 1,440",
            },
            {
              x: 1679875200000,
              y: 0.7246753246753247,
              percentage: 72,
              opportunities: 770,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 770",
            },
            {
              x: 1680480000000,
              y: 0.8114343029087262,
              percentage: 81,
              opportunities: 997,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 997",
            },
            {
              x: 1681084800000,
              y: 0.8120740019474196,
              percentage: 81,
              opportunities: 1027,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 1,027",
            },
            {
              x: 1681689600000,
              y: 0,
              percentage: 0,
              opportunities: 2,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 2",
            },
          ],
          visible: false,
          opportunities: 13498,
          successful_opportunities: 10440,
          performance: 0.7734479182101052,
        },
        {
          name: "Techs-978",
          data: [
            {
              x: 1675641600000,
              y: 0.8460943542150039,
              percentage: 85,
              opportunities: 1293,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 1,293",
            },
            {
              x: 1676246400000,
              y: 0.8347781744008159,
              percentage: 83,
              opportunities: 1961,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1,961",
            },
            {
              x: 1676851200000,
              y: 0.873117033603708,
              percentage: 87,
              opportunities: 1726,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 1,726",
            },
            {
              x: 1677456000000,
              y: 0.8337912087912088,
              percentage: 83,
              opportunities: 1456,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 1,456",
            },
            {
              x: 1678060800000,
              y: 0.8303693570451436,
              percentage: 83,
              opportunities: 1462,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 1,462",
            },
            {
              x: 1678665600000,
              y: 0.8312559923298178,
              percentage: 83,
              opportunities: 1043,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 1,043",
            },
            {
              x: 1679270400000,
              y: 0.8146341463414634,
              percentage: 81,
              opportunities: 820,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 820",
            },
            {
              x: 1679875200000,
              y: 0.8225806451612904,
              percentage: 82,
              opportunities: 1488,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 1,488",
            },
            {
              x: 1680480000000,
              y: 0.8018518518518518,
              percentage: 80,
              opportunities: 1080,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 1,080",
            },
            {
              x: 1681084800000,
              y: 0.7941463414634147,
              percentage: 79,
              opportunities: 1025,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 1,025",
            },
            {
              x: 1681689600000,
              y: 0.7368421052631579,
              percentage: 74,
              opportunities: 19,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 19",
            },
          ],
          visible: false,
          opportunities: 13373,
          successful_opportunities: 11119,
          performance: 0.8314514319898303,
        },
        {
          name: "Nurses (RN)-957",
          data: [
            {
              x: 1675641600000,
              y: 0.8530465949820788,
              percentage: 85,
              opportunities: 1395,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 1,395",
            },
            {
              x: 1676246400000,
              y: 0.8428314337132573,
              percentage: 84,
              opportunities: 1667,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1,667",
            },
            {
              x: 1676851200000,
              y: 0.8520710059171598,
              percentage: 85,
              opportunities: 2028,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2,028",
            },
            {
              x: 1677456000000,
              y: 0.8554143980641258,
              percentage: 86,
              opportunities: 1653,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 1,653",
            },
            {
              x: 1678060800000,
              y: 0.798828125,
              percentage: 80,
              opportunities: 1024,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 1,024",
            },
            {
              x: 1678665600000,
              y: 0.8267241379310345,
              percentage: 83,
              opportunities: 1160,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 1,160",
            },
            {
              x: 1679270400000,
              y: 0.832282471626734,
              percentage: 83,
              opportunities: 793,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 793",
            },
            {
              x: 1679875200000,
              y: 0.81789802289282,
              percentage: 82,
              opportunities: 961,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 961",
            },
            {
              x: 1680480000000,
              y: 0.8195739014647138,
              percentage: 82,
              opportunities: 1502,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 1,502",
            },
            {
              x: 1681084800000,
              y: 0.7408906882591093,
              percentage: 74,
              opportunities: 741,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 741",
            },
            {
              x: 1681689600000,
              y: 0.7142857142857143,
              percentage: 71,
              opportunities: 7,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 7",
            },
          ],
          visible: false,
          opportunities: 12931,
          successful_opportunities: 10745,
          performance: 0.8309488825303534,
        },
        {
          name: "Social Workers-938",
          data: [
            {
              x: 1675641600000,
              y: 0.8701923076923077,
              percentage: 87,
              opportunities: 1040,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 1,040",
            },
            {
              x: 1676246400000,
              y: 0.8632478632478633,
              percentage: 86,
              opportunities: 936,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 936",
            },
            {
              x: 1676851200000,
              y: 0.8699186991869918,
              percentage: 87,
              opportunities: 984,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 984",
            },
            {
              x: 1677456000000,
              y: 0.8594339622641509,
              percentage: 86,
              opportunities: 1060,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 1,060",
            },
            {
              x: 1678060800000,
              y: 0.8858757062146893,
              percentage: 89,
              opportunities: 885,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 885",
            },
            {
              x: 1678665600000,
              y: 0.8748787584869059,
              percentage: 87,
              opportunities: 1031,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 1,031",
            },
            {
              x: 1679270400000,
              y: 0.8747433264887063,
              percentage: 87,
              opportunities: 974,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 974",
            },
            {
              x: 1679875200000,
              y: 0.852054794520548,
              percentage: 85,
              opportunities: 1095,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 1,095",
            },
            {
              x: 1680480000000,
              y: 0.8523908523908524,
              percentage: 85,
              opportunities: 962,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 962",
            },
            {
              x: 1681084800000,
              y: 0.8568421052631578,
              percentage: 86,
              opportunities: 950,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 950",
            },
          ],
          visible: false,
          opportunities: 9917,
          successful_opportunities: 8585,
          performance: 0.865685187052536,
        },
        {
          name: "Techs-982",
          data: [
            {
              x: 1675641600000,
              y: 0.8256549232158988,
              percentage: 83,
              opportunities: 1107,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 1,107",
            },
            {
              x: 1676246400000,
              y: 0.8780231335436383,
              percentage: 88,
              opportunities: 951,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 951",
            },
            {
              x: 1676851200000,
              y: 0.824438202247191,
              percentage: 82,
              opportunities: 712,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 712",
            },
            {
              x: 1677456000000,
              y: 0.839572192513369,
              percentage: 84,
              opportunities: 561,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 561",
            },
            {
              x: 1678060800000,
              y: 0.7909790979097909,
              percentage: 79,
              opportunities: 909,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 909",
            },
            {
              x: 1678665600000,
              y: 0.7987220447284346,
              percentage: 80,
              opportunities: 939,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 939",
            },
            {
              x: 1679270400000,
              y: 0.8088942307692307,
              percentage: 81,
              opportunities: 832,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 832",
            },
            {
              x: 1679875200000,
              y: 0.7478411053540587,
              percentage: 75,
              opportunities: 1158,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 1,158",
            },
            {
              x: 1680480000000,
              y: 0.8208802456499488,
              percentage: 82,
              opportunities: 977,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 977",
            },
            {
              x: 1681084800000,
              y: 0.7611788617886179,
              percentage: 76,
              opportunities: 984,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 984",
            },
          ],
          visible: false,
          opportunities: 9130,
          successful_opportunities: 7366,
          performance: 0.8067907995618839,
        },
        {
          name: "Food Services-937",
          data: [
            {
              x: 1675641600000,
              y: 0.8516902944383861,
              percentage: 85,
              opportunities: 917,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 917",
            },
            {
              x: 1676246400000,
              y: 0.8596713021491783,
              percentage: 86,
              opportunities: 791,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 791",
            },
            {
              x: 1676851200000,
              y: 0.8703506907545164,
              percentage: 87,
              opportunities: 941,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 941",
            },
            {
              x: 1677456000000,
              y: 0.8278787878787879,
              percentage: 83,
              opportunities: 825,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 825",
            },
            {
              x: 1678060800000,
              y: 0.8781284004352558,
              percentage: 88,
              opportunities: 919,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 919",
            },
            {
              x: 1678665600000,
              y: 0.8619756427604871,
              percentage: 86,
              opportunities: 739,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 739",
            },
            {
              x: 1679270400000,
              y: 0.8766169154228856,
              percentage: 88,
              opportunities: 1005,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 1,005",
            },
            {
              x: 1679875200000,
              y: 0.8378887841658812,
              percentage: 84,
              opportunities: 1061,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 1,061",
            },
            {
              x: 1680480000000,
              y: 0.8484848484848485,
              percentage: 85,
              opportunities: 858,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 858",
            },
            {
              x: 1681084800000,
              y: 0.8424657534246576,
              percentage: 84,
              opportunities: 730,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 730",
            },
          ],
          visible: false,
          opportunities: 8786,
          successful_opportunities: 7520,
          performance: 0.8559071249715456,
        },
        {
          name: "Nurses (RN)-951",
          data: [
            {
              x: 1675641600000,
              y: 0.8742937853107344,
              percentage: 87,
              opportunities: 708,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 708",
            },
            {
              x: 1676246400000,
              y: 0.8775743707093822,
              percentage: 88,
              opportunities: 874,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 874",
            },
            {
              x: 1676851200000,
              y: 0.8396946564885496,
              percentage: 84,
              opportunities: 655,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 655",
            },
            {
              x: 1677456000000,
              y: 0.8184615384615385,
              percentage: 82,
              opportunities: 650,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 650",
            },
            {
              x: 1678060800000,
              y: 0.8576960309777347,
              percentage: 86,
              opportunities: 1033,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 1,033",
            },
            {
              x: 1678665600000,
              y: 0.8286516853932584,
              percentage: 83,
              opportunities: 712,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 712",
            },
            {
              x: 1679270400000,
              y: 0.8618654073199528,
              percentage: 86,
              opportunities: 847,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 847",
            },
            {
              x: 1679875200000,
              y: 0.8261376896149358,
              percentage: 83,
              opportunities: 857,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 857",
            },
            {
              x: 1680480000000,
              y: 0.8370786516853933,
              percentage: 84,
              opportunities: 1246,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 1,246",
            },
            {
              x: 1681084800000,
              y: 0.8374331550802139,
              percentage: 84,
              opportunities: 935,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 935",
            },
            {
              x: 1681689600000,
              y: 1,
              percentage: 100,
              opportunities: 10,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 10",
            },
          ],
          visible: false,
          opportunities: 8527,
          successful_opportunities: 7218,
          performance: 0.8464876275360619,
        },
        {
          name: "Techs-988",
          data: [
            {
              x: 1675641600000,
              y: 0.8402061855670103,
              percentage: 84,
              opportunities: 776,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 776",
            },
            {
              x: 1676246400000,
              y: 0.8306709265175719,
              percentage: 83,
              opportunities: 939,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 939",
            },
            {
              x: 1676851200000,
              y: 0.8135593220338984,
              percentage: 81,
              opportunities: 413,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 413",
            },
            {
              x: 1677456000000,
              y: 0.8141097424412094,
              percentage: 81,
              opportunities: 893,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 893",
            },
            {
              x: 1678060800000,
              y: 0.8325925925925926,
              percentage: 83,
              opportunities: 675,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 675",
            },
            {
              x: 1678665600000,
              y: 0.8200934579439252,
              percentage: 82,
              opportunities: 856,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 856",
            },
            {
              x: 1679270400000,
              y: 0.7918367346938775,
              percentage: 79,
              opportunities: 735,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 735",
            },
            {
              x: 1679875200000,
              y: 0.798469387755102,
              percentage: 80,
              opportunities: 1176,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 1,176",
            },
            {
              x: 1680480000000,
              y: 0.7784290738569754,
              percentage: 78,
              opportunities: 853,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 853",
            },
            {
              x: 1681084800000,
              y: 0.7986767485822306,
              percentage: 80,
              opportunities: 1058,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 1,058",
            },
            {
              x: 1681689600000,
              y: 0.5,
              percentage: 50,
              opportunities: 6,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 6",
            },
          ],
          visible: false,
          opportunities: 8380,
          successful_opportunities: 6792,
          performance: 0.8105011933174224,
        },
        {
          name: "Nurses (RN)-3707",
          data: [
            {
              x: 1675641600000,
              y: 0.8647482014388489,
              percentage: 86,
              opportunities: 695,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 695",
            },
            {
              x: 1676246400000,
              y: 0.8482252141982864,
              percentage: 85,
              opportunities: 817,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 817",
            },
            {
              x: 1676851200000,
              y: 0.7755102040816326,
              percentage: 78,
              opportunities: 686,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 686",
            },
            {
              x: 1677456000000,
              y: 0.8407557354925776,
              percentage: 84,
              opportunities: 741,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 741",
            },
            {
              x: 1678060800000,
              y: 0.8400852878464818,
              percentage: 84,
              opportunities: 938,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 938",
            },
            {
              x: 1678665600000,
              y: 0.7983104540654699,
              percentage: 80,
              opportunities: 947,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 947",
            },
            {
              x: 1679270400000,
              y: 0.865,
              percentage: 87,
              opportunities: 800,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 800",
            },
            {
              x: 1679875200000,
              y: 0.8238289205702648,
              percentage: 82,
              opportunities: 982,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 982",
            },
            {
              x: 1680480000000,
              y: 0.8172043010752689,
              percentage: 82,
              opportunities: 744,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 744",
            },
            {
              x: 1681084800000,
              y: 0.8058252427184466,
              percentage: 81,
              opportunities: 824,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 824",
            },
            {
              x: 1681689600000,
              y: 0.5,
              percentage: 50,
              opportunities: 6,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 6",
            },
          ],
          visible: false,
          opportunities: 8180,
          successful_opportunities: 6769,
          performance: 0.8275061124694376,
        },
        {
          name: "Techs-985",
          data: [
            {
              x: 1675641600000,
              y: 0.7624434389140271,
              percentage: 76,
              opportunities: 884,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 884",
            },
            {
              x: 1676246400000,
              y: 0.7325428194993412,
              percentage: 73,
              opportunities: 759,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 759",
            },
            {
              x: 1676851200000,
              y: 0.774364406779661,
              percentage: 77,
              opportunities: 944,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 944",
            },
            {
              x: 1677456000000,
              y: 0.7292576419213974,
              percentage: 73,
              opportunities: 687,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 687",
            },
            {
              x: 1678060800000,
              y: 0.7495798319327731,
              percentage: 75,
              opportunities: 595,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 595",
            },
            {
              x: 1678665600000,
              y: 0.7672872340425532,
              percentage: 77,
              opportunities: 752,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 752",
            },
            {
              x: 1679270400000,
              y: 0.7091136079900124,
              percentage: 71,
              opportunities: 801,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 801",
            },
            {
              x: 1679875200000,
              y: 0.7268041237113402,
              percentage: 73,
              opportunities: 776,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 776",
            },
            {
              x: 1680480000000,
              y: 0.6924265842349304,
              percentage: 69,
              opportunities: 647,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 647",
            },
            {
              x: 1681084800000,
              y: 0.7122002085505735,
              percentage: 71,
              opportunities: 959,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 959",
            },
          ],
          visible: false,
          opportunities: 7804,
          successful_opportunities: 5748,
          performance: 0.7365453613531522,
        },
        {
          name: "Techs-979",
          data: [
            {
              x: 1675641600000,
              y: 0.8413461538461539,
              percentage: 84,
              opportunities: 832,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 832",
            },
            {
              x: 1676246400000,
              y: 0.8502732240437159,
              percentage: 85,
              opportunities: 915,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 915",
            },
            {
              x: 1676851200000,
              y: 0.9042933810375671,
              percentage: 90,
              opportunities: 1118,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 1,118",
            },
            {
              x: 1677456000000,
              y: 0.8918518518518519,
              percentage: 89,
              opportunities: 675,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 675",
            },
            {
              x: 1678060800000,
              y: 0.8843663274745606,
              percentage: 88,
              opportunities: 1081,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 1,081",
            },
            {
              x: 1678665600000,
              y: 0.8651275820170109,
              percentage: 87,
              opportunities: 823,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 823",
            },
            {
              x: 1679270400000,
              y: 0.9161425576519916,
              percentage: 92,
              opportunities: 477,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 477",
            },
            {
              x: 1679875200000,
              y: 0.8241308793456033,
              percentage: 82,
              opportunities: 489,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 489",
            },
            {
              x: 1680480000000,
              y: 0.8027812895069533,
              percentage: 80,
              opportunities: 791,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 791",
            },
            {
              x: 1681084800000,
              y: 0.7671517671517671,
              percentage: 77,
              opportunities: 481,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 481",
            },
          ],
          visible: false,
          opportunities: 7682,
          successful_opportunities: 6603,
          performance: 0.859541785993231,
        },
        {
          name: "Physicians-2615",
          data: [
            {
              x: 1675641600000,
              y: 0.8955223880597015,
              percentage: 90,
              opportunities: 536,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 536",
            },
            {
              x: 1676246400000,
              y: 0.8264014466546112,
              percentage: 83,
              opportunities: 553,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 553",
            },
            {
              x: 1676851200000,
              y: 0.8397683397683398,
              percentage: 84,
              opportunities: 518,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 518",
            },
            {
              x: 1677456000000,
              y: 0.8615916955017301,
              percentage: 86,
              opportunities: 578,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 578",
            },
            {
              x: 1678060800000,
              y: 0.8162729658792651,
              percentage: 82,
              opportunities: 381,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 381",
            },
            {
              x: 1678665600000,
              y: 0.8895833333333333,
              percentage: 89,
              opportunities: 480,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 480",
            },
            {
              x: 1679270400000,
              y: 0.883399209486166,
              percentage: 88,
              opportunities: 506,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 506",
            },
            {
              x: 1679875200000,
              y: 0.8631756756756757,
              percentage: 86,
              opportunities: 592,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 592",
            },
            {
              x: 1680480000000,
              y: 0.8754448398576512,
              percentage: 88,
              opportunities: 562,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 562",
            },
            {
              x: 1681084800000,
              y: 0.8398791540785498,
              percentage: 84,
              opportunities: 662,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 662",
            },
            {
              x: 1681689600000,
              y: 1,
              percentage: 100,
              opportunities: 2,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 2",
            },
          ],
          visible: false,
          opportunities: 5370,
          successful_opportunities: 4616,
          performance: 0.8595903165735568,
        },
        {
          name: "Techs-980",
          data: [
            {
              x: 1675641600000,
              y: 0.8526682134570766,
              percentage: 85,
              opportunities: 862,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 862",
            },
            {
              x: 1676246400000,
              y: 0.8839848675914249,
              percentage: 88,
              opportunities: 793,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 793",
            },
            {
              x: 1676851200000,
              y: 0.9014598540145985,
              percentage: 90,
              opportunities: 548,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 548",
            },
            {
              x: 1677456000000,
              y: 0.9049295774647887,
              percentage: 90,
              opportunities: 568,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 568",
            },
            {
              x: 1678060800000,
              y: 0.9,
              percentage: 90,
              opportunities: 760,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 760",
            },
            {
              x: 1678665600000,
              y: 0.847457627118644,
              percentage: 85,
              opportunities: 590,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 590",
            },
            {
              x: 1679270400000,
              y: 0.87248322147651,
              percentage: 87,
              opportunities: 298,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 298",
            },
            {
              x: 1679875200000,
              y: 0.8808664259927798,
              percentage: 88,
              opportunities: 277,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 277",
            },
            {
              x: 1680480000000,
              y: 0.8864265927977839,
              percentage: 89,
              opportunities: 361,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 361",
            },
            {
              x: 1681084800000,
              y: 0.8092105263157895,
              percentage: 81,
              opportunities: 152,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 152",
            },
            {
              x: 1681689600000,
              y: 0.5,
              percentage: 50,
              opportunities: 2,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 2",
            },
          ],
          visible: false,
          opportunities: 5211,
          successful_opportunities: 4576,
          performance: 0.878142391095759,
        },
        {
          name: "Radiology-934",
          data: [
            {
              x: 1675641600000,
              y: 0.8404907975460123,
              percentage: 84,
              opportunities: 489,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 489",
            },
            {
              x: 1676246400000,
              y: 0.8470149253731343,
              percentage: 85,
              opportunities: 536,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 536",
            },
            {
              x: 1676851200000,
              y: 0.891566265060241,
              percentage: 89,
              opportunities: 498,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 498",
            },
            {
              x: 1677456000000,
              y: 0.8357142857142857,
              percentage: 84,
              opportunities: 560,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 560",
            },
            {
              x: 1678060800000,
              y: 0.8539325842696629,
              percentage: 85,
              opportunities: 445,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 445",
            },
            {
              x: 1678665600000,
              y: 0.8467023172905526,
              percentage: 85,
              opportunities: 561,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 561",
            },
            {
              x: 1679270400000,
              y: 0.8179871520342612,
              percentage: 82,
              opportunities: 467,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 467",
            },
            {
              x: 1679875200000,
              y: 0.8552380952380952,
              percentage: 86,
              opportunities: 525,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 525",
            },
            {
              x: 1680480000000,
              y: 0.78,
              percentage: 78,
              opportunities: 550,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 550",
            },
            {
              x: 1681084800000,
              y: 0.831275720164609,
              percentage: 83,
              opportunities: 486,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 486",
            },
            {
              x: 1681689600000,
              y: 1,
              percentage: 100,
              opportunities: 2,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 2",
            },
          ],
          visible: false,
          opportunities: 5119,
          successful_opportunities: 4298,
          performance: 0.8396171127173276,
        },
        {
          name: "Physicians-2612",
          data: [
            {
              x: 1675641600000,
              y: 0.8346938775510204,
              percentage: 83,
              opportunities: 490,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 490",
            },
            {
              x: 1676246400000,
              y: 0.881578947368421,
              percentage: 88,
              opportunities: 456,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 456",
            },
            {
              x: 1676851200000,
              y: 0.8827751196172249,
              percentage: 88,
              opportunities: 418,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 418",
            },
            {
              x: 1677456000000,
              y: 0.8629856850715747,
              percentage: 86,
              opportunities: 489,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 489",
            },
            {
              x: 1678060800000,
              y: 0.8571428571428571,
              percentage: 86,
              opportunities: 651,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 651",
            },
            {
              x: 1678665600000,
              y: 0.84251968503937,
              percentage: 84,
              opportunities: 635,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 635",
            },
            {
              x: 1679270400000,
              y: 0.8377049180327869,
              percentage: 84,
              opportunities: 610,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 610",
            },
            {
              x: 1679875200000,
              y: 0.8196078431372549,
              percentage: 82,
              opportunities: 510,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 510",
            },
            {
              x: 1680480000000,
              y: 0.8312020460358056,
              percentage: 83,
              opportunities: 391,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 391",
            },
            {
              x: 1681084800000,
              y: 0.8350515463917526,
              percentage: 84,
              opportunities: 388,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 388",
            },
            {
              x: 1681689600000,
              y: 0.6666666666666666,
              percentage: 67,
              opportunities: 6,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 6",
            },
          ],
          visible: false,
          opportunities: 5044,
          successful_opportunities: 4277,
          performance: 0.8479381443298969,
        },
        {
          name: "Physicians-2218",
          data: [
            {
              x: 1675641600000,
              y: 0.8993576017130621,
              percentage: 90,
              opportunities: 467,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 467",
            },
            {
              x: 1676246400000,
              y: 0.9327956989247311,
              percentage: 93,
              opportunities: 372,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 372",
            },
            {
              x: 1676851200000,
              y: 0.9394495412844037,
              percentage: 94,
              opportunities: 545,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 545",
            },
            {
              x: 1677456000000,
              y: 0.9653579676674365,
              percentage: 97,
              opportunities: 433,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 433",
            },
            {
              x: 1678060800000,
              y: 0.9485981308411215,
              percentage: 95,
              opportunities: 428,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 428",
            },
            {
              x: 1678665600000,
              y: 0.9420935412026726,
              percentage: 94,
              opportunities: 449,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 449",
            },
            {
              x: 1679270400000,
              y: 0.930835734870317,
              percentage: 93,
              opportunities: 347,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 347",
            },
            {
              x: 1679875200000,
              y: 0.9217171717171717,
              percentage: 92,
              opportunities: 396,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 396",
            },
            {
              x: 1680480000000,
              y: 0.9532467532467532,
              percentage: 95,
              opportunities: 385,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 385",
            },
            {
              x: 1681084800000,
              y: 0.8873873873873874,
              percentage: 89,
              opportunities: 444,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 444",
            },
          ],
          visible: false,
          opportunities: 4266,
          successful_opportunities: 3975,
          performance: 0.9317862165963432,
        },
        {
          name: "Techs-977",
          data: [
            {
              x: 1675641600000,
              y: 0.8486312399355878,
              percentage: 85,
              opportunities: 621,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 621",
            },
            {
              x: 1676246400000,
              y: 0.8779803646563815,
              percentage: 88,
              opportunities: 713,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 713",
            },
            {
              x: 1676851200000,
              y: 0.8160377358490566,
              percentage: 82,
              opportunities: 636,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 636",
            },
            {
              x: 1677456000000,
              y: 0.8177083333333334,
              percentage: 82,
              opportunities: 384,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 384",
            },
            {
              x: 1678060800000,
              y: 0.8464912280701754,
              percentage: 85,
              opportunities: 228,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 228",
            },
            {
              x: 1678665600000,
              y: 0.7664670658682635,
              percentage: 77,
              opportunities: 334,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 334",
            },
            {
              x: 1679270400000,
              y: 0.8138297872340425,
              percentage: 81,
              opportunities: 188,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 188",
            },
            {
              x: 1679875200000,
              y: 0.7920604914933838,
              percentage: 79,
              opportunities: 529,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 529",
            },
            {
              x: 1680480000000,
              y: 0.6995073891625616,
              percentage: 70,
              opportunities: 203,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 203",
            },
            {
              x: 1681084800000,
              y: 0.7356687898089171,
              percentage: 74,
              opportunities: 314,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 314",
            },
          ],
          visible: false,
          opportunities: 4150,
          successful_opportunities: 3380,
          performance: 0.8144578313253013,
        },
        {
          name: "Physicians-2216",
          data: [
            {
              x: 1675641600000,
              y: 0.8497267759562842,
              percentage: 85,
              opportunities: 366,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 366",
            },
            {
              x: 1676246400000,
              y: 0.8389121338912134,
              percentage: 84,
              opportunities: 478,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 478",
            },
            {
              x: 1676851200000,
              y: 0.8361204013377926,
              percentage: 84,
              opportunities: 299,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 299",
            },
            {
              x: 1677456000000,
              y: 0.8817204301075269,
              percentage: 88,
              opportunities: 465,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 465",
            },
            {
              x: 1678060800000,
              y: 0.896774193548387,
              percentage: 90,
              opportunities: 310,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 310",
            },
            {
              x: 1678665600000,
              y: 0.888268156424581,
              percentage: 89,
              opportunities: 358,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 358",
            },
            {
              x: 1679270400000,
              y: 0.9013333333333333,
              percentage: 90,
              opportunities: 375,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 375",
            },
            {
              x: 1679875200000,
              y: 0.871875,
              percentage: 87,
              opportunities: 320,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 320",
            },
            {
              x: 1680480000000,
              y: 0.8719806763285024,
              percentage: 87,
              opportunities: 414,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 414",
            },
            {
              x: 1681084800000,
              y: 0.8581818181818182,
              percentage: 86,
              opportunities: 550,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 550",
            },
          ],
          visible: false,
          opportunities: 3935,
          successful_opportunities: 3418,
          performance: 0.8686149936467599,
        },
        {
          name: "Techs-987",
          data: [
            {
              x: 1675641600000,
              y: 0.772823779193206,
              percentage: 77,
              opportunities: 471,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 471",
            },
            {
              x: 1676246400000,
              y: 0.7784256559766763,
              percentage: 78,
              opportunities: 343,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 343",
            },
            {
              x: 1676851200000,
              y: 0.7928571428571428,
              percentage: 79,
              opportunities: 280,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 280",
            },
            {
              x: 1677456000000,
              y: 0.8112449799196787,
              percentage: 81,
              opportunities: 249,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 249",
            },
            {
              x: 1678060800000,
              y: 0.8571428571428571,
              percentage: 86,
              opportunities: 350,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 350",
            },
            {
              x: 1678665600000,
              y: 0.8318385650224215,
              percentage: 83,
              opportunities: 446,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 446",
            },
            {
              x: 1679270400000,
              y: 0.8866799204771372,
              percentage: 89,
              opportunities: 503,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 503",
            },
            {
              x: 1679875200000,
              y: 0.8088888888888889,
              percentage: 81,
              opportunities: 225,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 225",
            },
            {
              x: 1680480000000,
              y: 0.8754578754578755,
              percentage: 88,
              opportunities: 273,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 273",
            },
            {
              x: 1681084800000,
              y: 0.8550106609808102,
              percentage: 86,
              opportunities: 469,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 469",
            },
          ],
          visible: false,
          opportunities: 3609,
          successful_opportunities: 2994,
          performance: 0.829592684954281,
        },
        {
          name: "Physicians-2616",
          data: [
            {
              x: 1675641600000,
              y: 0.7150837988826816,
              percentage: 72,
              opportunities: 358,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 358",
            },
            {
              x: 1676246400000,
              y: 0.6487695749440716,
              percentage: 65,
              opportunities: 447,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 447",
            },
            {
              x: 1676851200000,
              y: 0.7194719471947195,
              percentage: 72,
              opportunities: 303,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 303",
            },
            {
              x: 1677456000000,
              y: 0.6972704714640199,
              percentage: 70,
              opportunities: 403,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 403",
            },
            {
              x: 1678060800000,
              y: 0.6831683168316832,
              percentage: 68,
              opportunities: 404,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 404",
            },
            {
              x: 1678665600000,
              y: 0.706060606060606,
              percentage: 71,
              opportunities: 330,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 330",
            },
            {
              x: 1679270400000,
              y: 0.6690997566909975,
              percentage: 67,
              opportunities: 411,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 411",
            },
            {
              x: 1679875200000,
              y: 0.8142857142857143,
              percentage: 81,
              opportunities: 280,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 280",
            },
            {
              x: 1680480000000,
              y: 0.7935483870967742,
              percentage: 79,
              opportunities: 310,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 310",
            },
            {
              x: 1681084800000,
              y: 0.7422222222222222,
              percentage: 74,
              opportunities: 225,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 225",
            },
            {
              x: 1681689600000,
              y: 0.8,
              percentage: 80,
              opportunities: 5,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 5",
            },
          ],
          visible: false,
          opportunities: 3476,
          successful_opportunities: 2474,
          performance: 0.7117376294591484,
        },
        {
          name: "Food Services-946",
          data: [
            {
              x: 1675641600000,
              y: 0.8880866425992779,
              percentage: 89,
              opportunities: 277,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 277",
            },
            {
              x: 1676246400000,
              y: 0.9261213720316622,
              percentage: 93,
              opportunities: 379,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 379",
            },
            {
              x: 1676851200000,
              y: 0.889487870619946,
              percentage: 89,
              opportunities: 371,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 371",
            },
            {
              x: 1677456000000,
              y: 0.9256410256410257,
              percentage: 93,
              opportunities: 390,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 390",
            },
            {
              x: 1678060800000,
              y: 0.9185667752442996,
              percentage: 92,
              opportunities: 307,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 307",
            },
            {
              x: 1678665600000,
              y: 0.9047619047619048,
              percentage: 90,
              opportunities: 357,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 357",
            },
            {
              x: 1679270400000,
              y: 0.8986013986013986,
              percentage: 90,
              opportunities: 286,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 286",
            },
            {
              x: 1679875200000,
              y: 0.8971631205673759,
              percentage: 90,
              opportunities: 282,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 282",
            },
            {
              x: 1680480000000,
              y: 0.8975069252077562,
              percentage: 90,
              opportunities: 361,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 361",
            },
            {
              x: 1681084800000,
              y: 0.8789625360230547,
              percentage: 88,
              opportunities: 347,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 347",
            },
          ],
          visible: false,
          opportunities: 3357,
          successful_opportunities: 3032,
          performance: 0.9031873696753053,
        },
        {
          name: "Group-947",
          data: [
            {
              x: 1675641600000,
              y: 0.8811188811188811,
              percentage: 88,
              opportunities: 286,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 286",
            },
            {
              x: 1676246400000,
              y: 0.8505434782608695,
              percentage: 85,
              opportunities: 368,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 368",
            },
            {
              x: 1676851200000,
              y: 0.8900804289544236,
              percentage: 89,
              opportunities: 373,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 373",
            },
            {
              x: 1677456000000,
              y: 0.8688524590163934,
              percentage: 87,
              opportunities: 305,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 305",
            },
            {
              x: 1678060800000,
              y: 0.8013245033112583,
              percentage: 80,
              opportunities: 302,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 302",
            },
            {
              x: 1678665600000,
              y: 0.848297213622291,
              percentage: 85,
              opportunities: 323,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 323",
            },
            {
              x: 1679270400000,
              y: 0.8757225433526011,
              percentage: 88,
              opportunities: 346,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 346",
            },
            {
              x: 1679875200000,
              y: 0.8316062176165803,
              percentage: 83,
              opportunities: 386,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 386",
            },
            {
              x: 1680480000000,
              y: 0.8283582089552238,
              percentage: 83,
              opportunities: 268,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 268",
            },
            {
              x: 1681084800000,
              y: 0.8228228228228228,
              percentage: 82,
              opportunities: 333,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 333",
            },
          ],
          visible: false,
          opportunities: 3290,
          successful_opportunities: 2798,
          performance: 0.8504559270516717,
        },
        {
          name: "Physicians-2339",
          data: [
            {
              x: 1675641600000,
              y: 0.9017094017094017,
              percentage: 90,
              opportunities: 234,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 234",
            },
            {
              x: 1676246400000,
              y: 0.8713450292397661,
              percentage: 87,
              opportunities: 342,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 342",
            },
            {
              x: 1676851200000,
              y: 0.8820375335120644,
              percentage: 88,
              opportunities: 373,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 373",
            },
            {
              x: 1677456000000,
              y: 0.8848484848484849,
              percentage: 88,
              opportunities: 165,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 165",
            },
            {
              x: 1678060800000,
              y: 0.8649350649350649,
              percentage: 86,
              opportunities: 385,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 385",
            },
            {
              x: 1678665600000,
              y: 0.8963855421686747,
              percentage: 90,
              opportunities: 415,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 415",
            },
            {
              x: 1679270400000,
              y: 0.887459807073955,
              percentage: 89,
              opportunities: 311,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 311",
            },
            {
              x: 1679875200000,
              y: 0.835,
              percentage: 84,
              opportunities: 200,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 200",
            },
            {
              x: 1680480000000,
              y: 0.9038076152304609,
              percentage: 90,
              opportunities: 499,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 499",
            },
            {
              x: 1681084800000,
              y: 0.8408163265306122,
              percentage: 84,
              opportunities: 245,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 245",
            },
          ],
          visible: false,
          opportunities: 3169,
          successful_opportunities: 2789,
          performance: 0.8800883559482486,
        },
        {
          name: "Techs-976",
          data: [
            {
              x: 1675641600000,
              y: 0.8675078864353313,
              percentage: 87,
              opportunities: 317,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 317",
            },
            {
              x: 1676246400000,
              y: 0.855072463768116,
              percentage: 86,
              opportunities: 207,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 207",
            },
            {
              x: 1676851200000,
              y: 0.8746268656716418,
              percentage: 87,
              opportunities: 335,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 335",
            },
            {
              x: 1677456000000,
              y: 0.8665254237288136,
              percentage: 87,
              opportunities: 472,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 472",
            },
            {
              x: 1678060800000,
              y: 0.883248730964467,
              percentage: 88,
              opportunities: 394,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 394",
            },
            {
              x: 1678665600000,
              y: 0.8875379939209727,
              percentage: 89,
              opportunities: 329,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 329",
            },
            {
              x: 1679270400000,
              y: 0.8974358974358975,
              percentage: 90,
              opportunities: 156,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 156",
            },
            {
              x: 1679875200000,
              y: 0.8949494949494949,
              percentage: 89,
              opportunities: 495,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 495",
            },
            {
              x: 1680480000000,
              y: 0.92578125,
              percentage: 93,
              opportunities: 256,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 256",
            },
            {
              x: 1681084800000,
              y: 0.9565217391304348,
              percentage: 96,
              opportunities: 92,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 92",
            },
          ],
          visible: false,
          opportunities: 3053,
          successful_opportunities: 2702,
          performance: 0.8850311169341631,
        },
        {
          name: "Midlevel Providers (MID/PA/NP)-2219",
          data: [
            {
              x: 1675641600000,
              y: 0.9090909090909091,
              percentage: 91,
              opportunities: 253,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 253",
            },
            {
              x: 1676246400000,
              y: 0.9433198380566802,
              percentage: 94,
              opportunities: 247,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 247",
            },
            {
              x: 1676851200000,
              y: 0.9566787003610109,
              percentage: 96,
              opportunities: 277,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 277",
            },
            {
              x: 1677456000000,
              y: 0.9725490196078431,
              percentage: 97,
              opportunities: 255,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 255",
            },
            {
              x: 1678060800000,
              y: 0.896774193548387,
              percentage: 90,
              opportunities: 310,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 310",
            },
            {
              x: 1678665600000,
              y: 0.9127272727272727,
              percentage: 91,
              opportunities: 275,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 275",
            },
            {
              x: 1679270400000,
              y: 0.9481481481481482,
              percentage: 95,
              opportunities: 270,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 270",
            },
            {
              x: 1679875200000,
              y: 0.9523809523809523,
              percentage: 95,
              opportunities: 294,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 294",
            },
            {
              x: 1680480000000,
              y: 0.9423076923076923,
              percentage: 94,
              opportunities: 260,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 260",
            },
            {
              x: 1681084800000,
              y: 0.954248366013072,
              percentage: 95,
              opportunities: 306,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 306",
            },
            {
              x: 1681689600000,
              y: 1,
              percentage: 100,
              opportunities: 2,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 2",
            },
          ],
          visible: false,
          opportunities: 2749,
          successful_opportunities: 2580,
          performance: 0.9385230993088396,
        },
        {
          name: "Therapists-945",
          data: [
            {
              x: 1675641600000,
              y: 0.897887323943662,
              percentage: 90,
              opportunities: 284,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 284",
            },
            {
              x: 1676246400000,
              y: 0.8518518518518519,
              percentage: 85,
              opportunities: 243,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 243",
            },
            {
              x: 1676851200000,
              y: 0.9004739336492891,
              percentage: 90,
              opportunities: 211,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 211",
            },
            {
              x: 1677456000000,
              y: 0.8387096774193549,
              percentage: 84,
              opportunities: 248,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 248",
            },
            {
              x: 1678060800000,
              y: 0.8727272727272727,
              percentage: 87,
              opportunities: 275,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 275",
            },
            {
              x: 1678665600000,
              y: 0.9204545454545454,
              percentage: 92,
              opportunities: 264,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 264",
            },
            {
              x: 1679270400000,
              y: 0.8508064516129032,
              percentage: 85,
              opportunities: 248,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 248",
            },
            {
              x: 1679875200000,
              y: 0.9084507042253521,
              percentage: 91,
              opportunities: 284,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 284",
            },
            {
              x: 1680480000000,
              y: 0.8894230769230769,
              percentage: 89,
              opportunities: 208,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 208",
            },
            {
              x: 1681084800000,
              y: 0.8451327433628318,
              percentage: 85,
              opportunities: 226,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 226",
            },
          ],
          visible: false,
          opportunities: 2491,
          successful_opportunities: 2188,
          performance: 0.8783621035728623,
        },
        {
          name: "Nurses (RN)-1565",
          data: [
            {
              x: 1675641600000,
              y: 0.9289940828402367,
              percentage: 93,
              opportunities: 169,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 169",
            },
            {
              x: 1676246400000,
              y: 0.9194630872483222,
              percentage: 92,
              opportunities: 298,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 298",
            },
            {
              x: 1676851200000,
              y: 0.9254385964912281,
              percentage: 93,
              opportunities: 228,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 228",
            },
            {
              x: 1677456000000,
              y: 0.9358974358974359,
              percentage: 94,
              opportunities: 312,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 312",
            },
            {
              x: 1678060800000,
              y: 0.9639344262295082,
              percentage: 96,
              opportunities: 305,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 305",
            },
            {
              x: 1678665600000,
              y: 0.9516129032258065,
              percentage: 95,
              opportunities: 124,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 124",
            },
            {
              x: 1679270400000,
              y: 0.907608695652174,
              percentage: 91,
              opportunities: 184,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 184",
            },
            {
              x: 1679875200000,
              y: 0.9311740890688259,
              percentage: 93,
              opportunities: 247,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 247",
            },
            {
              x: 1680480000000,
              y: 0.9333333333333333,
              percentage: 93,
              opportunities: 225,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 225",
            },
            {
              x: 1681084800000,
              y: 0.8935361216730038,
              percentage: 89,
              opportunities: 263,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 263",
            },
          ],
          visible: false,
          opportunities: 2355,
          successful_opportunities: 2188,
          performance: 0.9290870488322718,
        },
        {
          name: "Nurses (RN)-1377",
          data: [
            {
              x: 1675641600000,
              y: 0.8957055214723927,
              percentage: 90,
              opportunities: 163,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 163",
            },
            {
              x: 1676246400000,
              y: 0.9411764705882353,
              percentage: 94,
              opportunities: 85,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 85",
            },
            {
              x: 1676851200000,
              y: 0.8972602739726028,
              percentage: 90,
              opportunities: 146,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 146",
            },
            {
              x: 1677456000000,
              y: 0.8625954198473282,
              percentage: 86,
              opportunities: 262,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 262",
            },
            {
              x: 1678060800000,
              y: 0.927170868347339,
              percentage: 93,
              opportunities: 357,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 357",
            },
            {
              x: 1678665600000,
              y: 0.8989547038327527,
              percentage: 90,
              opportunities: 287,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 287",
            },
            {
              x: 1679270400000,
              y: 0.9197530864197531,
              percentage: 92,
              opportunities: 162,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 162",
            },
            {
              x: 1679875200000,
              y: 0.9827586206896551,
              percentage: 98,
              opportunities: 58,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 58",
            },
            {
              x: 1680480000000,
              y: 0.9509803921568627,
              percentage: 95,
              opportunities: 102,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 102",
            },
            {
              x: 1681084800000,
              y: 0.9921259842519685,
              percentage: 99,
              opportunities: 127,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 127",
            },
          ],
          visible: false,
          opportunities: 1749,
          successful_opportunities: 1601,
          performance: 0.9153802172670097,
        },
        {
          name: "Techs-972",
          data: [
            {
              x: 1675641600000,
              y: 0.9230769230769231,
              percentage: 92,
              opportunities: 13,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 13",
            },
            {
              x: 1676246400000,
              y: 0,
              percentage: 0,
              opportunities: 1,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1",
            },
            {
              x: 1676851200000,
              y: 0.7669172932330827,
              percentage: 77,
              opportunities: 133,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 133",
            },
            {
              x: 1677456000000,
              y: 0.8181818181818182,
              percentage: 82,
              opportunities: 77,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 77",
            },
            {
              x: 1678060800000,
              y: 0.7267441860465116,
              percentage: 73,
              opportunities: 172,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 172",
            },
            {
              x: 1678665600000,
              y: 0.7891566265060241,
              percentage: 79,
              opportunities: 166,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 166",
            },
            {
              x: 1679270400000,
              y: 0.7961165048543689,
              percentage: 80,
              opportunities: 103,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 103",
            },
            {
              x: 1679875200000,
              y: 0.72,
              percentage: 72,
              opportunities: 175,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 175",
            },
            {
              x: 1680480000000,
              y: 0.7354260089686099,
              percentage: 74,
              opportunities: 223,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 223",
            },
            {
              x: 1681084800000,
              y: 0.7639751552795031,
              percentage: 76,
              opportunities: 161,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 161",
            },
          ],
          visible: false,
          opportunities: 1224,
          successful_opportunities: 928,
          performance: 0.7581699346405228,
        },
        {
          name: "Midlevel Providers (MID/PA/NP)-928",
          data: [
            {
              x: 1675641600000,
              y: 0.9036144578313253,
              percentage: 90,
              opportunities: 83,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 83",
            },
            {
              x: 1676246400000,
              y: 0.7982456140350878,
              percentage: 80,
              opportunities: 114,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 114",
            },
            {
              x: 1676851200000,
              y: 0.8823529411764706,
              percentage: 88,
              opportunities: 102,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 102",
            },
            {
              x: 1677456000000,
              y: 0.8449612403100775,
              percentage: 84,
              opportunities: 129,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 129",
            },
            {
              x: 1678060800000,
              y: 0.8666666666666667,
              percentage: 87,
              opportunities: 135,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 135",
            },
            {
              x: 1678665600000,
              y: 0.9571428571428572,
              percentage: 96,
              opportunities: 70,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 70",
            },
            {
              x: 1679270400000,
              y: 0.8548387096774194,
              percentage: 85,
              opportunities: 186,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 186",
            },
            {
              x: 1679875200000,
              y: 0.7945205479452054,
              percentage: 79,
              opportunities: 73,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 73",
            },
            {
              x: 1680480000000,
              y: 0.8076923076923077,
              percentage: 81,
              opportunities: 104,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 104",
            },
            {
              x: 1681084800000,
              y: 0.8020833333333334,
              percentage: 80,
              opportunities: 96,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 96",
            },
            {
              x: 1681689600000,
              y: 1,
              percentage: 100,
              opportunities: 2,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 2",
            },
          ],
          visible: false,
          opportunities: 1094,
          successful_opportunities: 929,
          performance: 0.8491773308957953,
        },
        {
          name: "Group-2684",
          data: [
            {
              x: 1675641600000,
              y: 0.8095238095238095,
              percentage: 81,
              opportunities: 42,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 42",
            },
            {
              x: 1676246400000,
              y: 0.8207547169811321,
              percentage: 82,
              opportunities: 106,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 106",
            },
            {
              x: 1676851200000,
              y: 0.9195402298850575,
              percentage: 92,
              opportunities: 174,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 174",
            },
            {
              x: 1677456000000,
              y: 0.8813559322033898,
              percentage: 88,
              opportunities: 118,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 118",
            },
            {
              x: 1678060800000,
              y: 0.8735632183908046,
              percentage: 87,
              opportunities: 87,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 87",
            },
            {
              x: 1678665600000,
              y: 0.9210526315789473,
              percentage: 92,
              opportunities: 114,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 114",
            },
            {
              x: 1679270400000,
              y: 0.9512195121951219,
              percentage: 95,
              opportunities: 41,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 41",
            },
            {
              x: 1679875200000,
              y: 0.9202898550724637,
              percentage: 92,
              opportunities: 138,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 138",
            },
            {
              x: 1680480000000,
              y: 0.9393939393939394,
              percentage: 94,
              opportunities: 165,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 165",
            },
            {
              x: 1681084800000,
              y: 0.897196261682243,
              percentage: 90,
              opportunities: 107,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 107",
            },
          ],
          visible: false,
          opportunities: 1092,
          successful_opportunities: 983,
          performance: 0.9001831501831502,
        },
        {
          name: "Physicians-2614",
          data: [
            {
              x: 1675641600000,
              y: 0.9090909090909091,
              percentage: 91,
              opportunities: 154,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 154",
            },
            {
              x: 1676246400000,
              y: 0.9069767441860465,
              percentage: 91,
              opportunities: 86,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 86",
            },
            {
              x: 1676851200000,
              y: 0.9285714285714286,
              percentage: 93,
              opportunities: 126,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 126",
            },
            {
              x: 1677456000000,
              y: 0.9444444444444444,
              percentage: 94,
              opportunities: 126,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 126",
            },
            {
              x: 1678060800000,
              y: 0.921875,
              percentage: 92,
              opportunities: 128,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 128",
            },
            {
              x: 1678665600000,
              y: 0.9047619047619048,
              percentage: 90,
              opportunities: 84,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 84",
            },
            {
              x: 1679270400000,
              y: 0.984375,
              percentage: 98,
              opportunities: 64,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 64",
            },
            {
              x: 1679875200000,
              y: 0.9230769230769231,
              percentage: 92,
              opportunities: 91,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 91",
            },
            {
              x: 1680480000000,
              y: 0.8791208791208791,
              percentage: 88,
              opportunities: 91,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 91",
            },
            {
              x: 1681084800000,
              y: 0.8928571428571429,
              percentage: 89,
              opportunities: 112,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 112",
            },
            {
              x: 1681689600000,
              y: 1,
              percentage: 100,
              opportunities: 4,
              name: "April 17, 2023–April 17, 2023 (Partial Week)<br>Opportunities: 4",
            },
          ],
          visible: false,
          opportunities: 1066,
          successful_opportunities: 979,
          performance: 0.9183864915572233,
        },
        {
          name: "Nurses (RN)-3979",
          data: [
            {
              x: 1675641600000,
              y: 0.5480769230769231,
              percentage: 55,
              opportunities: 104,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 104",
            },
            {
              x: 1676246400000,
              y: 0.6415094339622641,
              percentage: 64,
              opportunities: 53,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 53",
            },
            {
              x: 1676851200000,
              y: 0.45871559633027525,
              percentage: 46,
              opportunities: 109,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 109",
            },
            {
              x: 1677456000000,
              y: 0.6,
              percentage: 60,
              opportunities: 90,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 90",
            },
            {
              x: 1678060800000,
              y: 0.71,
              percentage: 71,
              opportunities: 100,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 100",
            },
            {
              x: 1678665600000,
              y: 0.7450980392156863,
              percentage: 75,
              opportunities: 102,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 102",
            },
            {
              x: 1679270400000,
              y: 0.7314814814814815,
              percentage: 73,
              opportunities: 108,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 108",
            },
            {
              x: 1679875200000,
              y: 0.7171717171717171,
              percentage: 72,
              opportunities: 99,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 99",
            },
            {
              x: 1680480000000,
              y: 0.7777777777777778,
              percentage: 78,
              opportunities: 90,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 90",
            },
            {
              x: 1681084800000,
              y: 0.8611111111111112,
              percentage: 86,
              opportunities: 36,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 36",
            },
          ],
          visible: false,
          opportunities: 891,
          successful_opportunities: 593,
          performance: 0.6655443322109988,
        },
        {
          name: "Group-3578",
          data: [
            {
              x: 1675641600000,
              y: 0.7478991596638656,
              percentage: 75,
              opportunities: 119,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 119",
            },
            {
              x: 1676246400000,
              y: 0.8852459016393442,
              percentage: 89,
              opportunities: 61,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 61",
            },
            {
              x: 1676851200000,
              y: 0.8253968253968254,
              percentage: 83,
              opportunities: 63,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 63",
            },
            {
              x: 1677456000000,
              y: 0.8,
              percentage: 80,
              opportunities: 60,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 60",
            },
            {
              x: 1678060800000,
              y: 0.8533333333333334,
              percentage: 85,
              opportunities: 75,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 75",
            },
            {
              x: 1678665600000,
              y: 0.8,
              percentage: 80,
              opportunities: 85,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 85",
            },
            {
              x: 1679270400000,
              y: 0.6666666666666666,
              percentage: 67,
              opportunities: 102,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 102",
            },
            {
              x: 1679875200000,
              y: 0.8369565217391305,
              percentage: 84,
              opportunities: 92,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 92",
            },
            {
              x: 1680480000000,
              y: 0.7704918032786885,
              percentage: 77,
              opportunities: 61,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 61",
            },
            {
              x: 1681084800000,
              y: 0.845360824742268,
              percentage: 85,
              opportunities: 97,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 97",
            },
          ],
          visible: false,
          opportunities: 815,
          successful_opportunities: 649,
          performance: 0.7963190184049079,
        },
        {
          name: "Physicians-2215",
          data: [
            {
              x: 1675641600000,
              y: 0.8333333333333334,
              percentage: 83,
              opportunities: 48,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 48",
            },
            {
              x: 1676246400000,
              y: 0.8857142857142857,
              percentage: 89,
              opportunities: 70,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 70",
            },
            {
              x: 1676851200000,
              y: 0.8931297709923665,
              percentage: 89,
              opportunities: 131,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 131",
            },
            {
              x: 1677456000000,
              y: 0.8620689655172413,
              percentage: 86,
              opportunities: 58,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 58",
            },
            {
              x: 1678060800000,
              y: 0.8504672897196262,
              percentage: 85,
              opportunities: 107,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 107",
            },
            {
              x: 1678665600000,
              y: 0.898989898989899,
              percentage: 90,
              opportunities: 99,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 99",
            },
            {
              x: 1679270400000,
              y: 0.898989898989899,
              percentage: 90,
              opportunities: 99,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 99",
            },
            {
              x: 1679875200000,
              y: 0.7735849056603774,
              percentage: 77,
              opportunities: 53,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 53",
            },
            {
              x: 1680480000000,
              y: 0.9444444444444444,
              percentage: 94,
              opportunities: 72,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 72",
            },
            {
              x: 1681084800000,
              y: 0.9230769230769231,
              percentage: 92,
              opportunities: 52,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 52",
            },
          ],
          visible: false,
          opportunities: 789,
          successful_opportunities: 695,
          performance: 0.8808618504435995,
        },
        {
          name: "Physicians-2617",
          data: [
            {
              x: 1675641600000,
              y: 0.9264705882352942,
              percentage: 93,
              opportunities: 68,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 68",
            },
            {
              x: 1676246400000,
              y: 0.9210526315789473,
              percentage: 92,
              opportunities: 38,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 38",
            },
            {
              x: 1676851200000,
              y: 0.9,
              percentage: 90,
              opportunities: 40,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 40",
            },
            {
              x: 1677456000000,
              y: 0.7631578947368421,
              percentage: 76,
              opportunities: 38,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 38",
            },
            {
              x: 1678060800000,
              y: 0.8846153846153846,
              percentage: 88,
              opportunities: 52,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 52",
            },
            {
              x: 1678665600000,
              y: 0.9295774647887324,
              percentage: 93,
              opportunities: 71,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 71",
            },
            {
              x: 1679270400000,
              y: 0.8947368421052632,
              percentage: 89,
              opportunities: 57,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 57",
            },
            {
              x: 1679875200000,
              y: 0.9071428571428571,
              percentage: 91,
              opportunities: 140,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 140",
            },
            {
              x: 1680480000000,
              y: 0.9032258064516129,
              percentage: 90,
              opportunities: 62,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 62",
            },
            {
              x: 1681084800000,
              y: 0.875,
              percentage: 88,
              opportunities: 72,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 72",
            },
          ],
          visible: false,
          opportunities: 638,
          successful_opportunities: 572,
          performance: 0.896551724137931,
        },
        {
          name: "Techs-2776",
          data: [
            {
              x: 1675641600000,
              y: 0.8113207547169812,
              percentage: 81,
              opportunities: 53,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 53",
            },
            {
              x: 1676246400000,
              y: 0.8663101604278075,
              percentage: 87,
              opportunities: 187,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 187",
            },
            {
              x: 1676851200000,
              y: 0.9312977099236641,
              percentage: 93,
              opportunities: 131,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 131",
            },
            {
              x: 1677456000000,
              y: 0.8611111111111112,
              percentage: 86,
              opportunities: 144,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 144",
            },
            {
              x: 1678060800000,
              y: 0.7916666666666666,
              percentage: 79,
              opportunities: 24,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 24",
            },
          ],
          visible: false,
          opportunities: 539,
          successful_opportunities: 470,
          performance: 0.8719851576994434,
        },
        {
          name: "Group-2510",
          data: [
            {
              x: 1675641600000,
              y: 0.9459459459459459,
              percentage: 95,
              opportunities: 37,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 37",
            },
            {
              x: 1676246400000,
              y: 0.8536585365853658,
              percentage: 85,
              opportunities: 41,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 41",
            },
            {
              x: 1676851200000,
              y: 0.9,
              percentage: 90,
              opportunities: 40,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 40",
            },
            {
              x: 1677456000000,
              y: 0.9814814814814815,
              percentage: 98,
              opportunities: 54,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 54",
            },
            {
              x: 1678060800000,
              y: 0.8524590163934426,
              percentage: 85,
              opportunities: 61,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 61",
            },
            {
              x: 1678665600000,
              y: 0.9310344827586207,
              percentage: 93,
              opportunities: 58,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 58",
            },
            {
              x: 1679270400000,
              y: 0.8636363636363636,
              percentage: 86,
              opportunities: 22,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 22",
            },
            {
              x: 1679875200000,
              y: 0.7846153846153846,
              percentage: 78,
              opportunities: 65,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 65",
            },
            {
              x: 1680480000000,
              y: 0.9,
              percentage: 90,
              opportunities: 40,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 40",
            },
            {
              x: 1681084800000,
              y: 0.9818181818181818,
              percentage: 98,
              opportunities: 55,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 55",
            },
          ],
          visible: false,
          opportunities: 473,
          successful_opportunities: 425,
          performance: 0.8985200845665962,
        },
        {
          name: "Physicians-929",
          data: [
            {
              x: 1675641600000,
              y: 0.9148936170212766,
              percentage: 91,
              opportunities: 47,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 47",
            },
            {
              x: 1676246400000,
              y: 0.877906976744186,
              percentage: 88,
              opportunities: 172,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 172",
            },
            {
              x: 1679270400000,
              y: 0.8853503184713376,
              percentage: 89,
              opportunities: 157,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 157",
            },
            {
              x: 1681084800000,
              y: 0.8641975308641975,
              percentage: 86,
              opportunities: 81,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 81",
            },
          ],
          visible: false,
          opportunities: 457,
          successful_opportunities: 403,
          performance: 0.8818380743982495,
        },
        {
          name: "Group-940",
          data: [
            {
              x: 1675641600000,
              y: 0.7727272727272727,
              percentage: 77,
              opportunities: 22,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 22",
            },
            {
              x: 1676246400000,
              y: 0.9166666666666666,
              percentage: 92,
              opportunities: 24,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 24",
            },
            {
              x: 1676851200000,
              y: 0.8181818181818182,
              percentage: 82,
              opportunities: 22,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 22",
            },
            {
              x: 1677456000000,
              y: 1,
              percentage: 100,
              opportunities: 15,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 15",
            },
            {
              x: 1678060800000,
              y: 0.7272727272727273,
              percentage: 73,
              opportunities: 22,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 22",
            },
            {
              x: 1678665600000,
              y: 0.9111111111111111,
              percentage: 91,
              opportunities: 45,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 45",
            },
            {
              x: 1679270400000,
              y: 0.8461538461538461,
              percentage: 85,
              opportunities: 39,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 39",
            },
            {
              x: 1679875200000,
              y: 0.9090909090909091,
              percentage: 91,
              opportunities: 33,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 33",
            },
            {
              x: 1680480000000,
              y: 0.90625,
              percentage: 91,
              opportunities: 32,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 32",
            },
            {
              x: 1681084800000,
              y: 0.8409090909090909,
              percentage: 84,
              opportunities: 44,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 44",
            },
          ],
          visible: false,
          opportunities: 298,
          successful_opportunities: 258,
          performance: 0.8657718120805369,
        },
        {
          name: "Group-2590",
          data: [
            {
              x: 1675641600000,
              y: 0.918918918918919,
              percentage: 92,
              opportunities: 37,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 37",
            },
            {
              x: 1676246400000,
              y: 1,
              percentage: 100,
              opportunities: 10,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 10",
            },
            {
              x: 1676851200000,
              y: 1,
              percentage: 100,
              opportunities: 5,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 5",
            },
            {
              x: 1677456000000,
              y: 0.9166666666666666,
              percentage: 92,
              opportunities: 48,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 48",
            },
            {
              x: 1678060800000,
              y: 0.9054054054054054,
              percentage: 91,
              opportunities: 74,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 74",
            },
            {
              x: 1678665600000,
              y: 0.9056603773584906,
              percentage: 91,
              opportunities: 53,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 53",
            },
            {
              x: 1679270400000,
              y: 1,
              percentage: 100,
              opportunities: 18,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 18",
            },
            {
              x: 1679875200000,
              y: 1,
              percentage: 100,
              opportunities: 1,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 1",
            },
            {
              x: 1680480000000,
              y: 0.6086956521739131,
              percentage: 61,
              opportunities: 23,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 23",
            },
            {
              x: 1681084800000,
              y: 0.8636363636363636,
              percentage: 86,
              opportunities: 22,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 22",
            },
          ],
          visible: false,
          opportunities: 291,
          successful_opportunities: 260,
          performance: 0.8934707903780069,
        },
        {
          name: "Administration-924",
          data: [
            {
              x: 1675641600000,
              y: 0.9545454545454546,
              percentage: 95,
              opportunities: 44,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 44",
            },
            {
              x: 1676246400000,
              y: 0.9696969696969697,
              percentage: 97,
              opportunities: 33,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 33",
            },
            {
              x: 1676851200000,
              y: 0.9130434782608695,
              percentage: 91,
              opportunities: 23,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 23",
            },
            {
              x: 1677456000000,
              y: 0.95,
              percentage: 95,
              opportunities: 20,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 20",
            },
            {
              x: 1678060800000,
              y: 0.9166666666666666,
              percentage: 92,
              opportunities: 36,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 36",
            },
            {
              x: 1678665600000,
              y: 0.9444444444444444,
              percentage: 94,
              opportunities: 36,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 36",
            },
            {
              x: 1679270400000,
              y: 0.9428571428571428,
              percentage: 94,
              opportunities: 35,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 35",
            },
            {
              x: 1679875200000,
              y: 0.8888888888888888,
              percentage: 89,
              opportunities: 9,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 9",
            },
            {
              x: 1680480000000,
              y: 0.9090909090909091,
              percentage: 91,
              opportunities: 11,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 11",
            },
            {
              x: 1681084800000,
              y: 0.9333333333333333,
              percentage: 93,
              opportunities: 15,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 15",
            },
          ],
          visible: false,
          opportunities: 262,
          successful_opportunities: 246,
          performance: 0.9389312977099237,
        },
        {
          name: "Nurses (RN)-1667",
          data: [
            {
              x: 1677456000000,
              y: 0,
              percentage: 0,
              opportunities: 1,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 1",
            },
            {
              x: 1680480000000,
              y: 0.9285714285714286,
              percentage: 93,
              opportunities: 14,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 14",
            },
            {
              x: 1681084800000,
              y: 0.9607843137254902,
              percentage: 96,
              opportunities: 51,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 51",
            },
          ],
          visible: false,
          opportunities: 66,
          successful_opportunities: 62,
          performance: 0.9393939393939394,
        },
        {
          name: "Techs-973",
          data: [
            {
              x: 1677456000000,
              y: 0.7368421052631579,
              percentage: 74,
              opportunities: 19,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 19",
            },
            {
              x: 1679270400000,
              y: 0.8571428571428571,
              percentage: 86,
              opportunities: 7,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 7",
            },
            {
              x: 1679875200000,
              y: 1,
              percentage: 100,
              opportunities: 2,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 2",
            },
            {
              x: 1681084800000,
              y: 0.8181818181818182,
              percentage: 82,
              opportunities: 33,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 33",
            },
          ],
          visible: false,
          opportunities: 61,
          successful_opportunities: 49,
          performance: 0.8032786885245902,
        },
        {
          name: "Physicians-2272",
          data: [
            {
              x: 1675641600000,
              y: 1,
              percentage: 100,
              opportunities: 6,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 6",
            },
            {
              x: 1676851200000,
              y: 0.9285714285714286,
              percentage: 93,
              opportunities: 14,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 14",
            },
            {
              x: 1678665600000,
              y: 0.8888888888888888,
              percentage: 89,
              opportunities: 9,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 9",
            },
            {
              x: 1679270400000,
              y: 0.6666666666666666,
              percentage: 67,
              opportunities: 9,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 9",
            },
            {
              x: 1681084800000,
              y: 0.75,
              percentage: 75,
              opportunities: 12,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 12",
            },
          ],
          visible: false,
          opportunities: 50,
          successful_opportunities: 42,
          performance: 0.84,
        },
        {
          name: "Administration-922",
          data: [
            {
              x: 1675641600000,
              y: 0.75,
              percentage: 75,
              opportunities: 4,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 4",
            },
            {
              x: 1676246400000,
              y: 0.5,
              percentage: 50,
              opportunities: 6,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 6",
            },
            {
              x: 1676851200000,
              y: 0.5454545454545454,
              percentage: 55,
              opportunities: 11,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 11",
            },
            {
              x: 1677456000000,
              y: 1,
              percentage: 100,
              opportunities: 2,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 2",
            },
            {
              x: 1678060800000,
              y: 1,
              percentage: 100,
              opportunities: 1,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 1",
            },
            {
              x: 1678665600000,
              y: 0.625,
              percentage: 63,
              opportunities: 8,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 8",
            },
            {
              x: 1679875200000,
              y: 0,
              percentage: 0,
              opportunities: 1,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 1",
            },
            {
              x: 1680480000000,
              y: 1,
              percentage: 100,
              opportunities: 1,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 1",
            },
            {
              x: 1681084800000,
              y: 0.8,
              percentage: 80,
              opportunities: 5,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 5",
            },
          ],
          visible: false,
          opportunities: 39,
          successful_opportunities: 25,
          performance: 0.6410256410256411,
        },
        {
          name: "Physicians-1751",
          data: [
            {
              x: 1676851200000,
              y: 0.9444444444444444,
              percentage: 94,
              opportunities: 18,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 18",
            },
          ],
          visible: false,
          opportunities: 18,
          successful_opportunities: 17,
          performance: 0.9444444444444444,
        },
        {
          name: "Physicians-2262",
          data: [
            {
              x: 1676246400000,
              y: 0.5,
              percentage: 50,
              opportunities: 2,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2",
            },
            {
              x: 1676851200000,
              y: 0.8333333333333334,
              percentage: 83,
              opportunities: 6,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 6",
            },
            {
              x: 1677456000000,
              y: 0.3333333333333333,
              percentage: 33,
              opportunities: 6,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 6",
            },
            {
              x: 1678060800000,
              y: 1,
              percentage: 100,
              opportunities: 2,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 2",
            },
            {
              x: 1681084800000,
              y: 1,
              percentage: 100,
              opportunities: 1,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 1",
            },
          ],
          visible: false,
          opportunities: 17,
          successful_opportunities: 11,
          performance: 0.6470588235294118,
        },
        {
          name: "Therapists-1368",
          data: [
            {
              x: 1681084800000,
              y: 0.9285714285714286,
              percentage: 93,
              opportunities: 14,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 14",
            },
          ],
          visible: false,
          opportunities: 14,
          successful_opportunities: 13,
          performance: 0.9285714285714286,
        },
        {
          name: "Group-1754",
          data: [
            {
              x: 1677456000000,
              y: 1,
              percentage: 100,
              opportunities: 6,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 6",
            },
            {
              x: 1678665600000,
              y: 1,
              percentage: 100,
              opportunities: 1,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 1",
            },
            {
              x: 1679875200000,
              y: 0.5,
              percentage: 50,
              opportunities: 2,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 2",
            },
          ],
          visible: false,
          opportunities: 9,
          successful_opportunities: 8,
          performance: 0.8888888888888888,
        },
        {
          name: "Phlebotomy-1647",
          data: [
            {
              x: 1677456000000,
              y: 1,
              percentage: 100,
              opportunities: 4,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 4",
            },
            {
              x: 1678060800000,
              y: 1,
              percentage: 100,
              opportunities: 4,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 4",
            },
          ],
          visible: false,
          opportunities: 8,
          successful_opportunities: 8,
          performance: 1,
        },
        {
          name: "Physicians-2613",
          data: [
            {
              x: 1677456000000,
              y: 0,
              percentage: 0,
              opportunities: 2,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 2",
            },
            {
              x: 1678060800000,
              y: 1,
              percentage: 100,
              opportunities: 3,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 3",
            },
            {
              x: 1679270400000,
              y: 0,
              percentage: 0,
              opportunities: 1,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 1",
            },
          ],
          visible: false,
          opportunities: 6,
          successful_opportunities: 3,
          performance: 0.5,
        },
        {
          name: "Nurses (RN)-1602",
          data: [
            {
              x: 1676246400000,
              y: 1,
              percentage: 100,
              opportunities: 3,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3",
            },
          ],
          visible: false,
          opportunities: 3,
          successful_opportunities: 3,
          performance: 1,
        },
        {
          name: "Therapists-1594",
          data: [
            {
              x: 1677456000000,
              y: 0.5,
              percentage: 50,
              opportunities: 2,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 2",
            },
            {
              x: 1678060800000,
              y: 1,
              percentage: 100,
              opportunities: 1,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 1",
            },
          ],
          visible: false,
          opportunities: 3,
          successful_opportunities: 2,
          performance: 0.6666666666666666,
        },
        {
          name: "Nurses (RN)-1757",
          data: [
            {
              x: 1678665600000,
              y: 0.5,
              percentage: 50,
              opportunities: 2,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 2",
            },
          ],
          visible: false,
          opportunities: 2,
          successful_opportunities: 1,
          performance: 0.5,
        },
        {
          name: "Therapists-1704",
          data: [
            {
              x: 1679270400000,
              y: 1,
              percentage: 100,
              opportunities: 2,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 2",
            },
          ],
          visible: false,
          opportunities: 2,
          successful_opportunities: 2,
          performance: 1,
        },
        {
          name: "Techs-3293",
          data: [
            {
              x: 1676246400000,
              y: 1,
              percentage: 100,
              opportunities: 1,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1",
            },
          ],
          visible: false,
          opportunities: 1,
          successful_opportunities: 1,
          performance: 1,
        },
        {
          name: "Environmental Services (EVS)-1690",
          data: [
            {
              x: 1678060800000,
              y: 0,
              percentage: 0,
              opportunities: 1,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 1",
            },
          ],
          visible: false,
          opportunities: 1,
          successful_opportunities: 0,
          performance: 0,
        },
        {
          name: "Environmental Services (EVS)-1358",
          data: [
            {
              x: 1678665600000,
              y: 1,
              percentage: 100,
              opportunities: 1,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 1",
            },
          ],
          visible: false,
          opportunities: 1,
          successful_opportunities: 1,
          performance: 1,
        },
        {
          name: "Nurses (RN)-1570",
          data: [
            {
              x: 1679270400000,
              y: 0,
              percentage: 0,
              opportunities: 1,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 1",
            },
          ],
          visible: false,
          opportunities: 1,
          successful_opportunities: 0,
          performance: 0,
        },
        {
          name: "Techs-2568",
          data: [
            {
              x: 1679875200000,
              y: 1,
              percentage: 100,
              opportunities: 1,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 1",
            },
          ],
          visible: false,
          opportunities: 1,
          successful_opportunities: 1,
          performance: 1,
        },
        {
          name: "Nurses (RN)-1380",
          data: [
            {
              x: 1679875200000,
              y: 0,
              percentage: 0,
              opportunities: 1,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 1",
            },
          ],
          visible: false,
          opportunities: 1,
          successful_opportunities: 0,
          performance: 0,
        },
      ],
      plotOptions: null,
      helpText:
        "This graph shows how the Providers working in your areas are doing at Hand Hygiene (HH) in percent broken down by which Group they belong to.",
      extraJavascript: "",
      isExportable: true,
    },
    topPerformersTable: {
      _component_class: "TopPerformersTable",
      footerMsg:
        "A Provider must have at least 40 Opportunities (ten per week)  to qualify for Top Performer.",
      subtitle: "Mar 27, 2023 - Apr 17, 2023",
      title: "Top Performers",
      userModelDisplayName: "Providers",
      users: {
        1: {
          id: 13929,
          customer_id: 18,
          performance: 1,
          total_opportunities: 135,
          name: "Stracke, Susan",
          group_name: "Nurses (RN)-952",
          drilldownLink:
            "https://local.swipesense.test/report/user/sp/61407/%7B%22customer_ids%22%3A%5B18%5D%2C%22user_ids%22%3A%5B13929%5D%7D?expires=1681834249&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Freport%2Funit%3F_fe%3D98f43b87-4b06-4aad-adfb-f70759d06540&signature=c2a35141356ccf61b569a8ded0d3dcd9749eae1e82d19bd90ccf0e11a8cd3051",
        },
        2: {
          id: 13987,
          customer_id: 18,
          performance: 1,
          total_opportunities: 91,
          name: "Borer, Amy",
          group_name: "Nurses (RN)-952",
          drilldownLink:
            "https://local.swipesense.test/report/user/sp/61407/%7B%22customer_ids%22%3A%5B18%5D%2C%22user_ids%22%3A%5B13987%5D%7D?expires=1681834249&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Freport%2Funit%3F_fe%3D98f43b87-4b06-4aad-adfb-f70759d06540&signature=02846ff0b0b8754847e269e0cacf1e65dae58a244143c7c2c36a02b5b8db12a4",
        },
        3: {
          id: 13907,
          customer_id: 18,
          performance: 1,
          total_opportunities: 74,
          name: "Rosenbaum, Carrie",
          group_name: "Nurses (RN)-952",
          drilldownLink:
            "https://local.swipesense.test/report/user/sp/61407/%7B%22customer_ids%22%3A%5B18%5D%2C%22user_ids%22%3A%5B13907%5D%7D?expires=1681834249&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Freport%2Funit%3F_fe%3D98f43b87-4b06-4aad-adfb-f70759d06540&signature=7ae77eec7f72f744c69a830ade0d7570ad7cb5e78d4eb292c7a07b722b856cfd",
        },
        4: {
          id: 59549,
          customer_id: 18,
          performance: 1,
          total_opportunities: 66,
          name: "Carter, Abigail",
          group_name: "Nurses (RN)-952",
          drilldownLink:
            "https://local.swipesense.test/report/user/sp/61407/%7B%22customer_ids%22%3A%5B18%5D%2C%22user_ids%22%3A%5B59549%5D%7D?expires=1681834249&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Freport%2Funit%3F_fe%3D98f43b87-4b06-4aad-adfb-f70759d06540&signature=d6a8c79d2b9e8f88092e8ee21b3ffcf45f12ab17f9b43c8f530622b6225d0546",
        },
        0: {
          id: 14053,
          customer_id: 18,
          performance: 0.996875,
          total_opportunities: 320,
          name: "Raynor, Melissa",
          group_name: "Nurses (RN)-952",
          drilldownLink:
            "https://local.swipesense.test/report/user/sp/61407/%7B%22customer_ids%22%3A%5B18%5D%2C%22user_ids%22%3A%5B14053%5D%7D?expires=1681834249&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Freport%2Funit%3F_fe%3D98f43b87-4b06-4aad-adfb-f70759d06540&signature=eeaf05ffbcb12e41f8d26d2423dbb4014d6af5ad08d609393f99e2fdf0f45401",
        },
      },
      componentName: null,
      attributes: {},
      setDrillDownRoutes: {},
      setFooterMessage: {},
      setSubtitleDateRange: {},
      jsonSerialize: {},
      resolve: {},
      flushCache: {},
      forgetFactory: {},
      forgetComponentsResolver: {},
      resolveComponentsUsing: {},
    },
    userHistogram: {
      _graph_class: "UserComplianceHistogram",
      chartId: "chart_a57f4e6c09",
      chartTestKey: "Histogram of Providers",
      chartType: "column",
      titleText:
        "Histogram of Providers<br /><span class='text-sm'>Mar 27, 2023 - Apr 17, 2023</span>",
      subtitle: {
        _plot_component_class: "SubtitleComponent",
        text: "Providers with fewer than 40 Opportunities are excluded from histogram",
        style: {
          color: "#999B9E",
          "font-style": "italic",
          "font-size": "9px",
          overflow: "visible",
        },
        verticalAlign: "bottom",
        floating: true,
        y: 25,
      },
      xAxis: {
        _plot_component_class: "CategoriesAxis",
        title: {
          text: "Hand Hygiene Performance (%)",
        },
        categories: [
          "0-10%",
          "10-20%",
          "20-30%",
          "30-40%",
          "40-50%",
          "50-60%",
          "60-70%",
          "70-80%",
          "80-90%",
          "90-100%",
        ],
      },
      yAxis: {
        _plot_component_class: "GenericAxis",
        title: {
          text: "Percentage of Providers (%)",
          style: {
            fontSize: "15px",
          },
        },
        labels: {
          formatter: "{@JS}function(){return this.value + ' %';}",
        },
      },
      xAxisPlotlines: "[]",
      yAxisPlotlines: "[]",
      showYearBoundaries: false,
      showColorBands: false,
      tooltip: {
        _plot_component_class: "GenericTooltip",
        pointFormat: "{@JS}window.HighchartsPointFormats.percentagePointFormat",
        headerFormat:
          '<span style="font-size:10px">Hand Hygiene Performance: {point.x}</span><br/>',
        valueDecimals: 0,
      },
      dataSeries: [
        {
          name: "Providers",
          data: [
            0, 0, 0.0546448087431694, 0.0546448087431694, 0.6010928961748634,
            1.5846994535519126, 6.557377049180328, 17.868852459016395,
            39.39890710382514, 33.87978142076503,
          ],
          color: null,
          showInLegend: false,
        },
      ],
      plotOptions:
        '{"column":{"zones":HighchartsHelper.getStoplightFormatZones(10.0),"zoneAxis":"x","cursor":"pointer","events":{"click":function (event) {displayHistogramBracket(event.point.category);}}}}',
      helpText:
        "This graph breaks down how many people's performance scores are in each percentile bracket. Try clicking on each bar in the graph for more details!",
      extraJavascript:
        "\nfunction displayHistogramBracket(category){\n    var providerTableModal = CoreScripts.openModal('large', 'Providers at Percentage: ' + category, null, true);\n    CoreScripts.linkOpenModal('https://local.swipesense.test/report/unit/ajax/histogramBucketTable/98f43b87-4b06-4aad-adfb-f70759d06540',providerTableModal,'POST',{bucket: category},'.modal-body', true);\n}\n",
      isExportable: true,
    },
  };
}
