import { get } from "../../api/laravelApi";
import { rtibEndpoint } from "../../constants";
import { getApiUrlFromGetRequest } from "../../utils/";

export const RtibLoader = async (request) => {
  const startDate = new URL(request.url).searchParams.get("startDate");
  const endDate = new URL(request.url).searchParams.get("endDate");
  const facilityIds = new URL(request.url).searchParams.getAll("facilitiesIds");
  const customerIds = new URL(request.url).searchParams.getAll("customersIds");
  const unitIds = new URL(request.url).searchParams.getAll("unitsIds");
  const shiftSelector = new URL(request.url).searchParams.get(
    "reportBlueprintRealTimeShiftSelector"
  );

  const rtibData = await get(rtibEndpoint, {
    startDate,
    endDate,
    facilityIds,
    customerIds,
    unitIds,
    shiftSelector,
  }).then((rtibData) => {
    const params = rtibData._params;

    const rtibDataApiUrl = rtibData.nodeMaps;

    const error = rtibData._error;

    const uuid = rtibData?._report?.uuid;

    const apiUrlWithoutDomain = getApiUrlFromGetRequest(rtibDataApiUrl, error);

    const fetchPromises = apiUrlWithoutDomain.map((url) =>
      get(url).then((result) => {
        const data = result.data;

        return data;
      })
    );

    return Promise.all(fetchPromises).then((results) => {
      const validDataResults = results.filter((data) => data !== null);

      return { validDataResults, params, error, uuid };
    });
  });

  return {
    rtibData,
  };
};
