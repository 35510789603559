import { defaultDateRange, missingDataMessageAT } from "../../constants";
import { IndividualAssetsDTO } from "../../model/types";
import { parseDateToMidnight } from "../../utils";

export function transformIndividualAssets(responseJson: IndividualAssetsDTO) {
  let startDate: Date;
  let endDate: Date;
  if (responseJson?.params?.date_range?.value === null) {
    startDate = defaultDateRange.startDate;
    endDate = defaultDateRange.endDate;
  } else {
    startDate = parseDateToMidnight(
      responseJson?.params?.date_range?.value?.[1]
    );
    endDate = parseDateToMidnight(responseJson?.params?.date_range?.value?.[2]);
  }

  const excelUrl = responseJson?.export;

  const individualAsset = responseJson?.asset ?? [];

  const dataTable = individualAsset?.roomEntries?.map((asset) => {
    const room =
      asset.roomName ||
      (individualAsset?.lastLocation?.nearestRoomName
        ? "Near " + individualAsset.lastLocation.nearestRoomName
        : missingDataMessageAT) ||
      missingDataMessageAT;
    const unit = asset.unitName || missingDataMessageAT;
    const facility = asset.facilityName || missingDataMessageAT;
    const entryAt = asset.entryTime;
    const exitAt = asset.exitTime;

    return {
      room,
      unit,
      facility,
      entryAt,
      exitAt,
    };
  });

  let imgUrl;
  if (!individualAsset?.model?.imageLocation) {
    imgUrl = "/at-default-image.png";
  } else {
    imgUrl = individualAsset.model.imageLocation;
  }

  return {
    excelUrl,
    startDate,
    endDate,
    image: imgUrl,
    nickname: individualAsset?.nickname ?? missingDataMessageAT,
    name: individualAsset?.typeName ?? missingDataMessageAT,
    id: individualAsset?.id ?? missingDataMessageAT,
    serialNumber: individualAsset?.serialNumber ?? missingDataMessageAT,
    nearestRoom:
      individualAsset?.lastLocation?.nearestRoomName ?? missingDataMessageAT,
    dataTable,
    timezone: individualAsset?.lastSeenAt?.timezone,
  };
}
