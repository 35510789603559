import { get } from "../../api/laravelApi";
import { positiveStaffCaseEndpoint } from "../../constants";

export const PositiveStaffCaseLoader = async ({ params }) => {
  const positiveCaseData = await get(
    `${positiveStaffCaseEndpoint}/${params.id}`
  );

  return positiveCaseData;
};
