import Hardware from "@untitled-ui/icons-react/build/cjs/Monitor05";
import { NavLink } from "react-router-dom";

type HardwareButtonProps = {
  link: string;
};

function HardwareButton(props: HardwareButtonProps) {
  return (
    <NavLink to={props.link}>
      <button className="themed hardware-button">
        <Hardware />
        Hardware
      </button>
    </NavLink>
  );
}

export default HardwareButton;
