import { useEffect } from "react";
import useOtibViewModel from "../../viewmodel/Otibs/useOtibsViewModel";
import useSharedFunctions from "../sharedFunctions/useSharedFunctions";

const useRtibViewController = () => {
  const { transformedOtib } = useOtibViewModel();
  const {
    handleDateChange,
    startDate,
    endDate,
    aggregate,
    onFilterChange,
    filters,
    applyFilters,
    setSelected,
    setPeerFilters,
    toggleButton,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
  } = useSharedFunctions(transformedOtib);

  useEffect(() => {
    transformedOtib.filters.forEach((filter) => {
      setPeerFilters(filter.name, filter.peerFilters!);
      setSelected(filter.name, filter.selection);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    transformedOtib,
    aggregate,
    startDate,
    endDate,
    handleDateChange,
    filters,
    onFilterChange,
    applyFilters,
    toggleButton,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
  };
};

export default useRtibViewController;
