import { useEffect } from "react";
import useAssetsViewModel from "../../viewmodel/Assets/useAssetsViewModel";
import useSharedFunctions from "../sharedFunctions/useSharedFunctions";

const useAssetsViewController = () => {
  const { totalAssets, transformedAssetsData } = useAssetsViewModel();
  const {
    onFilterChange,
    toggleButton,
    applyFilters,
    setPeerFilters,
    setSelected,
    filters,
    disableExcelDoc,
  } = useSharedFunctions(transformedAssetsData);

  const renderImg = (imgUrl: string | undefined) => {
    if (imgUrl === undefined) {
      return <div></div>;
    }
    return <img style={{ width: "100px" }} src={imgUrl} alt="Asset" />;
  };

  useEffect(() => {
    transformedAssetsData?.filters?.forEach((filter) => {
      setPeerFilters(filter.name, filter.peerFilters!);
      setSelected(filter.name, filter.selection);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    totalAssets,
    transformedAssetsData,
    renderImg,
    onFilterChange,
    disableExcelDoc,
    toggleButton,
    applyFilters,
    filters,
  };
};

export default useAssetsViewController;
