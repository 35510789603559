import { Link, useRouteError } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import TopNavigation from "../../Components/TopNavigation/TopNavigation";
import Alert from "@untitled-ui/icons-react/build/cjs/AlertTriangle";
import Home from "@untitled-ui/icons-react/build/cjs/Home02";
import LogIn from "@untitled-ui/icons-react/build/cjs/LogIn01";

interface routingError {
  status: number;
}

function ErrorPage() {
  const routingError = useRouteError() as routingError;

  let title = "Oops!";
  let message = "Something went wrong. Our team has been notified.";
  let button = "Take Me Home";
  let link = "/";
  let icon = <Home />;

  if (routingError) {
    if (routingError.status === 404) {
      title = "404";
      message = "Page not found. Please check that you have the correct URL.";
      button = "Take Me Home";
    } else if (routingError.status === 403) {
      title = "403";
      message =
        "You do not have access to this page. Please contact your administrator.";
      button = "Take Me Home";
    } else if (routingError.status === 500) {
      title = "500";
      message =
        "Internal Server Error. Our team has been notified of this issue. Please try again later.";
      button = "Take Me Home";
    } else if (routingError.status === 401) {
      title = "You are not logged in.";
      message = "Redirecting you to the login page.";
      button = "Redirect To Login";
      link = `${process.env.REACT_APP_AUTH_DOMAIN}/login`;
      icon = <LogIn />;
    }
  }

  return (
    <>
      <TopNavigation />
      <main className="overview-layout position-relative">
        <div className="center-content full-height">
          <div className="content-container">
            <h1 className="margin-bottom">{title}</h1>
            <div className="warning-icon">
              <Alert />
            </div>
            <p className="enlarged-text margin-bottom-2x">{message}</p>
            <Link to={link}>
              <button>
                {icon}
                {button}
              </button>
            </Link>
          </div>
        </div>
      </main>
      <footer className="overview-layout-footer">
        <Footer />
      </footer>
    </>
  );
}

export default ErrorPage;
