import useAssetsModel from "../../model/Assets/useAssetsModel";
import { transformAssets } from "../../transformers/assets/transformAssets";

const useAssetsViewModel = () => {
  const { assetsData } = useAssetsModel();
  const transformedAssetsData = transformAssets(assetsData);

  return {
    totalAssets: assetsData.totalAssets,
    transformedAssetsData,
    filters: assetsData.params,
  };
};

export default useAssetsViewModel;
