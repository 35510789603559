import BackwardsButton from "../../Components/UI/BackwardsButton/BackwardsButton";
import useIndividualStaffViewController from "../../viewcontroller/IndividualStaff/useIndividualStaffViewController";
import Performance from "../../Components/Performance/Performance";
import MetricsCard from "../../Components/MetricsCard/MetricsCard";
import LineGraph from "../../Components/LineGraph/LineGraph";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
import Cards from "../../Components/UI/Cards/Cards";
import DatePicker from "../../Components/DatePicker/DatePicker";
import { defaultDateRange, maxDayFilter, maxWeekFilter } from "../../constants";
import MissingData from "../../Components/MissingData/MissingData";
import { useUUIDWithLocation } from "../../Hooks/useUuid";
import { useEffect } from "react";
import DatePickerDropdown from "../../Components/DatePickerDropdown/DatePickerDropdown";
import usePdfDownload from "../../Hooks/usePdfDownload";
import DownloadButton from "../../Components/DownloadButton/DownloadButton";
import {
  individualStaffElementIds,
  individualStaffTitles,
} from "../../constants/pdfs";

function IndividualStaffView() {
  const {
    transformedIndividualStaffData: individualStaffData,
    returnToAllStaff,
    handleDateChange,
    toggleButton,
    applyFilters,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
    pdfTables,
  } = useIndividualStaffViewController();

  const { updateUUID } = useUUIDWithLocation();

  useEffect(() => {
    updateUUID(individualStaffData?.uuid);
  }, [individualStaffData?.uuid, updateUUID]);

  const { downloadPdf, loading } = usePdfDownload(
    individualStaffElementIds,
    individualStaffTitles,
    pdfTables,
    "Individual-Staff-Report.pdf",
    "Individual Staff Report PDF"
  );

  if (!individualStaffData) {
    return <MissingData />;
  }

  return (
    <>
      <div className="page-header">
        <div className="header-flex">
          <div>
            <h1>{individualStaffData.valueDisplay}</h1>
            <h2>Badge: {individualStaffData.badgeShortCode}</h2>
            <p>Battery: {individualStaffData.batteryPercent}</p>
            <p>Group: {individualStaffData.group}</p>
          </div>
        </div>
        <div className="buttons-container">
          <ExcelDownload
            link={individualStaffData.excelUrl}
            disabled={individualStaffData.disabled}
          />
          <DownloadButton click={downloadPdf} loading={loading} cta={"PDF"} />
        </div>
      </div>
      <div className="margin-bottom display-flex-left negative-margin-top">
        <BackwardsButton click={returnToAllStaff} text={"Back To All Staff"} />
      </div>
      <div className="margin-bottom">
        <Cards>
          <div className="filters filters-margin-top">
            <div>
              <DatePickerDropdown
                startDateLabel={
                  individualStaffData.startDate ?? defaultDateRange.startDate
                }
                endDateLabel={
                  individualStaffData.endDate ?? defaultDateRange.endDate
                }
                onChange={handleDatePickerChange}
              />
              {isDatePickerVisible && (
                <DatePicker
                  handleDatePickerChange={() => handleDatePickerChange}
                  visible={isDatePickerVisible}
                  maxNumDays={maxDayFilter}
                  maxNumWeeks={maxWeekFilter}
                  startDate={
                    individualStaffData.startDate ?? defaultDateRange.startDate
                  }
                  endDate={
                    individualStaffData.endDate ?? defaultDateRange.endDate
                  }
                  onChange={handleDateChange}
                  handleClick={handleDatePickerButtonClick}
                />
              )}
            </div>
            <div className="apply-button-container">
              <button
                className={`filters-margin themed select-button-height ${
                  toggleButton
                    ? "hand-hygiene flashing-button-hand-hygiene"
                    : ""
                }`}
                onClick={applyFilters}
              >
                Apply Filters
              </button>
            </div>
          </div>
        </Cards>
      </div>
      <div className="staff-pages-grid">
        <div className="performance-card">
          <Performance reportData={individualStaffData.tiles} />
        </div>
        <div className="metrics-card">
          <MetricsCard
            total={individualStaffData.tiles.totalOpportunities}
            performant={individualStaffData.tiles.totalHygieneEvents}
          />
        </div>
        <div className="graph-card spacing-cards-gap">
          <LineGraph
            title={`${
              individualStaffData.name || "Individual Staff"
            }'s Performance Over Time`}
            dataSeries={individualStaffData.dataSeries}
            yAxisPlotlines={individualStaffData.yAxisPlotlines}
          />
        </div>
      </div>
    </>
  );
}

export default IndividualStaffView;
