import {
  StaffOverviewStaffTableDTO,
  StaffOverviewSummaryDTO,
} from "../../model/types";
import {
  convertSecondsToHoursMinutesSeconds,
  formatNumberWithCommas,
} from "../../utils";
import { PdfTable } from "../../Hooks/usePdfDownload";

export function transformStaffOverviewPdfSummaryTable(
  summary: StaffOverviewSummaryDTO
): PdfTable {
  const averageVisitLength = summary.averageVisitLength
    ? convertSecondsToHoursMinutesSeconds(summary.averageVisitLength)
    : "No Data";
  const visitCount = summary.visitCount
    ? formatNumberWithCommas(summary.visitCount)
    : "No Data";

  return {
    title: "Summary",
    head: [["Average Length of Visit", "Total Staff Visits"]],
    body: [[averageVisitLength, visitCount]],
  };
}

export function transformStaffOverviewPdfStaffTable(
  users: StaffOverviewStaffTableDTO
): PdfTable {
  return {
    title: "Staff Table",
    head: [
      [
        "Staff Name",
        "Average Length of Visit",
        "Number of Visits",
        "Longest Visit",
        "Most Visited Room",
      ],
    ],
    body: users.staffOverviews.map((row) => {
      const userName = `${row.user.firstName} ${row.user.lastName}`;
      const visitLengthAvg = row.visitLengthAvg
        ? convertSecondsToHoursMinutesSeconds(row.visitLengthAvg)
        : "N/A";
      const visitCount = row.visitCount
        ? formatNumberWithCommas(row.visitCount)
        : 0;
      const longestVisit = row.longestVisit
        ? convertSecondsToHoursMinutesSeconds(row.longestVisit)
        : "N/A";
      const mostVisitedRoom =
        row.mostVisitedRoom === null ? "N/A" : row.mostVisitedRoom.roomName;
      return [
        userName,
        visitLengthAvg,
        visitCount,
        longestVisit,
        mostVisitedRoom,
      ];
    }),
  };
}
