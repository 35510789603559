import jsPDF from "jspdf";
import DownloadButton from "../DownloadButton/DownloadButton";
import autoTable from "jspdf-autotable";
import Logo from "../../assets/logo.png";
import html2canvas from "html2canvas";

import { formatDate } from "../../utils/index";
import { pdf } from "../../types/types";

declare module "jspdf" {
  interface jsPDF {
    previousAutoTable: {
      finalY: number;
    };
  }
}

function GeneratePdf(props: pdf) {
  let elementId: string;
  if (props.id === "executive") {
    elementId = "heatmap";
  } else if (
    props.id === "groups" ||
    props.id === "units" ||
    props.id === "my-report" ||
    props.id === "individual-staff" ||
    props.id === "ni-rounding"
  ) {
    elementId = "graph";
  }

  const convertToPdf = () => {
    if (document.getElementById(elementId) === null) {
      const logo = new Image();
      logo.src = Logo;
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(logo.src, "JPEG", 95, 10, 20, 20);
      pdf.setFont("helvetica");
      pdf.setFontSize(10);
      pdf.text("No Data to Download", 10, 10);
      pdf.save(props.pdfName);
    } else {
      html2canvas(document.getElementById(elementId) as HTMLCanvasElement).then(
        (canvas) => {
          const logo = new Image();
          logo.src = Logo;
          const pdf = new jsPDF("p", "mm", "a4");
          pdf.addImage(logo.src, "JPEG", 95, 10, 20, 20);
          pdf.setFont("helvetica");
          pdf.setFontSize(10);
          pdf.text("Generated on: " + formatDate(new Date()), 10, 10);
          if (props.id === "my-report") {
            pdf.text("Date Range: " + props.dateRange, 10, 20);
          }
          if (props.id === "executive") {
            const imgData = canvas.toDataURL("image/png");
            pdf.addImage(imgData, "PNG", 10, 40, 190, 100);
          }
          if (props.startDate && props.endDate) {
            pdf.text(
              "Date Range: " + props.startDate + " - " + props.endDate,
              10,
              20
            );
          }
          if (props.tableOneHead) {
            let startY: number;
            if (props.id === "executive") {
              startY = 140;
            } else {
              startY = 40;
            }
            autoTable(pdf, {
              headStyles: { fillColor: [106, 65, 198] },
              startY: startY,
              head: [props.tableOneHead],
              body: props.tableOneBody,
            });
          }
          if (props.tableTwoHead) {
            autoTable(pdf, {
              headStyles: { fillColor: [106, 65, 198] },
              head: [props.tableTwoHead],
              body: props.tableTwoBody,
            });
          }
          let finalY = pdf.previousAutoTable.finalY;
          const graph = document.getElementById("graph") as HTMLCanvasElement;
          if (graph !== null && props.id !== "ni-rounding") {
            pdf.addImage(graph, "PNG", 5, finalY + 10, 200, 100);
          }
          if (graph !== null && props.id === "ni-rounding") {
            pdf.addImage(graph, "PNG", 5, finalY + 70, 200, 100);
          }
          let startY: number;
          if (graph === null || props.id === "ni-rounding") {
            startY = finalY + 10;
          } else {
            // makes spacing dynamic by graph height, converts from px to mm + an extra 5 mm of space
            startY = finalY + 115;
          }
          if (props.tableThreeHead) {
            autoTable(pdf, {
              startY: startY,
              headStyles: { fillColor: [106, 65, 198] },
              head: [props.tableThreeHead],
              body: props.tableThreeBody,
            });
          }
          if (props.tableFourHead) {
            autoTable(pdf, {
              headStyles: { fillColor: [106, 65, 198] },
              head: [props.tableFourHead],
              body: props.tableFourBody,
            });
          }
          pdf.save(props.pdfName);
        }
      );
    }
  };
  return (
    <>
      <DownloadButton cta={"PDF"} click={convertToPdf} />
    </>
  );
}

export default GeneratePdf;
