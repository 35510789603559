import useRoundingModel from "../../model/Rounding/useRoundingModel";
import { ShiftBreakdown } from "../../model/types";
import { transformRounding } from "../../transformers/rounding/transformRounding";

const useRoundingViewModel = () => {
  const { roundingData } = useRoundingModel();
  const transformedRoundingData = transformRounding(roundingData);

  const roundingDataTable = roundingData?.aggregateData?.map(({ node }) => {
    const individualShifts = node.shifts.map((shift, index) => {
      return {
        [`shift${index + 1}`]: [shift.performance, shift.name],
      };
    });

    return {
      unit: node.unit,
      facility: node.facility,
      ...Object.assign({}, ...individualShifts),
    };
  });

  const bubbleChartData = roundingData?.aggregateData?.flatMap(({ node }) => {
    const { name: nodeName, shifts } = node;

    return shifts.map((shift) => ({
      shiftName: shift.name,
      nodeName,
      performance: shift.performance,
      opportunitySeconds: shift.opportunities,
    }));
  });

  const transformedBarGraphData = {
    "Avg. Time Between Rounds": { means: [], labels: [] },
    "Nurse Room Time %": { means: [], labels: [] },
    "Avg. Round Duration": { means: [], labels: [] },
  };

  function extractData(node: ShiftBreakdown) {
    if (!transformedBarGraphData[node.title]) {
      return;
    }

    const means = node.shifts.map((shift) => {
      const isTimeInSeconds =
        node.title === "Avg. Time Between Rounds" ||
        node.title === "Avg. Round Duration";

      if (shift.mean === null) {
        return null;
      }

      return isTimeInSeconds ? (shift.mean / 3600).toFixed(2) : shift.mean;
    });

    const labels = node.shifts.map((shift) => shift.name);

    transformedBarGraphData[node.title].means = means;
    transformedBarGraphData[node.title].labels = labels;
  }

  function areAllMeansNull(
    data: Record<string, { means: (number | null)[]; labels: string[] }>
  ) {
    return Object.values(data).every((item) =>
      item.means.every((value) => value === null)
    );
  }

  roundingData.allNodes.averages.forEach((node) => extractData(node));

  if (areAllMeansNull(transformedBarGraphData)) {
    Object.keys(transformedBarGraphData).forEach((title) => {
      transformedBarGraphData[title].means = [];
    });
  }

  return {
    bubbleChartData,
    transformedRoundingData,
    roundingDataTable: roundingDataTable,
    transformedBarGraphData,
  };
};

export default useRoundingViewModel;
