import RoomsOverviewPdfDownloadButton, {
  RoomsOverviewPdfDownloadButtonProps,
} from "./RoomsOverviewPdfDownloadButton";
import StaffOverviewPdfDownloadButton, {
  StaffOverviewPdfDownloadButtonProps,
} from "./StaffOverviewPdfDownloadButton";

export type VisitOverviewPdfDownloadButtonProps = {
  type: "rooms" | "staff";
  roomsProps: RoomsOverviewPdfDownloadButtonProps | null;
  staffProps: StaffOverviewPdfDownloadButtonProps | null;
};

function VisitOverviewPdfDownloadButton(
  props: VisitOverviewPdfDownloadButtonProps
) {
  if (props.type === "rooms" && props.roomsProps) {
    return (
      <>
        <RoomsOverviewPdfDownloadButton {...props.roomsProps} />
      </>
    );
  }

  if (props.type === "staff" && props.staffProps) {
    return (
      <>
        <StaffOverviewPdfDownloadButton {...props.staffProps} />
      </>
    );
  }

  throw new Error("Invalid props passed to VisitOverviewPdfDownloadButton");
}

export default VisitOverviewPdfDownloadButton;
