export default function mockGroupReportData() {
  return {
    summaryBlocks: {
      tiles: [
        {
          _component_class: "PerformanceOverall",
          performance: 0.80923,
        },
        {
          _component_class: "PerformanceChanged",
          percentChanged: 0.05,
          percentChangedFormatted: "+5%",
        },
        {
          _component_class: "EventCounts",
          opportunities: 20000,
          events: 15000,
        },
      ],
    },
    handHygienePlot: {
      _graph_class: "PercentageLineGraph",
      chartId: "chart_0d82baa12f",
      chartTestKey: "Hand Hygiene Performance Over Time",
      chartType: "line",
      titleText: "Hand Hygiene Performance Over Time",
      subtitle: null,
      xAxis: {
        _plot_component_class: "DateTimeAxis",
        type: "datetime",
        units:
          '{@JS}window.HighchartsHelper.getAxisDataIntervalUnits("weekly")',
        minorTickInterval:
          '{@JS}window.HighchartsHelper.getAxisDataIntervalMinorTickInterval("weekly")',
        title: {
          text: '{@JS}window.HighchartsHelper.getAxisDataIntervalLabel("weekly")',
          style: {
            fontSize: "15px",
          },
        },
        labels:
          "{@JS}{'formatter': function () { return Highcharts.dateFormat(window.HighchartsHelper.getAxisDataIntervalFormat(\"weekly\"), this.value);}}",
      },
      yAxis: {
        _plot_component_class: "PercentageAxis",
        title: {
          text: "Performance (%)",
          style: {
            fontSize: "15px",
          },
        },
        min: 0,
        max: 1,
        floor: 0,
        ceiling: 1,
        labels:
          "{@JS}{'formatter': function() { return (this.value * 100) + \" % \"; }}",
        tickPositions:
          "{@JS}window.HighchartsHelper.getTickPositions(1.0, true)",
      },
      xAxisPlotlines:
        '[{"series":{"name":"Phase Markers","data":[],"visible":true,"showInLegend":true,"legendIndex":-99,"type":"area","color":"#808080"}, "lines": []}]',
      yAxisPlotlines:
        '[{"series":{"name":"Hand Hygiene Goal","data":[],"visible":false,"showInLegend":true,"legendIndex":-99,"type":"area","color":"gold"}, "lines": [{"value":0.8,"label":{"align":"left","style":{"color":"grey"},"text":"<i>Goal:<\\/i> 80%","x":2,"y":15,"rotation":0},"zIndex":2.1,"color":"gold","dashStyle":null,"width":1.5}]}]',
      showYearBoundaries: true,
      showColorBands: true,
      tooltip: {
        _plot_component_class: "PercentageTooltip",
        pointFormat: "{@JS}window.HighchartsPointFormats.specifiedPercentage",
      },
      dataSeries: [
        {
          name: "System-1948 Health System",
          data: [
            {
              x: 1676246400000,
              y: 0.8430166575734567,
              percentage: 84,
              opportunities: 617677,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 617,677",
            },
            {
              x: 1676851200000,
              y: 0.8423990676465802,
              percentage: 84,
              opportunities: 608353,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 608,353",
            },
            {
              x: 1677456000000,
              y: 0.8428874364468563,
              percentage: 84,
              opportunities: 630968,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 630,968",
            },
            {
              x: 1678060800000,
              y: 0.8464264502078797,
              percentage: 85,
              opportunities: 606120,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 606,120",
            },
            {
              x: 1678665600000,
              y: 0.8428646567230083,
              percentage: 84,
              opportunities: 616004,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 616,004",
            },
            {
              x: 1679270400000,
              y: 0.8438942012383011,
              percentage: 84,
              opportunities: 590971,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 590,971",
            },
            {
              x: 1679875200000,
              y: 0.8460984512183092,
              percentage: 85,
              opportunities: 608220,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 608,220",
            },
            {
              x: 1680480000000,
              y: 0.8435278061757845,
              percentage: 84,
              opportunities: 607437,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 607,437",
            },
            {
              x: 1681084800000,
              y: 0.8453350288500716,
              percentage: 85,
              opportunities: 608664,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 608,664",
            },
            {
              x: 1681689600000,
              y: 0.8347949080622348,
              percentage: 83,
              opportunities: 3535,
              name: "April 17, 2023–April 23, 2023<br>Opportunities: 3,535",
            },
          ],
          showInLegend: true,
          visible: false,
          legendType: "nodeAncestor",
          nodeType_id: 8,
        },
        {
          name: "Region-3413",
          data: [
            {
              x: 1676246400000,
              y: 0.83846483563586,
              percentage: 84,
              opportunities: 303290,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 303,290",
            },
            {
              x: 1676851200000,
              y: 0.8378331388525747,
              percentage: 84,
              opportunities: 299087,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 299,087",
            },
            {
              x: 1677456000000,
              y: 0.8378623010815933,
              percentage: 84,
              opportunities: 306030,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 306,030",
            },
            {
              x: 1678060800000,
              y: 0.8422013201431676,
              percentage: 84,
              opportunities: 295877,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 295,877",
            },
            {
              x: 1678665600000,
              y: 0.8358416521871264,
              percentage: 84,
              opportunities: 302726,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 302,726",
            },
            {
              x: 1679270400000,
              y: 0.8340802112695328,
              percentage: 83,
              opportunities: 292328,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 292,328",
            },
            {
              x: 1679875200000,
              y: 0.8355136845729115,
              percentage: 84,
              opportunities: 302092,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 302,092",
            },
            {
              x: 1680480000000,
              y: 0.8293031135155813,
              percentage: 83,
              opportunities: 292788,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 292,788",
            },
            {
              x: 1681084800000,
              y: 0.8300788461206673,
              percentage: 83,
              opportunities: 289805,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 289,805",
            },
            {
              x: 1681689600000,
              y: 0.8376113148245155,
              percentage: 84,
              opportunities: 1909,
              name: "April 17, 2023–April 23, 2023<br>Opportunities: 1,909",
            },
          ],
          showInLegend: true,
          visible: false,
          legendType: "nodeAncestor",
          nodeType_id: 9,
        },
        {
          name: "RERUMCONSECTETUR Hospital",
          data: [
            {
              x: 1676246400000,
              y: 0.8482804831894973,
              percentage: 85,
              opportunities: 161179,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 161,179",
            },
            {
              x: 1676851200000,
              y: 0.8505436285747651,
              percentage: 85,
              opportunities: 162703,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 162,703",
            },
            {
              x: 1677456000000,
              y: 0.8465832215945978,
              percentage: 85,
              opportunities: 164819,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 164,819",
            },
            {
              x: 1678060800000,
              y: 0.8508516931864545,
              percentage: 85,
              opportunities: 156864,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 156,864",
            },
            {
              x: 1678665600000,
              y: 0.8460834289512129,
              percentage: 85,
              opportunities: 159489,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 159,489",
            },
            {
              x: 1679270400000,
              y: 0.8443835544255773,
              percentage: 84,
              opportunities: 151822,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 151,822",
            },
            {
              x: 1679875200000,
              y: 0.8470149253731343,
              percentage: 85,
              opportunities: 161336,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 161,336",
            },
            {
              x: 1680480000000,
              y: 0.8383287745116924,
              percentage: 84,
              opportunities: 155742,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 155,742",
            },
            {
              x: 1681084800000,
              y: 0.8381527048559413,
              percentage: 84,
              opportunities: 153132,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 153,132",
            },
            {
              x: 1681689600000,
              y: 0.8418647166361974,
              percentage: 84,
              opportunities: 1094,
              name: "April 17, 2023–April 23, 2023<br>Opportunities: 1,094",
            },
          ],
          showInLegend: true,
          visible: false,
          legendType: "nodeAncestor",
          nodeType_id: 0,
        },
        {
          name: "Group-374",
          data: [
            {
              x: 1676246400000,
              y: 0.8478825422161416,
              percentage: 85,
              opportunities: 157878,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 157,878",
            },
            {
              x: 1676851200000,
              y: 0.851343328828203,
              percentage: 85,
              opportunities: 158338,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 158,338",
            },
            {
              x: 1677456000000,
              y: 0.8471463464657953,
              percentage: 85,
              opportunities: 160899,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 160,899",
            },
            {
              x: 1678060800000,
              y: 0.850595047688682,
              percentage: 85,
              opportunities: 153181,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 153,181",
            },
            {
              x: 1678665600000,
              y: 0.8461196854042504,
              percentage: 85,
              opportunities: 155374,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 155,374",
            },
            {
              x: 1679270400000,
              y: 0.8444961281996128,
              percentage: 84,
              opportunities: 148768,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 148,768",
            },
            {
              x: 1679875200000,
              y: 0.8480034431033501,
              percentage: 85,
              opportunities: 157997,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 157,997",
            },
            {
              x: 1680480000000,
              y: 0.8392635853086192,
              percentage: 84,
              opportunities: 151708,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 151,708",
            },
            {
              x: 1681084800000,
              y: 0.8395074122410735,
              percentage: 84,
              opportunities: 149415,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 149,415",
            },
            {
              x: 1681689600000,
              y: 0.8428847392144238,
              percentage: 84,
              opportunities: 1553,
              name: "April 17, 2023–April 23, 2023<br>Opportunities: 1,553",
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_groups",
          legendType: "selectedIndividual",
        },
      ],
      plotOptions: null,
      helpText: "This graph shows Hand Hygiene (HH) in percent.",
      extraJavascript: "",
      isExportable: true,
    },
    handHygieneByUnitPlot: {
      dataSeries: [
        {
          name: "4 Surgical",
          data: [
            {
              percentage: 53,
              opportunities: 10154,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 10,154",
            },
            {
              percentage: 55,
              opportunities: 10367,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 10,367",
            },
            {
              percentage: 61,
              opportunities: 10693,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 10,693",
            },
            {
              percentage: 60,
              opportunities: 9695,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 9,695",
            },
          ],
          visible: true,
          opportunities: 40909,
          successful_opportunities: 23347,
          performance: 0.5707057126793615,
        },
        {
          name: "6 Floor",
          data: [
            {
              percentage: 66,
              opportunities: 9218,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 9,218",
            },
            {
              percentage: 65,
              opportunities: 9368,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 9,368",
            },
            {
              percentage: 67,
              opportunities: 9248,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 9,248",
            },
            {
              percentage: 71,
              opportunities: 9576,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 9,576",
            },
          ],
          visible: true,
          opportunities: 37410,
          successful_opportunities: 25175,
          performance: 0.6729484095161722,
        },
        {
          name: "3 Medical",
          data: [
            {
              percentage: 70,
              opportunities: 6651,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 6,651",
            },
            {
              percentage: 71,
              opportunities: 6004,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 6,004",
            },
            {
              percentage: 70,
              opportunities: 8274,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 8,274",
            },
            {
              percentage: 69,
              opportunities: 7983,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 7,983",
            },
          ],
          visible: true,
          opportunities: 28912,
          successful_opportunities: 20272,
          performance: 0.7011621472053127,
        },
        {
          name: "Unit 61",
          data: [
            {
              percentage: 85,
              opportunities: 7104,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 7,104",
            },
            {
              percentage: 84,
              opportunities: 6900,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 6,900",
            },
            {
              percentage: 83,
              opportunities: 6693,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 6,693",
            },
            {
              percentage: 85,
              opportunities: 7243,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 7,243",
            },
          ],
          visible: false,
          opportunities: 27940,
          successful_opportunities: 23489,
          performance: 0.8406943450250537,
        },
        {
          name: "Unit 41",
          data: [
            {
              percentage: 66,
              opportunities: 5188,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 5,188",
            },
            {
              percentage: 69,
              opportunities: 5254,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 5,254",
            },
            {
              percentage: 67,
              opportunities: 4987,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 4,987",
            },
            {
              percentage: 68,
              opportunities: 4908,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 4,908",
            },
          ],
          visible: false,
          opportunities: 20337,
          successful_opportunities: 13760,
          performance: 0.6765993017652554,
        },
        {
          name: "Unit 31",
          data: [
            {
              percentage: 56,
              opportunities: 4196,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 4,196",
            },
            {
              percentage: 57,
              opportunities: 4074,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 4,074",
            },
            {
              percentage: 58,
              opportunities: 4434,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 4,434",
            },
            {
              percentage: 61,
              opportunities: 4244,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 4,244",
            },
          ],
          visible: false,
          opportunities: 16948,
          successful_opportunities: 9802,
          performance: 0.5783573282983243,
        },
        {
          name: "Unit 11",
          data: [
            {
              percentage: 66,
              opportunities: 4180,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 4,180",
            },
            {
              percentage: 68,
              opportunities: 3867,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 3,867",
            },
            {
              percentage: 70,
              opportunities: 4219,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 4,219",
            },
            {
              percentage: 71,
              opportunities: 4301,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 4,301",
            },
          ],
          visible: false,
          opportunities: 16567,
          successful_opportunities: 11390,
          performance: 0.6875113176797247,
        },
        {
          name: "Unit 72",
          data: [
            {
              percentage: 70,
              opportunities: 3844,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 3,844",
            },
            {
              percentage: 73,
              opportunities: 3859,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 3,859",
            },
            {
              percentage: 72,
              opportunities: 3894,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3,894",
            },
            {
              percentage: 78,
              opportunities: 3570,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3,570",
            },
          ],
          visible: false,
          opportunities: 15167,
          successful_opportunities: 11088,
          performance: 0.731060855805367,
        },
        {
          name: "Unit 51",
          data: [
            {
              percentage: 70,
              opportunities: 3760,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 3,760",
            },
            {
              percentage: 67,
              opportunities: 3329,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 3,329",
            },
            {
              percentage: 69,
              opportunities: 3819,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3,819",
            },
            {
              percentage: 68,
              opportunities: 3471,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3,471",
            },
          ],
          visible: false,
          opportunities: 14379,
          successful_opportunities: 9882,
          performance: 0.6872522428541623,
        },
        {
          name: "Unit 21",
          data: [
            {
              percentage: 72,
              opportunities: 3300,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 3,300",
            },
            {
              percentage: 71,
              opportunities: 3764,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 3,764",
            },
            {
              percentage: 69,
              opportunities: 3553,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3,553",
            },
            {
              percentage: 74,
              opportunities: 3661,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3,661",
            },
          ],
          visible: false,
          opportunities: 14278,
          successful_opportunities: 10202,
          performance: 0.7145258439557362,
        },
        {
          name: "Unit 71",
          data: [
            {
              percentage: 83,
              opportunities: 3229,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 3,229",
            },
            {
              percentage: 82,
              opportunities: 3684,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 3,684",
            },
            {
              percentage: 83,
              opportunities: 3450,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3,450",
            },
            {
              percentage: 82,
              opportunities: 3363,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3,363",
            },
          ],
          visible: false,
          opportunities: 13726,
          successful_opportunities: 11300,
          performance: 0.8232551362377969,
        },
        {
          name: "Unit 22",
          data: [
            {
              percentage: 63,
              opportunities: 3432,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 3,432",
            },
            {
              percentage: 63,
              opportunities: 3004,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 3,004",
            },
            {
              percentage: 63,
              opportunities: 3172,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3,172",
            },
            {
              percentage: 63,
              opportunities: 3043,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3,043",
            },
          ],
          visible: false,
          opportunities: 12651,
          successful_opportunities: 8009,
          performance: 0.6330724843885859,
        },
        {
          name: "Unit 62",
          data: [
            {
              percentage: 55,
              opportunities: 2647,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 2,647",
            },
            {
              percentage: 54,
              opportunities: 2820,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 2,820",
            },
            {
              percentage: 59,
              opportunities: 2703,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2,703",
            },
            {
              percentage: 52,
              opportunities: 3145,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3,145",
            },
          ],
          visible: false,
          opportunities: 11315,
          successful_opportunities: 6197,
          performance: 0.5476800707026072,
        },
        {
          name: "41 ICU",
          data: [
            {
              percentage: 72,
              opportunities: 2812,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 2,812",
            },
            {
              percentage: 73,
              opportunities: 3041,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 3,041",
            },
            {
              percentage: 74,
              opportunities: 2697,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2,697",
            },
            {
              percentage: 80,
              opportunities: 2277,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2,277",
            },
          ],
          visible: false,
          opportunities: 10827,
          successful_opportunities: 8087,
          performance: 0.7469289738616421,
        },
        {
          name: "71 ICU",
          data: [
            {
              percentage: 69,
              opportunities: 2701,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 2,701",
            },
            {
              percentage: 72,
              opportunities: 2916,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 2,916",
            },
            {
              percentage: 73,
              opportunities: 2342,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2,342",
            },
            {
              percentage: 72,
              opportunities: 2321,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2,321",
            },
          ],
          visible: false,
          opportunities: 10280,
          successful_opportunities: 7347,
          performance: 0.7146887159533074,
        },
        {
          name: "Unit 54",
          data: [
            {
              percentage: 62,
              opportunities: 2759,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 2,759",
            },
            {
              percentage: 61,
              opportunities: 2437,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 2,437",
            },
            {
              percentage: 66,
              opportunities: 2471,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2,471",
            },
            {
              percentage: 66,
              opportunities: 2519,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2,519",
            },
          ],
          visible: false,
          opportunities: 10186,
          successful_opportunities: 6491,
          performance: 0.6372472020420185,
        },
        {
          name: "Unit 52",
          data: [
            {
              percentage: 69,
              opportunities: 3042,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 3,042",
            },
            {
              percentage: 63,
              opportunities: 2933,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 2,933",
            },
            {
              percentage: 64,
              opportunities: 3111,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3,111",
            },
            {
              percentage: 62,
              opportunities: 622,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 622",
            },
          ],
          visible: false,
          opportunities: 9708,
          successful_opportunities: 6318,
          performance: 0.6508034610630408,
        },
        {
          name: "3 ICU",
          data: [
            {
              percentage: 72,
              opportunities: 2153,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 2,153",
            },
            {
              percentage: 74,
              opportunities: 1787,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 1,787",
            },
            {
              percentage: 75,
              opportunities: 2869,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2,869",
            },
            {
              percentage: 77,
              opportunities: 2853,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2,853",
            },
          ],
          visible: false,
          opportunities: 9662,
          successful_opportunities: 7199,
          performance: 0.7450838335748292,
        },
        {
          name: "31 ICU",
          data: [
            {
              percentage: 67,
              opportunities: 2776,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 2,776",
            },
            {
              percentage: 71,
              opportunities: 2126,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 2,126",
            },
            {
              percentage: 70,
              opportunities: 2029,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2,029",
            },
            {
              percentage: 71,
              opportunities: 1720,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 1,720",
            },
          ],
          visible: false,
          opportunities: 8651,
          successful_opportunities: 6019,
          performance: 0.6957577158709976,
        },
        {
          name: "11 ICU",
          data: [
            {
              percentage: 67,
              opportunities: 1848,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 1,848",
            },
            {
              percentage: 65,
              opportunities: 2233,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 2,233",
            },
            {
              percentage: 64,
              opportunities: 1917,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1,917",
            },
            {
              percentage: 71,
              opportunities: 1701,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 1,701",
            },
          ],
          visible: false,
          opportunities: 7699,
          successful_opportunities: 5117,
          performance: 0.6646317703597869,
        },
        {
          name: "PICU",
          data: [
            {
              percentage: 69,
              opportunities: 1506,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 1,506",
            },
            {
              percentage: 73,
              opportunities: 1572,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 1,572",
            },
            {
              percentage: 72,
              opportunities: 2051,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2,051",
            },
            {
              percentage: 71,
              opportunities: 2033,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2,033",
            },
          ],
          visible: false,
          opportunities: 7162,
          successful_opportunities: 5118,
          performance: 0.7146048589779391,
        },
        {
          name: "Mother/Baby",
          data: [
            {
              percentage: 75,
              opportunities: 1622,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 1,622",
            },
            {
              percentage: 79,
              opportunities: 1609,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 1,609",
            },
            {
              percentage: 80,
              opportunities: 1523,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1,523",
            },
            {
              percentage: 78,
              opportunities: 1179,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 1,179",
            },
          ],
          visible: false,
          opportunities: 5933,
          successful_opportunities: 4631,
          performance: 0.7805494690712962,
        },
        {
          name: "Unit 42",
          data: [
            {
              percentage: 55,
              opportunities: 1164,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 1,164",
            },
            {
              percentage: 66,
              opportunities: 1076,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 1,076",
            },
            {
              percentage: 50,
              opportunities: 858,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 858",
            },
            {
              percentage: 49,
              opportunities: 752,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 752",
            },
          ],
          visible: false,
          opportunities: 3850,
          successful_opportunities: 2145,
          performance: 0.5571428571428572,
        },
        {
          name: "Unit 34",
          data: [
            {
              percentage: 80,
              opportunities: 465,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 465",
            },
            {
              percentage: 67,
              opportunities: 456,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 456",
            },
            {
              percentage: 70,
              opportunities: 335,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 335",
            },
            {
              percentage: 67,
              opportunities: 377,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 377",
            },
          ],
          visible: false,
          opportunities: 1633,
          successful_opportunities: 1166,
          performance: 0.7140232700551132,
        },
        {
          name: "5T North ICU",
          data: [
            {
              percentage: 59,
              opportunities: 119,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 119",
            },
            {
              percentage: 51,
              opportunities: 148,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 148",
            },
            {
              percentage: 40,
              opportunities: 146,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 146",
            },
            {
              percentage: 17,
              opportunities: 71,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 71",
            },
          ],
          visible: false,
          opportunities: 484,
          successful_opportunities: 217,
          performance: 0.44834710743801653,
        },
        {
          name: "5E ICU",
          data: [
            {
              percentage: 63,
              opportunities: 40,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 40",
            },
            {
              percentage: 77,
              opportunities: 22,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 22",
            },
            {
              percentage: 63,
              opportunities: 24,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 24",
            },
            {
              percentage: 73,
              opportunities: 41,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 41",
            },
          ],
          visible: false,
          opportunities: 127,
          successful_opportunities: 87,
          performance: 0.6850393700787402,
        },
        {
          name: "7T",
          data: [
            {
              percentage: 86,
              opportunities: 7,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 7",
            },
            {
              percentage: 81,
              opportunities: 21,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 21",
            },
            {
              percentage: 73,
              opportunities: 51,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 51",
            },
          ],
          visible: false,
          opportunities: 79,
          successful_opportunities: 60,
          performance: 0.759493670886076,
        },
        {
          name: "3 Southwest",
          data: [
            {
              percentage: 20,
              opportunities: 5,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 5",
            },
            {
              percentage: 32,
              opportunities: 31,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 31",
            },
          ],
          visible: false,
          opportunities: 36,
          successful_opportunities: 11,
          performance: 0.3055555555555556,
        },
        {
          name: "ICU",
          data: [
            {
              percentage: 31,
              opportunities: 36,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 36",
            },
          ],
          visible: false,
          opportunities: 36,
          successful_opportunities: 11,
          performance: 0.3055555555555556,
        },
        {
          name: "In-Patient Rehab (3600)",
          data: [
            {
              percentage: 100,
              opportunities: 1,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1",
            },
            {
              percentage: 57,
              opportunities: 28,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 28",
            },
          ],
          visible: false,
          opportunities: 29,
          successful_opportunities: 17,
          performance: 0.5862068965517241,
        },
        {
          name: "4th Floor",
          data: [
            {
              percentage: 75,
              opportunities: 8,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 8",
            },
            {
              percentage: 100,
              opportunities: 5,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 5",
            },
            {
              percentage: 11,
              opportunities: 9,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 9",
            },
            {
              percentage: 0,
              opportunities: 6,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 6",
            },
          ],
          visible: false,
          opportunities: 28,
          successful_opportunities: 12,
          performance: 0.42857142857142855,
        },
        {
          name: "Med Surg 2",
          data: [
            {
              percentage: 47,
              opportunities: 15,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 15",
            },
            {
              percentage: 13,
              opportunities: 8,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 8",
            },
          ],
          visible: false,
          opportunities: 23,
          successful_opportunities: 8,
          performance: 0.34782608695652173,
        },
        {
          name: "5T South ICU",
          data: [
            {
              percentage: 60,
              opportunities: 5,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 5",
            },
            {
              percentage: 80,
              opportunities: 5,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 5",
            },
            {
              percentage: 75,
              opportunities: 12,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 12",
            },
          ],
          visible: false,
          opportunities: 22,
          successful_opportunities: 16,
          performance: 0.7272727272727273,
        },
        {
          name: "6T",
          data: [
            {
              percentage: 87,
              opportunities: 15,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 15",
            },
            {
              percentage: 100,
              opportunities: 1,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 1",
            },
            {
              percentage: 100,
              opportunities: 5,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 5",
            },
            {
              percentage: 100,
              opportunities: 1,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 1",
            },
          ],
          visible: false,
          opportunities: 22,
          successful_opportunities: 20,
          performance: 0.9090909090909091,
        },
        {
          name: "9T",
          data: [
            {
              percentage: 78,
              opportunities: 9,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 9",
            },
            {
              percentage: 56,
              opportunities: 9,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 9",
            },
            {
              percentage: 33,
              opportunities: 3,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3",
            },
          ],
          visible: false,
          opportunities: 21,
          successful_opportunities: 13,
          performance: 0.6190476190476191,
        },
        {
          name: "6E",
          data: [
            {
              percentage: 100,
              opportunities: 1,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 1",
            },
            {
              percentage: 50,
              opportunities: 2,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2",
            },
            {
              percentage: 71,
              opportunities: 14,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 14",
            },
          ],
          visible: false,
          opportunities: 17,
          successful_opportunities: 12,
          performance: 0.7058823529411765,
        },
        {
          name: "CVICU",
          data: [
            {
              percentage: 25,
              opportunities: 8,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 8",
            },
            {
              percentage: 13,
              opportunities: 8,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 8",
            },
          ],
          visible: false,
          opportunities: 16,
          successful_opportunities: 3,
          performance: 0.1875,
        },
        {
          name: "8T South",
          data: [
            {
              percentage: 50,
              opportunities: 4,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 4",
            },
            {
              percentage: 40,
              opportunities: 5,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 5",
            },
            {
              percentage: 100,
              opportunities: 6,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 6",
            },
          ],
          visible: false,
          opportunities: 15,
          successful_opportunities: 10,
          performance: 0.6666666666666666,
        },
        {
          name: "7G South",
          data: [
            {
              percentage: 33,
              opportunities: 3,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 3",
            },
            {
              percentage: 50,
              opportunities: 2,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2",
            },
            {
              percentage: 75,
              opportunities: 8,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 8",
            },
          ],
          visible: false,
          opportunities: 13,
          successful_opportunities: 8,
          performance: 0.6153846153846154,
        },
        {
          name: "8E",
          data: [
            {
              percentage: 88,
              opportunities: 8,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 8",
            },
            {
              percentage: 75,
              opportunities: 4,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 4",
            },
          ],
          visible: false,
          opportunities: 12,
          successful_opportunities: 10,
          performance: 0.8333333333333334,
        },
        {
          name: "6G South",
          data: [
            {
              percentage: 43,
              opportunities: 7,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 7",
            },
            {
              percentage: 33,
              opportunities: 3,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3",
            },
          ],
          visible: false,
          opportunities: 10,
          successful_opportunities: 4,
          performance: 0.4,
        },
        {
          name: "5 West",
          data: [
            {
              percentage: 50,
              opportunities: 8,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 8",
            },
          ],
          visible: false,
          opportunities: 8,
          successful_opportunities: 4,
          performance: 0.5,
        },
        {
          name: "2 South",
          data: [
            {
              percentage: 29,
              opportunities: 7,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 7",
            },
          ],
          visible: false,
          opportunities: 7,
          successful_opportunities: 2,
          performance: 0.2857142857142857,
        },
        {
          name: "5 East",
          data: [
            {
              percentage: 86,
              opportunities: 7,
              name: "February 6, 2023–February 12, 2023<br>Opportunities: 7",
            },
          ],
          visible: false,
          opportunities: 7,
          successful_opportunities: 6,
          performance: 0.8571428571428571,
        },
        {
          name: "3rd Floor",
          data: [
            {
              percentage: 0,
              opportunities: 1,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1",
            },
            {
              percentage: 17,
              opportunities: 6,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 6",
            },
          ],
          visible: false,
          opportunities: 7,
          successful_opportunities: 1,
          performance: 0.14285714285714285,
        },
        {
          name: "ICU",
          data: [
            {
              percentage: 20,
              opportunities: 5,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 5",
            },
            {
              percentage: 100,
              opportunities: 1,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1",
            },
          ],
          visible: false,
          opportunities: 6,
          successful_opportunities: 2,
          performance: 0.3333333333333333,
        },
        {
          name: "3G North ICU",
          data: [
            {
              percentage: 33,
              opportunities: 3,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3",
            },
            {
              percentage: 100,
              opportunities: 3,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 3",
            },
          ],
          visible: false,
          opportunities: 6,
          successful_opportunities: 4,
          performance: 0.6666666666666666,
        },
        {
          name: "7E",
          data: [
            {
              percentage: 67,
              opportunities: 3,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3",
            },
            {
              percentage: 100,
              opportunities: 2,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2",
            },
          ],
          visible: false,
          opportunities: 5,
          successful_opportunities: 4,
          performance: 0.8,
        },
        {
          name: "9E",
          data: [
            {
              percentage: 60,
              opportunities: 5,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 5",
            },
          ],
          visible: false,
          opportunities: 5,
          successful_opportunities: 3,
          performance: 0.6,
        },
        {
          name: "8T North",
          data: [
            {
              percentage: 50,
              opportunities: 2,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 2",
            },
            {
              percentage: 100,
              opportunities: 2,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 2",
            },
          ],
          visible: false,
          opportunities: 4,
          successful_opportunities: 3,
          performance: 0.75,
        },
        {
          name: "4 West",
          data: [
            {
              percentage: 75,
              opportunities: 4,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 4",
            },
          ],
          visible: false,
          opportunities: 4,
          successful_opportunities: 3,
          performance: 0.75,
        },
        {
          name: "4G South",
          data: [
            {
              percentage: 75,
              opportunities: 4,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 4",
            },
          ],
          visible: false,
          opportunities: 4,
          successful_opportunities: 3,
          performance: 0.75,
        },
        {
          name: "5D",
          data: [
            {
              percentage: 100,
              opportunities: 1,
              name: "January 30, 2023–February 5, 2023<br>Opportunities: 1",
            },
            {
              percentage: 50,
              opportunities: 2,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 2",
            },
          ],
          visible: false,
          opportunities: 3,
          successful_opportunities: 2,
          performance: 0.6666666666666666,
        },
        {
          name: "4R",
          data: [
            {
              percentage: 100,
              opportunities: 3,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 3",
            },
          ],
          visible: false,
          opportunities: 3,
          successful_opportunities: 3,
          performance: 1,
        },
        {
          name: "6 East",
          data: [
            {
              percentage: 0,
              opportunities: 1,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1",
            },
          ],
          visible: false,
          opportunities: 1,
          successful_opportunities: 0,
          performance: 0,
        },
        {
          name: "3G South",
          data: [
            {
              percentage: 100,
              opportunities: 1,
              name: "February 13, 2023–February 19, 2023<br>Opportunities: 1",
            },
          ],
          visible: false,
          opportunities: 1,
          successful_opportunities: 1,
          performance: 1,
        },
      ],
    },
    topPerformersTable: {
      subtitle: "Jan 30, 2023 - Feb 26, 2023",
      users: {
        0: {
          performance: 1,
          total_opportunities: 106,
          name: "Ondricka, Georgeta",
          group_name: "Nurses (RN)-42",
        },
        1: {
          performance: 1,
          total_opportunities: 48,
          name: "Padberg, Kellye",
          group_name: "Nurses (RN)-1072",
        },
        2: {
          performance: 0.9825581395348837,
          total_opportunities: 172,
          name: "Prohaska, Deletha",
          group_name: "Nurses (RN)-1069",
        },
        4: {
          performance: 0.9670050761421319,
          total_opportunities: 394,
          name: "Halvorson, Kendra",
          group_name: "Techs-1084",
        },
        3: {
          performance: 0.965893587994543,
          total_opportunities: 733,
          name: "Kassulke, Jordan",
          group_name: "Nurses (RN)-42",
        },
      },
    },
    _params: {
      customer_ids: {
        _filter_component_class: "CustomerIdsFilter",
        visible: true,
        value: null,
        valueDisplay: "All",
        reloading: {
          peer_filters: [],
        },
        options: {
          1399: "Foo",
        },
      },
      facility_ids: {
        _filter_component_class: "FacilityIdsFilter",
        visible: true,
        value: [1000, 1010],
        valueDisplay: "[Facility-1000, Facility-1010]",
        reloading: {
          peer_filters: ["customer_ids"],
        },
        options: {
          1398: "Bar",
        },
      },
      unit_ids: {
        _filter_component_class: "UnitIdsFilter",
        visible: true,
        value: [61],
        valueDisplay: "Unit 61",
        reloading: {
          peer_filters: ["customer_ids", "facility_ids"],
        },
        options: {
          1397: "Biz",
        },
      },
      group_ids: {
        _filter_component_class: "GroupIdsFilter",
        visible: true,
        value: [42],
        valueDisplay: "Nurses (RN)-42",
        reloading: {
          peer_filters: ["customer_ids", "facility_ids", "unit_ids"],
        },
        options: {
          1396: "Baz",
        },
      },
    },
  };
}
