import { useEffect } from "react";
import useRtibViewModel from "../../viewmodel/Rtibs/useRtibsViewModel";
import useSharedFunctions from "../sharedFunctions/useSharedFunctions";

const useRtibViewController = () => {
  const { transformedRtib } = useRtibViewModel();
  const {
    handleDateChange,
    startDate,
    endDate,
    aggregate,
    onFilterChange,
    filters,
    applyFilters,
    setSelected,
    setPeerFilters,
    toggleButton,
  } = useSharedFunctions(transformedRtib);

  useEffect(() => {
    transformedRtib.filters.forEach((filter) => {
      setPeerFilters(filter.name, filter.peerFilters!);
      setSelected(filter.name, filter.selection);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    transformedRtib,
    aggregate,
    startDate,
    endDate,
    handleDateChange,
    filters,
    onFilterChange,
    applyFilters,
    toggleButton,
  };
};

export default useRtibViewController;
