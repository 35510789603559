import Cards from "../UI/Cards/Cards";
import CircularProgress from "../UI/CircularProgress/CircularProgress";
import ChangeText from "../UI/ChangeText/ChangeText";
import Tooltips from "../UI/Tooltip/Tooltip";
import { tooltipContent } from "../../content";
import { formatPercentage, getColor } from "../../utils";
import { missingDataAction, missingDataMessage } from "../../constants";
import MissingData from "../MissingData/MissingData";

type PerformanceProps = {
  reportData?: {
    total?: number | null;
    performant?: number | null;
    performance: number | null;
    percentChangedFormatted?: string | null;
  };
};

function Performance(props: PerformanceProps) {
  let performance, percentChangedFormatted;

  if (props.reportData) {
    ({ performance, percentChangedFormatted } = props.reportData);
  }

  const circularProgressText = performance ? "Total" : missingDataMessage;
  const circularProgressPct = performance ? formatPercentage(performance) : 0;

  const pathColor = getColor(performance * 100);

  return (
    <>
      <Cards
        headerLeft={<h2>Performance</h2>}
        headerRight={
          <Tooltips
            id="performanceCard-tooltip"
            content={tooltipContent.performanceCard["en-US"]}
          />
        }
      >
        {performance && (
          <div className="performance-container">
            <div className="performance-progress-container">
              <CircularProgress
                text={circularProgressText}
                value={circularProgressPct}
                changeText={<ChangeText percent={percentChangedFormatted} />}
                trailColor={"#F3F4F7"}
                pathColor={pathColor}
              ></CircularProgress>
            </div>
          </div>
        )}
        {!performance && (
          <MissingData
            message={missingDataMessage}
            action={missingDataAction}
            className="missing-data-container"
          />
        )}
        {/* <div className="performance-data-container">
            <PerformanceDataPoint title="Entry" value={performanceEntry} />

            <PerformanceDataPoint title="Day" value={performanceDay} />

            <PerformanceDataPoint
              title="Weekdays"
              value={performanceWeekdays}
            />

            <PerformanceDataPoint title="Exit" value={performanceExit} />

            <PerformanceDataPoint title="Night" value={performanceNight} />

            <PerformanceDataPoint
              title="Weekends"
              value={performanceWeekends}
            />
          </div> */}
      </Cards>
    </>
  );
}

export default Performance;
