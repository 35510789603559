import { getSigned, getSignedForComponent } from "../../api/laravelApi";

export const StaffOverviewSignedLoader = async (request, loader) => {
  const baseUrl = new URL(request.url);
  const uuid = baseUrl.searchParams.get("uuid");
  const expires = baseUrl.searchParams.get("expires");
  const signature = baseUrl.searchParams.get("signature");

  if (uuid && signature) {
    const signedDataPromiseForTable = getSignedForComponent({
      uuid,
      expires,
      signature,
      page: "staff-overview",
      component: "table",
    });

    const signedDataPromiseForSummary = getSignedForComponent({
      uuid,
      expires,
      signature,
      page: "staff-overview",
      component: "summary",
    });

    const filtersData = await getSigned(uuid, expires, signature);

    const dateRangeParamsData = {
      params: {
        date_range: null,
      },
    };

    // construct the filters object in the same format as the response from the params endpoint
    dateRangeParamsData.params.date_range = filtersData.params["date_range"];

    return {
      staffTable: signedDataPromiseForTable,
      summary: signedDataPromiseForSummary,
      filters: dateRangeParamsData,
    };
  }

  return loader(new Request(request));
};
