import useOtibModel from "../../model/Otib/useOtibModel";
import { transformOtib } from "../../transformers/otibs/transformOtibs";

const useOtibViewModel = () => {
  const { otibData } = useOtibModel();

  const transformedOtib = transformOtib(otibData);

  return {
    transformedOtib,
  };
};

export default useOtibViewModel;
