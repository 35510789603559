import { useLoaderData, useParams } from "react-router-dom";
import { IndividualAssetsDTO } from "../types";

const useIndividualStaffModel = () => {
  const individualStaffData = useLoaderData() as IndividualAssetsDTO;
  const params = useParams();

  return {
    params,
    individualStaffData,
  };
};

export default useIndividualStaffModel;
