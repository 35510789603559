type ChangeTextProps = {
  percent: string | null;
};

function ChangeText(props: ChangeTextProps) {
  let color = "";

  if (props.percent && props.percent[0] === "+") {
    color = "positive";
  } else if (props.percent && props.percent[0] === "-") {
    color = "negative";
  } else {
    color = "neutral";
  }

  return (
    <div className="ChangeText" data-testid="ChangeText">
      <div className={`${color} background`}>
        <h3 className={`${color} text`}>{props.percent}</h3>
      </div>
    </div>
  );
}

export default ChangeText;
