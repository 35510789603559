import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

type CircularProgressProps = {
  value: number;
  pathColor?: string;
  textColor?: string;
  trailColor?: string;
  text?: string;
  changeText?: JSX.Element;
  className?: string;
};

function CircularProgress(props: CircularProgressProps) {
  return (
    <div className={props.className}>
      <CircularProgressbarWithChildren
        value={props.value}
        styles={buildStyles({
          strokeLinecap: "round",
          pathTransitionDuration: 0.5,
          pathColor: props.pathColor,
          textColor: props.textColor,
          trailColor: props.trailColor,
        })}
      >
        <div>{props.text}</div>
        <div className="circular-progress-pct">{props.value}%</div>
      </CircularProgressbarWithChildren>
      {props.changeText && (
        <div data-testid="change-text" className="change-text-container">
          {props.changeText}
        </div>
      )}
    </div>
  );
}

export default CircularProgress;
