import { useLoaderData } from "react-router-dom";

const useExecutiveReportModel = () => {
  const executiveReportData = useLoaderData();

  return {
    executiveReportData,
  };
};

export default useExecutiveReportModel;
