import RoomLayer from "./RoomLayer";
import CoreScripts from "../../CoreScripts";

export default class RoomHeatmapLayer extends RoomLayer {
  get allowTogglingVisibility() {
    return false;
  }

  get defaultMarkers() {
    // Load up pre-saved markers if present - uses room markers (with data appended on php side)
    return this.chart.markers.heatmarker || {};
  }

  get defaultUnplacedMarkers() {
    /* Load up unplaced markers if present */
    return this.chart.unplacedMarkers.heatmarker || {};
  }

  /* What depth should the layer take (0 is bottom, inf is top) */
  get sortDepthIndex() {
    return 1;
  }

  get layerScale() {
    /* Max and min scales are used for heatmap, but also labels and bubbles are scaled by a layer scale factor */
    return this.chart.scales.heatmarker || 1;
  }

  setupElement(elementSel, elementInfo) {
    // to do - this eventually links to the bubble plot
    // figure out how this will work within care app.
    // https://local.swipesense.test/report/bubblePlot/individual/unit/sp/tr01_9a24006d-0c61-42c8-a99e-1ce317362583?redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Freport%2Fblueprint%2FoverTime%3F_fe%3D9a23fe2a-dc37-4513-a5ae-cf6b0e98d54e
    if (elementInfo.heatmarker_bubble_plot_link) {
      elementSel.on("click", function (event, elementInfo) {
        CoreScripts.openUrl(elementInfo.heatmarker_bubble_plot_link);
      });
    }
  }

  /* eslint-disable no-unused-vars */
  elementShouldAllowPositionalEditor(elementSel, elementInfo) {
    //Turn on and off the positional editor
    return false;
  }

  prepareMarker(marker) {
    //customize
    marker.heatmarker_performance_percent = this.chart.filterFloat(
      marker.heatmarker_performance_percent
    );
    marker.heatmarker_occupation_time_percent = this.chart.filterFloat(
      marker.heatmarker_occupation_time_percent
    );

    return super.prepareMarker(marker);
  }

  renderElement(elementImageSel, elementInfo) {
    if (elementInfo.heatmarker_bubble_plot_link) {
      elementImageSel.style("cursor", "pointer");
    }
    var alphaHeatmap = 0.8;
    var alphaHeatmapText = 1.0;
    var heatmapScale = this.scale;
    var textColor = this.chart.constants.heat_marker_details.text_color;
    var textStrokeColor =
      this.chart.constants.room_marker_details.text_stroke_color;
    // here is where we would need to input duration instead of heatmarker performance percent
    var performanceColor = this.chart.colorScale(
      // for NI if we can rewrite logic to say "lower number = more green" then we can use this for NI
      // right now it is the opposite.
      (elementInfo.heatmarker_performance_percent ?? 0) / 100
    );

    // console.log(elementInfo.heatmarker_performance_percent);
    // console.log(performanceColor);

    var shape = this.ElementArtwork.getD3ShapeCircle();
    if (elementInfo.heatmarker_room_mode_count > 1) {
      /*Multiple room modes = square */
      shape = this.ElementArtwork.getD3ShapeSquare();
    } else if (
      elementInfo.heatmarker_room_mode_is_cdiff === 1 &&
      elementInfo.heatmarker_room_mode_is_covid === 1
    ) {
      /* COVID-19 & C.Diff = Diamond */
      shape = this.ElementArtwork.getD3ShapeDiamond();
    } else if (elementInfo.heatmarker_room_mode_is_covid === 1) {
      /* COVID-19 = Star */
      shape = this.ElementArtwork.getD3ShapeStar();
    } else if (elementInfo.heatmarker_room_mode_is_cdiff === 1) {
      /* C.Diff = Triangle */
      shape = this.ElementArtwork.getD3ShapeTriangle();
    } else if (elementInfo.heatmarker_room_mode_is_isolation === 1) {
      /* is an isolation room mode = plus */
      shape = this.ElementArtwork.getD3ShapeCross();
    } else {
      /* Normal = circle */
    }

    // hard code scales instead of gets them from BE data so they can fit our CSS & card styles
    var heatMax = 3;
    var heatMin = 0.5;
    if (elementInfo.heatmarker_opportunities_total > 0) {
      var radius =
        ((elementInfo.heatmarker_opportunities_total /
          Math.max(elementInfo.heatmarker_max_heatmap_opportunities, 1)) *
          (heatMax - heatMin) +
          heatMin) *
        // lowered the number below to fit the new scale
        20;
      var symbolArtwork = this.ElementArtwork.drawSymbol(
        elementImageSel,
        shape,
        radius + (elementInfo.heatmarker_high_risk_alert_count > 0 ? 4 : 0),
        elementInfo.heatmarker_high_risk_alert_count > 0 ? 4 : 0,
        performanceColor,
        elementInfo.heatmarker_high_risk_alert_count > 0 ? "black" : "none",
        0,
        alphaHeatmap,
        heatmapScale
      );

      if (elementInfo.heatmarker_high_risk_alert_count > 0) {
        symbolArtwork.attr("filter", "url(#filter-glow-black)");
      }
    }
    elementImageSel.append("svg:title").text(function () {
      var hoverLabel =
        "Performance: " +
        (elementInfo.heatmarker_performance_percent === null
          ? "?"
          : Math.round(elementInfo.heatmarker_performance_percent) + "%") +
        "\nOpportunities: " +
        elementInfo.heatmarker_opportunities_total;
      if (elementInfo.heatmarker_room_mode_count === 1) {
        hoverLabel +=
          " \nRoom Mode: " + elementInfo.heatmarker_room_mode_name_list;
      } else if (elementInfo.heatmarker_room_mode_count > 1) {
        hoverLabel +=
          " \nRoom Modes: " + elementInfo.heatmarker_room_mode_name_list;
      }

      if (
        elementInfo.heatmarker_silenced_count === null &&
        elementInfo.heatmarker_opportunities_total !== null
      ) {
        hoverLabel += " \nMute Status: Unavailable for aggregated time periods";
      } else if (elementInfo.heatmarker_silenced_count > 1) {
        hoverLabel += " \nMute Status: Multiple";
      } else if (elementInfo.heatmarker_silenced === 1) {
        hoverLabel += " \nMute Status: Muted";
      } else if (elementInfo.heatmarker_silenced === 0) {
        hoverLabel += " \nMute Status: Unmuted";
      }
      if (elementInfo.heatmarker_occupation_time_percent !== null) {
        hoverLabel +=
          " \nOccupied: " +
          elementInfo.heatmarker_occupation_time_percent +
          "% (of this time period)";
      }
      if (elementInfo.heatmarker_suppression_time_percent > 0) {
        hoverLabel +=
          " \n\nDisabled: " +
          elementInfo.heatmarker_suppression_time_percent +
          "% (of this time period)";
      }

      if (elementInfo.heatmarker_high_risk_alert_count > 0) {
        hoverLabel +=
          " \n\nHigh-Risk Alerts: " +
          elementInfo.heatmarker_high_risk_alert_count +
          " (during this time period)";
      }

      return hoverLabel;
    });
    const displayName = this.chart.getRoomNumberFromString(
      elementInfo.name || "UNKNOWN"
    );
    var indicatorMarkers = [];
    if (elementInfo.heatmarker_silenced_count > 1) {
      indicatorMarkers.push("🔈");
    } else if (elementInfo.heatmarker_silenced === 1) {
      indicatorMarkers.push("🔇");
    }

    /* Show bust icon if occupied for at least 25% of the time period */
    if (elementInfo.heatmarker_occupation_time_percent > 25) {
      indicatorMarkers.push("👤");
    }

    /* Gray out if disabled at least 5% of time period */
    if (
      elementInfo.heatmarker_opportunities_total === 0 &&
      elementInfo.heatmarker_suppression_time_percent > 5
    ) {
      textColor = this.chart.constants.CHSHConstants.colors.gray;
    }
    this.ElementArtwork.drawText(
      elementImageSel,
      displayName,
      textColor,
      alphaHeatmapText,
      heatmapScale,
      18,
      0.2,
      textStrokeColor,
      800,
      ""
    );
    if (indicatorMarkers.length > 0) {
      this.ElementArtwork.drawText(
        elementImageSel,
        indicatorMarkers.join(""),
        textColor,
        alphaHeatmapText,
        heatmapScale,
        13,
        0.2,
        textStrokeColor,
        800,
        ""
      ).attr("transform", function () {
        return "translate(0," + 18 * heatmapScale + ")";
      });
    }
  }
}
