import useCasesModel from "../../model/Cases/useCasesModel";
import { transformFilters } from "../../transformers/utils";

const useCasesViewModel = () => {
  const { casesData } = useCasesModel() as any;

  const allCases = casesData.cases;

  const transformedCases = allCases.reduce(
    (result, item) => {
      if (item.subject_type === "Positive Staff") {
        result.positiveStaff.push(item);
      } else if (item.subject_type === "Positive Patient") {
        result.positivePatient.push(item);
      }
      return result;
    },
    {
      filters: transformFilters(casesData.params),
      excelUrl: casesData.export,
      positiveStaff: [],
      positivePatient: [],
    }
  );
  return {
    transformedCases,
    excelUrl: casesData.export,
    uuid: casesData?._report?.uuid,
  };
};

export default useCasesViewModel;
