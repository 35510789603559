import NavigationAssetTracking from "../Components/NavigationAssetTracking/NavigationAssetTracking";
import NavigationContactTracing from "../Components/NavigationContactTracing/NavigationContactTracing";
import NavigationHandHygiene from "../Components/NavigationHandHygiene/NavigationHandHygiene";
import NavigationNursingInsights from "../Components/NavigationNursingInsights/NavigationNursingInsights";

type Theme =
  | "asset-tracking"
  | "contact-tracing"
  | "nursing-insights"
  | "hand-hygiene";

const themeInfo = {
  "asset-tracking": {
    iconUrl: "/at_app.svg",
    subNavContent: <NavigationAssetTracking />,
    rangeColor: "#0f755f",
    loadSpinner: "#0f755f",
  },
  "contact-tracing": {
    iconUrl: "/ct-logo.svg",
    subNavContent: <NavigationContactTracing />,
    rangeColor: "#4887c9",
    loadSpinner: "#4887c9",
  },
  "nursing-insights": {
    iconUrl: "/ni-logo.svg",
    subNavContent: <NavigationNursingInsights />,
    rangeColor: "#c0418b",
    loadSpinner: "#c0418b",
  },
  "hand-hygiene": {
    iconUrl: "/hh-logo.svg",
    subNavContent: <NavigationHandHygiene />,
    rangeColor: "#623790",
    loadSpinner: "#623790",
  },
};

export function getValueFromTheme(theme: Theme) {
  return themeInfo[theme];
}
