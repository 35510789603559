import usePdfDownload from "../../Hooks/usePdfDownload";
import DownloadButton from "../DownloadButton/DownloadButton";
import {
  StaffOverviewStaffTableDTO,
  StaffOverviewSummaryDTO,
} from "../../model/types";
import {
  transformStaffOverviewPdfStaffTable,
  transformStaffOverviewPdfSummaryTable,
} from "../../transformers/visitOverview/transformStaffOverviewPdfTables";

export type StaffOverviewPdfDownloadButtonProps = {
  summary: StaffOverviewSummaryDTO;
  users: StaffOverviewStaffTableDTO;
};

function StaffOverviewPdfDownloadButton(
  props: StaffOverviewPdfDownloadButtonProps
) {
  const summaryTable = transformStaffOverviewPdfSummaryTable(props.summary);
  const staffTable = transformStaffOverviewPdfStaffTable(props.users);

  const { loading, downloadPdf } = usePdfDownload(
    [],
    [],
    [summaryTable, staffTable],
    "Visit-Overview-Staff-Report.pdf",
    "Visit Overview Staff PDF"
  );

  return (
    <>
      <DownloadButton cta="PDF" loading={loading} click={downloadPdf} />
    </>
  );
}

export default StaffOverviewPdfDownloadButton;
