import { getSigned } from "../../api/laravelApi";

export const SignedReportLoader = async (request, loader) => {
  const uuid = new URL(request.url).searchParams.get("uuid");
  const expires = new URL(request.url).searchParams.get("expires");
  const signature = new URL(request.url).searchParams.get("signature");

  if (uuid && signature) {
    const signedData = getSigned(uuid, expires, signature);
    return signedData;
  }

  const data = loader(new Request(request));
  return data;
};
