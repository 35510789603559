import { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

let initialTheme;

switch (true) {
  case window.location.pathname.includes("HH"):
    initialTheme = "hand-hygiene";
    break;
  case window.location.pathname.includes("AT"):
    initialTheme = "asset-tracking";
    break;
  case window.location.pathname.includes("NI"):
    initialTheme = "nursing-insights";
    break;
  case window.location.pathname.includes("CT"):
    initialTheme = "contact-tracing";
    break;
  default:
    initialTheme = "hand-hygiene";
}

const ModuleThemeContext = createContext({
  theme: initialTheme,
});

export function useModuleTheme() {
  return useContext(ModuleThemeContext);
}

export function ModuleThemeProvider({ children }) {
  const location = useLocation();

  const [theme, setTheme] = useState(initialTheme);

  useEffect(() => {
    if (window.location.pathname.includes("AT")) {
      setTheme("asset-tracking");
    } else if (window.location.pathname.includes("NI")) {
      setTheme("nursing-insights");
    } else if (window.location.pathname.includes("CT")) {
      setTheme("contact-tracing");
    } else {
      setTheme("hand-hygiene");
    }
  }, [location.pathname]);

  return (
    <ModuleThemeContext.Provider value={{ theme }}>
      {children}
    </ModuleThemeContext.Provider>
  );
}
