import { useLoaderData } from "react-router-dom";
import { RoundingDTO } from "../types";

const useRoundingModel = () => {
  const roundingData = useLoaderData() as RoundingDTO;

  return {
    roundingData: roundingData,
  };
};

export default useRoundingModel;
