import useExecutiveReportModel from "../../model/Executive/useExecutiveReportModel";
import { transformExecutiveReport } from "../../transformers/executiveReport/transformExecutiveReport";

const useExecutiveReportViewModel = () => {
  const { executiveReportData } = useExecutiveReportModel();

  const transformedExecutiveReportData =
    transformExecutiveReport(executiveReportData);

  return {
    transformedExecutiveReportData,
  };
};

export default useExecutiveReportViewModel;
