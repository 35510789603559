import { useEffect } from "react";
import useGroupReportViewModel from "../../viewmodel/Groups/useGroupReportViewModel";

import useSharedFunctions from "../sharedFunctions/useSharedFunctions";
import { formatPercentage } from "../../utils";

const useGroupReportViewController = () => {
  const { transformedGroupReportData } = useGroupReportViewModel();
  const {
    handleDateChange,
    startDate,
    endDate,
    aggregate,
    onFilterChange,
    filters,
    applyFilters,
    setSelected,
    setPeerFilters,
    toggleButton,
    bestDateAggregate,
    disableExcelDoc,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
  } = useSharedFunctions(transformedGroupReportData);

  useEffect(() => {
    transformedGroupReportData?.filters?.forEach((filter) => {
      setPeerFilters(filter.name, filter.peerFilters!);
      setSelected(filter.name, filter.selection);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pdfTables = [
    {
      head: [
        [
          "Total",
          "Performant",
          "Best " + bestDateAggregate,
          "Best Performance " + bestDateAggregate,
        ],
      ],
      body: [
        [
          transformedGroupReportData?.tiles?.total ?? "No Data",
          transformedGroupReportData?.tiles?.performant ?? "No Data",
          transformedGroupReportData?.bestTimePeriod?.date
            ? transformedGroupReportData?.bestTimePeriod?.date + ""
            : "No Data",
          transformedGroupReportData?.bestTimePeriod?.performance
            ? transformedGroupReportData?.bestTimePeriod?.performance + "%"
            : "No Data",
        ],
      ],
    },
    {
      head: [["Total Performance", "Percentage Changed"]],
      body: [
        [
          transformedGroupReportData?.tiles?.performance
            ? formatPercentage(transformedGroupReportData?.tiles?.performance) +
              "%"
            : "No Data",
          transformedGroupReportData?.tiles?.percentChangedFormatted ??
            "No Data",
        ],
      ],
    },
    {
      head: [
        ["Weekday", "Weekend", "Day Shift", "Night Shift", "Entry", "Exit"],
      ],
      body: [
        [
          transformedGroupReportData?.weekdayWeekendData?.weekday ?? "No Data",
          transformedGroupReportData?.weekdayWeekendData?.weekend ?? "No Data",
          ...(transformedGroupReportData?.shiftData
            ? Object.values(transformedGroupReportData?.shiftData).map(
                (value) => value ?? "No Data"
              )
            : ["No Data", "No Data"]),
          transformedGroupReportData?.entryExitData?.Entry ?? "No Data",
          transformedGroupReportData?.entryExitData?.Exit ?? "No Data",
        ],
      ],
    },

    {
      head: [["Unit Name", "Opportunities", "Performance"]],
      body: (transformedGroupReportData?.unitDataSeries &&
        transformedGroupReportData?.unitDataSeries?.map((item) => [
          item?.name ?? "No Data",
          item?.opportunities ?? "No Data",
          formatPercentage(item?.performance) + "%" ?? "No Data",
        ])) ?? [["No Data", "No Data", "No Data"]],
    },
    {
      head: [["Staff Name", "Group Name", "Opportunities", "Performance"]],
      body: (transformedGroupReportData?.unitDataSeries &&
        Object.values(transformedGroupReportData?.topPerformers).map((item) => [
          item?.name ?? "No Data",
          item?.group_name ?? "No Data",
          item?.total_opportunities ?? "No Data",
          formatPercentage(item?.performance) + "%" ?? "No Data",
        ])) ?? [["No Data", "No Data", "No Data"]],
    },
    {
      head: [["Staff Name", "Group Name", "Opportunities", "Performance"]],
      body:
        transformedGroupReportData?.unitDataSeries &&
        transformedGroupReportData?.userHistogramDrilldownData
          ? [
              "0-10",
              "10-20",
              "20-30",
              "30-40",
              "40-50",
              "50-60",
              "60-70",
              "70-80",
              "80-90",
              "90-100",
            ]
              .filter((rangeKey) =>
                Array.isArray(
                  transformedGroupReportData.userHistogramDrilldownData[
                    rangeKey
                  ]
                )
              )
              .flatMap((rangeKey) =>
                transformedGroupReportData.userHistogramDrilldownData[
                  rangeKey
                ].map((item) => [
                  item.name ?? "No Data",
                  item.group_name ?? "No Data",
                  item.total_opportunities ?? "No Data",
                  item.performance !== undefined
                    ? formatPercentage(item.performance) + "%"
                    : "No Data",
                ])
              )
          : [["No Data", "No Data", "No Data", "No Data"]],
    },
  ];

  return {
    pdfTables,
    transformedGroupReportData,
    aggregate,
    startDate,
    endDate,
    handleDateChange,
    filters,
    onFilterChange,
    applyFilters,
    toggleButton,
    bestDateAggregate,
    disableExcelDoc,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
  };
};

export default useGroupReportViewController;
