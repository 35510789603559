export default class BaseNodeMapElement {
  /* Gen the Unique Identifier for the layer - overwrite on child classes */
  get baseElementIdentifier() {
    return null;
  }

  /* Can we change visibility (hide) */
  get allowTogglingVisibility() {
    return true;
  }

  /* Get the Layer's Selector - overwrite on child classes */
  get sel() {
    return null;
  }

  /* Get if the layer should default as hidden */
  get defaultVisibilityHidden() {
    return false;
  }

  /* Is Layer Hidden? */
  get isHidden() {
    try {
      return (this.sel.style("display") ?? "block") === "none";
    } catch (e) {
      return this.shouldLoadHiddenAtFirst ?? false;
    }
  }

  /* Should the layer be hidden on first load */
  get shouldLoadHiddenAtFirst() {
    const identifier = this.baseElementIdentifier;
    if (typeof Storage !== "undefined") {
      const windowVal = window.sessionStorage.getItem(
        "nodeMapElementHide-" + identifier
      );
      if (windowVal !== null) {
        return windowVal === "1";
      }

      const localStoreVal = window.localStorage.getItem(
        "nodeMapElementHide-" + identifier
      );
      if (localStoreVal !== null) {
        return localStoreVal === "1";
      }
    }

    return this.defaultVisibilityHidden;
  }

  compareDeepEqual(x, y) {
    const _this = this;

    return x && y && typeof x === "object" && typeof y === "object"
      ? Object.keys(x).length === Object.keys(y).length &&
          Object.keys(x).reduce(function (isEqual, key) {
            //Skip comparing internal map attributes
            if (key === "internal_map_attributes") {
              return isEqual;
            }

            return isEqual && _this.compareDeepEqual(x[key], y[key]);
          }, true)
      : x === y;
  }

  /* Hide/Show a Layer */
  setHidden(bool) {
    const identifier = this.baseElementIdentifier;
    if (!this.allowTogglingVisibility) {
      return;
    }
    if (typeof Storage !== "undefined") {
      window.sessionStorage.setItem(
        "nodeMapElementHide-" + identifier,
        bool ? "1" : "0"
      );
      window.localStorage.setItem(
        "nodeMapElementHide-" + identifier,
        bool ? "1" : "0"
      );
    }
    this.sel.style("display", bool ? "none" : "inherit");
  }
}
