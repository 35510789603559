import { useLoaderData } from "react-router-dom";
import { rtibDTO } from "../types";

const useRtibModel = () => {
  const rtibData = useLoaderData() as rtibDTO;

  return {
    rtibData,
  };
};

export default useRtibModel;
