import { Option } from "../../../../src/types/types";
import Select from "react-select";

interface DropdownProps {
  options: Option[];
  onChange: (value: string) => void;
  title: string;
}

function Dropdown({ options, onChange, title }: DropdownProps) {
  const handleChange = (event) => {
    onChange(event.value);
  };

  const styles = {
    control: (base: any) => ({
      ...base,
      height: "100%",
      border: "#e1e4e9 1px solid",
      boxShadow: "1px 1px 1px 0 rgba(16, 24, 40, 0.06)",
      "&:hover": {
        border: "#6a41c6 1px solid 0.75",
        boxShadow: "0 0 0 3px #f8f5fe",
      },
      fontFamily: "Inter",
      fontSize: "14px",
    }),
    menu: (base: any) => ({
      ...base,
      border: "0px solid black",
      fontFamily: "Inter",
      fontSize: "14px",
    }),
    input: (base: any) => ({
      ...base,
      border: "0px solid black",
      fontFamily: "Inter",
      fontSize: "14px",
    }),
  };

  const formatOptions = options.map((option) => {
    return {
      value: option.value,
      label: option.label,
    };
  });

  return (
    <div className="dropdown">
      <div className="label-container">
        <label htmlFor={title}>{title}</label>
      </div>
      <Select
        inputId={title}
        styles={styles}
        options={formatOptions}
        onChange={handleChange}
        defaultValue={formatOptions[0]}
      ></Select>
    </div>
  );
}

export default Dropdown;
