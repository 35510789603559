export default function mockMyReportData() {
  return {
    _error: [],
    summaryBlocks: {
      _component_class: "SummaryTiles",
      tiles: [
        {
          _component_class: "PerformanceOverall",
          performance: 0.9637462235649547,
          subtitle: "In This Time Period",
          panelType: "info",
          panelWidth: 4,
          componentName: null,
          attributes: {},
          getPanelTypeFromPerformance: {},
          jsonSerialize: {},
          resolve: {},
          flushCache: {},
          forgetFactory: {},
          forgetComponentsResolver: {},
          resolveComponentsUsing: {},
        },
        {
          _component_class: "PerformanceChanged",
          icon: "arrow-up",
          percentChanged: 0.02344095532950019,
          percentChangedFormatted: "+2%",
          subtitle: "2/20/2023 to 5/3/2023",
          panelType: "success",
          panelWidth: 4,
          componentName: null,
          attributes: {},
          formatPercentChanged: {},
          formatSubtitle: {},
          setIcon: {},
          getPanelTypeFromPerformance: {},
          jsonSerialize: {},
          resolve: {},
          flushCache: {},
          forgetFactory: {},
          forgetComponentsResolver: {},
          resolveComponentsUsing: {},
        },
        {
          _component_class: "EventCounts",
          events: 2552,
          opportunities: 2648,
          panelType: "info",
          panelWidth: 4,
          componentName: null,
          attributes: {},
          getPanelTypeFromPerformance: {},
          jsonSerialize: {},
          resolve: {},
          flushCache: {},
          forgetFactory: {},
          forgetComponentsResolver: {},
          resolveComponentsUsing: {},
        },
      ],
      componentName: null,
      attributes: {},
      addSummaryTile: {},
      jsonSerialize: {},
      resolve: {},
      flushCache: {},
      forgetFactory: {},
      forgetComponentsResolver: {},
      resolveComponentsUsing: {},
    },
    handHygienePlot: {
      _graph_class: "PercentageLineGraph",
      chartId: "chart_4067571d3b",
      chartTestKey: "Mayla Gleason's Hand Hygiene Performance Over Time",
      chartType: "line",
      titleText: "Mayla Gleason's Hand Hygiene Performance Over Time",
      subtitle: null,
      xAxis: {
        _plot_component_class: "DateTimeAxis",
        type: "datetime",
        units:
          '{@JS}window.HighchartsHelper.getAxisDataIntervalUnits("weekly")',
        minorTickInterval:
          '{@JS}window.HighchartsHelper.getAxisDataIntervalMinorTickInterval("weekly")',
        title: {
          text: '{@JS}window.HighchartsHelper.getAxisDataIntervalLabel("weekly")',
          style: {
            fontSize: "15px",
          },
        },
        labels:
          "{@JS}{'formatter': function () { return Highcharts.dateFormat(window.HighchartsHelper.getAxisDataIntervalFormat(\"weekly\"), this.value);}}",
      },
      yAxis: {
        _plot_component_class: "PercentageAxis",
        title: {
          text: "Performance (%)",
          style: {
            fontSize: "15px",
          },
        },
        min: 0,
        max: 1,
        floor: 0,
        ceiling: 1,
        labels:
          "{@JS}{'formatter': function() { return (this.value * 100) + \" % \"; }}",
        tickPositions:
          "{@JS}window.HighchartsHelper.getTickPositions(1.0, true)",
      },
      xAxisPlotlines:
        '[{"series":{"name":"Phase Markers","data":[],"visible":true,"showInLegend":true,"legendIndex":-99,"type":"area","color":"#808080"}, "lines": []}]',
      yAxisPlotlines:
        '[{"series":{"name":"Hand Hygiene Goal","data":[],"visible":true,"showInLegend":true,"legendIndex":-99,"type":"area","color":"gold"}, "lines": [{"value":0.8,"label":{"align":"left","style":{"color":"grey"},"text":"<i>Goal:<\\/i> 80%","x":2,"y":15,"rotation":0},"zIndex":2.1,"color":"gold","dashStyle":null,"width":1.5}]}]',
      showYearBoundaries: true,
      showColorBands: true,
      tooltip: {
        _plot_component_class: "PercentageTooltip",
        pointFormat: "{@JS}window.HighchartsPointFormats.specifiedPercentage",
      },
      dataSeries: [
        {
          name: "Mayla Gleason",
          data: [
            {
              x: 1676851200000,
              y: 0.9597523219814241,
              percentage: 96,
              opportunities: 323,
              name: "February 20, 2023–February 26, 2023<br>Opportunities: 323",
            },
            {
              x: 1677456000000,
              y: 0.9414414414414415,
              percentage: 94,
              opportunities: 222,
              name: "February 27, 2023–March 5, 2023<br>Opportunities: 222",
            },
            {
              x: 1678060800000,
              y: 0.9580152671755725,
              percentage: 96,
              opportunities: 262,
              name: "March 6, 2023–March 12, 2023<br>Opportunities: 262",
            },
            {
              x: 1678665600000,
              y: 0.9209302325581395,
              percentage: 92,
              opportunities: 215,
              name: "March 13, 2023–March 19, 2023<br>Opportunities: 215",
            },
            {
              x: 1679270400000,
              y: 0.9691780821917808,
              percentage: 97,
              opportunities: 292,
              name: "March 20, 2023–March 26, 2023<br>Opportunities: 292",
            },
            {
              x: 1679875200000,
              y: 0.9830508474576272,
              percentage: 98,
              opportunities: 236,
              name: "March 27, 2023–April 2, 2023<br>Opportunities: 236",
            },
            {
              x: 1680480000000,
              y: 0.9442231075697212,
              percentage: 94,
              opportunities: 251,
              name: "April 3, 2023–April 9, 2023<br>Opportunities: 251",
            },
            {
              x: 1681084800000,
              y: 0.9891696750902527,
              percentage: 99,
              opportunities: 277,
              name: "April 10, 2023–April 16, 2023<br>Opportunities: 277",
            },
            {
              x: 1681689600000,
              y: 0.9745222929936306,
              percentage: 97,
              opportunities: 314,
              name: "April 17, 2023–April 23, 2023<br>Opportunities: 314",
            },
            {
              x: 1682294400000,
              y: 0.9854014598540146,
              percentage: 99,
              opportunities: 137,
              name: "April 24, 2023–April 30, 2023<br>Opportunities: 137",
            },
            {
              x: 1682899200000,
              y: 0.9831932773109243,
              percentage: 98,
              opportunities: 119,
              name: "May 1, 2023–May 3, 2023 (Partial Week)<br>Opportunities: 119",
            },
          ],
          showInLegend: true,
          visible: true,
          groupTag: "_chshReport_individualItems_users",
          legendType: "selectedIndividual",
        },
      ],
      plotOptions: null,
      helpText:
        "This graph shows Mayla Gleason's Hand Hygiene (HH) in percent.",
      extraJavascript: "",
      isExportable: true,
    },
  };
}
