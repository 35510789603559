import { convertNumToString } from "../../utils";
import Cards from "../UI/Cards/Cards";

import Performant from "@untitled-ui/icons-react/build/cjs/CheckCircleBroken";
import Total from "@untitled-ui/icons-react/build/cjs/HeartHand";
import MissingData from "../MissingData/MissingData";
import Tooltips from "../UI/Tooltip/Tooltip";
import { tooltipContent } from "../../content";

type MetricsCardProps = {
  performant: number | null;
  total: number | null;
};

function MetricsCard(props: MetricsCardProps) {
  const { performant, total } = props;

  let missingData = (
    <MissingData
      className="margin-top"
      message="No Data found."
      action="Try a different selection or date range."
    />
  );

  return (
    <>
      <Cards
        height="100%"
        headerLeft={<h2>Opportunities</h2>}
        headerRight={
          <Tooltips
            id="OpportunitiesTooltip"
            content={tooltipContent.opportunitiesMetrics["en-US"]}
          />
        }
        verticalAlign={true}
      >
        {!performant && !total && missingData}

        <div className="metrics-container">
          {performant && (
            <div className="performant-opportunities vertically-align">
              <div>
                <h2>Performant</h2>
              </div>

              {!performant ? (
                missingData
              ) : (
                <>
                  <div className="display-flex-row">
                    <div className="center-content metrics-svg">
                      <Performant />
                    </div>
                    <h1>{convertNumToString(performant)}</h1>
                  </div>
                </>
              )}
            </div>
          )}

          {total && (
            <div className="performant-opportunities vertically-align">
              <div>
                <h2>Total</h2>
              </div>

              {!total ? (
                missingData
              ) : (
                <>
                  <div className="display-flex-row">
                    {" "}
                    <div className="center-content metrics-svg">
                      <Total />
                    </div>
                    <h1>{convertNumToString(total)}</h1>{" "}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </Cards>
    </>
  );
}

export default MetricsCard;
