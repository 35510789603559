import { NavLink } from "react-router-dom";
import { useUserInfo } from "../../contexts/UserInfo/userInfo";
import Clipboard from "@untitled-ui/icons-react/build/cjs/Clipboard";
import ClipboardPlus from "@untitled-ui/icons-react/build/cjs/ClipboardPlus";

function NavigationContactTracing() {
  const { userInfo } = useUserInfo();

  // loading bug where pages stick to scroll state of pervious page.
  const scrollToTop = () => {
    document.querySelector("body")?.scrollTo(0, 0);
  };

  return (
    <>
      <div className="navigation-content">
        <div>
          {userInfo?.permissions?.includes("CT.case.create") && (
            // to do - add links
            <li>
              <NavLink to="/CT/new-case" onClick={scrollToTop}>
                <ClipboardPlus />
                New Case
              </NavLink>
            </li>
          )}
          {userInfo?.permissions?.includes("CT.case.view") && (
            <li>
              <NavLink to="/CT/cases" onClick={scrollToTop}>
                <Clipboard />
                Cases
              </NavLink>
            </li>
          )}
        </div>
      </div>
    </>
  );
}

export default NavigationContactTracing;
