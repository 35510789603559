import {
  line as d3Line,
  path as d3Path,
  symbol as d3Symbol,
  symbolCircle as d3SymbolCircle,
  symbolCross as d3SymbolCross,
  symbolDiamond as d3SymbolDiamond,
  symbolSquare as d3SymbolSquare,
  symbolStar as d3SymbolStar,
  symbolTriangle as d3SymbolTriangle,
  symbolWye as d3SymbolWye,
} from "d3";

export default class ElementArtworkHelper {
  public drawShape(
    sel: any,
    shape_path: any,
    stroke_width: number,
    body_color: string,
    stroke_color: string,
    angle: number,
    alpha: number,
    scale_factor: number,
    cx = 0,
    cy = 0,
    classes = ""
  ) {
    const line = sel
      .append("path")
      .attr("d", shape_path)
      .attr("fill", body_color)
      .attr("stroke", stroke_color)
      .attr("stroke-width", scale_factor * stroke_width)
      .attr(
        "transform",
        "rotate(" +
          angle +
          ") translate(" +
          cx * scale_factor +
          ", " +
          cy * scale_factor +
          ")"
      )
      .style("opacity", alpha);
    if (classes) {
      line.attr("class", classes);
    }

    return line;
  }

  public drawSymbol(
    sel: any,
    shape: any,
    radius: number,
    stroke_width: number,
    body_color: string,
    stroke_color: string,
    angle: number,
    alpha: number,
    scale_factor: number,
    cx = 0,
    cy = 0,
    classes = ""
  ) {
    const shape_object = this.getShapeObjectForD3Shape(shape).size(
      scale_factor * radius * 2 * (scale_factor * radius * 2)
    );

    return this.drawShape(
      sel,
      shape_object,
      stroke_width,
      body_color,
      stroke_color,
      angle,
      alpha,
      scale_factor,
      cx,
      cy,
      classes
    );
  }

  public drawCircle(
    sel: any,
    radius: number,
    stroke_width: number,
    body_color: string,
    stroke_color: string,
    angle: number,
    alpha: number,
    scale_factor: number,
    cx = 0,
    cy = 0,
    classes = ""
  ) {
    return this.drawSymbol(
      sel,
      this.getD3ShapeCircle(),
      radius,
      stroke_width,
      body_color,
      stroke_color,
      angle,
      alpha,
      scale_factor,
      cx,
      cy,
      classes
    );
  }

  public drawDiamond(
    sel: any,
    radius: number,
    stroke_width: number,
    body_color: string,
    stroke_color: string,
    angle: number,
    alpha: number,
    scale_factor: number,
    cx = 0,
    cy = 0,
    classes = ""
  ) {
    return this.drawSymbol(
      sel,
      this.getD3ShapeDiamond(),
      radius,
      stroke_width,
      body_color,
      stroke_color,
      angle,
      alpha,
      scale_factor,
      cx,
      cy,
      classes
    );
  }

  public drawSquare(
    sel: any,
    radius: number,
    stroke_width: number,
    body_color: string,
    stroke_color: string,
    angle: number,
    alpha: number,
    scale_factor: number,
    cx = 0,
    cy = 0,
    classes = ""
  ) {
    return this.drawSymbol(
      sel,
      this.getD3ShapeSquare(),
      radius,
      stroke_width,
      body_color,
      stroke_color,
      angle,
      alpha,
      scale_factor,
      cx,
      cy,
      classes
    );
  }

  public drawRectangle(
    sel: any,
    height: number,
    width: number,
    radius: number,
    stroke_width: number,
    body_color: string,
    stroke_color: string,
    angle: number,
    alpha: number,
    scale_factor: number,
    cx: number = 0,
    cy: number = 0,
    classes: string = ""
  ) {
    return this.drawShape(
      sel,
      this.getShapeRectangle(height, width, radius),
      stroke_width,
      body_color,
      stroke_color,
      angle,
      alpha,
      scale_factor,
      cx,
      cy,
      classes
    );
  }

  public drawCross(
    sel: any,
    radius: number,
    stroke_width: number,
    body_color: string,
    stroke_color: string,
    angle: number,
    alpha: number,
    scale_factor: number,
    cx = 0,
    cy = 0,
    classes = ""
  ) {
    return this.drawSymbol(
      sel,
      this.getD3ShapeCross(),
      radius,
      stroke_width,
      body_color,
      stroke_color,
      angle,
      alpha,
      scale_factor,
      cx,
      cy,
      classes
    );
  }

  public drawStar(
    sel: any,
    radius: number,
    stroke_width: number,
    body_color: string,
    stroke_color: string,
    angle: number,
    alpha: number,
    scale_factor: number,
    cx = 0,
    cy = 0,
    classes = ""
  ) {
    return this.drawSymbol(
      sel,
      this.getD3ShapeStar(),
      radius,
      stroke_width,
      body_color,
      stroke_color,
      angle,
      alpha,
      scale_factor,
      cx,
      cy,
      classes
    );
  }

  public drawWye(
    sel: any,
    radius: number,
    stroke_width: number,
    body_color: string,
    stroke_color: string,
    angle: number,
    alpha: number,
    scale_factor: number,
    cx = 0,
    cy = 0,
    classes = ""
  ) {
    return this.drawSymbol(
      sel,
      this.getD3ShapeWye(),
      radius,
      stroke_width,
      body_color,
      stroke_color,
      angle,
      alpha,
      scale_factor,
      cx,
      cy,
      classes
    );
  }

  public drawTriangle(
    sel: any,
    radius: number,
    stroke_width: number,
    body_color: string,
    stroke_color: string,
    angle: number,
    alpha: number,
    scale_factor: number,
    cx = 0,
    cy = 0,
    classes = ""
  ) {
    return this.drawSymbol(
      sel,
      this.getD3ShapeTriangle(),
      radius,
      stroke_width,
      body_color,
      stroke_color,
      angle,
      alpha,
      scale_factor,
      cx,
      cy,
      classes
    );
  }

  public getShapeRectangle(height: number, width: number, radius: number) {
    const h = height / 2;
    const w = width / 2;

    const p = d3Path();
    p.moveTo(0, -h);
    p.arcTo(-w, -h, -w, 0, radius);
    p.arcTo(-w, h, 0, h, radius);
    p.arcTo(w, h, w, 0, radius);
    p.arcTo(w, -h, 0, -h, radius);

    p.closePath();

    return p;
  }

  public getShapeObjectForD3Shape(aShape: any) {
    return d3Symbol().type(aShape);
  }

  public getD3ShapeCircle() {
    return d3SymbolCircle;
  }

  public getD3ShapeDiamond() {
    return d3SymbolDiamond;
  }

  public getD3ShapeSquare() {
    return d3SymbolSquare;
  }

  public getD3ShapeCross() {
    return d3SymbolCross;
  }

  public getD3ShapeWye() {
    return d3SymbolWye;
  }

  public getD3ShapeStar() {
    return d3SymbolStar;
  }

  public getD3ShapeTriangle() {
    return d3SymbolTriangle;
  }

  public drawCrosshair(
    sel: any,
    radius: number,
    stroke_width: number,
    stroke_color: string,
    angle: string,
    alpha: number,
    scale_factor: number,
    classes = ""
  ): any {
    var top = [0, -radius * scale_factor];
    var bottom = [0, radius * scale_factor];
    var left = [-radius * scale_factor, 0];
    var right = [radius * scale_factor, 0];
    var crosshairSel = sel
      .append("path")
      .attr("d", "M" + left + "L" + right + "M" + top + "L" + bottom)
      .attr("stroke-width", stroke_width * scale_factor)
      .attr("stroke", stroke_color)
      .attr("transform", "rotate(" + angle + ")")
      .style("cursor", "move")
      .style("opacity", alpha);
    if (classes) {
      crosshairSel.attr("class", classes);
    }

    return crosshairSel;
  }

  public drawProx(
    sel: any,
    width: number,
    height: number,
    fill_color: string,
    line_color: string,
    alpha: number,
    scale_factor: number,
    classes = ""
  ): any {
    var stroke_width = 2;
    var lineData = [
      {
        x: -width / 2,
        y: 2,
      },
      {
        x: width / 2,
        y: -height / 2,
      },
      {
        x: width / 2,
        y: height / 2,
      },
      {
        x: -width / 2,
        y: -2,
      },
      {
        x: -width / 2,
        y: 2,
      },
      {
        x: width / 2,
        y: -height / 2,
      },
    ];

    return this.drawLinearPath(
      sel,
      lineData,
      stroke_width,
      fill_color,
      line_color,
      0,
      alpha,
      scale_factor,
      classes
    );
  }

  // scale_factor only impacts stroke-width
  public drawAbsoluteLine(
    sel: any,
    start_x: number,
    start_y: number,
    end_x: number,
    end_y: number,
    stroke_width: number,
    line_color: any,
    alpha: number,
    scale_factor: number,
    classes = ""
  ): any {
    var points = [
      [start_x, start_y],
      [end_x, end_y],
    ];
    var lineGenerator = d3Line();
    var pathData = lineGenerator(points);

    var pathSel = sel
      .append("path")
      .attr("d", pathData)
      .attr("stroke-width", scale_factor * stroke_width)
      .attr("stroke", line_color)
      .style("opacity", alpha);
    if (classes) {
      pathSel.attr("class", classes);
    }

    return pathSel;
  }

  public drawLinearPath(
    sel: any,
    lineData: { x: number; y: number }[],
    stroke_width: number,
    fill_color: string,
    line_color: string,
    angle: number,
    alpha: number,
    scale_factor: number,
    classes = ""
  ): any {
    var lineFunction = d3Line()
      .x(function (d: [number, number]) {
        return d[0] * scale_factor;
      })
      .y(function (d: [number, number]) {
        return d[1] * scale_factor;
      });

    var pathSel = sel
      .append("path")
      .attr(
        "d",
        lineFunction(
          lineData.map(function (item) {
            return [item.x, item.y];
          })
        )
      )
      .attr("fill", fill_color)
      .attr("stroke-width", stroke_width * scale_factor)
      .attr("stroke", line_color)
      .attr("transform", "rotate(" + angle + ")")
      .style("opacity", alpha);
    if (classes) {
      pathSel.attr("class", classes);
    }

    return pathSel;
  }

  public drawText(
    sel: any,
    t: string,
    text_color: string,
    alpha: number,
    scale_factor: number,
    font_size = 18,
    stroke_width = 0,
    stroke_color = "transparent",
    font_weight: number | string = "normal",
    classes: string = ""
  ): any {
    var textSel = sel
      .append("text")
      .text(t)
      .attr("class", "text-unselectable")
      .attr("text-anchor", "middle")
      .attr("dy", font_size * scale_factor * 0.375 + "px")
      .attr("line-height", font_size * scale_factor + "px")
      // .attr("dominant-baseline", "middle")
      // .attr("alignment-baseline", "middle")
      .attr("stroke-width", stroke_width * scale_factor)
      .attr("stroke", stroke_color)
      .attr("fill", text_color)
      .style("font-weight", font_weight)
      .style("font-size", font_size * scale_factor + "px")
      .style("text-rendering", "optimizeLegibility")
      .style("opacity", alpha);

    if (classes) {
      textSel.attr("class", classes);
    }

    return textSel;
  }
}
