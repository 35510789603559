import { useEffect, useState } from "react";
import LeftNavigation from "../LeftNavigation/LeftNavigation";
import TopNavigation from "../TopNavigation/TopNavigation";
import { useModuleTheme } from "../../contexts/ModuleTheme/moduleThemes";
import { useLocation } from "react-router-dom";

function TopAndLeftNavigation() {
  const [isNavOpen, setIsNavOpen] = useState(true);

  const location = useLocation();

  useEffect(() => {
    setIsNavOpen(false);
  }, [location, setIsNavOpen]);

  const { theme } = useModuleTheme();

  const moveToTop = () => {
    setIsNavOpen(!isNavOpen);
    document.querySelector("body")?.scrollTo(0, 0);
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <>
      {/* button value must be a string so we need to convert bool to string */}
      <TopNavigation
        displayHamburgerButton={true}
        navigation={isNavOpen.toString()}
        onClick={moveToTop}
      />
      <div
        className={`side-nav ${isNavOpen ? "open-navigation " : ""}` + theme}
      >
        <LeftNavigation toggleNav={toggleNav} />
      </div>
    </>
  );
}

export default TopAndLeftNavigation;
