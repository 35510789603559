import { createContext, useContext, useState } from "react";

export type UserInfo = {
  modules?: Object;
  permissions: string[];
  tokens: { csrf: string };
  user: {
    lastName: string;
    firstName: string;
    id: number;
    hierarchy: {
      facility_id: number;
    };
  };
};

type UserInfoContextType = {
  updateUserInfo: (userInfo: UserInfo) => void;
  userInfo: UserInfo;
};

const UserInfoContext = createContext({} as UserInfoContextType);

// create custom hook to check whether we're inside a provider
export const useUserInfo = () => {
  const contextValue = useContext(UserInfoContext);

  if (!contextValue) {
    throw new Error("useUserInfo must be used within an UserInfoProvider");
  }

  return contextValue;
};

export function UserInfoProvider(props) {
  const [userInfo, setUserInfo] = useState({});

  function updateUserInfo(userInfo: UserInfo) {
    setUserInfo(userInfo);
  }

  const value = { updateUserInfo, userInfo };

  return <UserInfoContext.Provider value={value} {...props} />;
}
