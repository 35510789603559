import Alert from "@untitled-ui/icons-react/build/cjs/AlertTriangle";

type MissingDataProps = {
  className?: string;
  message?: string;
  action?: string;
};

function MissingData(props: MissingDataProps) {
  return (
    <>
      <div className={props.className}>
        <div className="warning-icon">
          <Alert />
        </div>
        <h2>{props.message}</h2>
      </div>
      <p className="reduce-margin">{props.action}</p>
    </>
  );
}

export default MissingData;
