import { formatPercentage } from "../../utils";
import useMyReportViewModel from "../../viewmodel/MyReport/useMyReportViewModel";

const useMyReportViewController = () => {
  const { transformedMyReportData } = useMyReportViewModel();

  const pdfTables = [
    {
      head: [["Total Performance", "Percentage Changed"]],
      body: [
        [
          transformedMyReportData?.tiles?.performance
            ? formatPercentage(transformedMyReportData?.tiles?.performance) +
              "%"
            : "No Data",
          transformedMyReportData?.tiles?.percentChangedFormatted || "No Data",
        ],
      ],
    },
    {
      head: [["Total", "Performant"]],
      body: [
        [
          transformedMyReportData?.tiles?.totalOpportunities || "No Data",
          transformedMyReportData?.tiles?.totalHygieneEvents || "No Data",
        ],
      ],
    },
  ];

  return {
    transformedMyReportData,
    pdfTables,
  };
};

export default useMyReportViewController;
