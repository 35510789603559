import { useLoaderData } from "react-router-dom";

const useCasesModel = () => {
  const casesData = useLoaderData();

  return {
    casesData,
  };
};

export default useCasesModel;
