import { NavLink } from "react-router-dom";
import { useUserInfo } from "../../contexts/UserInfo/userInfo";

// icons
import Clock from "@untitled-ui/icons-react/build/cjs/Clock";
import Executive from "@untitled-ui/icons-react/build/cjs/Briefcase01";
import Groups from "@untitled-ui/icons-react/build/cjs/Users01";
import Units from "@untitled-ui/icons-react/build/cjs/MedicalCross";
import Staff from "@untitled-ui/icons-react/build/cjs/ActivityHeart";
import MyReport from "@untitled-ui/icons-react/build/cjs/UserSquare";
import HourGlass from "@untitled-ui/icons-react/build/cjs/Hourglass01";
import Award from "@untitled-ui/icons-react/build/cjs/Award01";
import Trophy from "@untitled-ui/icons-react/build/cjs/Trophy01";

function NavigationHandHygiene() {
  const { userInfo } = useUserInfo();
  // loading bug where pages stick to scroll state of pervious page.
  const scrollToTop = () => {
    document.querySelector("body")?.scrollTo(0, 0);
  };

  return (
    <>
      <div className="navigation-content">
        <div>
          {userInfo?.permissions?.includes("HH.dashboard.executive") && (
            <li>
              <NavLink to="/HH/executive" onClick={scrollToTop}>
                <Executive />
                Executive
              </NavLink>
            </li>
          )}
          {userInfo?.permissions?.includes("HH.report.group") && (
            <li>
              <NavLink to="/HH/groups" onClick={scrollToTop}>
                <Groups />
                Groups
              </NavLink>
            </li>
          )}
          {userInfo?.permissions?.includes("HH.report.unit") && (
            <li>
              <NavLink to="/HH/units" onClick={scrollToTop}>
                <Units />
                Units
              </NavLink>
            </li>
          )}
          {userInfo?.permissions?.includes("HH.report.staff") && (
            <li>
              <NavLink to="/HH/staff" onClick={scrollToTop}>
                <Staff />
                Staff
              </NavLink>
            </li>
          )}
          {userInfo?.permissions?.includes("HH.report.me") && (
            <li>
              <NavLink to="/HH/my-report" onClick={scrollToTop}>
                <MyReport />
                My Report
              </NavLink>
            </li>
          )}
          {userInfo?.permissions?.includes("HH.report.blueprint.realTime") && (
            <li>
              <NavLink to="/HH/rtib">
                <Clock />
                RTIB
              </NavLink>
            </li>
          )}
          {userInfo?.permissions?.includes("HH.report.blueprint.overTime") && (
            <li>
              <NavLink to="/HH/otib">
                <HourGlass />
                OTIB
              </NavLink>
            </li>
          )}
          {userInfo?.permissions?.includes("report.individualCompetition") && (
            <li>
              <NavLink
                to={`${process.env.REACT_APP_AUTH_DOMAIN}/report/individualCompetition`}
              >
                <Award />
                Individual Competitions
              </NavLink>
            </li>
          )}
          {userInfo?.permissions?.includes("league.view") && (
            <li>
              <NavLink
                to={`${process.env.REACT_APP_AUTH_DOMAIN}/report/teamCompetition`}
              >
                <Trophy />
                Team Competitions
              </NavLink>
            </li>
          )}
        </div>
      </div>
    </>
  );
}

export default NavigationHandHygiene;
