import { missingDataAction, missingDataMessage } from "../../constants";
import { tooltipContent } from "../../content";
import BarChart from "../BarChart/BarChart";
import { histogramOptions } from "../BarChart/HistogramOptions";
import MissingData from "../MissingData/MissingData";
import Cards from "../UI/Cards/Cards";
import Tooltips from "../UI/Tooltip/Tooltip";
import Table from "../Table/Table";
import LinearProgress from "../UI/LinearProgress/LinearProgress";
import TabsList from "../UI/Tabs/Tabs";

import TableIcon from "@untitled-ui/icons-react/build/cjs/LayoutAlt03";
import BarGraph from "@untitled-ui/icons-react/build/cjs/BarChart07";
import { SetStateAction, useState } from "react";
import { performanceRanges } from "../../constants";

type HistogramCardProps = {
  data: Object;
  tableData: Object;
  title: string;
  id?: string;
};

function HistogramCard(props: HistogramCardProps) {
  const [openIndex, setOpenIndex] = useState(0);

  if (!props.data) return null;
  const histogramData = Object.entries(props.data);

  const handleButtonClick = (index: SetStateAction<number>) => {
    setOpenIndex(index === openIndex ? 0 : index);
  };

  function getPerformanceRangesForData(data): string[] {
    if (!data) return [];
    return Object.values(data).map((item: any) => {
      const xValue = item.x as number;
      const range = performanceRanges.find((r) => r.x === xValue);
      return range ? range.label : "";
    });
  }

  const dataResultFormattedWithRanges = getPerformanceRangesForData(props.data);

  const columns = [
    {
      name: "name",
      label: "Staff Name",
    },
    {
      name: "group_name",
      label: "Group Name",
    },
    {
      name: "total_opportunities",
      label: "Opportunities",
    },
    {
      name: "performance",
      label: "Performance",
      options: {
        customBodyRender: (value: number) => {
          return (
            <LinearProgress
              percentage={Math.round(value * 100)}
              data-testid="linear-progress"
            ></LinearProgress>
          );
        },
      },
    },
  ];

  const options = {
    sortOrder: {
      name: "performance",
      direction: "desc",
    },
    textLabels: {
      body: {
        noMatch: <MissingData message={"No providers for this range."} />,
      },
    },
  };

  return (
    <div className="negative-margin-top">
      <TabsList
        forceRenderTabPanel={true}
        tabs={["Histogram Of Providers", "Providers at Percentage"]}
        icons={[
          <BarGraph className="tab-icon" />,
          <TableIcon className="tab-icon" />,
        ]}
        content={[
          <Cards
            headerLeft={<h2 className="margin-bottom">{props.title}</h2>}
            headerRight={
              <Tooltips
                id="histogram-graph"
                content={tooltipContent.histogram["en-US"]}
              />
            }
          >
            {histogramData.length === 0 ? (
              <MissingData
                message={missingDataMessage}
                action={missingDataAction}
              />
            ) : (
              <div id={props.id}>
                <BarChart
                  title={"Histogram Of Providers"}
                  options={histogramOptions}
                  style={{ height: "500px" }}
                  data={{
                    datasets: [
                      {
                        data: props.data || [],
                        backgroundColor: "rgba(106, 65, 198, 0.70)",
                        borderColor: "rgba(106, 65, 198, 1)",
                        borderWidth: 2,
                        borderRadius: 5,
                        categoryPercentage: 0.99,
                        barPercentage: 1,
                      },
                    ],
                  }}
                />
              </div>
            )}
          </Cards>,
          <div>
            {" "}
            <div className="margin-bottom">
              {dataResultFormattedWithRanges?.map(
                (dataRanges: string, index: number) => (
                  <>
                    {" "}
                    <button
                      data-testid={"legend-button-" + index}
                      onClick={() => handleButtonClick(index)}
                      className={
                        openIndex === index ? "active-legend-button" : ""
                      }
                      key={index}
                    >
                      {dataRanges}
                    </button>
                  </>
                )
              )}
            </div>
            {dataResultFormattedWithRanges?.map((_item, index: number) => (
              <>
                {openIndex === index && (
                  <div key={index}>
                    {
                      <Table
                        title={
                          "Providers at Percentage: " +
                          dataResultFormattedWithRanges[index]
                        }
                        columns={columns}
                        data={Object.values(props.tableData)[index] as any[]}
                        optionOverrides={options}
                      ></Table>
                    }
                  </div>
                )}
              </>
            ))}
          </div>,
        ]}
      />
    </div>
  );
}

export default HistogramCard;
