import { Tooltip } from "react-tooltip";

import InfoCircle from "@untitled-ui/icons-react/build/cjs/InfoCircle";

import "react-tooltip/dist/react-tooltip.css";

type TooltipProps = {
  content: string;
  id: string;
};

function Tooltips(props: TooltipProps) {
  return (
    <>
      <span id={props.id} data-testid="tooltip-selector">
        <InfoCircle id={props.id} className="grey opaque" />
      </span>
      <Tooltip place="bottom" content={props.content} anchorId={props.id} />
    </>
  );
}

export default Tooltips;
