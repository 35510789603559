import { useLoaderData } from "react-router-dom";
import { CaseOptionsDTO } from "../types";

const useCaseOptionsModel = () => {
  const caseOptionsData = useLoaderData() as CaseOptionsDTO;

  return {
    caseOptionsData,
  };
};

export default useCaseOptionsModel;
