//Imports
import LegendRenderer from "../shared/LegendRenderer";
import BaseNodeMapElement from "./BaseNodeMapElement";

export default class NodeMapLegend extends BaseNodeMapElement {
  constructor(nodeMap) {
    super();
    // console.log(nodeMap.constants.CHSHConstants.colors.primarySS);
    this.legendRenderer = new LegendRenderer(
      nodeMap.constants.CHSHConstants.colors.primarySS
    );
    this.nodeMap = nodeMap;

    if (this.shouldLoadHiddenAtFirst) {
      this.setHidden(true);
    }
  }

  /* Gen the Unique Identifier for the layer */
  get baseElementIdentifier() {
    return "legend";
  }

  /* Get the Layer's Selector */
  get sel() {
    return this.legendRenderer.container;
  }

  draw() {
    this.legendRenderer.draw(() => {
      if (this.isLayerVisible("heatmap")) {
        this.drawHeatmapLegendSection();
      }

      if (this.isLayerVisible("comment")) {
        this.drawCommentLegendSection();
      }

      if (
        this.isLayerVisible("gateway") ||
        this.isLayerVisible("router") ||
        this.isLayerVisible("sensorNetworkParent") ||
        this.isLayerVisible("routerNetworkParent")
      ) {
        this.drawNetworkLegendSection();
      }

      if (this.isLayerVisible("room")) {
        this.drawRoomLegendSection();
      }

      if (
        this.isLayerVisible("sensor") ||
        this.isLayerVisible("sensorBatteryList")
      ) {
        this.drawSensorLegendSection();
      }

      if (
        this.isLayerVisible("sensorProblemList") ||
        this.isLayerVisible("routerProblemList") ||
        this.isLayerVisible("gatewayProblemList") ||
        this.isLayerVisible("roomProblemList") ||
        this.isLayerVisible("sensorOfflineList") ||
        this.isLayerVisible("routerOfflineList") ||
        this.isLayerVisible("gatewayOfflineList") ||
        this.isLayerVisible("sensorNetworkParent") ||
        this.isLayerVisible("routerNetworkParent")
      ) {
        this.drawProblemsLegendSection();
      }

      if (this.isLayerVisible("sensorIgnorePair")) {
        this.drawSensorIgnorePairLegendSection();
      }

      if (this.isLayerVisible("roomIgnorePair")) {
        this.drawRoomIgnorePairLegendSection();
      }
    });
  }

  isLayerVisible(layerName) {
    if (!(layerName in this.nodeMap.layers)) {
      return false;
    }

    return !this.nodeMap.layers[layerName].isHidden;
  }

  drawSensorLegendSection() {
    const wrapper = this.legendRenderer.addLegendSection();
    const constants = this.nodeMap.constants;
    let yPosition = 0;

    wrapper
      .append("text")
      .attr("class", "legendTitle")
      .attr("y", yPosition)
      .style("fill", constants.CHSHConstants.colors.primarySS)
      .text("Sensors");
    yPosition += 20;

    if (this.isLayerVisible("sensor")) {
      // hallway

      let color = constants.sensor_marker_details.inRoom.sensor_line_color;
      this.legendRenderer.drawSquare(
        wrapper,
        color,
        "stroke",
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawLabel(wrapper, "In-Room", 0, yPosition);
      yPosition += 20;

      // in room
      color = constants.sensor_marker_details.hallway.sensor_line_color;
      this.legendRenderer.drawSquare(
        wrapper,
        color,
        "stroke",
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawLabel(wrapper, "Hallway", 0, yPosition);
      yPosition += 20;

      // soap

      color = constants.sensor_marker_details.soap.sensor_text_color;
      this.legendRenderer.drawText(
        wrapper,
        color,
        "S",
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawLabel(wrapper, "Soap Dispenser", 0, yPosition);
      yPosition += 20;

      // Sanitizer

      color = constants.sensor_marker_details.alcohol.sensor_text_color;
      this.legendRenderer.drawText(
        wrapper,
        color,
        "A",
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawLabel(
        wrapper,
        "Sanitizer Dispenser",
        0,
        yPosition
      );
      yPosition += 20;

      // Over Door
      color = constants.sensor_marker_details.agnostic.sensor_text_color;
      this.legendRenderer.drawText(
        wrapper,
        color,
        "OD",
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawLabel(wrapper, "Doorway Sensor", 0, yPosition);
      yPosition += 20;
    }

    if (this.isLayerVisible("sensorBatteryList")) {
      wrapper
        .append("text")
        .attr("class", "label")
        .attr("y", yPosition + 5)
        .style("fill", constants.CHSHConstants.colors.primarySS)
        .text("Battery List:");
      yPosition += 20;

      let color = constants.sensor_battery_list_detail.circle_changed;
      this.legendRenderer.drawCircle(
        wrapper,
        color,
        "fill",
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawLabel(wrapper, "Already Changed", 0, yPosition);
      yPosition += 20;

      color = constants.sensor_battery_list_detail.circle_unchanged;
      this.legendRenderer.drawCircle(
        wrapper,
        color,
        "fill",
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawLabel(wrapper, "Not Yet Changed", 0, yPosition);
      yPosition += 20;
    }
  }

  drawCommentLegendSection() {
    const wrapper = this.legendRenderer.addLegendSection();
    const constants = this.nodeMap.constants;
    let yPosition = 0;

    wrapper
      .append("text")
      .attr("class", "legendTitle")
      .attr("y", yPosition)
      .style("fill", constants.CHSHConstants.colors.primarySS)
      .text("Comments");
    yPosition += 20;

    // Notes

    const okayColor = constants.comment_marker_details.text_color_note;
    this.legendRenderer.drawText(
      wrapper,
      okayColor,
      "ABC",
      this.legendRenderer.leftOffset,
      yPosition
    );
    this.legendRenderer.drawLabel(wrapper, "Note/Not an Issue", 0, yPosition);
    yPosition += 20;

    // Problems

    const problemColor = constants.comment_marker_details.text_color_problem;
    this.legendRenderer.drawText(
      wrapper,
      problemColor,
      "ABC",
      this.legendRenderer.leftOffset,
      yPosition
    );
    this.legendRenderer.drawLabel(wrapper, "Problem/Issue", 0, yPosition);
    yPosition += 20;
  }

  drawProblemsLegendSection() {
    const wrapper = this.legendRenderer.addLegendSection();
    const constants = this.nodeMap.constants;
    let yPosition = 0;

    wrapper
      .append("text")
      .attr("class", "legendTitle")
      .attr("y", yPosition)
      .style("fill", constants.CHSHConstants.colors.primarySS)
      .text("Issues");
    yPosition += 20;

    if (
      this.isLayerVisible("sensorNetworkParent") ||
      this.isLayerVisible("routerNetworkParent")
    ) {
      // Incorrect Gateway Device
      const coordinatorDisplayName =
        this.nodeMap.getLayerDisplayName("gateway");
      const color = constants.network_parent_line_detail.bad_color;
      this.legendRenderer.drawCircle(
        wrapper,
        color,
        "fill",
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawLabel(
        wrapper,
        "Incorrect " + coordinatorDisplayName + " Device",
        0,
        yPosition
      );
      yPosition += 20;
    }

    if (
      this.isLayerVisible("sensorOfflineList") ||
      this.isLayerVisible("routerOfflineList") ||
      this.isLayerVisible("gatewayOfflineList") ||
      this.isLayerVisible("sensorNetworkParent") ||
      this.isLayerVisible("routerNetworkParent")
    ) {
      // Offline Devices
      const color = constants.offline_list_detail.circle_gateway;
      this.legendRenderer.drawCircle(
        wrapper,
        color,
        "fill",
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawLabel(wrapper, "Offline Device", 0, yPosition);
      yPosition += 20;
    }

    if (
      this.isLayerVisible("sensorProblemList") ||
      this.isLayerVisible("routerProblemList") ||
      this.isLayerVisible("gatewayProblemList")
    ) {
      // Problematic Room
      const color = constants.problem_list_detail.circle_sensor;
      this.legendRenderer.drawCircle(
        wrapper,
        color,
        "fill",
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawLabel(
        wrapper,
        "Problematic Device",
        0,
        yPosition
      );
      yPosition += 20;
    }

    if (this.isLayerVisible("roomProblemList")) {
      // Problematic Device
      const color = constants.problem_list_detail.circle_sensor;
      this.legendRenderer.drawCircle(
        wrapper,
        color,
        "fill",
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawLabel(wrapper, "Problematic Room", 0, yPosition);
      yPosition += 20;
    }
  }

  drawRoomLegendSection() {
    const wrapper = this.legendRenderer.addLegendSection();
    const constants = this.nodeMap.constants;
    let yPosition = 0;

    wrapper
      .append("text")
      .attr("class", "legendTitle")
      .attr("y", yPosition)
      .style("fill", constants.CHSHConstants.colors.primarySS)
      .text("Rooms");
    yPosition += 20;

    // Current rooms

    const okayColor = constants.room_marker_details.text_color;
    this.legendRenderer.drawText(
      wrapper,
      okayColor,
      "123",
      this.legendRenderer.leftOffset,
      yPosition
    );
    this.legendRenderer.drawLabel(wrapper, "Room", 0, yPosition);
    yPosition += 20;

    // Uninstalled rooms

    const uninstalledColor =
      constants.room_marker_details.text_color_uninstalled;
    this.legendRenderer.drawText(
      wrapper,
      uninstalledColor,
      "123",
      this.legendRenderer.leftOffset,
      yPosition
    );
    this.legendRenderer.drawLabel(wrapper, "Uninstalled Room", 0, yPosition);
    yPosition += 20;
  }

  drawNetworkLegendSection() {
    const wrapper = this.legendRenderer.addLegendSection();
    const constants = this.nodeMap.constants;
    let yPosition = 0;

    wrapper
      .append("text")
      .attr("class", "legendTitle")
      .attr("y", yPosition)
      .style("fill", constants.CHSHConstants.colors.primarySS)
      .text("Network Devices");
    yPosition += 20;

    // Coordinators
    if (this.isLayerVisible("gateway")) {
      const coordinatorDisplayName =
        this.nodeMap.getLayerDisplayName("gateway");
      this.legendRenderer.drawSquare(
        wrapper,
        constants.gateway_marker_details.body_color,
        "fill",
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawText(
        wrapper,
        constants.gateway_marker_details.text_color,
        coordinatorDisplayName[0],
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawLabel(
        wrapper,
        coordinatorDisplayName,
        0,
        yPosition
      );
      yPosition += 20;
    }

    // Extenders
    if (this.isLayerVisible("router")) {
      const routerDisplayName = this.nodeMap.getLayerDisplayName("router");
      this.legendRenderer.drawSquare(
        wrapper,
        constants.router_marker_details.body_color,
        "fill",
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawText(
        wrapper,
        constants.router_marker_details.text_color,
        routerDisplayName[0],
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawLabel(wrapper, routerDisplayName, 0, yPosition);
      yPosition += 20;
    }

    if (this.isLayerVisible("routerNetworkParent")) {
      const routerDisplayName = this.nodeMap.getLayerDisplayName("router");
      this.legendRenderer.drawLine(
        wrapper,
        constants.network_parent_line_detail.router_color,
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawLabel(
        wrapper,
        routerDisplayName + " Network Parent",
        0,
        yPosition
      );
      yPosition += 20;
    }

    if (this.isLayerVisible("sensorNetworkParent")) {
      const sensorDisplayName = this.nodeMap.getLayerDisplayName("sensor");
      this.legendRenderer.drawLine(
        wrapper,
        constants.network_parent_line_detail.sensor_color,
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawLabel(
        wrapper,
        sensorDisplayName + " Network Parent",
        0,
        yPosition
      );
      yPosition += 20;
    }
    if (
      this.isLayerVisible("routerNetworkParent") ||
      this.isLayerVisible("sensorNetworkParent")
    ) {
      this.legendRenderer.drawLine(
        wrapper,
        constants.network_parent_line_detail.offline_color,
        this.legendRenderer.leftOffset,
        yPosition
      );
      this.legendRenderer.drawLabel(
        wrapper,
        "Offline Network Link",
        0,
        yPosition
      );
      yPosition += 20;
    }
  }

  drawHeatmapLegendSection() {
    const wrapper = this.legendRenderer.addLegendSection();
    const constants = this.nodeMap.constants;
    let currentY = 0;

    //Shapes
    const legendRoomModeItems = {
      Normal: "circle",
      Isolation: "cross",
      "C.Diff": "triangle",
      "COVID−19": "star",
      "C.Diff & COVID−19": "diamond",
      Multiple: "square",
    };

    wrapper
      .append("text")
      .attr("class", "legendTitle")
      .attr("y", currentY)
      .style("fill", constants.CHSHConstants.colors.primarySS)
      .text("Symbols (Mode)");
    currentY += 20;

    for (const x in legendRoomModeItems) {
      this.legendRenderer.drawShape(
        wrapper,
        constants.CHSHConstants.colors.primarySS,
        legendRoomModeItems[x],
        "fill",
        this.legendRenderer.leftOffset,
        currentY,
        5
      );
      this.legendRenderer.drawLabel(wrapper, x, 0, currentY);
      currentY += 20;
    }

    //Colors

    currentY += 10;
    const legendColorItems = {};
    legendColorItems[this.nodeMap.colorScale(0)] = "0-25%";
    legendColorItems[this.nodeMap.colorScale(0.25)] = "25%-50%";
    legendColorItems[this.nodeMap.colorScale(0.5)] = "50-75%";
    legendColorItems[this.nodeMap.colorScale(0.75)] = "75-100%";

    wrapper
      .append("text")
      .attr("class", "legendTitle")
      .attr("y", currentY)
      .style("fill", constants.CHSHConstants.colors.primarySS)
      .text("Colors");
    currentY += 20;

    this.legendRenderer.drawColorScale(wrapper, legendColorItems, 0, currentY);
    currentY += 35;

    //High-risk room alerts
    currentY += 10;
    wrapper
      .append("text")
      .attr("class", "legendTitle")
      .attr("y", currentY)
      .style("fill", constants.CHSHConstants.colors.primarySS)
      .text("Other Indicators");
    currentY += 20;
    this.legendRenderer
      .drawCircle(
        wrapper,
        "none",
        "stroke",
        this.legendRenderer.leftOffset,
        currentY
      )
      .attr("filter", "url(#filter-glow-black)");
    this.legendRenderer.drawLabel(wrapper, "High-Risk Room", 0, currentY);
  }

  drawSensorIgnorePairLegendSection() {
    const wrapper = this.legendRenderer.addLegendSection();
    const constants = this.nodeMap.constants;
    let currentY = 0;

    wrapper
      .append("text")
      .attr("class", "legendTitle")
      .attr("y", currentY)
      .style("fill", constants.colors.primarySS)
      .text("Sensor Ignore Pairs");
    currentY += 20;

    // Special Timing Override Set
    this.legendRenderer.drawCircle(
      wrapper,
      constants.ignore_pair_marker_details.timing_override_color,
      "fill",
      this.legendRenderer.leftOffset,
      currentY
    );
    this.legendRenderer.drawLabel(
      wrapper,
      "Special Timing Override Set",
      0,
      currentY
    );
    currentY += 20;

    wrapper
      .append("text")
      .attr("class", "label")
      .attr("y", currentY + 5)
      .style("fill", constants.colors.primarySS)
      .text("On Room Hover:");
    currentY += 20;

    // Assigned to Room
    this.legendRenderer.drawCircleWithTail(
      wrapper,
      constants.ignore_pair_marker_details.same_room_color,
      "fill",
      0,
      currentY
    );
    this.legendRenderer.drawLabel(wrapper, "Assigned to Room", 0, currentY);
    currentY += 20;

    // Assigned to a Different Room
    this.legendRenderer.drawCircleWithTail(
      wrapper,
      constants.ignore_pair_marker_details.adjacent_room_color,
      "fill",
      0,
      currentY
    );
    this.legendRenderer.drawLabel(
      wrapper,
      "Assigned to a Different Room",
      0,
      currentY
    );
    currentY += 20;

    // Hallway Sensor
    this.legendRenderer.drawCircleWithTail(
      wrapper,
      constants.ignore_pair_marker_details.hallway_color,
      "fill",
      0,
      currentY
    );
    this.legendRenderer.drawLabel(wrapper, "Hallway Sensor", 0, currentY);
    currentY += 20;

    // Prox-Only (with ignore pairs)
    this.legendRenderer.drawCircleWithTail(
      wrapper,
      constants.sensor_marker_details.prox.fillColor,
      "fill",
      0,
      currentY
    );
    this.legendRenderer.drawLabel(
      wrapper,
      "Prox-Only (with ignore pairs)",
      0,
      currentY
    );
    currentY += 20;

    // Room's Entry Sensor
    this.legendRenderer.drawCircle(
      wrapper,
      constants.ignore_pair_marker_details.entry_sensor_color,
      "stroke",
      this.legendRenderer.leftOffset,
      currentY
    );
    this.legendRenderer.drawLabel(wrapper, "Room's Entry Sensor", 0, currentY);
    currentY += 20;

    // Non-critical Sensor
    this.legendRenderer.drawLine(
      wrapper,
      constants.ignore_pair_marker_details.non_critical_color,
      this.legendRenderer.leftOffset,
      currentY
    );
    this.legendRenderer.drawLabel(wrapper, "Non-critical Sensor", 0, currentY);
    currentY += 20;
  }

  drawRoomIgnorePairLegendSection() {
    const wrapper = this.legendRenderer.addLegendSection();
    const constants = this.nodeMap.constants;
    let currentY = 0;

    wrapper
      .append("text")
      .attr("class", "legendTitle")
      .attr("y", currentY)
      .style("fill", constants.colors.primarySS)
      .text("Room Ignore Pairs");
    currentY += 20;

    // Special Timing Override Set
    this.legendRenderer.drawCircle(
      wrapper,
      constants.ignore_pair_marker_details.timing_override_color,
      "fill",
      this.legendRenderer.leftOffset,
      currentY
    );
    this.legendRenderer.drawLabel(
      wrapper,
      "Special Timing Override Set",
      0,
      currentY
    );
    currentY += 20;

    // No Entry Sensors
    this.legendRenderer.drawCircle(
      wrapper,
      constants.room_marker_details.no_entry_sensors_color,
      "fill",
      this.legendRenderer.leftOffset,
      currentY
    );
    this.legendRenderer.drawLabel(wrapper, "No Entry Sensors", 0, currentY);
    currentY += 20;

    // Multiple Entry Sensors
    this.legendRenderer.drawCircle(
      wrapper,
      constants.room_marker_details.multiple_entry_sensors_color,
      "fill",
      this.legendRenderer.leftOffset,
      currentY
    );
    this.legendRenderer.drawLabel(
      wrapper,
      "Multiple Entry Sensors",
      0,
      currentY
    );
    currentY += 20;
  }
}
