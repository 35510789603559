import { useSearchParams } from "react-router-dom";
import useIndividualStaffViewModel from "../../viewmodel/IndividualStaff/useIndividualStaffViewModel";
import useSharedFunctions from "../sharedFunctions/useSharedFunctions";
import { formatPercentage } from "../../utils";

const useIndividualStaffViewController = () => {
  const { transformedIndividualStaffData } = useIndividualStaffViewModel();
  const {
    handleDateChange,
    startDate,
    endDate,
    aggregate,
    toggleButton,
    applyFilters,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
  } = useSharedFunctions(transformedIndividualStaffData);

  const [searchParams, setSearchParams] = useSearchParams({});

  const returnToAllStaff = () => {
    window.location.href = `/HH/staff`;
    setSearchParams({
      ...searchParams,
      user_ids_filter: "",
    });
  };

  const pdfTables = [
    {
      title: transformedIndividualStaffData?.valueDisplay
        ? transformedIndividualStaffData?.valueDisplay + " Performance"
        : "Performance",
      head: [
        [
          "Performance",
          "Percentage Changed",
          "Total Opportunities",
          "Total Events",
        ],
      ],
      body: [
        [
          transformedIndividualStaffData?.tiles?.performance
            ? formatPercentage(
                transformedIndividualStaffData?.tiles?.performance
              )
            : "No Data",

          transformedIndividualStaffData?.tiles?.percentChangedFormatted ??
            "No Data",
          transformedIndividualStaffData?.tiles?.totalOpportunities ??
            "No Data",

          transformedIndividualStaffData?.tiles?.totalHygieneEvents ??
            "No Data",
        ],
      ],
    },
  ];

  return {
    pdfTables,
    returnToAllStaff,
    transformedIndividualStaffData,
    handleDateChange,
    startDate,
    endDate,
    aggregate,
    toggleButton,
    applyFilters,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
  };
};

export default useIndividualStaffViewController;
