import {
  parseOptionsHeaders,
  parseOptions,
  isRawOptionsWithHeaders,
  isGroupedOptions,
} from "../Components/Filters/hooks/helpers";
import {
  RawOptionsObject,
  RawOptionsWithHeaders,
} from "../Components/Filters/types/types";
import { Option, GroupedOptions } from "../types/types";

type FilterKey = {
  options: RawOptionsObject | RawOptionsWithHeaders;
  valueDisplay?: string;
  value: null | boolean | number[];
};

export function findSelection(filterKey: FilterKey): Option[] {
  const stripBrackets = (str: string) => str.slice(1, -1);
  const flattenOptions = (options: GroupedOptions[]) => {
    let flatOptions: Option[] = [];

    options.forEach((group) => {
      flatOptions = [...flatOptions, ...group.options];
    });

    return flatOptions;
  };

  const buildSelection = (
    options: Option[] | GroupedOptions[],
    valueDisplayString: string
  ) => {
    if (isGroupedOptions(options)) {
      const flatOptions = flattenOptions(options);
      return flatOptions.filter((opt) => {
        return valueDisplayString.includes(opt.label);
      });
    }

    return options.filter((opt) => {
      return valueDisplayString.includes(opt.label);
    });
  };

  let selection: Option[] = [];
  let parsedValueDisplay = "";
  if (filterKey.valueDisplay?.charAt(0) === "[") {
    parsedValueDisplay = stripBrackets(filterKey.valueDisplay);
  } else {
    parsedValueDisplay = filterKey.valueDisplay || "";
  }

  let responseOptions: Option[] | GroupedOptions[] = [];
  if (isRawOptionsWithHeaders(filterKey.options)) {
    responseOptions = parseOptionsHeaders(filterKey.options);
  } else {
    responseOptions = parseOptions(filterKey.options);
  }

  selection = buildSelection(responseOptions, parsedValueDisplay);

  return selection;
}
