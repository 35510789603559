import { Outlet, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useState } from "react";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
import VisitOverviewPdfDownloadButton, {
  VisitOverviewPdfDownloadButtonProps,
} from "../../Components/VisitOverviewPdfButtons/VisitOverviewPdfDownloadButton";

function VisitOverview() {
  const location = useLocation();
  const selectedTab = location.pathname.includes("staff") ? 1 : 0;
  const [excelDownloadUrl, setExcelDownloadUrl] = useState<string | null>(null);
  const [pdfProps, setPdfProps] =
    useState<VisitOverviewPdfDownloadButtonProps | null>(null);

  return (
    <>
      <div className="page-header">
        <h1>Visit Overview</h1>
        <div className="buttons-container">
          {excelDownloadUrl && <ExcelDownload link={excelDownloadUrl} />}
          {pdfProps && <VisitOverviewPdfDownloadButton {...pdfProps} />}
        </div>
      </div>
      <div className="Tabs">
        <div className="themed-tabs-container">
          <Tabs
            className="right-align"
            focusTabOnClick={false}
            selectedTabClassName="themed-tabs"
            selectedIndex={selectedTab}
            onSelect={() => {}}
          >
            <TabList>
              <div className="display-flex flex-wrap">
                <Tab>
                  <Link to="/NI/visit-overview/rooms">Rooms Overview</Link>
                </Tab>
                <Tab>
                  <Link to="/NI/visit-overview/staff">Staff Overview</Link>
                </Tab>
              </div>
            </TabList>
            <TabPanel></TabPanel>
            <TabPanel></TabPanel>
          </Tabs>
        </div>
      </div>
      <Outlet context={{ setExcelDownloadUrl, setPdfProps }} />
    </>
  );
}
export default VisitOverview;
