import { Asset, AssetsDTO } from "../../model/types";
import { transformFilters } from "../utils";
import { TransformedAsset } from "../../viewmodel/types";
import { missingDataMessageAT } from "../../constants";

export function transformAssets(assetObj: AssetsDTO): TransformedAsset {
  function getOwnership(asset: Asset): string {
    if (asset.assignedFacility?.name && asset.assignedUnit?.name) {
      return `${asset.assignedFacility.name}\n${asset.assignedUnit.name}`;
    } else if (asset.assignedFacility?.name) {
      return asset.assignedFacility.name;
    } else {
      return missingDataMessageAT;
    }
  }

  const excelUrl = assetObj?.export;

  const assetsTable = assetObj?.assets?.map((asset) => {
    let status;
    let room;
    if (asset.isInUse === undefined) {
      status = missingDataMessageAT;
    } else {
      status = asset.isInUse === true ? "In Use" : "Not Used";
    }

    let imgUrl;
    if (!asset?.model?.imageLocation) {
      imgUrl = "/at-default-image.png";
    } else {
      imgUrl = asset.model.imageLocation;
    }

    if (asset.lastLocation?.isInRoom === undefined) {
      room = missingDataMessageAT;
    } else {
      room =
        asset.lastLocation.isInRoom === false
          ? "Near " + asset.lastLocation.nearestRoomName
          : asset.lastLocation.roomName;
    }
    return {
      image: imgUrl,
      type: asset.typeName ?? missingDataMessageAT,
      model: asset?.model?.name ?? missingDataMessageAT,
      room,
      unitLocation: asset.lastLocation?.unitName ?? missingDataMessageAT,
      facilityLocation:
        asset.lastLocation?.facilityName ?? missingDataMessageAT,
      ownership: getOwnership(asset),
      serialNumber: asset.serialNumber ?? missingDataMessageAT,
      id: asset.id ?? -1,
      status,
      ownershipStatus: asset.ownershipStatus ?? missingDataMessageAT,
      nickname: asset.nickname ?? missingDataMessageAT,
      timeLastSeen: asset.lastSeenAt?.datetime,
      timezone: asset.lastSeenAt?.timezone,
    };
  });

  const uuid = assetObj?._report?.uuid;

  const filters = transformFilters(assetObj?.params);

  return {
    assetsTable,
    filters,
    excelUrl,
    uuid,
  };
}
