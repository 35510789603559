import { get } from "../../api/laravelApi";
import { individualUserReportEndpoint } from "../../constants";

export const IndividualStaffLoader = async ({ request, params }) => {
  const startDate = new URL(request.url).searchParams.get("startDate");
  const endDate = new URL(request.url).searchParams.get("endDate");

  const individualStaffReportData = await get(individualUserReportEndpoint, {
    startDate,
    endDate,
    user_ids_filter: params.id,
    sumOverTimeRange: false,
  });

  return individualStaffReportData;
};
