import { StaffOverview } from "../../model/types";

// if the number of visits is 0 then show something differenent than if most visited by info is just missing
// also need to handle case of how unit_ids cannot be just the string null in the new pattern

type StaffOverviewTableData = {
  staffName: string;
  visitLengthAvg: number;
  numberOfVisits: number;
  longestVisit: number;
  mostVisitedRoom: string;
};

export function transformStaffOverview(
  staffOverview: StaffOverview
): StaffOverviewTableData {
  const mostVisitedRoom = staffOverview.mostVisitedRoom?.roomName ?? "N/A";

  return {
    staffName: `${staffOverview.user.firstName} ${staffOverview.user.lastName}`,
    visitLengthAvg: staffOverview.visitLengthAvg || 0,
    numberOfVisits: staffOverview.visitCount || 0,
    longestVisit: staffOverview.longestVisit || 0,
    mostVisitedRoom,
  };
}
