import Cards from "../UI/Cards/Cards";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Tooltips from "../UI/Tooltip/Tooltip";
import { tooltipContent } from "../../content";
import MissingData from "../MissingData/MissingData";
import Graph from "@untitled-ui/icons-react/build/cjs/LineChartUp01";
import { DataSeries } from "../../types/types";
import { colorLines, goalLineColor, baseLineColor } from "./lineGraphOptions";
import { missingDataMessage, missingDataAction } from "../../constants";
import { htmlLegendPlugin } from "./lineGraphOptions";
import Alert from "@untitled-ui/icons-react/build/cjs/AlertTriangle";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const formatXAxisLabel = (label: string) => {
  let startDate: Date | null = null;
  let endDate: Date | null = null;

  const includesTo = label.toLowerCase().includes(" to ");

  if (!label.includes("–") && !includesTo) {
    // We are dealing with a daily or monthly label
    const dateRange = label.split("<br>");
    startDate = new Date(dateRange[0]);
  } else if (includesTo) {
    const dateRange = label.split(" to ");
    startDate = dateRange.length === 2 ? new Date(dateRange[0]) : null;
    endDate = startDate ? new Date(dateRange[1]) : null;
  } else {
    // We are dealing with a weekly label
    const dateRange = label.split("–");
    startDate = dateRange.length === 2 ? new Date(dateRange[0]) : null;
    endDate = startDate ? new Date(dateRange[1].split("<br>")[0]) : null;
  }

  const startFormatted = startDate?.toLocaleString("default", {
    month: "short",
    day: "numeric",
  });

  const endFormatted = endDate?.toLocaleString("default", {
    month: startDate?.getMonth() === endDate.getMonth() ? undefined : "short",
    day: "numeric",
  });

  if (startDate && endDate) {
    // Concatenate formatted start and end dates with a hyphen and return the result
    return `${startFormatted} - ${endFormatted}`;
  } else if (startDate) {
    return startFormatted;
  } else {
    return label;
  }
};

function transformPlotlinesToDataSeries(plotlines, dataSeriesLength) {
  return plotlines.map((plotline) => {
    const { series, lines } = plotline;

    return {
      ...series,
      data: lines.flatMap((line) =>
        Array(dataSeriesLength).fill({
          name: line.label.text,
          percentage: line.value * 100,
        })
      ),
    };
  });
}

type LineGraphProps = {
  yAxisPlotlines?: string | null;
  dataSeries: DataSeries[];
  title: string;
};

function LineGraph(props: LineGraphProps) {
  const dataSeries = props.dataSeries;
  const yAxisPlotlines = props.yAxisPlotlines || "";

  let parsedYAxisPlotlines;

  if (yAxisPlotlines.length > 0) {
    parsedYAxisPlotlines = JSON.parse(yAxisPlotlines);
  } else {
    parsedYAxisPlotlines = [];
  }

  const transformedYAxisPlotlines = transformPlotlinesToDataSeries(
    parsedYAxisPlotlines,
    dataSeries[0]?.data.length || 0
  );

  const combinedDataSeries = [...dataSeries, ...transformedYAxisPlotlines];

  if (combinedDataSeries.length === 0) {
    return (
      <Cards
        headerLeft={<h2>{props.title}</h2>}
        headerRight={
          <Tooltips
            id={"missing-data-graph"}
            content={tooltipContent.missingDataGraph["en-US"]}
          ></Tooltips>
        }
      >
        <div className="graph-text-center">
          <MissingData
            message={missingDataMessage}
            action={missingDataAction}
          />
          <Graph />
        </div>
      </Cards>
    );
  }

  let min = Infinity;
  let max = -Infinity;

  for (let i = 0; i < combinedDataSeries.length; i++) {
    const group = combinedDataSeries[i];
    const groupData = group.data;

    // Only calculate min/max for groups that are visible in the legend
    if (group.showInLegend === true) {
      for (let j = 0; j < groupData.length; j++) {
        const value = groupData[j].percentage;
        if (value < min) {
          min = value;
        }
        if (value > max) {
          max = value;
        }
      }
    }
  }

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const performance = context.parsed.y;
            return `Performance: ${performance}%`;
          },
        },
      },
      htmlLegend: {
        // ID of the container to put the legend in
        containerID: "legend-container",
      },
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
        },
        onHover: function () {
          document.body.style.cursor = "pointer";
        },
        onLeave: function () {
          document.body.style.cursor = "unset";
        },
      },
    },
    scales: {
      y: {
        title: {
          text: "Total Performance",
          display: true,
          font: {
            weight: "bold" as "bold",
          },
        },
        // Max and min calculations below scale the top/bottom of graph to largest value plus/minus 5 and rounded up/down to the next multiple of 5
        max: Math.min(100, Math.ceil((max + 5) / 5) * 5),
        min: Math.max(0, Math.floor((min - 5) / 5) * 5),
        ticks: {
          callback: function (value) {
            return value + "%";
          },
        },
      },
      x: {
        title: {
          text: "Time Aggregates",
          display: true,
          font: {
            weight: "bold" as "bold",
          },
        },
      },
    },
    title: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  // Only show data series that are set to show in legend
  const visibleDataSeries = combinedDataSeries.filter(
    (group) => group.showInLegend === true && group.data.length !== 0
  );

  const graphData = {
    labels: visibleDataSeries[0]?.data?.map((data) =>
      formatXAxisLabel(data.name)
    ),
    datasets: visibleDataSeries?.map((group, idx) => {
      let colorLinetoUse = colorLines[idx] || colorLines[0];
      let pointRadiusToUse = 6;
      let pointStyle = "circle";

      if (group.name.includes("Baseline")) {
        colorLinetoUse = baseLineColor;
        pointRadiusToUse = 10;
        pointStyle = "triangle";
      } else if (group.name.includes("Goal")) {
        colorLinetoUse = goalLineColor;
        pointRadiusToUse = 10;
        pointStyle = "star";
      } else if (group.legendType === "nodeAncestor") {
        pointStyle = "rectRot";
      }

      return {
        label: group.name,
        data: group.data.map((data) => data.percentage),
        borderColor: colorLinetoUse,
        backgroundColor: colorLinetoUse,
        pointStyle: pointStyle,
        pointRadius: pointRadiusToUse, // affects size of circles on the lines in the line graph
        lineTension: 0.3, // affects how "curvy" the lines look
        hidden: !(
          group.name.includes("Baseline") ||
          group.name === "Hand Hygiene Goal" ||
          group.visible
        ),
      };
    }),
  };

  return (
    <>
      {dataSeries.length !== 0 && (
        <div>
          <Cards
            headerLeft={<h2 className="margin-bottom">{props.title}</h2>}
            headerRight={
              <Tooltips
                id="groups-graph"
                content={tooltipContent.lineGraphCard["en-US"]}
              />
            }
          >
            <div id="pdf-graph">
              <div id="legend-container" className="margin-bottom"></div>
              <div style={{ height: "750px" }}>
                <Line
                  id="graph"
                  options={options}
                  data={graphData}
                  plugins={[htmlLegendPlugin]}
                />
              </div>
            </div>
            {combinedDataSeries.some((group) => group.data.length === 0) && (
              <div className="display-flex-align-center">
                <Alert />
                <h2>
                  No data for:{" "}
                  {combinedDataSeries
                    .filter((group) => group.data.length === 0)
                    .map((data) => data.name)
                    .join(", ")}
                </h2>
              </div>
            )}
          </Cards>
        </div>
      )}
    </>
  );
}

export default LineGraph;
