import { MyReport } from "../../types/types";
import { filterHtmlTags } from "../utils";

export function transformMyReport(responseJson): MyReport {
  const data = responseJson?.handHygienePlot?.dataSeries;

  const response: MyReport = {
    tiles: {
      performance: null,
      percentChangedFormatted: "",
      totalOpportunities: null,
      totalHygieneEvents: null,
      dateRange: "",
    },
    yAxisPlotlines: null,
    name: "",
    dataSeries: [],
    error: null,
    excelUrl: "",
    disabled: false,
    uuid: "",
  };

  const yAxisPlotlines = responseJson?.handHygienePlot?.yAxisPlotlines;

  if (yAxisPlotlines) {
    response.yAxisPlotlines = yAxisPlotlines;
  }

  const tiles = responseJson?.summaryBlocks?.tiles;

  if (tiles) {
    const eventCounts = responseJson.summaryBlocks.tiles.filter(
      (tile: { _component_class: string }) =>
        tile._component_class === "EventCounts"
    )[0];
    const performanceChanged = responseJson.summaryBlocks.tiles.filter(
      (tile: { _component_class: string }) =>
        tile._component_class === "PerformanceChanged"
    )[0];
    const performanceOverall = responseJson.summaryBlocks.tiles.filter(
      (tile: { _component_class: string }) =>
        tile._component_class === "PerformanceOverall"
    )[0];

    response.tiles = {
      dateRange: performanceChanged?.subtitle,
      totalOpportunities: eventCounts?.opportunities,
      totalHygieneEvents: eventCounts?.events,
      performance: performanceOverall?.performance,
      percentChangedFormatted: performanceChanged?.percentChangedFormatted,
    };
  }

  const dataSeries = responseJson?.handHygienePlot?.dataSeries;

  if (dataSeries && dataSeries.length > 0) {
    const sanitizedDataSeries = filterHtmlTags(dataSeries);
    response.dataSeries = sanitizedDataSeries;
  }

  response.name = data?.map((data: { name: string }) => {
    return data.name;
  });

  if (responseJson?._export?.url === null) {
    response.disabled = true;
  } else {
    response.disabled = false;
  }

  response.error = responseJson?._error;

  response.excelUrl = responseJson?._export?.url;

  response.uuid = responseJson?._report?.uuid;

  return response;
}
