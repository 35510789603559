type HamburgerButtonProps = {
  navigation?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

function HamburgerButton(props: HamburgerButtonProps) {
  return (
    <div className="side-navigation-container">
      <button
        className="hamburger-btn"
        data-testid="hamburger-btn"
        value={props.navigation}
        onClick={props.onClick}
      >
        ☰
      </button>
    </div>
  );
}

export default HamburgerButton;
