import useIndividualAssetsViewModel from "../../viewmodel/IndividualAssets/useIndividualAssetsViewModel";
import useSharedFunctions from "../sharedFunctions/useSharedFunctions";

const useIndividualAssetsViewController = () => {
  const { transformedIndividualAssetsData } = useIndividualAssetsViewModel();
  const {
    handleDateChange,
    startDate,
    endDate,
    applyFilters,
    toggleButton,
    disableExcelDoc,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
  } = useSharedFunctions(transformedIndividualAssetsData);

  return {
    transformedIndividualAssetsData,
    dataTable: transformedIndividualAssetsData.dataTable,
    handleDateChange,
    applyFilters,
    startDate,
    endDate,
    toggleButton,
    disableExcelDoc,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
  };
};

export default useIndividualAssetsViewController;
