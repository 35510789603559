import Cards from "../UI/Cards/Cards";
import BarChart from "../BarChart/BarChart";
import TabsList from "../UI/Tabs/Tabs";
import {
  averageRoundDuration,
  nurseRoomTimePercent,
  averageTimeBetweenRounds,
} from "../BarChart/ShiftAnalysis";
import Tooltips from "../UI/Tooltip/Tooltip";
import { tooltipContent } from "../../content";
import MissingData from "../MissingData/MissingData";
import { missingDataAction, missingDataMessage } from "../../constants";

type RoundingMetricsCardProps = {
  barGraphData: {
    [key: string]: {
      labels: string[];
      means: string[] | null[] | [];
    };
  };
};

function RoundingMetricsCard(props: RoundingMetricsCardProps) {
  const canvas = document.querySelector(
    "[id^='bar-chart']"
  ) as HTMLCanvasElement;

  const ctx = canvas?.getContext("2d");
  const gradient = ctx?.createLinearGradient(1, 1, 0, 100);

  gradient?.addColorStop(0, "#F855B3");
  gradient?.addColorStop(0.5, "#FFB94C");

  const averageTimeBetweenRoundsData = {
    labels: props.barGraphData["Avg. Time Between Rounds"].labels,
    datasets: [
      {
        data: props.barGraphData["Avg. Time Between Rounds"].means,
        backgroundColor: [gradient, "#F855B3", "#F855B3", "#FFB94C", "#FFB94C"],
      },
    ],
  };

  const averageRoundDurationData = {
    labels: props.barGraphData["Avg. Round Duration"].labels,
    datasets: [
      {
        data: props.barGraphData["Avg. Round Duration"].means,
        backgroundColor: [gradient, "#F855B3", "#F855B3", "#FFB94C", "#FFB94C"],
      },
    ],
  };

  const nurseRoomTimePercentData = {
    labels: props.barGraphData["Nurse Room Time %"].labels,
    datasets: [
      {
        data: props.barGraphData["Nurse Room Time %"].means,
        backgroundColor: [gradient, "#F855B3", "#F855B3", "#FFB94C", "#FFB94C"],
      },
    ],
  };

  return (
    <>
      <Cards
        className="full-height"
        headerLeft={<h2>Shift Analysis</h2>}
        headerRight={
          <Tooltips
            id={"roundingMetricsCard-tooltip"}
            content={tooltipContent.roundingMetrics["en-US"]}
          />
        }
      >
        {" "}
        {props.barGraphData["Nurse Room Time %"].means.length === 0 &&
        props.barGraphData["Avg. Time Between Rounds"].means.length === 0 &&
        props.barGraphData["Avg. Round Duration"].means.length === 0 ? (
          <MissingData
            message={missingDataMessage}
            action={missingDataAction}
          />
        ) : (
          <>
            <TabsList
              className={"grey-tabs"}
              tabs={[
                "Avg. Time Between Rounds",
                "Avg. Round Duration",
                "Nurse Room Time %",
              ]}
              content={[
                <>
                  <BarChart
                    style={{ height: "250px" }}
                    isRoundingPage={true}
                    options={averageTimeBetweenRounds}
                    data={averageTimeBetweenRoundsData}
                  />
                </>,
                <BarChart
                  style={{ height: "250px" }}
                  isRoundingPage={true}
                  options={averageRoundDuration}
                  data={averageRoundDurationData}
                />,
                <BarChart
                  style={{ height: "250px" }}
                  isRoundingPage={true}
                  options={nurseRoomTimePercent}
                  data={nurseRoomTimePercentData}
                />,
              ]}
            ></TabsList>
          </>
        )}
      </Cards>
    </>
  );
}

export default RoundingMetricsCard;
