import {
  create as d3Create,
  // drag as d3Drag,
  select as d3Select,
  zoom as d3Zoom,
  zoomIdentity as d3ZoomIdentity,
  zoomTransform as d3ZoomTransform,
} from "d3";
import NodeMapLegend from "./NodeMapLegend";
import Pluralize from "pluralize";
import Themes from "../Themes";
import ImageLayer from "./mapLayers/ImageLayer";
import RoomHeatmapLayer from "./mapLayers/RoomHeatmapLayer";
import RoomLayer from "./mapLayers/RoomLayer";
import $ from "jquery";
import CoreScripts from "../CoreScripts";
import { CHSHConstants } from "../../../utils";

/**
 *
 * @param JSON array of parameters
 */
export default class NodeMap {
  constants = {
    CHSHConstants,
    room_marker_details: {
      body_color: "white",
      line_color: "red",
      text_color: "#0084a3",
      text_color_disabled: "#a1a1a1",
      text_color_editing: "#2aef23",
      text_color_light: "#00d0ff",
      text_stroke_color: "white",
      text_color_uninstalled: "#FFA2E9",
      no_entry_sensors_color: "red",
      multiple_entry_sensors_color: "red",
    },
    heat_marker_details: {
      text_color: "black",
    },
  };
  /* Configurable config vars */
  layerTypes = {
    heatmap: RoomHeatmapLayer,
    room: RoomLayer,
  };
  fullscreen = false;
  currentTheme = null;
  renderingTimer = null;
  renderingTimerWatchdog = null;
  mapWidth = 0; //stores loaded map width
  mapHeight = 0; //stores loaded map height
  centerTransformElement = null; //stored first transform once calculated
  centerTransformObject = null; //stored first transform once calculated
  defaultButtons = [
    {
      identifier: "fullscreenButton",
      label: "\uf0B2",
      localFunction: "toggleFullscreen",
    },
    {
      identifier: "themeButton",
      label: "\uf06e",
      localFunction: "toggleTheme",
      shouldBeVisibleCallback: function (nodeMap) {
        return nodeMap.map_layers.includes("heatmap");
      },
    },
    {
      identifier: "toggleLayersButton",
      label: "\uf0c9",
      localFunction: "showToggleLayersModal",
    },
  ];
  root_svg;
  button_layer;
  canvasLayer;
  transform_selector;
  definition_layers;
  viewportContainer;
  background_layer;
  static_layers_bottom;
  static_layers_top;
  legend;
  static_interaction_layers;
  buttons = [];
  parsedMapXML = null;

  constructor(userOptions) {
    Object.assign(this, userOptions);
    this.currentTheme = Themes.getCurrentD3Theme();

    /* Create Viewport */
    this.createViewport();

    // Compute and calculate values
    this.buildCalculatedValues();

    // Set up scales
    this.buildScales();

    // Set up starting map transforms
    this.buildTransforms();

    //Set up glow filters
    this.buildDefinitionLayers();

    // Create Layer Hierarchy of User Desired Layers
    this.createLayers();

    // Create Legend
    this.legend = new NodeMapLegend(this);

    // Draw Sensors and Go!
    this.draw();

    /* Monitor window size changes */
    const _this = this;
    $(window).on("resize", function () {
      _this.windowResized();
    });
    $(".alert").on("closed.bs.alert", function () {
      _this.windowResized();
    });
    $(this.viewportContainer.node()).on("mapRerender", function () {
      _this.legend.draw();
      _this.mapResized();

      return false;
    });
    //check for the orientation event and bind accordingly
    if (window.DeviceOrientationEvent) {
      window.addEventListener("orientationchange", _this.windowResized, false);
    }
    //Watch for panel collapses/expands and start a continuous update when these are happening (and changing map size)
    $(".collapse")
      .on("show.bs.collapse", function () {
        _this.continuousUpdateLayoutStart();
      })
      .on("shown.bs.collapse", function () {
        _this.continuousUpdateLayoutFinish();
      })
      .on("hide.bs.collapse", function () {
        _this.continuousUpdateLayoutStart();
      })
      .on("hidden.bs.collapse", function () {
        _this.continuousUpdateLayoutFinish();
      });
  }

  /* Add Getters */
  get globalScale() {
    return this.scales.scale_factor || 1;
  }

  get mapDataXml() {
    //Creates xml if needed, otherwise returns cached version
    if (this.parsedMapXML == null) {
      this.parsedMapXML = $.parseXML(this.map_data.raw);
    }

    return this.parsedMapXML;
  }

  get mapIsDirty() {
    var _this = this;
    var isDirty = false;
    $.each(_this.layers, function (layerKey) {
      /* Skip checking the image layer - nothing can ever be dirty here */
      if (layerKey === "image") {
        //Continue

        return true;
      }

      /* Check for Modified Items */
      _this
        .exportCoordinatesFromElements(_this.layers[layerKey].items)
        .forEach(function (marker) {
          if (marker.internal_map_attributes.dirty !== false) {
            isDirty = true;
            //break - only 1 dirty thing = dirty

            return false;
          }
        });
      if (isDirty) {
        //break - only 1 dirty thing = dirty

        return false;
      }

      /* Check for Removed Items */
      $.each(_this.layers[layerKey].unplacedItems, function (key, marker) {
        if (marker.internal_map_attributes.dirty !== false) {
          isDirty = true;
          //break - only 1 dirty thing = dirty

          return false;
        }
      });
      if (isDirty) {
        //break - only 1 dirty thing = dirty
        return true;
      }

      /* Check for Deleted Items */
      $.each(_this.layers[layerKey].deletedItems, function (key, marker) {
        if (marker.internal_map_attributes.dirty !== false) {
          isDirty = true;
          //break - only 1 dirty thing = dirty

          return false;
        }
      });
      if (isDirty) {
        //break - only 1 dirty thing = dirty

        return false;
      }
    });

    return isDirty;
  }

  get rootWidth() {
    //Get width of box
    var $selector = $(this.root_svg.node());
    var val = $selector.innerWidth();
    if (isNaN(val)) {
      val = $selector.width();
    }
    if (isNaN(val)) {
      val = this.mapWidth;
    }

    return val;
  }

  get rootHeight() {
    //Get height of box'
    var $selector = $(this.root_svg.node());
    var val = $selector.innerHeight();
    if (isNaN(val)) {
      val = $selector.height();
    }
    if (isNaN(val)) {
      val = this.mapHeight;
    }

    return val;
  }

  get allowedMaxHeight() {
    const $contentMainPanel = $(".content-container");
    let contentPanelOffsetTop;
    if ($contentMainPanel.length === 0) {
      contentPanelOffsetTop = 0;
    } else {
      contentPanelOffsetTop =
        $contentMainPanel.offset().top - $(document).scrollTop();
    }
    const elementOuterHeight = $(this.element).outerHeight(true);
    //Get the height at which the node map should be displayed to fill the screen, min 300
    var val = Math.max(
      $(window).height() -
        contentPanelOffsetTop -
        $("footer").outerHeight(true) -
        ($contentMainPanel.outerHeight(true) - elementOuterHeight) -
        (elementOuterHeight - $(this.root_svg.node()).height()),
      300
    );
    if (isNaN(val)) {
      val = this.rootHeight;
    }

    return val;
  }

  /**
   * Triggers the map to check if it has any dirty elements and trigger off trigger on chart element accordingly
   */
  refreshDirtyStatus() {
    $(this.element).trigger("dirtyStatusUpdated", [this.mapIsDirty]);
  }

  /**
   * Triggers a d3 selection
   * Alias to d3.select
   */
  d3Select(...args) {
    return d3Select(...args);
  }

  /**
   * Triggers a d3 creation
   * Alias to d3.create
   */
  d3Create(...args) {
    return d3Create(...args);
  }

  generateRandomID = function (length = 10) {
    return (
      new Date().getTime() +
      "-" +
      Math.random()
        .toString(36)
        .substring(2, length + 2)
    );
  };

  pluralizeString = function (str, counter) {
    /* eslint-disable new-cap */
    return Pluralize(str, counter, false);
  };

  /**
   * Return float val of value if convertable or return fallback if not
   * @param  {potential number to parse} value
   * @param  {Number} fallback to output in case error (if input is NaN) - defaults to null
   * @return number
   */
  filterFloat(value, fallback = null) {
    return CoreScripts.filterFloat(value, fallback);
  }

  /**
   * Return int val of value if convertable or return fallback if not
   * @param  {potential number to parse} value
   * @param  {Number} fallback to output in case error (if input is NaN) - defaults to null
   * @return number
   */
  filterInt(value, fallback = null, radix = 10) {
    return CoreScripts.filterInt(value, fallback, radix);
  }

  /* Handle leading and trailing whitespace strings */
  trimStringAndReplaceEmptyWithNull(value) {
    if (value == null) {
      return value;
    }

    //Trim it
    value = value.trim();

    //Replace double+ spaces with single spaces
    value = value.replace(/[^\S\r\n]+/g, " ");

    //If no length, null!
    if (value.length === 0) {
      return null;
    }

    return value;
  }

  /**
   * Toggle whether or not the map can be interacted with at all or if all pointer events are disabled
   */
  toggleInteractability(interactionable) {
    if (interactionable) {
      $(this.root_svg.node()).removeClass("pointer-no-interaction-force");
      $(this.canvasLayer.node()).removeClass("pointer-no-interaction-force");
      $(this.root_svg.node())
        .find(".maplayer")
        .removeClass("pointer-no-interaction-force");
      $(this.root_svg.node())
        .find(".mapMarker")
        .removeClass("pointer-no-interaction-force");
    } else {
      $(this.root_svg.node()).addClass("pointer-no-interaction-force");
      $(this.canvasLayer.node()).addClass("pointer-no-interaction-force");
      $(this.root_svg.node())
        .find(".maplayer")
        .addClass("pointer-no-interaction-force");
      $(this.root_svg.node())
        .find(".mapMarker")
        .addClass("pointer-no-interaction-force");
    }
  }

  /**
   * Toggle Fullscreen display of the node map
   * @return {[type]}
   */
  toggleFullscreen() {
    this.fullscreen = !this.fullscreen;
    this.root_svg.classed("fullscreen", this.fullscreen);
    this.mapResized();
  }

  /**
   * Switch between various color themes
   * @return {[type]}
   */
  toggleTheme() {
    this.currentTheme = Themes.toggleToNextTheme(this.currentTheme);
    this.colorScale = this.currentTheme.scale;
    this.drawLegend();
    this.getAllMarkers().dispatch("rerender");
  }

  /**
   * Deselect all active markers
   * (Called by genericLayer before adding a new marker)
   */
  deselectActiveMarkers() {
    this.getActiveMarkers().dispatch("deselectelement");
  }

  /**
   * getActiveMarkers
   * Get D3 selectors for all currently active markers
   * @return {[d3sel]}
   */
  getActiveMarkers() {
    return this.root_svg.selectAll(".mapMarker[active=true]");
  }

  /**
   * getAllMarkers
   * Get D3 selectors for all markers
   * @return {[d3sel]}
   */
  getAllMarkers() {
    return this.root_svg.selectAll(".mapMarker");
  }

  /**
   * isDraggingMarker
   * Check if the user is currently dragging a marker
   * @return {Boolean}
   */
  isDraggingMarker() {
    var isDraggingMarker = false;
    this.getActiveMarkers().each(function (markerInfo) {
      if (markerInfo.internal_map_attributes.dragging === true) {
        isDraggingMarker = true;
        //break -
        return false;
      }
    });

    return isDraggingMarker;
  }

  /**
   * Get the coordinates for the center of the visible frame
   * @return {x: float, y:float}
   */
  visibleCenter() {
    var viewCenter = {
      x: 0,
      y: 0,
    };

    var transform = d3ZoomTransform(this.root_svg.node());
    var transform_original = this.centerTransformObject;

    var totalWidthScaledOriginal = this.mapWidth * transform_original.k;
    var totalHeightScaledOriginal = this.mapHeight * transform_original.k;

    var originalCenterX = totalWidthScaledOriginal / 2 + transform_original.x;
    var originalCenterY = totalHeightScaledOriginal / 2 + transform_original.y;

    var NewCenterX = (originalCenterX - transform.x) / transform.k;
    var NewCenterY = (originalCenterY - transform.y) / transform.k;

    viewCenter.x = NewCenterX;
    viewCenter.y = NewCenterY;

    return viewCenter;
  }

  /**
   * Get an array of layer keys and visibilities
   * @param layer
   */
  getLayersInfo() {
    var _this = this;
    var toReturn = [];
    $.each(_this.layers, function (layerKey) {
      const layer = _this.layers[layerKey];
      if (!layer.allowTogglingVisibility) {
        //Continue
        return true;
      }
      toReturn.push({
        key: layerKey,
        name: _this.layers[layerKey].name,
        visible: !_this.layers[layerKey].isHidden,
      });
    });

    toReturn.sort(function (a, b) {
      return a.name.localeCompare(b.name, "en");
    });

    return toReturn;
  }

  /**
   * Helper function to get the display name of a layer
   *
   * @param layerKey
   */
  getLayerDisplayName(layerKey) {
    return this.map_data.layerDisplayNames[layerKey];
  }

  /**
   * Make a layer hidden or not
   * @param layer
   */
  setLayerHidden(layerName, boolean) {
    if (layerName === "image") {
      //Don't allow hiding the map
      return;
    }
    if (layerName === "legend") {
      //Special hiding and showing for legend
      this.legend.setHidden(boolean);
      if (!boolean) {
        this.legend.draw();
      }

      return;
    }

    this.layers[layerName].setHidden(boolean);

    this.legend.draw();
  }

  /**
   * Get a given layer
   * @param layer name
   * @return layer
   */
  getLayer(layerName) {
    return this.layers[layerName];
  }

  /*
    Add new item to node map
    Pass in layer and item payload
    */
  addMarker(layerName, payload) {
    return this.layers[layerName].addMarker(payload);
  }

  /*
    Create a new item on the node map
    Pass in layer and optionally specify parameters in the payload
    */
  createMarker(layerName, payload = null) {
    return this.layers[layerName].createMarker(payload);
  }

  /*
    Get a json array of items on a layer
    Pass in layer name and keys for name and value
    */
  getOptions(layerName, value_id = "eid", text_id = "name") {
    var outputOptions = [];
    var coordinatesOfType = this.exportCoordinatesFromElements(
      this.layers[layerName].items
    );
    //Iterate
    coordinatesOfType.forEach(function (marker) {
      outputOptions.push({
        value: marker[value_id],
        text: marker[text_id],
      });
    });
    /* Alphabetize */
    return outputOptions.sort(function (a, b) {
      return a.text.localeCompare(b.text, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    });
  }

  getItemByEid(layerName, eid) {
    return this.getLayer(layerName).getItemByEid(eid);
  }

  /**
   * Get coordinates of map elements
   */
  exportCoordinatesFromElements(elements) {
    var returner = [];
    elements.each(function (d) {
      returner.push(d);
    });

    return returner;
  }

  /**
   * Validate a name is indeed unique and valid
   * @param string layer
   * @param string device name
   * @param (optional) string existing element ID on map (to allow "re-using" name if same device)
   */
  validateDeviceName(layer, name, existingEid) {
    const preppedName = this.trimStringAndReplaceEmptyWithNull(name || null);
    if (preppedName == null) {
      return "Name must not be empty";
    }
    if (preppedName.includes("_")) {
      return "Name cannot contain _";
    }
    if (
      this.getOptions(layer, "eid", "name").some(function (item) {
        //Make sure device address can only be "re-used" if this was already my device address
        return item.text === preppedName && item.value !== existingEid;
      })
    ) {
      return "Name must be unique - " + name + " already in use on map";
    }

    return true;
  }

  /**
   * Create a bootstrap alert modal and display it
   */
  bootstrapAlert(title, message, actionButton1, actionButton2, actionButton3) {
    return d3Select(
      CoreScripts.bootstrapAlert(
        title,
        message,
        actionButton1 ? $(actionButton1.node()) : null,
        actionButton2 ? $(actionButton2.node()) : null,
        actionButton3 ? $(actionButton3.node()) : null
      )
    );
  }

  /**
   * getElementsMatchingEidFromObject
   * Filter a json object to find all markers with a proper eid
   * @param  {string} testEid
   * @param  {json object} inputObject
   * @return {json object}
   */
  getElementsMatchingEidFromObject(testEid, inputObject) {
    var outputObject = {};
    Object.keys(inputObject).forEach(function (key) {
      var value = inputObject[key];
      if (value.eid === testEid) {
        outputObject[key] = value;
      }
    });

    return outputObject;
  }

  /**
   * Take a string and trim out extraneous characters to get just a room "Number"
   */
  getRoomNumberFromString(roomNameString) {
    const nameRegex = /(\s|-)*\bRoom\b(\s|-)*/i;
    var replaced = roomNameString
      .replace(nameRegex, " ")
      .replace("  ", " ")
      .trim();
    if (replaced.indexOf("Anteroom") >= 0) {
      const anteroomRegex = /(\s|-)*\bAnteroom\b(\s|-)*/i;
      replaced =
        roomNameString.replace(anteroomRegex, " ").replace("  ", " ").trim() +
        "A";
    }

    return replaced;
  }

  /**
   * Show the Toggle Layers Modal
   */
  showToggleLayersModal() {
    var _this = this;

    if (
      $("#nodeMapToggleLayersModal") &&
      $("#nodeMapToggleLayersModal").is(":visible")
    ) {
      $("#nodeMapToggleLayersModal").modal("hide");
    }

    //Create Modal
    var modalToggleLayers = _this
      .d3Select(":root body")
      .append("div")
      .attr("id", "nodeMapToggleLayersModal")
      .attr(
        "class",
        "modal fade retain overlay nodeMap-modal-shared-toggleLayers"
      )
      .attr("role", "dialog");
    var modalContent = modalToggleLayers
      .append("div")
      .attr("class", "modal-dialog modal-md")
      .append("div")
      .attr("class", "modal-content");
    var modalHeader = modalContent.append("div").attr("class", "modal-header");
    modalHeader
      .append("button")
      .attr("class", "close")
      .attr("data-dismiss", "modal")
      .html("&times;");
    modalHeader
      .append("h4")
      .attr("class", "modal-title")
      .html("Toggle Visible Layers");
    var modalBody = modalContent.append("div").attr("class", "modal-body");
    var modalFooter = modalContent
      .append("div")
      .attr("class", "modal-footer clearfix");
    // modalFooter.append("div")
    //     .attr('class', 'pull-left no-padding-left text-left');
    var rightButtons = modalFooter
      .append("div")
      .attr("class", "pull-right no-padding-right text-right");
    rightButtons
      .append("button")
      .attr("type", "button")
      .attr("class", "btn btn-primary")
      .attr("data-dismiss", "modal")
      .html("Done");

    function addShowHideAllButtons(mainHolder) {
      var btnHolder = mainHolder.append("div").attr("class", "pull-right");
      btnHolder
        .append("div")
        .attr(
          "class",
          "btn btn-sm btn-default toggleAllVisibleButton toggleAllVisibleButtonReversed"
        )
        .html(
          '<i class="toggleAllVisibleButtonIcon fa fa-fw fa-sm fa-check-square-o"> </i> All'
        );
    }

    function updateCheckedAllButtonsStatus($holder) {
      var unCheckedCount = $holder.find(
        ".toggleLayersLayerCheckbox:not(:checked)"
      ).length;
      if (unCheckedCount === 0) {
        $holder
          .find(".toggleAllVisibleButton")
          .addClass("toggleAllVisibleButtonReversed");
        $holder
          .find(".toggleAllVisibleButtonIcon")
          .addClass("fa-check-square-o")
          .removeClass("fa-square-o");
      } else {
        $holder
          .find(".toggleAllVisibleButton")
          .removeClass("toggleAllVisibleButtonReversed");
        $holder
          .find(".toggleAllVisibleButtonIcon")
          .addClass("fa-square-o")
          .removeClass("fa-check-square-o");
      }
    }

    var groups = {};
    groups[""] = modalBody
      .append("div")
      .attr("class", "row")
      .append("div")
      .attr("class", "col-xs-12")
      .append("div")
      .attr("class", "toggleLayersGroupHolder");
    addShowHideAllButtons(groups[""]);

    //Add legend
    var legendCheckHolder = groups[""].append("div").attr("class", "fieldset");

    var legendCheckHolderLabel = legendCheckHolder
      .append("label")
      .attr("class", "add-padding-sides");
    var legendCheckbox = legendCheckHolderLabel
      .append("input")
      .attr("type", "checkbox")
      .attr("layerInfoKey", "legend")
      .attr("class", "toggleLayersLayerCheckbox");
    legendCheckHolderLabel
      .append("span")
      .attr("class", "add-padding-sides")
      .html("Legend");
    if (!_this.legend.isHidden) {
      legendCheckbox.property("checked", true);
    }

    const layersInfo = this.getLayersInfo();
    $.each(layersInfo, function (layerIdx) {
      const layerInfo = layersInfo[layerIdx];
      var groupName = "";
      var displayName = layerInfo.name;
      if (displayName.includes(":")) {
        const splitted = displayName.split(":");
        groupName = splitted[0].trim();
        displayName = splitted[1].trim();
      }
      var groupHolder = groups[groupName];
      if (groupHolder === undefined || groupHolder == null) {
        if (Object.keys(groups).length > 0) {
          modalBody.append("hr").attr("class", "minimal-padding-bottom");
        }
        groupHolder = modalBody
          .append("div")
          .attr("class", "row")
          .append("div")
          .attr("class", "col-xs-12")
          .append("div")
          .attr("class", "toggleLayersGroupHolder");
        groups[groupName] = groupHolder;
        var groupNameHolder = groupHolder
          .append("h5")
          .attr("class", "text-underline")
          .html(groupName + ":");
        addShowHideAllButtons(groupNameHolder);
      }
      var checkHolder = groupHolder.append("div").attr("class", "fieldset");

      var checkHolderLabel = checkHolder
        .append("label")
        .attr("class", "add-padding-sides");
      var checkbox = checkHolderLabel
        .append("input")
        .attr("type", "checkbox")
        .attr("layerInfoKey", layerInfo.key)
        .attr("class", "toggleLayersLayerCheckbox");
      checkHolderLabel
        .append("span")
        .attr("class", "add-padding-sides")
        .html(displayName);
      if (layerInfo.visible) {
        checkbox.property("checked", true);
      }
    });

    $(modalContent.node())
      .find(".toggleLayersGroupHolder")
      .each(function () {
        updateCheckedAllButtonsStatus($(this));
      });

    $(modalContent.node())
      .find(".toggleLayersLayerCheckbox")
      .on("change", function () {
        var $thisCheckbox = $(this);
        _this.setLayerHidden(
          $thisCheckbox.attr("layerInfoKey"),
          !($thisCheckbox.prop("checked") === true)
        );
        updateCheckedAllButtonsStatus(
          $thisCheckbox.closest(".toggleLayersGroupHolder")
        );
      });

    $(modalContent.node())
      .find(".toggleAllVisibleButton")
      .on("click", function () {
        var $holder = $(this).closest(".toggleLayersGroupHolder");

        if ($(this).hasClass("toggleAllVisibleButtonReversed")) {
          $holder
            .find(".toggleLayersLayerCheckbox:checked")
            .prop("checked", false)
            .trigger("change");
          updateCheckedAllButtonsStatus($holder);

          return;
        }

        $holder
          .find(".toggleLayersLayerCheckbox:not(:checked)")
          .prop("checked", true)
          .trigger("change");
        updateCheckedAllButtonsStatus($holder);
      });

    //Destroy modal when hidden
    $(modalToggleLayers.node()).on("hidden.bs.modal", function () {
      $(this).remove();
    });
    //Handle Modal Show
    $(modalToggleLayers.node()).modal("show");
  }

  /**
   * Start a continuous size update lasting (at most) 10 seconds
   */
  continuousUpdateLayoutStart = function () {
    const _this = this;
    this.renderingTimer = setInterval(function () {
      // _this.mapResized();
    }, 4);
    //Create watchdog to prevent running forever if something weird happens
    this.renderingTimerWatchdog = setTimeout(function () {
      _this.continuousUpdateLayoutFinish();
    }, 10000);
  };

  /**
   * Finish continuous update and cancel timers
   */
  continuousUpdateLayoutFinish = function () {
    clearInterval(this.renderingTimer);
    clearTimeout(this.renderingTimerWatchdog);
  };

  /**
   * Compute any calculated values we will need many times and cache them.
   */
  buildCalculatedValues() {
    var loadedSvg = this.mapDataXml.getElementsByTagName("svg");
    if (loadedSvg.length > 0) {
      this.mapWidth = this.filterFloat(loadedSvg[0].getAttribute("width"), 0);
      this.mapHeight = this.filterFloat(loadedSvg[0].getAttribute("height"), 0);
    }
  }

  /**
   * Define scales that will be used in the chart
   */
  buildScales() {
    //Store usable THIS var for within this loop
    var _this = this;

    _this.scales = {
      zoomScale:
        Math.min(
          _this.rootWidth / _this.mapWidth,
          _this.rootHeight / _this.mapHeight
        ) / 1.02,
      scale_factor: 1 / _this.filterFloat(_this.map_data.scales.map, 1),
    };
    // Performance color scale
    _this.colorScale = this.currentTheme.scale;

    $.each(_this.map_data.scales, function (key, scale) {
      _this.scales[key] = _this.filterFloat(scale, 1);
    });
    this.kfactor = null;
  }

  /**
   * Called when Window resized/rotated method triggered
   */
  windowResized() {
    this.mapResized();
  }

  /**
   * Resize Map with correct size
   */
  mapResized() {
    this.buildTransforms();
    this.root_svg.call(
      this.transform_selector.transform,
      this.centerTransformObject
    );
  }

  /**
   * Build definition (filter) layers to be used throughout on elements
   */
  buildDefinitionLayers() {
    //Filter for the outside glow red
    this.addFilterGlowLayer("filter-glow-red", CHSHConstants.colors.danger);

    //Filter for the outside glow blue
    this.addFilterGlowLayer("filter-glow-blue", CHSHConstants.colors.primary);

    //Filter for the outside glow purple
    this.addFilterGlowLayer("filter-glow-purple", CHSHConstants.colors.purple);

    //Filter for the outside glow purple
    this.addFilterGlowLayer("filter-glow-black", "black");

    //Rotate counter clockwise image
    this.definition_layers
      .append("pattern")
      .attr("id", "pattern-image-rotate-counterclockwise")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 1)
      .attr("height", 1)
      .attr("viewBox", "0 0 15 20")
      .attr("preserveAspectRatio", "xMidYMid meet")
      .append("svg:image")
      .attr("width", 15)
      .attr("height", 20)
      .attr("xlink:href", "/images/nodeMap/rotate-counterclockwise.svg");

    //Rotate clockwise image
    this.definition_layers
      .append("pattern")
      .attr("id", "pattern-image-rotate-clockwise")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 1)
      .attr("height", 1)
      .attr("viewBox", "0 0 15 20")
      .attr("preserveAspectRatio", "xMidYMid meet")
      .append("svg:image")
      .attr("width", 15)
      .attr("height", 20)
      .attr("xlink:href", "/images/nodeMap/rotate-clockwise.svg");

    //Edit image
    this.definition_layers
      .append("pattern")
      .attr("id", "pattern-image-edit")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 1)
      .attr("height", 1)
      .attr("viewBox", "0 0 23 23")
      .attr("preserveAspectRatio", "xMidYMid meet")
      .append("svg:image")
      .attr("width", 23)
      .attr("height", 23)
      .attr("xlink:href", "/images/nodeMap/edit.svg");
  }

  /**
   * Build definition (filter) layer for a specific name and color combo
   */
  addFilterGlowLayer(name, color) {
    //Better, but not IE and Edge compatible
    var filterGlow = this.definition_layers
      .append("filter")
      .attr("id", name)
      .classed("overflow-hidden", true)
      .attr("x", "-300%")
      .attr("y", "-300%")
      .attr("width", "700%")
      .attr("height", "700%");
    filterGlow
      .append("feDropShadow")
      .attr("dx", 0)
      .attr("dy", 0)
      .attr("stdDeviation", 0.2)
      .attr("flood-color", color)
      .attr("flood-opacity", 1);
    filterGlow
      .append("feDropShadow")
      .attr("dx", 0)
      .attr("dy", 0)
      .attr("stdDeviation", 1.8)
      .attr("flood-color", color)
      .attr("flood-opacity", 1);
    filterGlow
      .append("feDropShadow")
      .attr("dx", 0)
      .attr("dy", 0)
      .attr("stdDeviation", 2)
      .attr("flood-color", color)
      .attr("flood-opacity", 1)
      .attr("result", name + "_glowColor");

    //Key bug fix to make things not dissapear on microsoft browsers
    filterGlow
      .append("feGaussianBlur")
      .attr("in", "SourceAlpha")
      .attr("stdDeviation", 3)
      .attr("result", name + "_blur");
    filterGlow
      .append("feOffset")
      .attr("in", name + "_blur")
      .attr("result", name + "_offsetBlur");
    filterGlow
      .append("feFlood")
      .attr("in", name + "_offsetBlur")
      .attr("flood-color", color)
      .attr("flood-opacity", 1)
      .attr("result", name + "_offsetColor");
    filterGlow
      .append("feComposite")
      .attr("in", name + "_offsetColor")
      .attr("in2", name + "_offsetBlur")
      .attr("operator", "in")
      .attr("result", name + "_offsetBlur");
    var filterMerge = filterGlow.append("feMerge");
    filterMerge.append("feMergeNode").attr("in", name + "_glowColor");
    filterMerge.append("feMergeNode").attr("in", name + "_offsetBlur");
    filterMerge.append("feMergeNode").attr("in", "SourceGraphic");
  }

  /**
   * Build Root Map Transform - This is the starting map transform
   */
  buildTransforms() {
    if (!this.fullscreen) {
      this.root_svg.attr("height", this.allowedMaxHeight);
    }
    var width = this.rootWidth;
    var height = this.rootHeight;

    this.scales.zoomScale =
      Math.min(width / this.mapWidth, height / this.mapHeight) / 1.02;
    var translateX = (width - this.mapWidth * this.scales.zoomScale) / 2;
    var translateY = (height - this.mapHeight * this.scales.zoomScale) / 2;
    this.centerTransformElement =
      "translate(" +
      translateX +
      "," +
      translateY +
      ")scale(" +
      this.scales.zoomScale +
      ")";
    this.centerTransformObject = d3ZoomIdentity
      .translate(translateX, translateY)
      .scale(this.scales.zoomScale);
  }

  /**
   * Within the 'element' that was passed in when the NodeMap was instantiated, build the SVG
   * and group framework, draw bubbles, and hook up event listeners.
   */
  draw() {
    this.drawLegend();
    this.drawMapLayers();
    // this.drawButtons();
    /* Update map dirty status */
    this.refreshDirtyStatus();
  }

  /**
   * Instantiate the root SVG block and append a group to apply a root transform to
   * play with the X/Y coordinates (center to 0,0 currently).
   * Save selector of this root group as this.svg.
   */
  createViewport() {
    this.viewportContainer = d3Select(this.element);
    this.viewportContainer.html("");
    //Create base SVG
    this.root_svg = this.viewportContainer
      // base svg tag setting canvas dimensions
      .append("svg")
      .attr("id", "node_map_root_svg")
      .attr("width", "100%")
      .attr("height", "500")
      .style("cursor", "grab");
    //Definition Layers
    this.definition_layers = this.root_svg
      .append("defs")
      .attr("class", "unexportable");
    //Create white background for export
    this.background_layer = this.root_svg
      .append("rect")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("fill", "white");
    //Bottom static layers group
    this.static_layers_bottom = this.root_svg.append("g");
    //Main map layers group
    this.layer_root = this.root_svg
      .append("g")
      .attr("transform", this.centerTransformElement);
    //Top static layers group
    this.static_layers_top = this.root_svg.append("g");

    /* Static, Interaction (button) Layers that are not exported */
    this.static_interaction_layers = this.root_svg
      .append("g")
      .attr("class", "unexportable");
  }

  /**
   * Create Layer Hierarchy
   */
  createLayers() {
    var _this = this;
    /* Create Image Layer for SVG */
    _this.layers.image = new ImageLayer(
      _this,
      "image",
      _this.map_data.layerDisplayNames.image
    );
    /* Render user desired layers */
    _this.map_layers.forEach(function (layerName) {
      if (_this.layerTypes[layerName] === undefined) {
        // Continue
        return true;
      }
      _this.layers[layerName] = new _this.layerTypes[layerName](
        _this,
        layerName,
        _this.map_data.layerDisplayNames[layerName]
      );
    });
  }

  /**
   * Draw all map layers in the map layer root
   */
  drawMapLayers() {
    var _this = this;
    _this.canvasLayer = _this.layer_root
      .append("rect")
      .attr("class", "canvas")
      .attr("pointer-events", "all")
      .attr("height", _this.mapHeight)
      .attr("width", _this.mapWidth)
      .style("opacity", 0)
      .datum({});

    var flatLayers = [];
    $.each(_this.layers, function (index, layer) {
      flatLayers.push(layer);
    });

    $.each(
      flatLayers.sort(function (a, b) {
        return a.sortDepthIndex - b.sortDepthIndex;
      }),
      function (index, layer) {
        _this.layer_root
          .append("g")
          .attr("class", "maplayer " + layer.class)
          .attr("id", layer.id);
        layer.render();
      }
    );

    this.transform_selector = d3Zoom()
      .extent([
        [0, 0],
        [300, 300],
      ])
      .scaleExtent([_this.scales.zoomScale, _this.scales.zoomScale * 8])
      .on("zoom", function (event) {
        if (isNaN(event.transform.x)) {
          return;
        }
        _this.layer_root.attr("transform", event.transform);
        _this.kfactor = event.transform.k;
        _this.getAllMarkers().dispatch("mapzoomchanged");
      });

    this.root_svg.call(this.transform_selector);
    this.root_svg.call(
      this.transform_selector.transform,
      this.centerTransformObject
    );
    this.root_svg.on("dblclick.zoom", null);
  }

  /**
   * Draw Button interaction controls
   */
  // drawButtons() {
  //   var _this = this;
  //   _this.buttons = this.defaultButtons
  //     .concat(_this.extraButtons)
  //     .filter(function (item) {
  //       if ("shouldBeVisibleCallback" in item) {
  //         return item.shouldBeVisibleCallback(_this);
  //       }

  //       return true;
  //     });
  //   if (_this.buttons.length === 0) {
  //     return;
  //   }
  //   /* fontawesome button labels */

  //   /* colors for different button states */
  //   function getDefaultColorForButton(buttonData) {
  //     return buttonData.color || _CHSHConstants.buttonPrimary;
  //   }

  //   function getHoverColorForButton(buttonData) {
  //     return shadeColor(getDefaultColorForButton(buttonData), -0.3);
  //   }

  //   function shadeColor(color, percent) {
  //     /* eslint-disable no-bitwise, sort-vars */
  //     var f = parseInt(color.slice(1), 16),
  //       t = percent < 0 ? 0 : 255,
  //       p = percent < 0 ? percent * -1 : percent,
  //       R = f >> 16,
  //       G = (f >> 8) & 0x00ff,
  //       B = f & 0x0000ff;

  //     return (
  //       "#" +
  //       (
  //         0x1000000 +
  //         (Math.round((t - R) * p) + R) * 0x10000 +
  //         (Math.round((t - G) * p) + G) * 0x100 +
  //         (Math.round((t - B) * p) + B)
  //       )
  //         .toString(16)
  //         .slice(1)
  //     );
  //   }

  //   /* groups for each button (which will hold a rect and text) */
  //   this.button_layer = this.static_interaction_layers
  //     .append("g")
  //     .attr("class", "button-layer");
  //   var buttonGroups = this.button_layer
  //     .selectAll("g.button")
  //     .data(this.buttons)
  //     .enter()
  //     .append("a")
  //     .attr("class", "button")
  //     .attr("id", function (d) {
  //       return d.identifier;
  //     })
  //     .style("cursor", "pointer")
  //     .on("changeColor", function (event, buttonData) {
  //       var newColor = event.detail.color;
  //       if (newColor === "primary") {
  //         newColor = _CHSHConstants.buttonPrimary;
  //       } else if (newColor === "danger") {
  //         newColor = _CHSHConstants.bootstrapDanger;
  //       } else if (newColor === "success") {
  //         newColor = _CHSHConstants.bootstrapSuccess;
  //       } else if (newColor === "info") {
  //         newColor = _CHSHConstants.bootstrapInfo;
  //       }
  //       buttonData.color = newColor;
  //       d3buttonUpdateColor(this, buttonData, true);
  //     })
  //     .on("click", function (event, buttonData) {
  //       event.stopPropagation();
  //       d3ButtonClicked(this, buttonData);

  //       return false;
  //     })
  //     .on("mouseover", function (event, buttonData) {
  //       buttonData.moused = true;
  //       d3buttonUpdateColor(this, buttonData);
  //     })
  //     .on("mouseout", function (event, buttonData) {
  //       buttonData.moused = false;
  //       d3buttonUpdateColor(this, buttonData);
  //     })
  //     .call(
  //       d3Drag()
  //         // .on("start", function () {
  //         // })
  //         .on("drag", function (event, buttonData) {
  //           buttonData.dragged = true;
  //         })
  //         .on("end", function (event, buttonData) {
  //           if (buttonData.moused === true && buttonData.dragged === true) {
  //             d3ButtonClicked(this, buttonData);
  //           }
  //           buttonData.dragged = false;
  //         })
  //     );

  //   function d3buttonUpdateColor(button, buttonData, animated = false) {
  //     var active = buttonData.moused || false;

  //     if (animated) {
  //       d3Select(button)
  //         .select("rect")
  //         .transition()
  //         .duration(200)
  //         .attr("fill", function (buttonData) {
  //           return active
  //             ? getHoverColorForButton(buttonData)
  //             : getDefaultColorForButton(buttonData);
  //         });
  //     } else {
  //       d3Select(button)
  //         .select("rect")
  //         .attr("fill", function (buttonData) {
  //           return active
  //             ? getHoverColorForButton(buttonData)
  //             : getDefaultColorForButton(buttonData);
  //         });
  //     }
  //   }

  //   var bWidth = 40; //button width
  //   var bHeight = 35; //button height
  //   var bSpace = 3; //space between buttons
  //   var x0 = 0; //x offset
  //   var y0 = 0; //y offset

  //   //adding a rect to each toggle button group
  //   //rx and ry give the rect rounded corner
  //   buttonGroups
  //     .append("rect")
  //     .attr("class", "buttonRect")
  //     .attr("width", bWidth)
  //     .attr("height", bHeight)
  //     .attr("x", function (d, i) {
  //       return x0 + (bWidth + bSpace) * i;
  //     })
  //     .attr("y", y0)
  //     /* rx and ry give the buttons rounded corners */
  //     .attr("rx", 5)
  //     .attr("ry", 5)
  //     .attr("fill", function (buttonData) {
  //       return getDefaultColorForButton(buttonData);
  //     });

  //   //adding text to each toggle button group, centered
  //   //within the toggle button rect
  //   buttonGroups
  //     .append("text")
  //     .attr("class", "buttonText")
  //     .attr("font-family", "FontAwesome")
  //     .attr("x", function (d, i) {
  //       return x0 + (bWidth + bSpace) * i + bWidth / 2;
  //     })
  //     .attr("y", y0 + bHeight / 2)
  //     .attr("dy", "0.375em")
  //     .attr("line-height", "1em")
  //     .attr("text-anchor", "middle")
  //     .attr("fill", "white")
  //     .text(function (d) {
  //       return d.label;
  //     });

  //   function d3ButtonClicked(button, buttonData) {
  //     /* Animate Click */
  //     buttonData.moused = false;
  //     //Timeout to avoid accidental double clicks
  //     if (
  //       buttonData.lastClicked === undefined ||
  //       buttonData.lastClicked < Date.now() - 200
  //     ) {
  //       d3Select(button)
  //         .select("rect")
  //         .transition()
  //         .duration(100)
  //         .attr("fill", function (buttonData) {
  //           return getHoverColorForButton(buttonData);
  //         })
  //         .on("end", function () {
  //           d3Select(button)
  //             .select("rect")
  //             .transition()
  //             .delay(50)
  //             .duration(200)
  //             .attr("fill", function (buttonData) {
  //               return getDefaultColorForButton(buttonData);
  //             });
  //         });
  //       /* Act on Click */
  //       if (buttonData.localFunction !== undefined) {
  //         _this[buttonData.localFunction]();
  //       } else if (buttonData.windowFunction !== undefined) {
  //         var _window = window;
  //         _window[buttonData.windowFunction]();
  //       }
  //     }
  //     buttonData.lastClicked = Date.now();
  //   }
  // }

  /**
   * Draw the map legend
   */
  drawLegend() {
    const _this = this;

    this.static_layers_top.append(function () {
      return _this.legend.sel.node();
    });

    _this.legend.draw();
  }
}
