import ModuleLogo from "../../Components/ModuleLogo/ModuleLogo";
import Cards from "../../Components/UI/Cards/Cards";
import Tooltip from "../../Components/UI/Tooltip/Tooltip";
import { tooltipContent } from "../../content";
import { useUserInfo } from "../../contexts/UserInfo/userInfo";
import { modulesData } from "../../constants";

function OverviewPage() {
  const { userInfo } = useUserInfo();

  return (
    <>
      <div className="page-header">
        <h1>
          Hello {userInfo?.user?.firstName} {userInfo?.user?.lastName}
        </h1>
      </div>
      <div className="overview-description-grid">
        <div className="modules-card">
          <Cards
            headerLeft={<h2 className="enlarged-text">Your Modules</h2>}
            headerRight={
              <Tooltip
                content={tooltipContent.overviewPage["en-US"]}
                id="overview-module-toggle"
              ></Tooltip>
            }
          >
            <div className="display-flex flex-wrap" data-testid="module-logos">
              {modulesData.map(
                ({ moduleId, iconUrl, testid, isModuleToggle }) => {
                  const hasModule = userInfo?.modules?.hasOwnProperty(moduleId);

                  return (
                    <ModuleLogo
                      key={moduleId}
                      moduleId={hasModule ? moduleId : null}
                      iconUrl={iconUrl}
                      testid={testid}
                      isModuleToggle={isModuleToggle}
                      isDisabled={!hasModule}
                    />
                  );
                }
              )}
            </div>
          </Cards>
        </div>
      </div>
    </>
  );
}

export default OverviewPage;
