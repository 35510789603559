import LinearProgress from "../UI/LinearProgress/LinearProgress";
import Table from "../../Components/Table/Table";
import { formatTransformedPerformanceData } from "../../utils";
import { UnitOrGroupDataSeries } from "../../types/types";

type PerformanceDetailProps = {
  dataSeries: UnitOrGroupDataSeries[];
  overrideColumnNames?: { name: string; label: string }[];
};

function PerformanceDetail(props: PerformanceDetailProps) {
  const defaultColumns = [
    {
      name: "unit_name",
      label: "Unit Name",
    },
    {
      name: "opportunities",
      label: "Opportunities",
      tableHead: {
        size: "small",
      },
    },
    {
      name: "performance",
      label: "Performance",
      options: {
        customBodyRender: (value: number) => {
          return (
            <LinearProgress
              percentage={value}
              data-testid="linear-progress"
            ></LinearProgress>
          );
        },
      },
    },
  ];

  const columns = defaultColumns.map((column, i) => {
    if (!props.overrideColumnNames) {
      return column;
    }

    if (i < props?.overrideColumnNames.length) {
      return { ...column, ...props.overrideColumnNames[i] };
    }

    return column;
  });

  const options = {
    sortOrder: {
      name: "performance",
      direction: "desc",
    },
  };

  return (
    <Table
      title={"Performance Detail"}
      data={formatTransformedPerformanceData(props.dataSeries)}
      columns={columns}
      optionOverrides={options}
    ></Table>
  );
}

export default PerformanceDetail;
