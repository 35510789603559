import Table from "../Table/Table";
import MissingData from "../MissingData/MissingData";
import { NIDataTable } from "../../types/types";
import LinearProgress from "../UI/LinearProgress/LinearProgress";
import Cards from "../UI/Cards/Cards";

type RoundingTableProps = {
  data: NIDataTable[];
  userInfo?: Object;
  isFilterSelected?: boolean;
};

type Column = {
  name: string;
  label: string;
  options?: {
    display?: boolean;
    customBodyRender?: (value: number) => JSX.Element | string;
  };
};

function RoundingTable(props: RoundingTableProps) {
  if (props.data.length === 0) {
    return (
      <Cards headerLeft={<h2>Rounding Performance Over Time</h2>}>
        <div className="graph-text-center">
          <MissingData
            message={"No Data Available."}
            action={"Please make a different selection."}
          />
        </div>
      </Cards>
    );
  }
  let columns: Column[] = [
    {
      name: "unit",
      label: "Unit",
      options: {
        display: props.isFilterSelected,
      },
    },
    {
      name: "facility",
      label: "Facility",
    },
  ];
  for (const key in props.data[0]) {
    if (key.includes("shift") && props.data[0][key][0] !== null) {
      let shiftObj = {
        name: key,
        label: props.data[0][key][1],
        options: {
          customBodyRender: (value: number) => {
            return (
              <LinearProgress
                percentage={value[0].toFixed()}
                data-testid="linear-progress"
              ></LinearProgress>
            );
          },
        },
      };
      columns.push(shiftObj);
    } else if (key.includes("shift") && props.data[0][key][0] === null) {
      let shiftObj = {
        name: key,
        label: props.data[0][key][1],
        options: {
          customBodyRender: () => {
            return "No Data Available";
          },
        },
      };
      columns.push(shiftObj);
    }
  }

  const options = {
    sortOrder: {
      name: "percentage",
      direction: "desc",
    },
  };

  return (
    <div>
      <Table
        title={"Rounding Performance Breakout"}
        data={props.data}
        columns={columns}
        optionOverrides={options}
      ></Table>
    </div>
  );
}

export default RoundingTable;
