import { get } from "../../api/laravelApi";
import { assetsEndpoint } from "../../constants";

export const AssetsLoader = async ({ request }) => {
  const customerIds = new URL(request.url).searchParams.getAll("customersIds");
  const facilityIds = new URL(request.url).searchParams.getAll("facilitiesIds");
  const typeIds = new URL(request.url).searchParams.getAll("typeIds");
  const modelIds = new URL(request.url).searchParams.getAll("modelIds");
  const unitIds = new URL(request.url).searchParams.getAll("unitsIds");

  const data = await get(assetsEndpoint, {
    customerIds,
    facilityIds,
    typeIds,
    modelIds,
    unitIds,
  });

  return data;
};
