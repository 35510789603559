import Cards from "../../Components/UI/Cards/Cards";
import useOtibViewController from "../../viewcontroller/Otibs/useOtibsViewController";
import DatePicker from "../../Components/DatePicker/DatePicker";
import { defaultDateRange, maxDayFilter, maxWeekFilter } from "../../constants";
import Filters from "../../Components/Filters/Filters";
import NodeMap from "../../Components/NodeMap/NodeMap";
import { useUUIDWithLocation } from "../../Hooks/useUuid";
import { useEffect } from "react";
import DatePickerDropdown from "../../Components/DatePickerDropdown/DatePickerDropdown";

function Otib() {
  const {
    transformedOtib,
    handleDateChange,
    filters,
    onFilterChange,
    applyFilters,
    toggleButton,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
    aggregate,
  } = useOtibViewController();

  const { updateUUID } = useUUIDWithLocation();

  useEffect(() => {
    updateUUID(transformedOtib?.uuid);
  }, [transformedOtib?.uuid, updateUUID]);

  return (
    <div className="content-container">
      <div className="page-header">
        <div>
          <h1>Over Time Intervention Blueprint</h1>
        </div>
      </div>
      <div className="margin-bottom">
        <Cards>
          <div className="filters filters-margin-top">
            <div>
              <DatePickerDropdown
                startDateLabel={
                  transformedOtib.startDate ?? defaultDateRange.startDate
                }
                endDateLabel={
                  transformedOtib.endDate ?? defaultDateRange.endDate
                }
                onChange={handleDatePickerChange}
              />
              {isDatePickerVisible && (
                <DatePicker
                  handleDatePickerChange={() => handleDatePickerChange}
                  visible={isDatePickerVisible}
                  maxNumDays={maxDayFilter}
                  maxNumWeeks={maxWeekFilter}
                  aggregate={aggregate}
                  startDate={
                    transformedOtib.startDate ?? defaultDateRange.startDate
                  }
                  endDate={transformedOtib.endDate ?? defaultDateRange.endDate}
                  onChange={handleDateChange}
                  handleClick={handleDatePickerButtonClick}
                />
              )}
            </div>
            <Filters
              filters={filters}
              onChange={onFilterChange}
              toggleButton={toggleButton}
              applyFilters={applyFilters}
            />
          </div>
        </Cards>
      </div>
      <NodeMap transformedData={transformedOtib} />
    </div>
  );
}

export default Otib;
