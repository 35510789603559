//Imports
import ElementArtworkHelper from "./ElementArtworkHelper";
import { create as d3Create } from "d3";

export default class LegendRenderer {
  container;

  artwork;

  sectionsCount;

  shapeSize = 7;

  textSize = 7;

  col1 = { x: 10 };

  col2 = { x: 30 };

  leftOffset = 2;

  primaryColor;

  constructor(primaryColor) {
    this.container = d3Create("svg:g")
      .attr("class", "legend-holder")
      .attr("id", "legend-holder")
      .attr("transform", "translate(6, 55)");
    this.artwork = new ElementArtworkHelper();
    this.primaryColor = primaryColor;
    this.sectionsCount = 0;
  }

  /* Get the Layer's Selector */
  get sel() {
    return this.container;
  }

  draw(itemsCallback) {
    this.container
      .selectAll(".legend-background,.legendHolderInner,.legendBackground")
      .remove();
    this.sectionsCount = 0;

    const legendBackground = this.container
      .append("rect")
      .attr("fill", "white")
      .style("opacity", 0.9)
      .attr("stroke", this.primaryColor)
      .attr("stroke-width", 1)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("id", "legend-background");

    const innerLegendHolder = this.container
      .append("g")
      .attr("class", "legendHolderInner");

    itemsCallback();

    if (this.sectionsCount === 0) {
      return;
    }

    this.container
      .selectAll(".legendTitle")
      .style("text-decoration", "underline")
      .style("cursor", "grab")
      .style("display", "block")
      .style("font-size", "16px")
      .style("font-weight", "bold")
      .style("height", "20px")
      .style("line-height", "20px");

    this.container
      .selectAll(".label")
      .style("cursor", "grab")
      .style("display", "block")
      .style("font-size", "10.5px")
      .style("font-weight", "bold")
      .style("height", "18px")
      .style("line-height", "10px")
      .style("padding-left", "2px")
      .attr("dominant-baseline", "bottom")
      .attr("alignment-baseline", "bottom");

    const dim = innerLegendHolder.node().getBBox();
    legendBackground
      .attr("width", dim.width + 10)
      .attr("height", dim.height + 10)
      .attr("class", "legendBackground")
      .attr("transform", "translate(-5, -17)");
  }

  getHeight() {
    return this.container.select(".legendHolderInner").node().getBBox().height;
  }

  addLegendSection() {
    const padY = this.sectionsCount === 0 ? 0 : 10;
    this.sectionsCount++;

    return this.container
      .select(".legendHolderInner")
      .append("g")
      .attr("transform", "translate(0, " + (this.getHeight() + padY) + ")");
  }

  drawLine(
    wrapper,
    color,
    xPosition,
    yPosition,
    startCoords = [4, 0],
    endCoords = [4 + this.shapeSize * 2, 0]
  ) {
    wrapper
      .append("line")
      .style("stroke", color)
      .style("stroke-width", 3)
      .attr("x1", startCoords[0] + xPosition)
      .attr("y1", startCoords[1] + yPosition)
      .attr("x2", endCoords[0] + xPosition)
      .attr("y2", endCoords[1] + yPosition);
  }

  drawShape(
    wrapper,
    color,
    shapeType,
    fillType,
    xPosition,
    yPosition,
    shapeSize = null
  ) {
    const bodyColor = fillType === "fill" ? color : "white";
    const strokeColor = fillType === "stroke" ? color : null;
    xPosition += this.col1.x;

    let shape = null;
    if (shapeType === "square") {
      shape = this.artwork.drawSquare(
        wrapper,
        shapeSize ?? this.shapeSize,
        2,
        bodyColor,
        strokeColor,
        0,
        1,
        1
      );
    } else if (shapeType === "circle") {
      shape = this.artwork.drawCircle(
        wrapper,
        shapeSize ?? this.shapeSize,
        2,
        bodyColor,
        strokeColor,
        0,
        1,
        1
      );
    } else if (shapeType === "diamond") {
      shape = this.artwork.drawDiamond(
        wrapper,
        shapeSize ?? this.shapeSize,
        2,
        bodyColor,
        strokeColor,
        0,
        1,
        1
      );
    } else if (shapeType === "triangle") {
      shape = this.artwork.drawTriangle(
        wrapper,
        shapeSize ?? this.shapeSize,
        2,
        bodyColor,
        strokeColor,
        0,
        1,
        1
      );
    } else if (shapeType === "cross") {
      shape = this.artwork.drawCross(
        wrapper,
        shapeSize ?? this.shapeSize,
        2,
        bodyColor,
        strokeColor,
        0,
        1,
        1
      );
    } else if (shapeType === "star") {
      shape = this.artwork.drawStar(
        wrapper,
        shapeSize ?? this.shapeSize,
        2,
        bodyColor,
        strokeColor,
        0,
        1,
        1
      );
    }

    if (shape === null) {
      throw new Error("invalid shapeType: " + shapeType);
    }

    return shape.attr("transform", function () {
      return "translate(" + xPosition + "," + yPosition + ")";
    });
  }

  drawSquare(wrapper, color, fillType, xPosition, yPosition) {
    return this.drawShape(
      wrapper,
      color,
      "square",
      fillType,
      xPosition,
      yPosition
    );
  }

  drawCircle(wrapper, color, fillType, xPosition, yPosition) {
    return this.drawShape(
      wrapper,
      color,
      "circle",
      fillType,
      xPosition,
      yPosition
    );
  }

  drawCircleWithTail(wrapper, color, fillType, xPosition, yPosition) {
    this.drawShape(wrapper, color, "circle", fillType, xPosition, yPosition);

    return this.drawLine(
      wrapper,
      color,
      xPosition,
      yPosition,
      [18, 0],
      [24, 0]
    );
  }

  drawText(wrapper, color, text, xPosition, yPosition) {
    xPosition += this.col1.x;

    return this.artwork
      .drawText(
        wrapper,
        text,
        color,
        1,
        1,
        this.textSize + 4,
        0,
        "transparent",
        600
      )
      .attr("transform", function () {
        return "translate(" + xPosition + "," + yPosition + ")";
      });
  }

  drawLabel(wrapper, text, xPosition, yPosition) {
    const color = this.primaryColor;
    xPosition += this.col2.x;
    yPosition += 1;

    return this.artwork
      .drawText(
        wrapper,
        text,
        color,
        1,
        1,
        this.textSize,
        0,
        "transparent",
        600
      )
      .attr("class", "label")
      .attr("text-anchor", "start")
      .attr("transform", function () {
        return "translate(" + xPosition + "," + yPosition + ")";
      });
  }

  drawColorScale(wrapper, colorScale, xPosition, yPosition) {
    let legendColorXOffset = xPosition + 2;
    for (const color in colorScale) {
      this.drawShape(
        wrapper,
        color,
        "square",
        "fill",
        legendColorXOffset,
        yPosition,
        9
      );
      const textXOffset = legendColorXOffset + this.col1.x;
      const textYOffset = yPosition + 20;
      this.artwork
        .drawText(
          wrapper,
          colorScale[color],
          this.primaryColor,
          1,
          1,
          this.textSize,
          10,
          "transparent",
          600
        )
        .attr("class", "label")
        .attr("text-anchor", "middle")
        .attr("transform", function () {
          // labels that go under the legend
          return "translate(" + textXOffset + "," + textYOffset + ")";
        });
      legendColorXOffset += 55;
    }
  }
}
