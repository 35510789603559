import { get } from "../../api/laravelApi";
import { assetsEndpoint } from "../../constants";

export const IndividualAssetsLoader = async ({ params, request }) => {
  const startDate = new URL(request.url).searchParams.get("startDate");
  const endDate = new URL(request.url).searchParams.get("endDate");
  const data = get(`${assetsEndpoint}/${params.id}`, {
    startDate,
    endDate,
  });

  return data;
};
