export const groupElementIds = ["pdf-graph", "histogram"];

export const groupTitles = ["Performance Report", "Histogram of Providers"];

export const executiveElementIds = [
  "heatmap",
  "exec-dashboard-unit-current",
  "exec-dashboard-unit-previous",
  "exec-dashboard-group-current",
  "exec-dashboard-group-previous",
];

export const executiveTitles = [
  "Hand Hygiene Performance by Facility",
  "Performance by Unit Type - Current Month",
  "Performance by Unit Type - Previous Month",
  "Performance by Group Type - Current Month",
  "Performance by Group Type - Previous Month",
];

export const unitElementIds = ["pdf-graph", "histogram"];

export const unitTitles = ["Performance Report", "Histogram of Providers"];

export const staffElementIds = ["pdf-graph"];

export const staffTitles = ["Hand Hygiene Performance By Staff"];

export const myReportElementIds = ["pdf-graph"];

export const myReportTitles = ["My Reports Performance Over Time"];

export const individualStaffElementIds = ["pdf-graph"];

export const individualStaffTitles = ["Hand Hygiene Performance By Staff"];
