import { confirmAlert } from "react-confirm-alert";

import Danger from "@untitled-ui/icons-react/build/cjs/Trash01";

const DeleteButton = (props) => {
  const submitAlert = (e) => {
    e.stopPropagation();
    confirmAlert({
      title: props.title,
      message: props.message,
      buttons: [
        {
          label: "Yes",
          onClick: props.onClickYes,
        },
        {
          label: "No",
          onClick: props.onClickNo,
        },
      ],
    });
  };

  return (
    <>
      <button className="danger" onClick={submitAlert}>
        <Danger />
        Delete
      </button>
    </>
  );
};
export default DeleteButton;
