import { useLoaderData } from "react-router-dom";
import { StaffReportDTO } from "../types";

const useStaffReportModel = () => {
  const staffReportData = useLoaderData() as StaffReportDTO;

  return {
    staffReportData,
  };
};

export default useStaffReportModel;
