import Logout from "@untitled-ui/icons-react/build/cjs/LogOut01";
import { useUserInfo } from "../../contexts/UserInfo/userInfo";
import { post } from "../../api/laravelApi";

function LogoutButton() {
  const { userInfo } = useUserInfo();
  async function handleClick() {
    const token = userInfo?.tokens.csrf;
    const additionalHeaders = {
      "X-CSRF-TOKEN": token,
    };

    try {
      const response = await post({
        url: `${process.env.REACT_APP_AUTH_DOMAIN}/logout`,
        additionalHeaders,
      });

      if (response.ok) {
        window.location.assign(
          process.env.REACT_APP_AUTH_DOMAIN +
            "?redirectAddress=https://" +
            process.env.REACT_APP_ANALYTICS_DOMAIN +
            "&_storeSourceAsIntendedUrl=true"
        );
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  }

  return (
    <>
      <button onClick={handleClick} className="logout navigation-button-white">
        <Logout />
        Log Out
      </button>
    </>
  );
}

export default LogoutButton;
