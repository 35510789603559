import { getColor } from "../../../utils";

type LinearProgressProps = {
  percentage: number;
};

function LinearProgress(props: LinearProgressProps) {
  return (
    <div className="LinearProgress">
      <div className="progress-container">
        <div className="background">
          <div
            className="filler"
            style={{
              width: props.percentage + "%",
              backgroundColor: getColor(props.percentage),
            }}
          ></div>
        </div>
        <p>{props.percentage}%</p>
      </div>
    </div>
  );
}

export default LinearProgress;
