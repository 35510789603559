import LinearProgress from "../UI/LinearProgress/LinearProgress";
import Table from "../../Components/Table/Table";
import MissingData from "../MissingData/MissingData";
import { AverageCompliance } from "../../types/types";
import { navigateToStaffPage } from "../../utils";

type StaffTableProps = {
  data: AverageCompliance[];
  userInfo?: Object;
};

function StaffTable(props: StaffTableProps) {
  const columns = [
    {
      name: "name",
      label: "Staff Name",
    },
    {
      name: "lastDetected",
      label: "Last Detected",
    },
    {
      name: "opportunities",
      label: "Opportunities",
    },
    {
      name: "percentage",
      label: "Performance",
      options: {
        customBodyRender: (value: number) => {
          return (
            <LinearProgress
              percentage={value}
              data-testid="linear-progress"
            ></LinearProgress>
          );
        },
      },
    },
  ];

  const options = {
    onRowClick: (rowData) => {
      navigateToStaffPage(props.userInfo, rowData[0]);
    },
    rowHover: true,
    sortOrder: {
      name: "percentage",
      direction: "desc",
    },
    textLabels: {
      body: {
        noMatch: (
          <MissingData
            message={"No Data Available."}
            action={"Please make a different selection."}
          />
        ),
      },
    },
  };

  return (
    <div className="staff-table">
      <Table
        title={"Hand Hygiene Performance by Staff"}
        data={props.data}
        columns={columns}
        optionOverrides={options}
      ></Table>
    </div>
  );
}

export default StaffTable;
