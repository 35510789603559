import Download from "@untitled-ui/icons-react/build/cjs/Download01";
import Hourglass from "@untitled-ui/icons-react/build/cjs/Hourglass01";

type DownloadButtonProps = {
  click?: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  cta: string;
};

function DownloadButton(props: DownloadButtonProps) {
  return (
    <>
      <button onClick={props.click} className="themed download-button">
        {props.loading ? <Hourglass /> : <Download />}

        {props.loading ? "Downloading..." : props.cta}
      </button>
    </>
  );
}

export default DownloadButton;
