import { AverageComplianceNI } from "../../types/types";
import { getColor } from "../../utils";

type BubbleData = {
  x: number;
  y: number;
  r: number;
};

export const getBubbleChartNIData = (data: AverageComplianceNI[]) => {
  if (!data || data.length === 0) {
    return {
      labels: [],
      backgroundColors: [],
      mappedAndFilteredData: [],
    };
  }

  const MIN_BUBBLE_RADIUS = 3;
  const MAX_BUBBLE_RADIUS = 33;

  // we convert seconds to hours below, so anything less than an hour will result in 0 on the x-axis
  const filterZeroOpportunityHours = (data: AverageComplianceNI[]) => {
    return data.filter((item) => {
      return item.opportunitySeconds >= 3600;
    });
  };

  const filteredData = filterZeroOpportunityHours(data);

  const labels = filteredData.map(
    (item) => item.name || item.nodeName + ": " + item.shiftName
  );

  const mapComplianceToBackgroundColor = (data: AverageComplianceNI[]) => {
    return data.map((item) => {
      return getColor(item.performance);
    });
  };

  const backgroundColors = mapComplianceToBackgroundColor(filteredData);

  const findDivisor = (data: AverageComplianceNI[]) => {
    const highestNumOpps = Math.max(
      ...data.map((item) => item.opportunitySeconds)
    );
    let divisor = Math.floor(highestNumOpps / MAX_BUBBLE_RADIUS);

    return divisor;
  };

  const divisor = findDivisor(filteredData);

  const mappedAndFilteredData: BubbleData[] = filteredData.map((item) => {
    let rValue = Math.round(item.opportunitySeconds / divisor);
    if (rValue < MIN_BUBBLE_RADIUS) {
      rValue = MIN_BUBBLE_RADIUS;
    }
    const occupiedHours = Math.floor(item.opportunitySeconds / 3600);
    return {
      x: occupiedHours,
      y: item.performance,
      r: rValue,
    };
  });

  return {
    labels,
    backgroundColors,
    mappedAndFilteredData,
  };
};
