import useStaffReportModel from "../../model/Staff/useStaffReportModel";
import { transformStaffReport } from "../../transformers/staffReport/transformStaffReport";

const useStaffReportViewModel = () => {
  const { staffReportData } = useStaffReportModel();

  const transformedStaffReportData = transformStaffReport(staffReportData);

  return {
    transformedStaffReportData,
  };
};

export default useStaffReportViewModel;
