import { rtibDTO } from "../../model/types";
import { transformFilters } from "../utils";

export function transformRtib(responseJson: rtibDTO) {
  const uuid = responseJson?.rtibData?.uuid;
  const rtibData = responseJson?.rtibData?.validDataResults;
  const filtersData = responseJson?.rtibData?.params;
  const error = responseJson?.rtibData?.error;

  const filters = transformFilters(filtersData);

  const d3Data = rtibData?.map((data) => {
    return {
      map_data: data.map_data,
      markers: data.markers,
      subtitle: data.subtitle,
    };
  });

  return { d3Data, filters, error, uuid };
}
