import { useEffect, useState } from "react";
import MissingData from "../../Components/MissingData/MissingData";
import useCasesViewModel from "../../viewmodel/Cases/useCasesViewModel";
import useSharedFunctions from "../sharedFunctions/useSharedFunctions";
import Archive from "@untitled-ui/icons-react/build/cjs/Pin02";
import { useUserInfo } from "../../contexts/UserInfo/userInfo";
import { patch, deleteMethod } from "../../api/laravelApi";
import { newCaseEndpoint } from "../../constants";
import DeleteButton from "../../Components/DeleteButton/DeleteButton";
import { formatTimeWithDate } from "../../transformers/utils";

const useCasesViewController = () => {
  const { transformedCases, uuid } = useCasesViewModel();
  const {
    onFilterChange,
    filters,
    applyFilters,
    toggleButton,
    disableExcelDoc,
    setSelected,
    setPeerFilters,
  } = useSharedFunctions(transformedCases);

  useEffect(() => {
    transformedCases.filters.forEach((filter) => {
      setPeerFilters(filter.name, filter.peerFilters!);
      setSelected(filter.name, filter.selection);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { userInfo } = useUserInfo();
  const [apiError, setApiError] = useState<string | null>(null);

  const renderBooleanStatus = (value) =>
    value === false ? "Open" : "Archived";

  const createCustomBodyRender = (renderFunction) => ({
    options: {
      customBodyRender: renderFunction,
    },
  });

  const commonColumns = [
    { name: "id", label: "Case Number" },
    { name: "exposure_type", label: "Exposure Type" },
    {
      name: "started_at",
      label: "Exposure Start",
      options: {
        customBodyRender: (value) => {
          return formatTimeWithDate(value.datetime, value.timezone, "Time");
        },
      },
    },
    {
      name: "ended_at",
      label: "Exposure End",
      options: {
        customBodyRender: (value) => {
          return formatTimeWithDate(value.datetime, value.timezone, "Time");
        },
      },
    },
    {
      name: "created_at",
      label: "Created Date",
      options: {
        customBodyRender: (value) => {
          return formatTimeWithDate(value.datetime, value.timezone, "Time");
        },
      },
    },
    { name: "created_by_name", label: "Created By" },
    {
      name: "is_archived",
      label: "Case Status",
      ...createCustomBodyRender(renderBooleanStatus),
    },
    {
      name: "is_archived",
      label: "Actions",
      options: {
        sort: false,
        customBodyRender: (_, tableMeta) => {
          const isArchivedCase = tableMeta.rowData[7];
          const isDisabled = isArchivedCase === true;
          const archiveAction = async (event) => {
            // prevents the row from being clicked and being taken to another link
            event.stopPropagation();

            const token = userInfo?.tokens.csrf;
            const additionalHeaders = {
              "X-CSRF-TOKEN": token,
            };

            const id = tableMeta.rowData[1];
            try {
              await patch({
                additionalHeaders,
                url: `${process.env.REACT_APP_AUTH_DOMAIN}/${newCaseEndpoint}/${id}/archive`,
              });

              setApiError(null);
              window.location.assign(
                "https://" +
                  process.env.REACT_APP_ANALYTICS_DOMAIN +
                  "/CT/cases"
              );
            } catch (e: any) {
              setApiError(e.message);
            }
          };
          const deleteAction = async () => {
            const token = userInfo?.tokens.csrf;
            const additionalHeaders = {
              "X-CSRF-TOKEN": token,
            };

            const id = tableMeta.rowData[1];
            try {
              await deleteMethod({
                additionalHeaders,
                url: `${process.env.REACT_APP_AUTH_DOMAIN}/${newCaseEndpoint}/${id}`,
              });

              setApiError(null);
              window.location.assign(
                "https://" +
                  process.env.REACT_APP_ANALYTICS_DOMAIN +
                  "/CT/cases"
              );
            } catch (e: any) {
              setApiError(e.message);
            }
          };
          return (
            <>
              <button
                className={isDisabled ? "disabled cta" : "cta"}
                onClick={archiveAction}
              >
                <Archive /> Archive
              </button>
              <DeleteButton
                onClickYes={deleteAction}
                message={"Are you sure you want to delete this case?"}
              />
            </>
          );
        },
      },
    },
  ];

  const tableColumnsPositiveStaff = [
    { name: "user_name", label: "Name" },
    ...commonColumns,
  ];

  const tableColumnsPositiveRooms = [
    { name: "room_name", label: "Room Name" },
    ...commonColumns,
  ];

  const positiveStaff = transformedCases?.positiveStaff;
  const positivePatients = transformedCases?.positivePatient;

  const createTableOptions = (path: string) => ({
    rowHover: true,
    download: false,
    sortOrder: {
      name: "is_archived",
      direction: "asc",
    },
    onRowClick: (rowData: any[]) => {
      const id = rowData[1];
      window.location.href = `${path}/${id}`;
    },
    textLabels: {
      body: {
        noMatch: (
          <MissingData
            message={"No Data Available."}
            action={"Please try a different selection"}
          />
        ),
      },
    },
  });

  const tableOptionsStaff = createTableOptions("/CT/positive-staff-case");
  const tableOptionsRooms = createTableOptions("/CT/positive-room-case");

  return {
    tableColumnsPositiveRooms,
    tableColumnsPositiveStaff,
    positiveStaff,
    positivePatients,
    tableOptionsRooms,
    tableOptionsStaff,
    transformedCases,
    filters,
    onFilterChange,
    applyFilters,
    toggleButton,
    disableExcelDoc,
    apiError,
    excelUrl: transformedCases.excelUrl,
    uuid,
  };
};

export default useCasesViewController;
