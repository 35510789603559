import {
  RoomsOverviewRoomsDTO,
  RoomsOverviewSummaryDTO,
} from "../../model/types";
import {
  convertSecondsToHoursMinutesSeconds,
  formatNumberWithCommas,
} from "../../utils";
import { PdfTable } from "../../Hooks/usePdfDownload";

export function transformRoomsOverviewPdfSummaryTable(
  summary: RoomsOverviewSummaryDTO
): PdfTable {
  const timeAlone = summary.averages.timeAlone + "%";
  const timeBetween = convertSecondsToHoursMinutesSeconds(
    summary.averages.timeBetween
  );
  const visitLength = convertSecondsToHoursMinutesSeconds(
    summary.averages.visitLength
  );

  return {
    title: "Summary",
    head: [["Percentage Time Alone", "Time Between Visits", "Length of Visit"]],
    body: [[timeAlone, timeBetween, visitLength]],
  };
}

export function transformRoomsOverviewPdfRoomsTable(
  rooms: RoomsOverviewRoomsDTO
): PdfTable {
  return {
    title: "Rooms",
    head: [
      [
        "Room Name",
        "Average Length of Visit",
        "Number of Visits",
        "Longest Visit",
        "Most Visited By",
      ],
    ],
    body: rooms.roomOverviews.map((row) => {
      const visitLengthAvg = row.visitLengthAvg
        ? convertSecondsToHoursMinutesSeconds(row.visitLengthAvg)
        : "N/A";
      const visitCount = row.visitCount
        ? formatNumberWithCommas(row.visitCount)
        : 0;
      const longestVisit = row.longestVisit
        ? convertSecondsToHoursMinutesSeconds(row.longestVisit)
        : "N/A";
      const user = row.mostVisitedByUser;
      const mostVisitedBy =
        user === null ? "N/A" : `${user.firstName} ${user.lastName}`;

      return [
        row.room.name,
        visitLengthAvg,
        visitCount,
        longestVisit,
        mostVisitedBy,
      ];
    }),
  };
}
