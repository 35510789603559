import { useEffect, useState } from "react";
import RTIB from "../../Components/D3/nodeMap/NodeMap";
import TabsList from "../../Components/UI/Tabs/Tabs";
import Cards from "../../Components/UI/Cards/Cards";
import { missingDataAction, missingDataMessage } from "../../constants";
import MissingData from "../../Components/MissingData/MissingData";
import ApiError from "../../Components/ApiError/ApiError";
import { tooltipContent } from "../../content";
import { formatDate } from "../../utils";

function NodeMap(props) {
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    props.transformedData?.d3Data?.map(
      (data) => {
        return new RTIB({
          element: document.querySelector("#chart" + data.map_data.node_id),
          map_layers: ["heatmap"],
          unplacedMarkers: {},
          layers: {},
          map_data: data.map_data,
          markers: data.markers,
        });
      },
      [props.transformedData]
    );
  });

  const tabs = props.transformedData?.d3Data?.map((data) => {
    return data.subtitle.text;
  });

  const handleSelect = (index) => {
    setSelectedTab(index);
  };

  const content = props.transformedData?.d3Data?.map((data, index) => {
    return (
      <Cards
        headerLeft={
          <div>
            <h2>Location: {data.subtitle.text}</h2>
            <h2>
              Date: {formatDate(new Date(data.subtitle.startDate))} -{" "}
              {formatDate(new Date(data.subtitle.endDate))}
            </h2>
          </div>
        }
        className={`tab ${index === selectedTab ? "active" : "hidden"}`}
      >
        <div
          key={"chart" + data.map_data.node_id}
          id={"chart" + data.map_data.node_id}
        ></div>
      </Cards>
    );
  });

  return (
    <div className="content-container">
      {props.transformedData?.d3Data?.length === 0 ? (
        <>
          <ApiError message={props.transformedData?.error?.message} />
          <Cards>
            <MissingData
              className="margin-top"
              message={missingDataMessage}
              action={missingDataAction}
            />
          </Cards>
        </>
      ) : (
        <TabsList
          showTooltip
          forceRenderTabPanel={true}
          select={handleSelect}
          tabs={tabs}
          content={content}
          tooltipContent={tooltipContent.otibs["en-US"]}
          tooltipId="otib-tooltip"
        ></TabsList>
      )}
    </div>
  );
}

export default NodeMap;
