import { NavLink } from "react-router-dom";
import { useUserInfo } from "../../contexts/UserInfo/userInfo";

import Table from "@untitled-ui/icons-react/build/cjs/LayoutAlt03";

function NavigationAssetTracking() {
  const { userInfo } = useUserInfo();

  // loading bug where pages stick to scroll state of pervious page.
  const scrollToTop = () => {
    document.querySelector("body")?.scrollTo(0, 0);
  };
  return (
    <>
      <div className="navigation-content">
        <div>
          {userInfo?.permissions?.includes("assetTracking.view") && (
            <li>
              <NavLink to="/AT/assets" onClick={scrollToTop}>
                <Table />
                Assets Catalog
              </NavLink>
            </li>
          )}
        </div>
      </div>
    </>
  );
}

export default NavigationAssetTracking;
