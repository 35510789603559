import { useLoaderData } from "react-router-dom";
import { PositiveRoomCaseDTO } from "../types";

const usePositiveRoomCaseModel = () => {
  const positiveRoomCaseData = useLoaderData() as PositiveRoomCaseDTO;

  return {
    positiveRoomCaseData,
  };
};

export default usePositiveRoomCaseModel;
