import { drag as d3Drag } from "d3";
import jQuery from "jquery";
import $ from "jquery";

export default class MapElementEvents {
  /**
   * Extracted setting up event handlers on the primary element group.
   * Set up things like drag, and global (for each element) click handlers.
   *
   * @param  {GenericLayer} layerRef
   * @param  {d3 selection} elementSel
   * @param  {d3 data ref} elementInfo
   */
  setupEvents(layerRef, elementSel, elementInfo) {
    var _this = this;
    var elementInteractionsSel =
      layerRef.elementInteractionSelFromElementSel(elementSel);
    var controlScale = layerRef.scale;
    //Create element interaction sel bounding rect to catch more events
    elementInteractionsSel
      .append("rect")
      .attr("id", "element-interaction-bounding-rect")
      .attr("class", "text-unselectable")
      .style("pointer-events", "visible")
      .attr("width", controlScale * 5)
      .attr("height", controlScale * 5)
      .attr("x", (-controlScale * 5) / 2)
      .attr("y", (-controlScale * 5) / 2)
      .attr("fill", "transparent")
      .attr("rx", controlScale * 5)
      .attr("ry", controlScale * 5)
      .style("cursor", "move")
      .style("visibility", function (d) {
        return d.internal_map_attributes.active ? "visible" : "hidden";
      });

    elementSel
      .on("mouseenter", function (event) {
        event.preventDefault();
        elementInfo.internal_map_attributes.moused = true;
        elementInfo.internal_map_attributes.moused_at =
          elementInfo.internal_map_attributes.moused_at || Date.now();

        //See if currently dragging something
        if (layerRef.chart.isDraggingMarker() === true) {
          //Don't change active if mouseenter is the result of something being dragged
          return;
        }
        if (elementInfo.internal_map_attributes.active) {
          //Don't change active if already active
          return;
        }
        //Deselect everything else before selecting me!
        layerRef.chart.deselectActiveMarkers();
        //Update my display status
        _this.elementActiveStatusChanged(
          layerRef,
          elementSel,
          elementInfo,
          true
        );
      })
      .on("mouseleave", function (event) {
        event.preventDefault();
        elementInfo.internal_map_attributes.moused = false;
        elementInfo.internal_map_attributes.moused_at = null;
        //See if currently dragging something
        if (layerRef.chart.isDraggingMarker() === true) {
          //Don't change active if mouseleave is the result of something being dragged
          return;
        }
        if (!elementInfo.internal_map_attributes.active) {
          //Don't change active if already inactive
          return;
        }
        //Update my display status
        _this.elementActiveStatusChanged(
          layerRef,
          elementSel,
          elementInfo,
          false
        );
      })
      .on("click", function (event) {
        if (event.defaultPrevented === false && layerRef.chart.allowClicking) {
          if (
            elementInfo.internal_map_attributes.moused &&
            elementInfo.internal_map_attributes.active
          ) {
            //Handle repositioning mode
            if (
              event.shiftKey &&
              layerRef.elementShouldAllowPositionalEditor(
                elementSel,
                elementInfo
              )
            ) {
              layerRef.elementShowPositionalEditor(elementSel, elementInfo);

              return;
            }
            layerRef.elementClicked(elementSel, elementInfo);
          }

          elementInfo.internal_map_attributes.moused = true;
          elementInfo.internal_map_attributes.moused_at =
            elementInfo.internal_map_attributes.moused_at || Date.now();

          if (elementInfo.internal_map_attributes.active !== true) {
            //Deselect everything else before selecting me!
            layerRef.chart.deselectActiveMarkers();
            //Update my display status
            this.elementActiveStatusChanged(
              layerRef,
              elementSel,
              elementInfo,
              true
            );
          }
        }
      })

      .on("rerender", function () {
        //Update dirty status
        _this.elementUpdateDirtyStatus(elementInfo, layerRef);

        //Update Position
        _this.elementPositionChanged(elementSel, elementInfo);

        //Re-render element display
        var elementImageSel =
          layerRef.elementImageSelFromElementSel(elementSel);
        elementImageSel.html(null);
        if (window.requestAnimationFrame !== undefined) {
          window.requestAnimationFrame(doRender);
        } else {
          window.setTimeout(doRender, 1);
        }

        function doRender() {
          layerRef.renderElement(elementImageSel, elementInfo);

          //Update rotate and glow
          _this.elementRotationAndGlowStatusChanged(
            layerRef,
            elementSel,
            elementInfo,
            elementImageSel
          );
        }
      })
      .on("rendermove", function () {
        //Update dirty status
        _this.elementUpdateDirtyStatus(elementInfo, layerRef);

        //Update position display
        _this.elementPositionChanged(elementSel, elementInfo);

        //Update Glow
        _this.elementGlowStatusChanged(layerRef, elementSel, elementInfo);
      })
      .on("resetToOriginal", function () {
        //Replace all attributes with original attributes
        var _window = window;
        const attributesToResetTo = _window.CoreScripts.cloneDeep(
          elementInfo.internal_map_attributes.originalAttributes
        );

        //Leverage the same methods a backend refresh does to reset attributes and update dirty status
        elementSel.dispatch("setAttributesToPayload", function () {
          return {
            detail: {
              newData: attributesToResetTo,
              mode: "resetToOriginal",
            },
          };
        });
      })
      .on("setAttributesToPayload", function (event) {
        const newData = event.detail.newData;
        const previousAttributes = jQuery.extend(true, {}, elementInfo);
        const newDataIsDifferentFromCurrentAttributes =
          !layerRef.compareDeepEqual(elementInfo, newData);
        const newDataIsDifferentFromOriginalAttributes =
          !layerRef.compareDeepEqual(
            elementInfo.internal_map_attributes.originalAttributes || {},
            newData
          );

        //If new data is different from current data, we need to go ahead and close any open modals and reset our attributes
        if (newDataIsDifferentFromCurrentAttributes) {
          // Close any open modals for this element since we're actually changing its attributes
          $("." + layerRef.getModalTagClass(elementInfo)).modal("hide");

          // Update the data in the marker from the incoming data
          Object.keys(newData).forEach(function (newDataKey) {
            //skip updating internal map attributes, keep old ones (to preserve drag state and more)
            if (newDataKey === "internal_map_attributes") {
              return true; //Continue
            }
            //Update data key
            elementInfo[newDataKey] = newData[newDataKey];
          });
        }

        // If new data is different from original attributes, re-save the original attributes
        if (newDataIsDifferentFromOriginalAttributes) {
          //RESET ORIGINALATTRIBUTES VARIABLE
          delete elementInfo.internal_map_attributes.originalAttributes;
          elementInfo.internal_map_attributes.originalAttributes =
            jQuery.extend(true, {}, elementInfo);
        }

        // If new data is different from current attributes or element is dirty, re-render
        if (
          newDataIsDifferentFromCurrentAttributes === true ||
          elementInfo.internal_map_attributes.dirty !== false
        ) {
          //Re-render now
          elementSel.dispatch("rerender");
        }

        //If the mode of this replacement is resetToOriginal, dispatch accordingly
        if (event.detail.mode === "resetToOriginal") {
          layerRef.elementWasResetToOriginal(
            elementSel,
            elementInfo,
            previousAttributes
          );
        }
      })

      .on("deselectelement", function () {
        elementInfo.internal_map_attributes.moused = false;
        elementInfo.internal_map_attributes.moused_at = null;
        _this.elementActiveStatusChanged(
          layerRef,
          elementSel,
          elementInfo,
          false
        );
      })
      .on("mapzoomchanged", function () {
        _this.elementZoomStatusChanged(layerRef, elementSel, elementInfo);
      })
      .call(
        d3Drag()
          .filter(function () {
            return (
              !elementInfo.locked && elementInfo.internal_map_attributes.active
            );
          })
          .on("start", function () {
            elementInfo.internal_map_attributes.dragging = true;
            elementInfo.internal_map_attributes.dragstartX = elementInfo.x;
            elementInfo.internal_map_attributes.dragstartY = elementInfo.y;
          })
          .on("drag", function (event) {
            elementInfo.x = event.x;
            elementInfo.y = event.y;
            //Render move now
            elementSel.dispatch("rendermove");
          })
          .on("end", function () {
            elementInfo.internal_map_attributes.dragging = false;

            //Check if dragged an actual amount, otherwise reset back
            const normalizedZoom =
              layerRef.chart.kfactor / layerRef.chart.scales.zoomScale;
            const oldX =
              elementInfo.internal_map_attributes.dragstartX || elementInfo.x;
            const oldY =
              elementInfo.internal_map_attributes.dragstartY || elementInfo.y;
            if (
              Math.abs(oldX - elementInfo.x) * normalizedZoom < 1.0 &&
              Math.abs(oldY - elementInfo.y) * normalizedZoom < 1.0
            ) {
              //No significant drag occurred, revert back to previous pos
              elementInfo.x = oldX;
              elementInfo.y = oldY;
            }

            /* If we moused out in the drag, make element inactive now */
            if (elementInfo.internal_map_attributes.moused !== true) {
              _this.elementActiveStatusChanged(
                layerRef,
                elementSel,
                elementInfo,
                false,
                true,
                false
              );
            }

            //Render move now
            elementSel.dispatch("rendermove");
          })
      );
  }

  /**
   * Called after element interaction setup complete
   * Finishes any setup that must be done after interaction controls created
   * @param  {GenericLayer} layerRef
   * @param  {d3 selection} elementSel
   * @param  {d3 data ref} elementInfo
   */
  completeSetupEvents(layerRef, elementSel, elementInfo) {
    this.updateEventCaptureBox(layerRef, elementSel);
    this.elementActiveStatusChanged(
      layerRef,
      elementSel,
      elementInfo,
      elementInfo.internal_map_attributes.active,
      false
    );
    this.elementZoomStatusChanged(layerRef, elementSel, elementInfo, false);
  }

  elementUpdateDirtyStatus(elementInfo, layerRef) {
    //Normal re-render event - updates element dirty display and makes element look like it's current attributes should
    const wasDirty = elementInfo.internal_map_attributes.dirty !== false;
    //An element is dirty if it has no node map ID (never saved to DB) or if it doesn't exactly match the original version of itself
    const isDirty =
      elementInfo.node_map_id == null ||
      !layerRef.elementInfoMatchesOriginal(elementInfo);

    //Update current element status
    elementInfo.internal_map_attributes.dirty = isDirty;

    //If element dirty, make map dirty
    if (wasDirty !== isDirty) {
      layerRef.chart.refreshDirtyStatus();
    }
  }

  elementPositionChanged(elementSel, elementInfo) {
    //Update Position
    const positionTransform =
      "translate(" + elementInfo.x + "," + elementInfo.y + ")";
    if (elementSel.attr("transform") !== positionTransform) {
      elementSel.attr("transform", positionTransform);
    }
  }

  elementRotationAndGlowStatusChanged(
    layerRef,
    elementSel,
    elementInfo,
    elementImageSel = null
  ) {
    var _this = this;
    //Render glow and rotation together to be seamless
    const rotationTransform = "rotate(" + elementInfo.angle + ")";
    if (elementImageSel.attr("transform") !== rotationTransform) {
      //Update Rotation
      elementImageSel
        .transition()
        .duration(90)
        .attr("transform", rotationTransform)
        .on("end", function () {
          //Update Glow
          _this.elementGlowStatusChanged(
            layerRef,
            elementSel,
            elementInfo,
            elementImageSel
          );
        });
    } else {
      //Update Glow
      _this.elementGlowStatusChanged(
        layerRef,
        elementSel,
        elementInfo,
        elementImageSel
      );
    }
  }

  elementGlowStatusChanged(
    layerRef,
    elementSel,
    elementInfo,
    elementImageSel = null
  ) {
    var _this = this;
    if (elementImageSel == null) {
      elementImageSel = layerRef.elementImageSelFromElementSel(elementSel);
    }

    elementImageSel
      .selectAll(".nodeMapVisibleOnlyOnActive")
      .classed("hidden", !elementInfo.internal_map_attributes.active);

    if (elementImageSel.classed("nodeMapNoGlow")) {
      elementImageSel.attr("filter", null);

      return true;
    }

    var glowSels = elementImageSel;
    var overrideGlowSels = elementImageSel.selectAll(".nodeMapGlowSel");

    //If we're doing cool glow
    if (overrideGlowSels.size() > 0) {
      elementImageSel.attr("filter", null);
      glowSels = overrideGlowSels;
    }

    glowSels.each(function () {
      var glowSel = layerRef.chart.d3Select(this); // Transform to d3 Object

      if (glowSel.attr("filter") !== _this.getProperGlowColor(elementInfo)) {
        glowSel.attr("filter", null);
        glowSel
          .transition()
          .duration(0.1)
          .on("end", function () {
            layerRef.chart
              .d3Select(this)
              .attr("filter", _this.getProperGlowColor(elementInfo));
          });
        glowSel
          .transition()
          .duration(0.2)
          .on("end", function () {
            layerRef.chart
              .d3Select(this)
              .attr("filter", _this.getProperGlowColor(elementInfo));
          });
        glowSel
          .transition()
          .duration(1)
          .on("end", function () {
            layerRef.chart
              .d3Select(this)
              .attr("filter", _this.getProperGlowColor(elementInfo));
          });
      }
    });
  }

  /**
   * Get the proper glow color for an item on the map
   * @param currentInfo
   */
  getProperGlowColor(currentInfo) {
    if (
      currentInfo.internal_map_attributes.dirty &&
      currentInfo.internal_map_attributes.active
    ) {
      return "url(#filter-glow-purple)";
    } else if (currentInfo.internal_map_attributes.dirty) {
      return "url(#filter-glow-red)";
    } else if (currentInfo.internal_map_attributes.active) {
      return "url(#filter-glow-blue)";
    }

    return null;
  }

  /**
   * Called when map zoom status changed
   * Updates appearance
   * @param  {GenericLayer} layerRef
   * @param  {d3 selection} elementSel
   * @param  {d3 data}  elementInfo
   * @param  {Boolean} animate
   * @return {[type]}
   */
  elementZoomStatusChanged(layerRef, elementSel, elementInfo, animate = true) {
    var normalizedZoom =
      layerRef.chart.kfactor / layerRef.chart.scales.zoomScale;
    var shouldBeVisible = normalizedZoom > 3;
    /* Select only elements which need visibility toggled */
    var visibilitySelectionParameter = shouldBeVisible
      ? "[isHidden]"
      : ":not([isHidden])";
    var elementsToTransition = elementSel.selectAll(
      ".visible-when-zoomed" + visibilitySelectionParameter
    );
    elementsToTransition
      .attr("isHidden", shouldBeVisible ? null : true)
      .transition()
      .duration(animate ? 200 : 0)
      .style("opacity", shouldBeVisible ? 1 : 0)
      .on(shouldBeVisible ? "start" : "end", function () {
        elementsToTransition.style(
          "visibility",
          shouldBeVisible ? "visible" : "hidden"
        );
      });
  }

  /**
   * Called when element active status changed
   * Updates appearance
   * @param  {GenericLayer} layerRef
   * @param  {d3 selection} elementSel
   * @param  {d3 data ref}  elementInfo
   * @param  {Boolean} newStatus
   * @param  {Boolean} animate
   * @return {[type]}
   */
  elementActiveStatusChanged(
    layerRef,
    elementSel,
    elementInfo,
    newStatus,
    animate = true,
    updateGlow = true
  ) {
    const oldStatus = elementInfo.internal_map_attributes.active;
    elementSel.attr("active", newStatus);
    elementInfo.internal_map_attributes.active = newStatus;

    if (newStatus && !oldStatus) {
      layerRef.elementActiveOn(elementSel, elementInfo);
    } else if (!newStatus && oldStatus) {
      layerRef.elementActiveOff(elementSel, elementInfo);
    }

    /* If becoming active, show on top of everything else */
    if (newStatus === true) {
      elementSel.raise();
    }

    /* Update Glow */
    if (updateGlow) {
      this.elementGlowStatusChanged(layerRef, elementSel, elementInfo);
    }

    /* If not locked, show interaction controls */
    if (!elementInfo.locked) {
      var elementInteractionsSel =
        layerRef.elementInteractionSelFromElementSel(elementSel);

      elementInteractionsSel
        .transition()
        .duration(animate ? 200 : 0)
        .style("opacity", newStatus ? 1 : 0)
        .on(newStatus ? "start" : "end", function () {
          elementInteractionsSel
            .select("#element-interaction-bounding-rect")
            .style("visibility", newStatus ? "visible" : "hidden");
          elementInteractionsSel.style(
            "visibility",
            newStatus ? "visible" : "hidden"
          );
        });
    }
  }

  /**
   * Updates the transparent rect to encompass events when element active
   * @param  {GenericLayer} layerRef
   * @param  {d3 selection} elementSel
   */
  updateEventCaptureBox(layerRef, elementSel) {
    var elementInteractionsSel =
      layerRef.elementInteractionSelFromElementSel(elementSel);
    var interactionBoundingBox = elementSel.node().getBBox();

    elementInteractionsSel
      .select("#element-interaction-bounding-rect")
      .attr("width", interactionBoundingBox.width)
      .attr("height", interactionBoundingBox.height)
      .attr("x", interactionBoundingBox.x)
      .attr("y", interactionBoundingBox.y);
  }
}
