import { formatTimeWithDate } from "../utils";

export type CaseDetailsDTO = {
  id: number;
  subject_type: string;
  user_name: string;
  started_at: {
    datetime: string;
    timezone: string;
  };
  ended_at: {
    datetime: string;
    timezone: string;
  };
  exposure_type: string;
  facility_name: string;
  is_archived: boolean;
  created_at: {
    datetime: string;
    timezone: string;
  };
  created_by_name: string;
  group_name?: string;
  unit_name?: string;
  room_name?: string;
};

type TransformedCaseDetails = {
  "Case Number": string | null;
  "Case Type": string | null;
  Name?: string | null;
  "Room Name"?: string | null;
  "Exposure Dates": string | null;
  Group?: string | null;
  Facility: string | null;
  Unit?: string | null;
  "Case Status": string | null;
};

// todo [sw-3702]: handle nulls - should display missing data if null, but do it here or do it at the view level?
export function transformCaseDetails(
  caseDetails: CaseDetailsDTO
): TransformedCaseDetails {
  const formattedStartedAt = formatTimeWithDate(
    caseDetails?.started_at?.datetime,
    caseDetails?.started_at?.timezone,
    "Time"
  );
  const formattedEndedAt = formatTimeWithDate(
    caseDetails?.ended_at?.datetime,
    caseDetails?.ended_at?.timezone,
    "Time"
  );
  const formattedCreatedAt = formatTimeWithDate(
    caseDetails?.created_at?.datetime,
    caseDetails?.created_at?.timezone,
    "Time"
  );
  const exposureDates =
    formattedStartedAt && formattedEndedAt
      ? `${formattedStartedAt} - ${formattedEndedAt}`
      : null;

  let heading = "Name";
  let name = caseDetails.user_name;
  let subjectType = "Positive Staff";

  if (caseDetails.subject_type === "Positive Patient") {
    heading = "Room Name";
    name = caseDetails.room_name || "";
    subjectType = "Positive Room";
  }

  const transformedCaseDetails = {
    [heading]: name,
    "Created By": caseDetails.created_by_name,
    "Created At": formattedCreatedAt || null,
    "Case Status": caseDetails.is_archived ? "Closed" : "Open",
    "Case Number": caseDetails?.id ? caseDetails.id.toString() : null,
    "Case Type": subjectType,
    "Exposure Dates": exposureDates,
    Facility: caseDetails.facility_name,
  };

  if (caseDetails.group_name) {
    transformedCaseDetails["Group"] = caseDetails.group_name;
  }

  if (caseDetails.unit_name) {
    transformedCaseDetails["Unit"] = caseDetails.unit_name;
  }

  return transformedCaseDetails;
}
