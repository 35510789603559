import { NursingInsightsUrlParams, get } from "../../api/laravelApi";
import { roundingEndpoint } from "../../constants";

export const RoundingLoader = async ({ request }) => {
  const customerIds = new URL(request.url).searchParams.getAll("customersIds");
  const facilityIds = new URL(request.url).searchParams.getAll("facilitiesIds");
  const unitIds = new URL(request.url).searchParams.getAll("unitsIds");
  const roomIds = new URL(request.url).searchParams.getAll("roomIds");
  const startDate = new URL(request.url).searchParams.get("startDate");
  const endDate = new URL(request.url).searchParams.get("endDate");
  const aggregation = new URL(request.url).searchParams.get("aggregationIds");

  const data = await get(roundingEndpoint, {
    customerIds,
    facilityIds,
    roomIds,
    unitIds,
    startDate,
    endDate,
    aggregation,
  } as NursingInsightsUrlParams);

  return data;
};
