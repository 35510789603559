import { useEffect } from "react";
import useSharedFunctionsForFilters from "../sharedFunctions/useSharedFunctionsForFilters";
import { parseDateToMidnight } from "../../utils";

const useFiltersViewController = (resolvedFilters) => {
  const {
    handleDateChange,
    startDate,
    endDate,
    onFilterChange,
    applyFilters,
    setSelected,
    setPeerFilters,
    toggleButton,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
    filters,
  } = useSharedFunctionsForFilters(resolvedFilters);

  useEffect(() => {
    filters.forEach((filter) => {
      setPeerFilters(filter.name, filter.peerFilters!);
      setSelected(filter.name, filter.selection);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    startDate: new Date(parseDateToMidnight(startDate)),
    endDate: new Date(parseDateToMidnight(endDate)),
    handleDateChange,
    onFilterChange,
    filters,
    applyFilters,
    toggleButton,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
  };
};

export default useFiltersViewController;
