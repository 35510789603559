import GenericLayer from "./GenericLayer";

export default class ImageLayer extends GenericLayer {
  get allowTogglingVisibility() {
    return false;
  }

  get layerScale() {
    /* Map scale is handled exclusively through the global scale */
    return 1;
  }

  /* What depth should the layer take (0 is bottom, inf is top) */
  get sortDepthIndex() {
    return 0;
  }

  render() {
    var subElms = this.chart.mapDataXml.getElementsByTagName("g");
    // console.log("subElms", subElms);
    var layerSelector = this.sel;
    // console.log(layerSelector);
    for (var i = 0; i < subElms.length; i++) {
      layerSelector.node().appendChild(subElms[i]);
    }
  }
}
