import { IndividualStaffReport } from "../../types/types";
import { parseDateToMidnight } from "../../utils";
import { filterHtmlTags } from "../utils";

export function transformIndividualStaff(responseJson) {
  const data = responseJson?.handHygienePlot?.dataSeries;
  let startDate, endDate;
  const dateRange = responseJson?._params?.date_range?.value;

  if (!dateRange) {
    startDate = responseJson?.subtitle?.startDate;
    endDate = responseJson?.subtitle?.endDate;
  } else {
    startDate = dateRange[1];
    endDate = dateRange[2];
  }

  const response: IndividualStaffReport = {
    summaryBlocks: [],
    handHygienePlot: [],
    params: [],
    value: [],
    valueDisplay: "",
    batteryPercent: "",
    badgeShortCode: "",
    group: "",
    tiles: {
      performance: null,
      percentChangedFormatted: "",
      totalOpportunities: null,
      totalHygieneEvents: null,
      dateRange: "",
    },
    yAxisPlotlines: "",
    name: [],
    dataSeries: [],
    excelUrl: "",
    disabled: false,
    startDate: startDate ? new Date(parseDateToMidnight(startDate)) : null,
    endDate: endDate ? new Date(parseDateToMidnight(endDate)) : null,
    uuid: "",
  };

  if (!responseJson?.badgeInfo?.battery_percent) {
    response.batteryPercent = "No Data Available";
  } else {
    response.batteryPercent = responseJson?.badgeInfo?.battery_percent;
  }

  if (!responseJson?.badgeInfo?.short_code) {
    response.badgeShortCode = "No Data Available";
  } else {
    response.badgeShortCode = responseJson?.badgeInfo?.short_code;
  }

  response.summaryBlocks = responseJson?.summaryBlocks?.tiles;
  response.handHygienePlot = responseJson?.handHygienePlot?.dataSeries;
  response.params = responseJson?._params?.user_ids;

  response.value = responseJson?._params?.user_ids?.value;
  response.valueDisplay =
    responseJson?._params?.user_ids?.valueDisplay?.replace(
      /\s*\[.*?\]\s*$/,
      ""
    );

  if (!responseJson?.groupHandHygienePlot) {
    response.group = "No Data Available";
  }

  // to do, fix this on mock. yarn start:mock defaults to all
  responseJson?.groupHandHygienePlot?.dataSeries.map(
    // eslint-disable-next-line array-callback-return
    (data) => {
      if (data.groupTag) {
        response.group = data.name;
      } else {
        response.group = "No Data Available";
      }
      return response.group;
    }
  );

  const yAxisPlotlines = responseJson?.handHygienePlot?.yAxisPlotlines;

  if (yAxisPlotlines) {
    response.yAxisPlotlines = yAxisPlotlines;
  }

  const dataSeries = responseJson?.handHygienePlot?.dataSeries;

  if (dataSeries && dataSeries?.length > 0) {
    const sanitizedDataSeries = filterHtmlTags(dataSeries);
    response.dataSeries = sanitizedDataSeries;
  }

  response.name = data?.map((data: { name: string }) => {
    return data.name;
  });

  const tiles = responseJson?.summaryBlocks?.tiles;

  if (tiles) {
    const eventCounts = responseJson.summaryBlocks.tiles.filter(
      (tile: { _component_class: string }) =>
        tile._component_class === "EventCounts"
    )[0];
    const performanceChanged = responseJson.summaryBlocks.tiles.filter(
      (tile: { _component_class: string }) =>
        tile._component_class === "PerformanceChanged"
    )[0];
    const performanceOverall = responseJson.summaryBlocks.tiles.filter(
      (tile: { _component_class: string }) =>
        tile._component_class === "PerformanceOverall"
    )[0];

    response.tiles = {
      dateRange: performanceChanged?.subtitle,
      totalOpportunities: eventCounts?.opportunities,
      totalHygieneEvents: eventCounts?.events,
      performance: performanceOverall?.performance,
      percentChangedFormatted: performanceChanged?.percentChangedFormatted,
    };
  }

  if (responseJson?._export?.url === null) {
    response.disabled = true;
  } else if (responseJson?._params?.customer_ids?.value?.length > 1) {
    response.disabled = true;
  } else {
    response.disabled = false;
  }

  response.excelUrl = responseJson?._export?.url;

  response.uuid = responseJson?._report?.uuid;

  if (!responseJson) {
    return response;
  }

  return response;
}
