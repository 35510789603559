import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { zeroPlaceholderPlugin } from "./ShiftAnalysis";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type BarChartProps = {
  title?: string;
  options: Object;
  style?: Object;
  isExecPage?: boolean;
  isRoundingPage?: boolean;
  data: {
    labels?: string[];
    datasets: {
      label?: string;
      data: any;
      backgroundColor: string | any[];
      borderColor?: string;
      borderWidth?: number;
      borderRadius?: number;
      categoryPercentage?: number;
      barPercentage?: number;
    }[];
  };
};

function BarChart(props: BarChartProps) {
  let plugins = [] as any;

  if (props.isRoundingPage) {
    plugins = [zeroPlaceholderPlugin];
  }

  return (
    <>
      <Bar
        id="bar-chart complianceDataPlugin zeroPlaceholderPlugin"
        style={props.style}
        options={props.options}
        data={props.data}
        plugins={plugins}
      />
    </>
  );
}

export default BarChart;
