import Cards from "../../Components/UI/Cards/Cards";
import Filters from "../../Components/Filters/Filters";
import ApiError from "../../Components/ApiError/ApiError";
import useExecutiveReportViewController from "../../viewcontroller/Executive/useExecutiveReportViewController";

import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
import { useUUIDWithLocation } from "../../Hooks/useUuid";
import { useEffect } from "react";
import BackwardsButton from "../../Components/UI/BackwardsButton/BackwardsButton";
import Heatmap from "../../Components/Heatmap/Heatmap";
import ExecDashboardGrid from "../../Components/ExecutiveDashboardDataGrid/ExecutiveDashbardGrid";
import usePdfDownload from "../../Hooks/usePdfDownload";
import DownloadButton from "../../Components/DownloadButton/DownloadButton";
import { executiveElementIds, executiveTitles } from "../../constants/pdfs";

function ExecutiveView() {
  const {
    transformedExecutiveReportData: executiveReportData,
    filters,
    onFilterChange,
    applyFilters,
    onFacilitiesView,
    viewingBy,
    returnToAllFacilities,
    toggleButton,
    disableExcelDoc,
    handleCardClick,
  } = useExecutiveReportViewController();
  const apiError = executiveReportData?.error;

  const { updateUUID } = useUUIDWithLocation();

  const { downloadPdf, loading } = usePdfDownload(
    executiveElementIds,
    executiveTitles,
    [],
    "Executive-Report.pdf",
    "Executive Report PDF"
  );

  useEffect(() => {
    updateUUID(executiveReportData?.uuid);
  }, [executiveReportData?.uuid, updateUUID]);

  return (
    <div className="exec-dashboard">
      <div className="top-content">
        <div className="page-header">
          <div>
            <h1 className="no-margin-bottom">Executive Dashboard</h1>
            {!onFacilitiesView && (
              <h2 className="no-margin-bottom no-margin-top subtitle">
                {executiveReportData.subtitle}
              </h2>
            )}
          </div>

          <div className="buttons-container">
            <ExcelDownload
              disabled={disableExcelDoc}
              link={executiveReportData.excelUrl}
            />
            <DownloadButton click={downloadPdf} loading={loading} cta={"PDF"} />
          </div>
        </div>

        <div className="display-flex">
          {!onFacilitiesView && (
            <div className="margin-bottom">
              <BackwardsButton
                click={returnToAllFacilities}
                text={"Back To All Facilities"}
              ></BackwardsButton>{" "}
            </div>
          )}
        </div>

        {apiError && <ApiError message={executiveReportData?.error?.message} />}
        {filters.length !== 0 && (
          <div>
            <Cards>
              <div className="filters filters-margin-top">
                <Filters
                  filters={filters}
                  onChange={onFilterChange}
                  toggleButton={toggleButton}
                  applyFilters={applyFilters}
                />
              </div>
            </Cards>
          </div>
        )}
      </div>

      <div
        className={
          !onFacilitiesView ? "heatmap-card-facilities" : "heatmap-card"
        }
      >
        <Heatmap
          id={"heatmap"}
          viewingBy={viewingBy}
          data={executiveReportData.heatmapData}
          xLabels={executiveReportData.heatmapXLabels}
          yLabels={executiveReportData.heatmapYLabels}
          onClick={handleCardClick}
          onFacilitiesView={onFacilitiesView}
        />
      </div>

      <div className="unit-row">
        <ExecDashboardGrid
          id={"exec-dashboard-unit"}
          category={"Unit"}
          dataCurrentM={executiveReportData.unitTypeDataCurrentM}
          dataPreviousM={executiveReportData.unitTypeDataPreviousM}
        />
      </div>
      <div className="groups-row">
        <ExecDashboardGrid
          id={"exec-dashboard-group"}
          category={"Group"}
          dataCurrentM={executiveReportData.groupTypeDataCurrentM}
          dataPreviousM={executiveReportData.groupTypeDataPreviousM}
        />
      </div>
    </div>
  );
}

export default ExecutiveView;
