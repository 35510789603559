import { useLoaderData } from "react-router-dom";
import { IndividualAssetsDTO } from "../types";

const useIndividualAssetsModel = () => {
  const individualAssetData = useLoaderData() as IndividualAssetsDTO;

  return {
    individualAssetData,
  };
};

export default useIndividualAssetsModel;
