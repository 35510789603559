import Close from "@untitled-ui/icons-react/build/cjs/XClose";

import Logo from "../UI/Logo/Logo";

import NavigationBottom from "../NavigationBottom/NavigationBottom";
import { useModuleTheme } from "../../contexts/ModuleTheme/moduleThemes";
import { getValueFromTheme } from "../../utils/theme";

type LeftNavigationProps = {
  toggleNav: () => void;
};

function LeftNavigation(props: LeftNavigationProps) {
  const { theme } = useModuleTheme();

  const themeValues = getValueFromTheme(theme);

  return (
    <nav>
      <ul>
        <div className="top-content">
          <Logo url={"/"} iconUrl={themeValues.iconUrl} title="SwipeSense" />
          <button
            className="close-button"
            data-testid="close-button"
            onClick={props.toggleNav}
          >
            <Close />
          </button>

          {themeValues.subNavContent}
        </div>
        <NavigationBottom />
      </ul>
    </nav>
  );
}

export default LeftNavigation;
