import usePositiveRoomCaseModel from "../../model/PositiveRoomCase/usePositiveRoomCaseModel";
import { transformCaseDetails } from "../../transformers/positiveCase/transformCaseDetails";

const usePositiveRoomCaseViewModel = () => {
  const { positiveRoomCaseData } = usePositiveRoomCaseModel();

  const transformedCaseDetails = transformCaseDetails(
    positiveRoomCaseData.case
  );

  return {
    positiveRoomCaseData,
    transformedCaseDetails,
  };
};

export default usePositiveRoomCaseViewModel;
