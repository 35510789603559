import { Navigate } from "react-router";
import { useUserInfo } from "../../contexts/UserInfo/userInfo";

function InitialNavigationDisplay() {
  const { userInfo } = useUserInfo();

  function getPermissions() {
    if (userInfo?.permissions?.includes("HH.dashboard.executive")) {
      return <Navigate to="/HH/executive" />;
    } else if (userInfo?.permissions?.includes("HH.report.group")) {
      return <Navigate to="/HH/groups" />;
    } else if (userInfo?.permissions?.includes("HH.report.unit")) {
      return <Navigate to="/HH/units" />;
    } else if (userInfo?.permissions?.includes("HH.report.staff")) {
      return <Navigate to="/HH/staff" />;
    } else {
      return <Navigate to="/HH/my-report" />;
    }
  }

  return <>{getPermissions()}</>;
}

export default InitialNavigationDisplay;
