import MissingData from "../../Components/MissingData/MissingData";
import usePositiveStaffCaseViewModel from "../../viewmodel/PositiveStaffCase/usePositiveStaffCaseViewModel";
import { newCaseEndpoint } from "../../constants";
import { useUserInfo } from "../../contexts/UserInfo/userInfo";
import { useEffect, useState } from "react";
import { deleteMethod, patch } from "../../api/laravelApi";
import { formatTimeWithDate } from "../../transformers/utils";

// todo[sw-3702]: add a return type here and everywhere we are missing one in MVVM stack
const usePositiveStaffCaseViewController = () => {
  const { positiveStaffCaseData, transformedCaseDetails } =
    usePositiveStaffCaseViewModel();

  const [disableExcelDoc, setDisableExcelDoc] = useState(true);

  useEffect(() => {
    if (!positiveStaffCaseData.export) {
      setDisableExcelDoc(true);
    } else {
      setDisableExcelDoc(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positiveStaffCaseData.export]);
  const { userInfo } = useUserInfo();
  const [apiError, setApiError] = useState<string | null>(null);

  const returnToAllCases = () => {
    window.location.href = `/CT/cases`;
  };

  function separateRoomNameFromVisitTimes(visit: string): {
    roomName: string;
    visitTimes: string;
  } {
    const roomName = visit.split(":", 1);
    let visitTimes;

    for (let i = 0; i < visit.length; i++) {
      if (visit[i] === ":") {
        visitTimes = visit.substring(i + 1);
        break;
      }
    }
    return { roomName: roomName[0], visitTimes: visitTimes };
  }

  const archiveAction = async () => {
    const token = userInfo?.tokens.csrf;
    const additionalHeaders = {
      "X-CSRF-TOKEN": token,
    };

    const id = positiveStaffCaseData.case.id;
    try {
      await patch({
        additionalHeaders,
        url: `${process.env.REACT_APP_AUTH_DOMAIN}/${newCaseEndpoint}/${id}/archive`,
      });
      setApiError(null);
      window.location.assign(
        "https://" +
          process.env.REACT_APP_ANALYTICS_DOMAIN +
          `/CT/positive-staff-case/${id}`
      );
    } catch (e: any) {
      setApiError(e.message);
    }
  };

  const deleteAction = async () => {
    const token = userInfo?.tokens.csrf;
    const additionalHeaders = {
      "X-CSRF-TOKEN": token,
    };

    const id = positiveStaffCaseData.case.id;
    try {
      await deleteMethod({
        additionalHeaders,
        url: `${process.env.REACT_APP_AUTH_DOMAIN}/${newCaseEndpoint}/${id}`,
      });
      setApiError(null);
      window.location.assign(
        "https://" + process.env.REACT_APP_ANALYTICS_DOMAIN + `/CT/cases`
      );
    } catch (e: any) {
      setApiError(e.message);
    }
  };

  const isDisabled = positiveStaffCaseData.case.is_archived === true;

  const tableColumnsStaff = [
    { name: "staffName", label: "Name" },
    {
      name: "overlappingRoomVisits",
      label: "Overlapping Room Visits",
      options: {
        customBodyRender: (value: string[]) => {
          return (
            <>
              {value.map((visit) => {
                const { roomName, visitTimes } =
                  separateRoomNameFromVisitTimes(visit);

                return (
                  <div>
                    {<b>{roomName}</b>}:{visitTimes}
                  </div>
                );
              })}
            </>
          );
        },
      },
    },
  ];

  const tableColumnsRooms = [
    { name: "roomName", label: "Room Name" },
    {
      name: "unitName",
      label: "Unit",
    },
    {
      name: "visits",
      label: "Visits",
      options: {
        customBodyRender: (value: string[]) => {
          return (
            <>
              {value.map((visit) => (
                <div>{visit}</div>
              ))}
            </>
          );
        },
      },
    },
  ];

  const roomTableData = positiveStaffCaseData?.roomExposures?.map(
    (roomExposure) => {
      const formattedVisits = roomExposure.visits.map((visit) => {
        const startedAtTime = formatTimeWithDate(
          visit.startedAt.datetime,
          visit.startedAt.timezone,
          "Time"
        );
        const endedAtTime = formatTimeWithDate(
          visit.endedAt.datetime,
          visit.endedAt.timezone,
          "Time"
        );
        return `${startedAtTime} - ${endedAtTime}`;
      });

      return {
        roomName: roomExposure.roomName,
        unitName: roomExposure.unitName,
        visits: formattedVisits,
      };
    }
  );

  const staffTableData = positiveStaffCaseData?.staffExposures?.map(
    (staffExposure) => {
      const formattedOverlappingVisits = staffExposure.overlappingVisits.map(
        (visit) => {
          const roomName = visit.roomName;
          const startedAtTime = formatTimeWithDate(
            visit.startedAt.datetime,
            visit.startedAt.timezone,
            "Time"
          );
          const endedAtTime = formatTimeWithDate(
            visit.endedAt.datetime,
            visit.endedAt.timezone,
            "Time"
          );
          return `${roomName}: ${startedAtTime} - ${endedAtTime}`;
        }
      );
      return {
        overlappingRoomVisits: formattedOverlappingVisits,
        staffName: staffExposure.staffName,
      };
    }
  );

  const tableOptions = {
    rowHover: true,
    textLabels: {
      body: {
        noMatch: (
          <MissingData
            message={"No Data Available."}
            action={"Please try a different selection"}
          />
        ),
      },
    },
  };

  return {
    positiveStaffCaseData,
    tableColumnsStaff,
    tableColumnsRooms,
    tableOptions,
    roomTableData,
    staffTableData,
    caseDetails: transformedCaseDetails,
    disableExcelDoc,
    returnToAllCases,
    apiError,
    archiveAction,
    isDisabled,
    deleteAction,
  };
};

export default usePositiveStaffCaseViewController;
