import { useLoaderData } from "react-router-dom";
import { GroupReportDTO } from "../types";

const useGroupReportModel = () => {
  const groupReportData = useLoaderData() as GroupReportDTO;

  return {
    groupReportData,
  };
};

export default useGroupReportModel;
