import useMyReportModel from "../../model/MyReport/useMyReportModel";
import { transformMyReport } from "../../transformers/myReport/transformMyReport";

const useMyReportViewModel = () => {
  const { myReportData } = useMyReportModel();

  const transformedMyReportData = transformMyReport(myReportData);

  return {
    transformedMyReportData,
  };
};

export default useMyReportViewModel;
