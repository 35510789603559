import { useLoaderData } from "react-router-dom";

const useMyReportModel = () => {
  const myReportData = useLoaderData();

  return {
    myReportData,
  };
};

export default useMyReportModel;
