import { get } from "../../api/laravelApi";
import { otibEndpoint } from "../../constants";
import { getApiUrlFromGetRequest } from "../../utils/";

export const OtibLoader = async (request) => {
  const startDate = new URL(request.url).searchParams.get("startDate");
  const endDate = new URL(request.url).searchParams.get("endDate");
  const facilityIds = new URL(request.url).searchParams.getAll("facilitiesIds");
  const customerIds = new URL(request.url).searchParams.getAll("customersIds");
  const unitIds = new URL(request.url).searchParams.getAll("unitsIds");
  const aggregation = new URL(request.url).searchParams.get("aggregationIds");

  const otibData = await get(otibEndpoint, {
    startDate,
    endDate,
    facilityIds,
    customerIds,
    unitIds,
    aggregation,
  }).then((otibData) => {
    const params = otibData._params;

    const uuid = otibData?._report?.uuid;

    const otibDataApiUrl = otibData.nodeMaps;

    const error = otibData._error;

    const apiUrlWithoutDomain = getApiUrlFromGetRequest(otibDataApiUrl, error);

    const fetchPromises = apiUrlWithoutDomain.map((url) =>
      get(url).then((result) => {
        const data = result.data;

        return data;
      })
    );

    return Promise.all(fetchPromises).then((results) => {
      const validDataResults = results.filter((data) => data !== null);

      return { validDataResults, params, error, uuid };
    });
  });
  return {
    otibData,
  };
};
