import { get } from "../../api/laravelApi";
import { executiveDashboardReportEndpoint } from "../../constants";

export const ExecutiveLoader = async (request) => {
  const customerIds = new URL(request.url).searchParams.getAll("customersIds");
  const facilityIds = new URL(request.url).searchParams.getAll("facilityIds");
  const useFacilityIds = new URL(request.url).searchParams.get(
    "useFacilityIds"
  );

  const data = get(executiveDashboardReportEndpoint, {
    unitIds: ["null"],
    facilityIds: useFacilityIds === "true" ? facilityIds : ["null"],
    customerIds,
  });

  return data;
};
