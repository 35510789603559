import usePdfDownload from "../../Hooks/usePdfDownload";
import DownloadButton from "../DownloadButton/DownloadButton";
import {
  RoomsOverviewRoomsDTO,
  RoomsOverviewSummaryDTO,
} from "../../model/types";
import {
  transformRoomsOverviewPdfRoomsTable,
  transformRoomsOverviewPdfSummaryTable,
} from "../../transformers/visitOverview/transformRoomsOverviewPdfTables";

export type RoomsOverviewPdfDownloadButtonProps = {
  summary: RoomsOverviewSummaryDTO;
  rooms: RoomsOverviewRoomsDTO;
};

function RoomsOverviewPdfDownloadButton(
  props: RoomsOverviewPdfDownloadButtonProps
) {
  const summaryTable = transformRoomsOverviewPdfSummaryTable(props.summary);
  const roomsTable = transformRoomsOverviewPdfRoomsTable(props.rooms);

  const { loading, downloadPdf } = usePdfDownload(
    [],
    [],
    [summaryTable, roomsTable],
    "Visit-Overview-Rooms-Report.pdf",
    "Visit Overview Report PDF"
  );

  return (
    <>
      <DownloadButton cta="PDF" loading={loading} click={downloadPdf} />
    </>
  );
}

export default RoomsOverviewPdfDownloadButton;
