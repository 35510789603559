import { get } from "../../api/laravelApi";
import { groupReportEndpoint } from "../../constants";

export const GroupsLoader = async (request) => {
  const startDate = new URL(request.url).searchParams.get("startDate");
  const endDate = new URL(request.url).searchParams.get("endDate");
  const aggregation = new URL(request.url).searchParams.get("aggregationIds");
  const facilityIds = new URL(request.url).searchParams.getAll("facilitiesIds");
  const customerIds = new URL(request.url).searchParams.getAll("customersIds");
  const unitIds = new URL(request.url).searchParams.getAll("unitsIds");
  const groupIds = new URL(request.url).searchParams.getAll("groupsIds");

  const groupReportData = await get(groupReportEndpoint, {
    startDate,
    endDate,
    aggregation,
    facilityIds,
    customerIds,
    unitIds,
    groupIds,
  });

  return groupReportData;
};
