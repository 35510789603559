import Login from "@untitled-ui/icons-react/build/cjs/LogIn01";

function LoginButton() {
  async function handleClick() {
    try {
      window.location.assign(
        process.env.REACT_APP_AUTH_DOMAIN +
          "?redirectAddress=https://" +
          process.env.REACT_APP_ANALYTICS_DOMAIN +
          "&_storeSourceAsIntendedUrl=true"
      );
    } catch (error) {
      console.error("Error logging in:", error);
    }
  }

  return (
    <>
      <button onClick={handleClick} className="login navigation-button-white">
        <Login />
        Log In
      </button>
    </>
  );
}

export default LoginButton;
