import PlusSign from "@untitled-ui/icons-react/build/cjs/Plus";
import Logo from "../UI/Logo/Logo";

const ModuleLogo = ({
  moduleId,
  iconUrl,
  testid,
  isModuleToggle,
  isDisabled,
}) => (
  <div className="overview-toggle" data-testid="module-logo-test">
    {moduleId ? (
      <Logo
        testid={testid}
        url={`/${moduleId}/`}
        iconUrl={iconUrl}
        isModuleToggle={isModuleToggle}
      />
    ) : (
      <div className="position-relative">
        <div
          className="plus-sign-icon"
          data-testid={"plus-sign-icon-" + testid}
        >
          <PlusSign />
        </div>
        <Logo
          testid={testid}
          url={"https://www.swipesense.com/hospital-safety-platform-demo"}
          iconUrl={iconUrl}
          isModuleToggle={isModuleToggle}
          isDisabled={isDisabled}
        />
      </div>
    )}
  </div>
);

export default ModuleLogo;
