import useGroupReportModel from "../../model/Groups/useGroupReportModel";
import { transformGroupReport } from "../../transformers/groupReport/transformGroupReport";

const useGroupReportViewModel = () => {
  const { groupReportData } = useGroupReportModel();

  const transformedGroupReportData = transformGroupReport(groupReportData);

  return {
    transformedGroupReportData,
  };
};

export default useGroupReportViewModel;
