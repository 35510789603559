import { useLoaderData } from "react-router-dom";
import { otibDTO } from "../types";

const useOtibModel = () => {
  const otibData = useLoaderData() as otibDTO;

  return {
    otibData,
  };
};

export default useOtibModel;
