import useIndividualStaffModel from "../../model/IndividualStaff/useIndividualStaffModel";
import { transformIndividualStaff } from "../../transformers/individualStaff/transformIndividualStaff";

const useIndividualAssetsViewModel = () => {
  const { individualStaffData } = useIndividualStaffModel();
  const transformedIndividualStaffData =
    transformIndividualStaff(individualStaffData);

  return {
    transformedIndividualStaffData,
  };
};

export default useIndividualAssetsViewModel;
