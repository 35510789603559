import DatePicker from "../../Components/DatePicker/DatePicker";
import Performance from "../../Components/Performance/Performance";
import LineGraph from "../../Components/LineGraph/LineGraph";
import PerformanceDetail from "../../Components/PerformanceDetail/PerformanceDetail";
import { defaultDateRange, maxDayFilter, maxWeekFilter } from "../../constants";
import Stars from "../../Components/Stars/Stars";

import Filters from "../../Components/Filters/Filters";

import ApiError from "../../Components/ApiError/ApiError";
import useUnitReportViewController from "../../viewcontroller/Units/useUnitReportViewController";
import MetricsCard from "../../Components/MetricsCard/MetricsCard";
import HardwareButton from "../../Components/HardwareButton/HardwareButton";
import Cards from "../../Components/UI/Cards/Cards";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
import BestDay from "../../Components/BestDay/BestDay";
import { useUUIDWithLocation } from "../../Hooks/useUuid";
import { useEffect } from "react";
import DatePickerDropdown from "../../Components/DatePickerDropdown/DatePickerDropdown";
import OtherMetricsHH from "../../Components/OtherMetricsHH/OtherMetricsHH";
import HistogramCard from "../../Components/HistogramCard/HistogramCard";
import DownloadButton from "../../Components/DownloadButton/DownloadButton";
import usePdfDownload from "../../Hooks/usePdfDownload";
import { unitElementIds, unitTitles } from "../../constants/pdfs";

const UnitsView = () => {
  const {
    transformedUnitReportData: unitReportData,

    handleDateChange,
    filters,
    onFilterChange,
    applyFilters,
    toggleButton,
    bestDateAggregate,
    disableExcelDoc,
    handleDatePickerChange,
    isDatePickerVisible,
    aggregate,
    pdfTables,
    handleDatePickerButtonClick,
  } = useUnitReportViewController();
  const apiError = unitReportData.error;
  const { total, performant } = unitReportData.tiles;

  const { updateUUID } = useUUIDWithLocation();

  useEffect(() => {
    updateUUID(unitReportData?.uuid);
  }, [unitReportData?.uuid, updateUUID]);

  const { downloadPdf, loading } = usePdfDownload(
    unitElementIds,
    unitTitles,
    pdfTables,
    "Unit-Report.pdf",
    "Unit Report PDF"
  );

  return (
    <>
      <div className="page-header">
        <h1>Units</h1>
        <div className="buttons-container">
          <ExcelDownload
            link={unitReportData.excelUrl}
            disabled={disableExcelDoc}
          />
          <DownloadButton click={downloadPdf} loading={loading} cta={"PDF"} />
          <HardwareButton
            link={
              process.env.REACT_APP_AUTH_DOMAIN + "/dashboard/vitalSigns/node"
            }
          />
        </div>
      </div>
      {apiError && <ApiError message={unitReportData.error!.message} />}
      <div className="margin-bottom">
        <Cards>
          <div className="filters filters-margin-top">
            <div>
              <DatePickerDropdown
                startDateLabel={
                  unitReportData.startDate ?? defaultDateRange.startDate
                }
                endDateLabel={
                  unitReportData.endDate ?? defaultDateRange.endDate
                }
                onChange={handleDatePickerChange}
              />
              {isDatePickerVisible && (
                <DatePicker
                  data-testid="datepicker"
                  handleDatePickerChange={() => handleDatePickerChange}
                  visible={isDatePickerVisible}
                  maxNumDays={maxDayFilter}
                  maxNumWeeks={maxWeekFilter}
                  aggregate={aggregate}
                  startDate={
                    unitReportData.startDate ?? defaultDateRange.startDate
                  }
                  endDate={unitReportData.endDate ?? defaultDateRange.endDate}
                  onChange={handleDateChange}
                  handleClick={handleDatePickerButtonClick}
                />
              )}
            </div>
            <Filters
              filters={filters}
              onChange={onFilterChange}
              toggleButton={toggleButton}
              applyFilters={applyFilters}
            />
          </div>
        </Cards>
      </div>

      <div className="overview-pages-grid">
        <div className="performance-card">
          <Performance reportData={unitReportData.tiles} />
        </div>
        <div className="metrics-card">
          <MetricsCard performant={performant} total={total} />
        </div>
        <div className="best-day-card">
          <BestDay
            bestTimePeriod={unitReportData.bestTimePeriod}
            aggregateLabel={bestDateAggregate}
          />
        </div>
        <div className="other-metrics-card-hh">
          <OtherMetricsHH
            weekday={unitReportData?.weekdayWeekendData?.weekday}
            weekend={unitReportData?.weekdayWeekendData?.weekend}
            shiftData={unitReportData?.shiftData ?? { Shifts: "No Data" }}
            entry={unitReportData?.entryExitData?.Entry}
            exit={unitReportData?.entryExitData?.Exit}
          />
        </div>
        <div className="graph-card">
          <LineGraph
            title={"Performance Over Time"}
            yAxisPlotlines={unitReportData.yAxisPlotlines}
            dataSeries={unitReportData.dataSeries}
          />
        </div>
        <div className="histogram-card">
          <HistogramCard
            id="histogram"
            title={"Histogram of Providers"}
            data={unitReportData.userHistogram}
            tableData={unitReportData.userHistogramDrilldownData}
          />
        </div>
        <div className="performance-breakdown-card">
          <PerformanceDetail
            dataSeries={unitReportData.groupDataSeries}
            overrideColumnNames={[{ name: "group_name", label: "Group Names" }]}
          />
        </div>
        <div className="hygiene-stars-card">
          <Stars topPerformers={unitReportData.topPerformers} />
        </div>
      </div>
    </>
  );
};

export default UnitsView;
