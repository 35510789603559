type CardsProps = {
  topBorder?: boolean;
  bottomBorder?: boolean;
  boxShadow?: boolean;
  fullWidth?: boolean;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  footerLeft?: React.ReactNode;
  footerRight?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  height?: string;
  verticalAlign?: boolean;
};

function Cards(props: CardsProps) {
  let content = props.children === undefined ? "" : "content";
  let boxShadow = props.boxShadow ? "" : "box-shadow";
  let fullWidth = props.fullWidth ? "full-width" : "";
  let className = props.className || "";

  let height = props.height || "auto";
  let verticallyCenter = props.verticalAlign ? "vertically-center-content" : "";

  const completeClassname = className + " Cards content " + boxShadow;

  return (
    <div
      style={{ height: height }}
      className={completeClassname + " " + verticallyCenter}
      onClick={props.onClick}
    >
      <div
        className={props.topBorder === true ? "top border" : "top borderless"}
      >
        <div className="card-titles header">
          <div className="header-left">{props.headerLeft}</div>
          <div className="header-right">{props.headerRight}</div>
        </div>
      </div>

      <div className={content + " " + fullWidth}>{props.children}</div>
      <div
        className={
          props.bottomBorder === true ? "border bottom" : "borderless bottom"
        }
      >
        <div className="card-titles footer">
          <div className="footer-left">{props.footerLeft}</div>
          <div className="footer-right">{props.footerRight}</div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
