import { otibDTO } from "../../model/types";
import { parseDateToMidnight } from "../../utils";
import { transformFilters } from "../utils";

export function transformOtib(responseJson: otibDTO) {
  const otibData = responseJson?.otibData?.validDataResults;
  const filtersData = responseJson?.otibData?.params;
  const error = responseJson?.otibData?.error;
  const uuid = responseJson?.otibData?.uuid;

  const filters = transformFilters(filtersData);
  let startDate, endDate;
  const dateRange = responseJson?.otibData?.params?.date_range?.value;

  if (!dateRange) {
    startDate = responseJson?.otibData?.subtitle?.startDate;
    endDate = responseJson?.otibData?.subtitle?.endDate;
  } else {
    startDate = new Date(parseDateToMidnight(dateRange[1]));
    endDate = new Date(parseDateToMidnight(dateRange[2]));
  }

  const d3Data = otibData?.map((data) => {
    return {
      map_data: data.map_data,
      markers: data.markers,
      subtitle: data.subtitle,
    };
  });

  return { d3Data, filters, error, startDate, endDate, uuid };
}
