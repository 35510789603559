import ElementArtworkHelper from "../../shared/ElementArtworkHelper";
import MapElementEvents from "../MapElementEvents";
import BaseNodeMapElement from "../BaseNodeMapElement";
import $ from "jquery";
import jQuery from "jquery";

export default class GenericLayer extends BaseNodeMapElement {
  chart;
  identifier;
  markerType;
  class;
  displayName;
  id;
  ElementEvents;
  ElementArtwork;
  unplacedItems;
  deletedItems;

  /* Layer Constructor - Pass in chart (nodeMap) and layerName */
  constructor(chart, layerName, displayName) {
    super();

    this.chart = chart;
    this.identifier = layerName;
    this.displayName = displayName || layerName;
    this.markerType = layerName + "_marker";
    this.class = layerName + "_layer";
    this.id = layerName + "-" + chart.generateRandomID();
    this.ElementEvents = new MapElementEvents();
    this.ElementArtwork = new ElementArtworkHelper();
    /* Get an array of Markers that could be on the map but aren't - either they were removed or have never been placed */
    this.unplacedItems = {};
    this.setUnplacedItems(this.defaultUnplacedMarkers, false);
    /* Get an array of Markers that were deleted from the map */
    this.deletedItems = {};
  }

  /* Get the Layer's Items */
  get items() {
    return this.sel.selectAll(
      "g[entity_type='" + this.markerType + "'].mapMarker"
    );
  }

  /* Gen the Unique Identifier for the layer */
  get baseElementIdentifier() {
    return this.identifier;
  }

  /* What depth should the layer take (0 is bottom, inf is top) */
  get sortDepthIndex() {
    return 0;
  }

  /* Get the Layer's Selector */
  get sel() {
    return this.chart.layer_root.select("#" + this.id + ".maplayer");
  }

  /* Get the Layer's Human Name (for toggling hidden) */
  get name() {
    return this.chart.pluralizeString(this.displayName, 2);
  }

  /* Get an array of Markers the layer should display on first render */
  get defaultMarkers() {
    /* Handle on individual layers */
    return {};
  }

  /* Get an array of Markers that could be on the map but aren't the first time the map is loaded */
  get defaultUnplacedMarkers() {
    /* Handle on individual layers */
    return {};
  }

  /* Layer Scaling (separate from global scaling) */
  get layerScale() {
    /* Handle on individual layers */
    return 1;
  }

  /* Scale to scale elements by */
  get scale() {
    return this.chart.globalScale * this.layerScale;
  }

  calculateRotatedPosition(cx, cy, x, y, angle) {
    if (angle === 0) {
      return {
        x: x,
        y: y,
      };
    }

    const radians = (Math.PI / 180) * angle;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);

    //Return [x,y]
    return {
      x: cos * (x - cx) + sin * (y - cy) + cx,
      y: cos * (y - cy) - sin * (x - cx) + cy,
    };
  }

  /* Set the value of the unplaced items array and generate EIDs for all new items */
  setUnplacedItems(items, dirty) {
    const _this = this;
    items = items || {};
    var newItems = {};
    //Generate Eids & Other Marker Info
    $.each(items, function (key, marker) {
      marker = _this.preformatMarker(marker, dirty);
      marker.node_map_id = null;
      marker.x = null;
      marker.y = null;
      marker.angle = null;
      newItems[marker.eid] = marker;
    });
    this.unplacedItems = newItems;

    return newItems;
  }

  /* Add an item to unplaced items array and choose to mark it dirty or not */
  addUnplacedItem(marker, dirty) {
    //Generate Eids & Other Marker Info
    marker = this.preformatMarker(marker, dirty);
    marker.node_map_id = null;
    marker.x = null;
    marker.y = null;
    marker.angle = null;

    this.unplacedItems[marker.eid] = marker;

    return marker;
  }

  /* Set the value of the deleted items array and generate EIDs for all new items */
  setDeletedItems(items, dirty = false) {
    const _this = this;
    items = items || {};
    var newItems = {};
    //Generate Eids & Other Marker Info
    $.each(items, function (key, marker) {
      newItems[marker.eid] = _this.preformatMarker(marker, dirty);
    });
    this.deletedItems = newItems;

    return newItems;
  }

  /* Add an item to deleted items array and choose to mark it dirty or not */
  addDeletedItem(marker, dirty = false) {
    //Generate Eids & Other Marker Info
    marker = this.preformatMarker(marker, dirty);

    this.deletedItems[marker.eid] = marker;

    return marker;
  }

  /* Prepare a Marker to move to Rendering - Called every time before a marker is rendered */
  prepareMarker(marker) {
    /* Overwrite on individual layers to customize markers */
    /* Called on both create and add existing and update */
    return marker;
  }

  /* eslint-disable no-unused-vars */
  elementClicked(elementSel, elementInfo) {
    /* Overwrite on individual layers to customize action taken when clicked */
  }

  /* eslint-disable no-unused-vars */
  elementShouldAllowPositionalEditor(elementSel, elementInfo) {
    //Turn on and off the positional editor - overwrite on child layers
    return false;
  }

  /* Called when an allowed element is shift-clicked */
  elementShowPositionalEditor(elementSel, elementInfo) {
    var _this = this;
    //Create Edit Modal
    var modalEditView = _this.chart
      .d3Select(":root body")
      .append("div")
      .attr("id", "elementPositionalModal-" + elementInfo.eid)
      .attr(
        "class",
        "modal fade retain overlay roomPrimaryModal " +
          _this.getModalTagClass(elementInfo)
      )
      .attr("role", "dialog");
    var modalContent = modalEditView
      .append("div")
      .attr("class", "modal-dialog modal-lg")
      .append("div")
      .attr("class", "modal-content");
    var modalHeader = modalContent.append("div").attr("class", "modal-header");
    var closeButton = modalHeader
      .append("button")
      .attr("class", "close")
      .attr("data-dismiss", "modal")
      .html("&times;");
    var modalTitle = modalHeader
      .append("h4")
      .attr("class", "modal-title")
      .html(_this.displayName + " Position");
    var modalBody = modalContent.append("div").attr("class", "modal-body");
    var modalFooter = modalContent
      .append("div")
      .attr("class", "modal-footer clearfix");
    var leftButtons = modalFooter
      .append("div")
      .attr("class", "pull-left no-padding-left text-left");
    var rightButtons = modalFooter
      .append("div")
      .attr("class", "pull-right no-padding-right text-right");

    var cancelButton = rightButtons
      .append("button")
      .attr("type", "button")
      .attr("class", "btn btn-default")
      .attr("data-dismiss", "modal")
      .html(elementInfo.locked ? "Dismiss" : "Cancel");
    var saveButton = null;
    if (!elementInfo.locked) {
      saveButton = rightButtons
        .append("button")
        .attr("type", "button")
        .attr("class", "btn btn-primary")
        .html("Update");
    }

    //View input text fields
    var bodyRow = modalBody.append("div").attr("class", "row");
    var nameFieldSet = bodyRow
      .append("fieldset")
      .attr("class", "form-group col-md-10 col-xs-9");
    var nameTextLabel = nameFieldSet
      .append("label")
      .attr("for", "name")
      .html("Name:");
    var nameTextField = nameFieldSet
      .append("input")
      .attr("type", "text")
      .attr("class", "form-control elementDetailInputField")
      .attr("name", "name")
      .attr("readonly", true)
      .property("value", elementInfo.name || null);
    var idFieldSet = bodyRow
      .append("fieldset")
      .attr("class", "form-group col-md-2 col-xs-3");
    var idTextLabel = idFieldSet
      .append("label")
      .attr("for", "roomConfigId")
      .html("ID:");
    var idTextField = idFieldSet
      .append("input")
      .attr("type", "text")
      .attr("class", "form-control elementDetailInputField")
      .attr("readonly", true)
      .attr("name", "roomConfigId")
      .property("value", elementInfo.id || null);

    var xFieldSet = bodyRow
      .append("fieldset")
      .attr("class", "form-group col-sm-4 col-xs-6");
    var xTextLabel = xFieldSet
      .append("label")
      .attr("for", "x")
      .html("Position X:");
    var xTextField = xFieldSet
      .append("input")
      .attr("type", "number")
      .attr("step", ".001")
      .attr("class", "form-control elementDetailInputField")
      .attr("name", "x")
      .property("value", elementInfo.x);
    var yFieldSet = bodyRow
      .append("fieldset")
      .attr("class", "form-group col-sm-4 col-xs-6");
    var yTextLabel = yFieldSet
      .append("label")
      .attr("for", "y")
      .html("Position Y:");
    var yTextField = yFieldSet
      .append("input")
      .attr("type", "number")
      .attr("step", ".001")
      .attr("class", "form-control elementDetailInputField")
      .attr("name", "y")
      .property("value", elementInfo.y);
    var angleFieldSet = bodyRow
      .append("fieldset")
      .attr("class", "form-group col-sm-4 col-xs-12");
    var angleTextLabel = angleFieldSet
      .append("label")
      .attr("for", "angle")
      .html("Rotationº:");
    var angleTextField = angleFieldSet
      .append("input")
      .attr("type", "number")
      .attr("step", ".1")
      .attr("class", "form-control elementDetailInputField")
      .attr("name", "angle")
      .property("value", elementInfo.angle);

    //Setup chosen selects for room mode mode sensor config selectors (but also any others would be setup by this func) - relies on core scripts being loaded independently
    window.CoreScripts.setupChosenSelects();

    //Lock all inputs if element locked
    if (elementInfo.locked) {
      var $inputFields = $(modalContent.node()).find(
        ".elementDetailInputField"
      );
      if ($inputFields.length > 0) {
        $inputFields.prop("readonly", true);
        var $selectInputFields = $inputFields.filter("select");
        if ($selectInputFields.length > 0) {
          $selectInputFields.prop("disabled", true);
        }
        var $btnFields = $inputFields.filter(".btn");
        if ($btnFields.length > 0) {
          $btnFields.addClass("btn-disabled pointer-no-interaction-force");
        }
      }
    }

    //Capture Modal Button Events
    if (saveButton != null) {
      $(saveButton.node()).on("click", function () {
        elementInfo.x = _this.chart.filterFloat(xTextField.node().value);
        elementInfo.angle =
          _this.chart.filterFloat(angleTextField.node().value) % 360;
        elementInfo.y = _this.chart.filterFloat(yTextField.node().value);

        // Update element rendering and render dirty if it is dirty
        elementSel.dispatch("rerender");

        $(modalEditView.node()).modal("hide");
      });
    }

    //Destroy modal when hidden
    $(modalEditView.node()).on("hidden.bs.modal", function () {
      $(this).remove();
    });
    //Handle Modal Show
    $(modalEditView.node()).modal("show");
  }

  /* Called after element was reset to original attributes */
  elementWasResetToOriginal(elementSel, elementInfo, previousAttributes) {
    /* Overwrite on individual layers to customize action taken when reset to original attributes */
  }

  /* eslint-disable no-unused-vars */
  elementActiveOn(elementSel, elementInfo) {
    /* Overwrite on individual layers to customize action taken when an element becomes active */
  }

  /* eslint-disable no-unused-vars */
  elementActiveOff(elementSel, elementInfo) {
    /* Overwrite on individual layers to customize action taken when an element becomes inactive */
  }

  /* Add an existing marker */
  addMarker(marker, updateMapDirtyStatus = true) {
    this.chart.deselectActiveMarkers();
    /* Pre-format marker & Call prepareMarker within to customize */
    var preparedMarker = this.preformatMarker(marker, false);
    /* If the marker was previously removed and released, un-release */
    delete preparedMarker.private_attributes.shouldRelease;

    /* instantiate */
    this.insertElementGroups(this.sel, [preparedMarker]);

    if (updateMapDirtyStatus) {
      /* Update map dirty status */
      this.chart.refreshDirtyStatus();
    }

    /* return the prepared marker data */
    return preparedMarker;
  }

  /* Add a new marker */
  createMarker(marker = {}, updateMapDirtyStatus = true) {
    this.chart.deselectActiveMarkers();
    /* Pre-format marker & Call prepareMarker within to customize */
    var preparedMarker = this.preformatMarker(marker, true);
    /* If the marker was previously removed and released, un-release */
    delete preparedMarker.private_attributes.shouldRelease;

    /* instantiate */
    this.insertElementGroups(this.sel, [preparedMarker]);

    if (updateMapDirtyStatus) {
      /* Update map dirty status */
      this.chart.refreshDirtyStatus();
    }

    /* return the prepared marker data */
    return preparedMarker;
  }

  getItemByEid(eid) {
    var _this = this;
    const items = _this.items;
    var toReturn = null;
    items.each(function (existingInfo) {
      var existingElement = this;
      if (existingInfo.eid === eid) {
        toReturn = {
          info: existingInfo,
          sel: _this.chart.d3Select(existingElement),
        };

        return false;
      }
    });

    return toReturn;
  }

  /* Formatting that must be done on every marker before any customization */
  preformatMarker(marker, dirty = true) {
    marker.eid = marker.eid || this.chart.generateRandomID();
    marker.node_map_id = marker.node_map_id || null;
    marker.x = this.chart.filterFloat(marker.x);
    marker.y = this.chart.filterFloat(marker.y);
    if (marker.x == null && marker.y == null) {
      var visibleCenter = this.chart.visibleCenter();
      marker.x = this.chart.filterFloat(visibleCenter.x, 0);
      marker.y = this.chart.filterFloat(visibleCenter.y, 0);
    }
    marker.angle = this.chart.filterFloat(marker.angle, 0);
    //Set up maintenance operations
    marker.pending_maintenance_operations =
      marker.pending_maintenance_operations || {};
    //Set up Internal Attributes - Map only items that cannot be exported but are needed on the map
    marker.internal_map_attributes = marker.internal_map_attributes || {};
    //Set up private_attributes - Attributes that may be computed or needed for saving, but are not part of models
    marker.private_attributes = marker.private_attributes || {};
    //Readonly attributes that don't need to be broadcasted back to the app
    marker.readonly_attributes = marker.readonly_attributes || {};
    marker.internal_map_attributes.entity_type = this.markerType;
    marker.internal_map_attributes.dirty = dirty;
    marker.internal_map_attributes.active =
      marker.internal_map_attributes.active || false;
    marker.internal_map_attributes.moused =
      marker.internal_map_attributes.moused || false;
    marker.internal_map_attributes.moused_at =
      marker.internal_map_attributes.moused_at || null;
    marker.internal_map_attributes.dragging =
      marker.internal_map_attributes.dragging || false;

    /* customize */
    marker = this.prepareMarker(marker);

    /* STORE A SAVED INSTANCE */
    //Store an instance of elementInfo pre modification - if not already set (if already set of course that would be the original)
    marker.internal_map_attributes.originalAttributes =
      marker.internal_map_attributes.originalAttributes ||
      jQuery.extend(true, {}, marker);

    /* Return ready-to-go marker */
    return marker;
  }

  /* Setup an Element - Called once per element (during setup) */

  /* Checks if element info passed in matches between the passed in data and original data */
  elementInfoMatchesOriginal(elementInfo) {
    return this.compareDeepEqual(
      elementInfo.internal_map_attributes.originalAttributes,
      elementInfo
    );
  }

  /* Render an Element - Called (potentially) more than once per element (re-rendering) */

  /* Render Layer */
  render() {
    /* Load up pre-saved markers if present */
    var defaultMarkersToShow = this.defaultMarkers;
    if (defaultMarkersToShow) {
      this.addMarkers(defaultMarkersToShow, false);
    }
    if (this.shouldLoadHiddenAtFirst) {
      // Store the new current tab so back always does what we expect

      this.setHidden(true);
    }
  }

  /* eslint-disable no-unused-vars */
  setupElement(elementSel, elementInfo) {
    /* Handle on individual layers */
    /* any special element setup can be done here */
    /* Can do things there like elementAddRotationControls */
  }

  /* eslint-disable no-unused-vars */
  renderElement(elementImageSel, elementInfo) {
    /* Handle on individual layers */
    /* Draw the element here */
  }

  /* Checks if a modal is open with the given jquery selector string */
  isModalOpen(selectorString) {
    return $(selectorString + ".modal").length > 0;
  }

  /* Gets a class to tag all modals for a particular element so we know which modals are associated with which elements */
  getModalTagClass(elementInfo) {
    return "nodeMap-modal-" + this.markerType + "-" + elementInfo.eid;
  }

  /* Close all modals for a given element */
  closeAllElementModals(elementInfo) {
    var $warningModals = $("." + this.getModalTagClass(elementInfo));
    if ($warningModals.length > 0) {
      $warningModals.modal("hide");
    }
  }

  /* Internal Function to handle element insertion */
  insertElementGroups(rootFrame, newElements) {
    var _this = this;
    var elementGroups = rootFrame
      .selectAll("g")
      .data(newElements, function (d) {
        return d.eid;
      })
      .enter()
      .append("g")
      .attr("class", "mapMarker text-unselectable")
      .attr("id", function (d) {
        return "eid-" + d.eid;
      })
      .attr("entity_type", function (d) {
        return d.internal_map_attributes.entity_type;
      })
      .attr("eid", function (d) {
        return d.eid;
      })
      .attr("active", function (d) {
        return d.internal_map_attributes.active;
      })
      .attr("transform", function (d) {
        return "translate(" + d.x + "," + d.y + ")";
      })
      .style("pointer-events", "visible")
      .style("display", "block")
      .style("opacity", 1.0);

    /* Add Image Layer */
    var elementImages = elementGroups
      .append("g")
      .attr("id", "element-image")
      .attr("class", "text-unselectable")
      .attr("transform", function (d) {
        return "rotate(" + d.angle + ")";
      });

    /* Add Interaction Controls */
    elementGroups
      .append("g")
      .attr("id", "element-interaction")
      .attr("class", "text-unselectable")
      .style("pointer-events", "visible")
      .style("opacity", function (d) {
        return d.internal_map_attributes.active ? 1 : 0;
      })
      .style("visibility", function (d) {
        return d.internal_map_attributes.active ? "visible" : "hidden";
      });

    /* Do Setup */
    elementGroups.call(_this.insertElementGroupsCallbackSetup, _this);

    /* Render Element */
    elementImages.call(_this.insertElementGroupsCallbackRender, _this);

    return elementGroups;
  }

  /* Add multiple Markers (Array) */
  addMarkers(data, updateMapDirtyStatus = true) {
    var _this = this;
    $.each(data, function (key, marker) {
      _this.addMarker(marker, false);
    });

    if (updateMapDirtyStatus) {
      /* Update map dirty status */
      this.chart.refreshDirtyStatus();
    }
  }

  /* Create multiple new Markers (Array) */
  createMarkers(data, updateMapDirtyStatus = true) {
    var _this = this;
    $.each(data, function (key, marker) {
      _this.createMarker(marker, false);
    });

    if (updateMapDirtyStatus) {
      /* Update map dirty status */
      this.chart.refreshDirtyStatus();
    }
  }

  /* Replace all markers on map with a passed in array */
  replaceMarkers(data, updateMapDirtyStatus = true) {
    this.chart.deselectActiveMarkers();
    var leftToAdd = data;
    var _this = this;
    const existingItems = this.items;

    existingItems.each(function (existingInfo) {
      var existingElement = this;
      const newMatch = _this.chart.getElementsMatchingEidFromObject(
        existingInfo.eid,
        leftToAdd
      );
      const newMatchKeys = Object.keys(newMatch);
      if (newMatchKeys.length > 0) {
        const newIndex = newMatchKeys[0];
        const newData = newMatch[newIndex];
        /* Do Marker Formatting for new data - mark element as not dirty (false)*/
        const preparedMarker = _this.preformatMarker(newData, false);

        /* Dispatch Replace Data Update to this element */
        _this.chart
          .d3Select(this)
          .dispatch("setAttributesToPayload", function () {
            return {
              bubbles: true,
              cancelable: true,
              detail: {
                newData: preparedMarker,
                mode: "replaceMarkers",
              },
            };
          });

        /* Remove from remaining to add list */
        delete leftToAdd[newIndex];
      } else {
        /* Element has been deleted */
        _this.elementRemove(existingElement, existingInfo, false);
      }
    });

    /* Add new markers to map - marked as not dirty */
    _this.addMarkers(leftToAdd, false);

    if (updateMapDirtyStatus) {
      /* Update map dirty status */
      this.chart.refreshDirtyStatus();
    }
  }

  /* Internal Function to callback element rendering */
  insertElementGroupsCallbackRender(imageSel, layerRef) {
    imageSel.each(function (elementInfo) {
      layerRef.renderElement(imageSel, elementInfo);
    });
  }

  /* Internal Function to callback element event setup */
  insertElementGroupsCallbackSetup(sel, layerRef) {
    sel.each(function (elementInfo) {
      layerRef.ElementEvents.setupEvents(layerRef, sel, elementInfo);
      layerRef.setupElement(sel, elementInfo);
      /* Call back to map element events indicating that interaction setup is complete */
      layerRef.ElementEvents.completeSetupEvents(layerRef, sel, elementInfo);
    });
  }

  /* Get image selector for an element given its element selector */
  elementImageSelFromElementSel(elementSel) {
    return elementSel.select("#element-image");
  }

  /* Get interaction selector for an element given its element selector */
  elementInteractionSelFromElementSel(elementSel) {
    return elementSel.select("#element-interaction");
  }

  /* Add Rotation Controls to an Element */
  elementAddRotationControls(
    elementSel,
    width = 22,
    height = 22,
    xOffset = 20,
    yOffset = -30,
    inc = 45
  ) {
    var controlScale = this.scale;
    var rotationHandles = this.elementInteractionSelFromElementSel(elementSel)
      .append("g")
      .attr("id", "rotation-controls-" + inc);
    /* Clockwise */
    rotationHandles
      .append("rect")
      .attr("width", controlScale * width)
      .attr("height", controlScale * height)
      .attr("x", controlScale * (-width / 2))
      .attr("y", controlScale * (-height / 2))
      .style("cursor", "pointer")
      .attr("transform", function () {
        return (
          "translate(" +
          -controlScale * xOffset +
          "," +
          controlScale * yOffset +
          ")"
        );
      })
      .on("click", function (event, elementInfo) {
        if (!elementInfo.locked) {
          event.stopPropagation();
          elementInfo.angle += inc;
          elementInfo.angle = Math.round(elementInfo.angle / inc) * inc;
          elementInfo.angle = elementInfo.angle % 360;
          //Re-render now
          elementSel.dispatch("rerender");
        }
      })
      .attr("fill", "url(#pattern-image-rotate-clockwise)");
    /* Counter-Clockwise */
    rotationHandles
      .append("rect")
      .attr("width", controlScale * width)
      .attr("height", controlScale * height)
      .attr("x", controlScale * (-width / 2))
      .attr("y", controlScale * (-height / 2))
      .style("cursor", "pointer")
      .attr("transform", function () {
        return (
          "translate(" +
          controlScale * xOffset +
          "," +
          controlScale * yOffset +
          ")"
        );
      })
      .on("click", function (event, elementInfo) {
        if (!elementInfo.locked) {
          event.stopPropagation();
          elementInfo.angle -= inc;
          elementInfo.angle = Math.round(elementInfo.angle / inc) * inc;
          elementInfo.angle = elementInfo.angle % 360;
          //Re-render now
          elementSel.dispatch("rerender");
        }
      })
      .attr("fill", "url(#pattern-image-rotate-counterclockwise)");

    return rotationHandles;
  }

  /* Add Edit/View Button to an Element - calls elementDetailShow when clicked */
  elementAddViewDetailControls(
    elementSel,
    width = 15,
    height = 15,
    xOffset = 0,
    yOffset = 30
  ) {
    var _this = this;
    var controlScale = this.scale;
    var removalControls = this.elementInteractionSelFromElementSel(elementSel)
      .append("g")
      .attr("id", "detail-controls");
    /* Remove Button */
    removalControls
      .append("rect")
      .attr("width", controlScale * width)
      .attr("height", controlScale * height)
      .attr("x", controlScale * (-width / 2))
      .attr("y", controlScale * (-height / 2))
      .style("cursor", "pointer")
      .attr("transform", function () {
        return (
          "translate(" +
          controlScale * xOffset +
          "," +
          controlScale * yOffset +
          ")"
        );
      })
      .on("click", function (event, elementInfo) {
        if (!elementInfo.locked) {
          event.stopPropagation();
          _this.elementDetailShow(elementSel, elementInfo);
        }
      })
      .attr("fill", "url(#pattern-image-edit)");
  }

  /* Trigger an element to be removed from the layer */
  elementRemove(elementSel, elementInfo, addToUnplacedItems = true) {
    //If stored in DB, need to specially handle remove by marking the item removed and retaining
    if (addToUnplacedItems && elementInfo.id != null) {
      //Add to unplaced items
      this.addUnplacedItem(elementInfo, true);
    }

    elementSel.remove();
    this.chart.refreshDirtyStatus();
  }

  /* Trigger an element to be deleted from the layer */
  elementDelete(elementSel, elementInfo, options = {}) {
    var _this = this;
    //If stored in DB, need to specially handle remove by marking the item removed and retaining
    if (elementInfo.id != null) {
      //Add to deleted items
      this.addDeletedItem(elementInfo, true);
    }

    //Close any open modals for the deleted thing
    _this.closeAllElementModals(elementInfo);

    //Actually remove from map
    elementSel.remove();
    this.chart.refreshDirtyStatus();
  }

  /* Show a detail view for an element - overwrite on layers */
  elementDetailShow(elementSel, elementInfo) {
    //Overwrite on layers
  }
}
