import { get } from "../../api/laravelApi";
import { userReportEndpoint } from "../../constants";

export const StaffLoader = async (request) => {
  const startDate = new URL(request.url).searchParams.get("startDate");
  const endDate = new URL(request.url).searchParams.get("endDate");
  const facilityIds = new URL(request.url).searchParams.getAll("facilitiesIds");
  const customerIds = new URL(request.url).searchParams.getAll("customersIds");
  const unitIds = new URL(request.url).searchParams.getAll("unitsIds");
  const groupIds = new URL(request.url).searchParams.getAll("groupsIds");

  const staffReportData = await get(userReportEndpoint, {
    startDate,
    endDate,
    facilityIds,
    customerIds,
    unitIds,
    sumOverTimeRange: true,
    user_ids_filter: "",
    groupIds,
  });

  return staffReportData;
};
