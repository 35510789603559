import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { useEffect, useRef, useState } from "react";
import Cards from "../UI/Cards/Cards";
import { formatDate } from "../../utils";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { useModuleTheme } from "../../contexts/ModuleTheme/moduleThemes";
import { getValueFromTheme } from "../../utils/theme";
import { dateDiffInDays } from "../../utils/index";

// TODO:  update types
// QUESTION - do we need to disable selection option of future dates?

type DatePickerProps = {
  startDate: Date;
  endDate: Date;
  maxNumDays?: number;
  maxNumWeeks?: number;
  aggregate?: string;
  maxDate?: Date;
  onChange: (startDate: string, endDate: string) => void;
  visible?: boolean;
  handleDatePickerChange?: () => void;
  handleClick?: () => void;
  isVisible?: boolean;
};

function totalWeeksBetweenDates(date1: Date, date2: Date): number {
  // Get the difference between the two dates in milliseconds
  const differenceInMilliseconds = Math.abs(date1.getTime() - date2.getTime());

  // Calculate the total number of weeks (rounded down)
  const weeks = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24 * 7)
  );

  return weeks;
}

function DatePicker(this: any, props: DatePickerProps) {
  const { theme } = useModuleTheme();

  const themeValues = getValueFromTheme(theme);

  const componentRef = useRef<HTMLDivElement | null>(null);
  const [maxDayWarning, setMaxDayWarning] = useState(false);
  const [maxWeekWarning, setMaxWeekWarning] = useState(false);

  // this is a hack to get useEffect to update the daterange only when
  // the page gets reloaded after applying Filters and not when you select dates
  const [shouldUpdateDateRange, setShouldUpdateDateRange] = useState(true);

  // this is the date selector - must by type date
  const [dateRange, setDateRange] = useState([
    {
      startDate: props.startDate,
      endDate: props.endDate,
      key: "selection",
    },
  ]);

  const maxDate = props.maxDate || new Date();

  useOutsideClick(componentRef, () => {
    setDateRange([
      {
        startDate: props.startDate,
        endDate: props.endDate,
        key: "selection",
      },
    ]);
  });

  useEffect(() => {
    if (shouldUpdateDateRange) {
      setDateRange([
        {
          startDate: props.startDate,
          endDate: props.endDate,
          key: "selection",
        },
      ]);
    } else {
      setShouldUpdateDateRange(true);
    }

    return () => {
      setShouldUpdateDateRange(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let initialState: boolean;
  if (window.innerWidth < 1200) {
    initialState = false;
  } else {
    initialState = true;
  }

  const [isLargeViewport, setIsLargeViewport] = useState(initialState);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeViewport(window.innerWidth > 1200);
    };

    window.addEventListener("resize", handleResize);

    // Remove the event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let numberOfMonths = 2;
  if (!isLargeViewport) {
    numberOfMonths = 1;
  }

  function applyButtonDisplay() {
    setShouldUpdateDateRange(false);
    props.onChange(
      formatDate(dateRange[0].startDate),
      formatDate(dateRange[0].endDate)
    );
    if (props.handleClick) {
      props.handleClick();
    }
  }

  function handleChange(item: {
    selection: { startDate: Date; endDate: Date; key: string };
  }) {
    const startDate = item.selection.startDate;
    const endDate = item.selection.endDate;

    switch (props.aggregate) {
      case "daily":
        if (
          props.maxNumDays !== undefined &&
          dateDiffInDays(startDate, endDate) > props.maxNumDays
        ) {
          setMaxDayWarning(true);
          return;
        }

        setMaxDayWarning(false);
        break;
      case "weekly":
        if (
          props.maxNumWeeks !== undefined &&
          totalWeeksBetweenDates(startDate, endDate) > props.maxNumWeeks
        ) {
          setMaxWeekWarning(true);
          return;
        }

        setMaxWeekWarning(false);
        break;
      default:
        setMaxDayWarning(false);
        setMaxWeekWarning(false);
        break;
    }

    setDateRange([item.selection]);
  }

  return (
    <div ref={componentRef}>
      {theme === "contact-tracing" && (
        <div className="label-container">
          <label htmlFor="date-picker-button">Exposure Dates</label>
        </div>
      )}

      <div className="datepicker-card-container">
        <Cards headerLeft={<h2>Custom Date Range Selector:</h2>}>
          <DateRangePicker
            data-testid="datepicker"
            inputId="date-picker"
            maxDate={maxDate}
            onChange={handleChange}
            editableDateInputs={true}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            retainEndDateOnFirstSelection={true}
            months={numberOfMonths}
            ranges={dateRange}
            direction="horizontal"
            rangeColors={[themeValues.rangeColor]}
            role="date-picker"
            dateDisplayFormat="MM/dd/yyyy"
          />
          {maxDayWarning && (
            <div className="flex-container-right negative-margin-top half-margin-bottom">
              {`Maximum number of days you can select is ${props.maxNumDays}`}
            </div>
          )}
          {maxWeekWarning && (
            <div className="flex-container-right margin-bottom">
              {`Maximum number of weeks you can select is ${props.maxNumWeeks}`}
            </div>
          )}
          <div className="flex-container-right negative-button-margin">
            <button className="button-container" onClick={props.handleClick}>
              Cancel
            </button>
            <button className="themed" onClick={() => applyButtonDisplay()}>
              Select Dates
            </button>
          </div>
        </Cards>
      </div>
    </div>
  );
}

export default DatePicker;
