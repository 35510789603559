// test helper function to give us a date string that looks like this: '2007-04-05T12:30−02:00'
export function withTimezoneOffset(date: Date): string {
  const timezoneOffsetMinutes = date.getTimezoneOffset();

  const offsetHours = String(
    Math.floor(Math.abs(timezoneOffsetMinutes) / 60)
  ).padStart(2, "0");
  const offsetMinutes = String(Math.abs(timezoneOffsetMinutes) % 60).padStart(
    2,
    "0"
  );
  const offsetSign = timezoneOffsetMinutes > 0 ? "-" : "+";

  const localISOTime = date.toISOString().slice(0, 16); // e.g. '2007-04-05T12:30'
  const localISOTimeWithOffset =
    localISOTime + offsetSign + offsetHours + ":" + offsetMinutes; // e.g. '2007-04-05T12:30−02:00'
  return localISOTimeWithOffset;
}
