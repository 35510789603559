import { ColorRing } from "react-loader-spinner";
import { useModuleTheme } from "../../../contexts/ModuleTheme/moduleThemes";
import { getValueFromTheme } from "../../../utils/theme";

function Loading() {
  const { theme } = useModuleTheme();

  const spinnerColor = getValueFromTheme(theme);

  return (
    <div className="center-content-loading loading">
      <ColorRing
        height="100"
        width="100"
        // must have 5 colors in the array, but I thought 1 color looked better so repeat hex code is deliberate.
        colors={[
          spinnerColor.loadSpinner,
          spinnerColor.loadSpinner,
          spinnerColor.loadSpinner,
          spinnerColor.loadSpinner,
          spinnerColor.loadSpinner,
        ]}
        ariaLabel="loading"
      />
    </div>
  );
}

export default Loading;
