import { useLoaderData } from "react-router-dom";
import { AssetsDTO } from "../types";

const useAssetsModel = () => {
  const assetsData = useLoaderData() as AssetsDTO;

  return {
    assetsData,
  };
};

export default useAssetsModel;
