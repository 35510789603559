import Backwards from "@untitled-ui/icons-react/build/cjs/FlipBackward";

import useIndividualAssetsViewController from "../../viewcontroller/IndividualAssets/useIndividualAssetsViewController";
import Table from "../../Components/Table/Table";
import { formatTimeWithDate, styleWordInText } from "../../transformers/utils";
import Cards from "../../Components/UI/Cards/Cards";
import DatePicker from "../../Components/DatePicker/DatePicker";
import {
  defaultDateRange,
  missingDataAction,
  missingDataMessageAT,
} from "../../constants";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
import MissingData from "../../Components/MissingData/MissingData";
import DatePickerDropdown from "../../Components/DatePickerDropdown/DatePickerDropdown";

function IndividualAssetView() {
  const {
    transformedIndividualAssetsData,
    dataTable,
    handleDateChange,
    applyFilters,
    toggleButton,
    disableExcelDoc,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
  } = useIndividualAssetsViewController();

  const returnToAllAssets = () => {
    window.location.href = `/AT/assets`;
  };

  return (
    <div>
      <div className="at-page-header">
        <div className="header-flex">
          <div>
            {transformedIndividualAssetsData.image !== missingDataMessageAT && (
              <img
                className="at-header-image"
                src={transformedIndividualAssetsData.image}
                alt={transformedIndividualAssetsData.nickname}
              />
            )}
          </div>
          <div>
            <h1>{transformedIndividualAssetsData.name}</h1>
            <h2>{transformedIndividualAssetsData.nickname}</h2>
            <p>Serial #: {transformedIndividualAssetsData.serialNumber}</p>
            <p>ID: {transformedIndividualAssetsData.id}</p>
          </div>
        </div>

        <div className="buttons-container">
          <ExcelDownload
            link={transformedIndividualAssetsData.excelUrl}
            disabled={disableExcelDoc}
          />
        </div>
      </div>
      <div className="display-flex-left margin-bottom">
        <button className="themed" onClick={() => returnToAllAssets()}>
          <Backwards />
          Back to all Assets
        </button>
      </div>
      <div className="margin-bottom">
        <Cards>
          <div className="filters filters-margin-top">
            <div>
              <DatePickerDropdown
                startDateLabel={
                  transformedIndividualAssetsData.startDate ??
                  defaultDateRange.startDate
                }
                endDateLabel={
                  transformedIndividualAssetsData.endDate ??
                  defaultDateRange.endDate
                }
                onChange={handleDatePickerChange}
              />
              {isDatePickerVisible && (
                <DatePicker
                  handleDatePickerChange={() => handleDatePickerChange}
                  visible={isDatePickerVisible}
                  startDate={
                    transformedIndividualAssetsData.startDate ??
                    defaultDateRange.startDate
                  }
                  endDate={
                    transformedIndividualAssetsData.endDate ??
                    defaultDateRange.endDate
                  }
                  onChange={handleDateChange}
                  handleClick={handleDatePickerButtonClick}
                />
              )}
            </div>
            <div className="apply-button-container">
              <button
                className={`filters-margin themed select-button-height ${
                  toggleButton
                    ? "asset-tracking flashing-button-asset-tracking"
                    : ""
                }`}
                onClick={() => applyFilters()}
              >
                Apply Filters
              </button>
            </div>
          </div>
        </Cards>
      </div>
      <div>
        <Table
          title="Asset History"
          columns={[
            {
              name: "room",
              label: "Room",
              options: {
                customBodyRenderLite: (dataIndex) => {
                  return styleWordInText({
                    textToStyle: "Near",
                    text: dataTable[dataIndex].room,
                  });
                },
              },
            },
            { name: "unit", label: "Unit Location" },
            { name: "facility", label: "Facility Location" },
            {
              name: "entryAt",
              label: "Entry At",
              options: {
                customBodyRenderLite: (dataIndex) => {
                  if (dataTable[dataIndex]?.entryAt === null) {
                    return "No exit detected";
                  } else {
                    return formatTimeWithDate(
                      dataTable[dataIndex]?.entryAt.datetime || null,
                      transformedIndividualAssetsData.timezone,
                      "Entry"
                    );
                  }
                },
              },
            },
            {
              name: "exitAt",
              label: "Exit At",
              options: {
                customBodyRenderLite: (dataIndex) => {
                  if (dataTable[dataIndex]?.exitAt === null) {
                    return "No exit detected";
                  } else {
                    return formatTimeWithDate(
                      dataTable[dataIndex]?.exitAt.datetime,
                      transformedIndividualAssetsData.timezone,
                      "Exit"
                    );
                  }
                },
              },
            },
          ]}
          optionOverrides={{
            sortOrder: {
              name: "entryAt",
              direction: "desc",
            },
            textLabels: {
              body: {
                noMatch: (
                  <MissingData
                    message={missingDataMessageAT}
                    action={missingDataAction}
                  />
                ),
              },
            },
          }}
          data={dataTable}
        ></Table>
      </div>
    </div>
  );
}

export default IndividualAssetView;
