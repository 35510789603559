import MissingData from "../../Components/MissingData/MissingData";
import usePositiveRoomCaseViewModel from "../../viewmodel/PositiveRoomCase/usePositiveRoomCaseViewModel";
import { deleteMethod, patch } from "../../api/laravelApi";
import { newCaseEndpoint } from "../../constants";
import { useUserInfo } from "../../contexts/UserInfo/userInfo";
import { useEffect, useState } from "react";
import { formatTimeWithDate } from "../../transformers/utils";

const usePositiveRoomCaseViewController = () => {
  const { positiveRoomCaseData, transformedCaseDetails } =
    usePositiveRoomCaseViewModel();
  const [disableExcelDoc, setDisableExcelDoc] = useState(true);

  useEffect(() => {
    if (!positiveRoomCaseData.export) {
      setDisableExcelDoc(true);
    } else {
      setDisableExcelDoc(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positiveRoomCaseData.export]);

  const { userInfo } = useUserInfo();
  const [apiError, setApiError] = useState<string | null>(null);

  const returnToAllCases = () => {
    window.location.href = `/CT/cases`;
  };

  function separateRoomNameFromVisitTimes(visit: string): {
    roomName: string;
    visitTimes: string;
  } {
    const roomName = visit.split(":", 1);
    let visitTimes;

    for (let i = 0; i < visit.length; i++) {
      if (visit[i] === ":") {
        visitTimes = visit.substring(i + 1);
        break;
      }
    }
    return { roomName: roomName[0], visitTimes: visitTimes };
  }

  const staffTableData = positiveRoomCaseData?.staffExposures?.map(
    (staffExposure) => {
      const formattedRoomVisits = staffExposure.overlappingVisits.map(
        (visit) => {
          const roomName = visit.roomName;
          const startedAtTime = formatTimeWithDate(
            visit.startedAt.datetime,
            visit.startedAt.timezone,
            "Time"
          );
          const endedAtTime = formatTimeWithDate(
            visit.endedAt.datetime,
            visit.endedAt.timezone,
            "Time"
          );
          return `${roomName}: ${startedAtTime} - ${endedAtTime}`;
        }
      );
      return {
        roomVisits: formattedRoomVisits,
        staffName: staffExposure.staffName,
      };
    }
  );

  const archiveAction = async () => {
    const token = userInfo?.tokens.csrf;
    const additionalHeaders = {
      "X-CSRF-TOKEN": token,
    };

    const id = positiveRoomCaseData.case.id;
    try {
      await patch({
        additionalHeaders,
        url: `${process.env.REACT_APP_AUTH_DOMAIN}/${newCaseEndpoint}/${id}/archive`,
      });
      setApiError(null);
      window.location.assign(
        "https://" +
          process.env.REACT_APP_ANALYTICS_DOMAIN +
          `/CT/positive-room-case/${id}`
      );
    } catch (e: any) {
      setApiError(e.message);
    }
  };

  const deleteAction = async () => {
    const token = userInfo?.tokens.csrf;
    const additionalHeaders = {
      "X-CSRF-TOKEN": token,
    };

    const id = positiveRoomCaseData.case.id;
    try {
      await deleteMethod({
        additionalHeaders,
        url: `${process.env.REACT_APP_AUTH_DOMAIN}/${newCaseEndpoint}/${id}`,
      });
      setApiError(null);
      window.location.assign(
        "https://" + process.env.REACT_APP_ANALYTICS_DOMAIN + `/CT/cases`
      );
    } catch (e: any) {
      setApiError(e.message);
    }
  };

  const isDisabled = positiveRoomCaseData.case.is_archived === true;

  const tableColumnsStaff = [
    { name: "staffName", label: "Name" },
    {
      name: "roomVisits",
      label: "Room Visits",
      options: {
        customBodyRender: (value: string[]) => {
          return (
            <>
              {value.map((visit) => {
                const { visitTimes } = separateRoomNameFromVisitTimes(visit);

                return <div>{visitTimes}</div>;
              })}
            </>
          );
        },
      },
    },
  ];

  const tableOptions = {
    rowHover: true,
    textLabels: {
      body: {
        noMatch: (
          <MissingData
            message={"No Data Available."}
            action={"Please try a different selection"}
          />
        ),
      },
    },
  };

  return {
    positiveRoomCaseData,
    caseDetails: transformedCaseDetails,
    disableExcelDoc,
    returnToAllCases,
    tableColumnsStaff,
    tableOptions,
    staffTableData,
    isDisabled,
    apiError,
    archiveAction,
    deleteAction,
  };
};

export default usePositiveRoomCaseViewController;
