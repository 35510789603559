import { useLoaderData } from "react-router-dom";
import { UnitReportDTO } from "../types";

const useUnitReportModel = () => {
  const unitReportData = useLoaderData() as UnitReportDTO;

  return {
    unitReportData,
  };
};

export default useUnitReportModel;
