function ComplianceBucketButtons({ selectedButton, onSelectButton }) {
  const buttons = [
    { id: 0, className: "red-compliance", label: "0%-25%" },
    { id: 1, className: "yellow-compliance", label: "25%-50%" },
    { id: 2, className: "green-compliance", label: "50%-75%" },
    { id: 3, className: "blue-compliance", label: "75%-100%" },
  ];

  const handleClick = (id: number) => {
    if (selectedButton === id) {
      onSelectButton(null);
    } else {
      onSelectButton(id);
    }
  };

  return (
    <div>
      {buttons.map((button) => (
        <button
          data-testid={"compliance-button-" + button.id}
          key={button.id}
          className={
            selectedButton === button.id
              ? "active-legend-button margin-right"
              : "margin-right"
          }
          onClick={() => handleClick(button.id)}
        >
          <div className={`compliance ${button.className}`}></div>
          {button.label}
        </button>
      ))}
    </div>
  );
}

export default ComplianceBucketButtons;
