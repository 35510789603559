import { useEffect, useState } from "react";
import useCaseOptionsViewModel from "../../viewmodel/NewCase/useNewCaseViewModel";
import { Option } from "../../types/types";
import useSharedFunctions from "../sharedFunctions/useSharedFunctions";
import { useSearchParams } from "react-router-dom";
import { formatDate } from "../../utils";
import { useUserInfo } from "../../contexts/UserInfo/userInfo";
import { newCaseEndpoint } from "../../constants";
import { post } from "../../api/laravelApi";

const useNewCaseViewController = () => {
  const { transformedCaseOptionsData } = useCaseOptionsViewModel();
  const { filters, setSelected, setPeerFilters, getSelectedOptions } =
    useSharedFunctions(transformedCaseOptionsData);

  useEffect(() => {
    transformedCaseOptionsData.filters.forEach((filter) => {
      setPeerFilters(filter.name, filter.peerFilters!);
      setSelected(filter.name, filter.selection);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedTab, setSelectedTab] = useState(0);
  const [apiError, setApiError] = useState<string | null>(null);
  const [exposureStart, setExposureStart] = useState<Date | null>(null);
  const [exposureEnd, setExposureEnd] = useState<Date | null>(null);
  const { userInfo } = useUserInfo();

  const customersFilter = filters?.find(
    (filter) => filter.name === "customers"
  );

  const facilitiesFilter = filters?.find(
    (filter) => filter.name === "facilities"
  );

  const exposureTypeFilter = filters?.find(
    (filter) => filter.name === "Exposure Type"
  );

  const roomFilter = filters?.find((filter) => filter.name === "rooms");
  const usersFilter = filters?.find((filter) => filter.name === "users");

  const createTopLevelFilters = (commonFilters, ...specificFilters) => [
    ...commonFilters,
    ...specificFilters,
  ];

  const commonFilters = [customersFilter!, facilitiesFilter!];

  const topLevelFiltersUsers = createTopLevelFilters(
    commonFilters,
    usersFilter!,
    exposureTypeFilter!
  );

  const topLevelFiltersRooms = createTopLevelFilters(
    commonFilters,
    roomFilter!,
    exposureTypeFilter!
  );

  const submitDisabled = !(
    exposureTypeFilter?.selection[0]?.value &&
    exposureStart &&
    exposureEnd &&
    (selectedTab === 0
      ? usersFilter?.selection[0]?.value
      : roomFilter?.selection[0]?.value)
  );

  const handleSelect = (index) => {
    setSelectedTab(index);
  };

  const caseSubmitHandler = async (event) => {
    event.preventDefault();
    const customer_id = customersFilter?.selection[0]?.value;
    const facility_id = facilitiesFilter?.selection[0]?.value;
    const exposure_type_id = exposureTypeFilter?.selection[0]?.value;
    const started_at = exposureStart;
    const ended_at = exposureEnd;

    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    };

    const dateOptions: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };

    // Get the time part and date part separately in order to format it how we want it
    const formattedStartTime = new Intl.DateTimeFormat("en-US", options).format(
      started_at!
    );
    const formattedEndTime = new Intl.DateTimeFormat("en-US", options).format(
      ended_at!
    );
    const formattedStartDate = new Intl.DateTimeFormat(
      "en-US",
      dateOptions
    ).format(started_at!);
    const formattedEndDate = new Intl.DateTimeFormat(
      "en-US",
      dateOptions
    ).format(ended_at!);

    const body = {
      customer_id,
      facility_id,
      exposure_type_id,
      subject_type: selectedTab === 0 ? "staff" : "room",
      started_at: formattedStartTime + " " + formattedStartDate,
      ended_at: formattedEndTime + " " + formattedEndDate,
    };

    if (selectedTab === 0) {
      body["user_id"] = usersFilter?.selection[0]?.value;
    } else if (selectedTab === 1) {
      body["room_config_id"] = roomFilter?.selection[0]?.value;
    }

    const token = userInfo?.tokens.csrf;
    const additionalHeaders = {
      "X-CSRF-TOKEN": token,
    };

    setSearchParams({
      ...selectedIds,
    });

    try {
      await post({
        additionalHeaders,
        body,
        url: `${process.env.REACT_APP_AUTH_DOMAIN}/${newCaseEndpoint}`,
      });

      setApiError(null);
      window.location.assign(
        "https://" + process.env.REACT_APP_ANALYTICS_DOMAIN + "/CT/cases"
      );
    } catch (e: any) {
      setApiError(e.message);
    }
  };

  const [, setSearchParams] = useSearchParams({
    startDate: formatDate(transformedCaseOptionsData.startDate) || "",
    endDate: formatDate(transformedCaseOptionsData.endDate) || "",
  });

  let selectedIdsArray: any = [];
  if (!filters) {
    selectedIdsArray = [];
  } else {
    selectedIdsArray = filters?.map((filter) => {
      const filterName = `${filter.name}Ids`;
      const selectedOptions = getSelectedOptions(filter.selection);

      return {
        [filterName]: selectedOptions,
      };
    });
  }

  const selectedIds = selectedIdsArray.reduce((acc, curr) => {
    return { ...acc, ...curr };
  }, {});

  const onFilterChange = (
    filterName: string,
    updatedSelected: readonly Option[]
  ) => {
    const updatedSelectedValues = [...updatedSelected];

    setSelected(filterName, updatedSelectedValues);
  };

  return {
    transformedCaseOptionsData,
    topLevelFiltersUsers,
    topLevelFiltersRooms,
    usersFilter,
    roomFilter,
    handleSelect,
    apiError,
    caseSubmitHandler,
    exposureStart,
    exposureEnd,
    setExposureStart,
    setExposureEnd,
    submitDisabled,
    onFilterChange,
  };
};

export default useNewCaseViewController;
