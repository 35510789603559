import Cards from "../UI/Cards/Cards";
import CircularProgress from "../UI/CircularProgress/CircularProgress";
import Tooltips from "../UI/Tooltip/Tooltip";
import { tooltipContent } from "../../content";
import { getColor } from "../../utils";
import { missingDataAction, missingDataMessage } from "../../constants";
import MissingData from "../MissingData/MissingData";

type RoundingPerformanceProps = {
  totalPerformance: number | null;
};

function RoundingPerformance(props: RoundingPerformanceProps) {
  let { totalPerformance } = props;
  let circularProgressText, circularProgressPct, pathColor;

  function isMissingtotalPerformance(
    totalPerformance: number | null | undefined
  ): totalPerformance is null | undefined {
    return totalPerformance === null || totalPerformance === undefined;
  }

  const missingTotalPerformance = isMissingtotalPerformance(totalPerformance);

  if (isMissingtotalPerformance(totalPerformance)) {
    circularProgressText = missingDataMessage;
    circularProgressPct = 0;
    pathColor = getColor(0);
  } else {
    circularProgressText = "Total";
    circularProgressPct = totalPerformance;
    pathColor = getColor(totalPerformance);
  }

  return (
    <>
      <Cards
        className="full-height"
        headerLeft={<h2>All Shifts Rounding Performance</h2>}
        headerRight={
          <Tooltips
            id="roundingPerformanceCard-tooltip"
            content={tooltipContent.roundingPerformanceCard["en-US"]}
          />
        }
      >
        {!missingTotalPerformance && (
          <div className="performance-container">
            <div>
              <CircularProgress
                className="margin-2x"
                text={circularProgressText}
                value={circularProgressPct}
                trailColor={"#F3F4F7"}
                pathColor={pathColor}
              ></CircularProgress>
            </div>
          </div>
        )}
        {missingTotalPerformance && (
          <MissingData
            message={missingDataMessage}
            action={missingDataAction}
            className="missing-data-container"
          />
        )}
      </Cards>
    </>
  );
}

export default RoundingPerformance;
