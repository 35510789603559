import { findSelection } from "..";
import {
  isRawOptionsWithHeaders,
  parseOptionsHeaders,
  parseOptions,
} from "../../Components/Filters/hooks/helpers";

import {
  CaseOptionsDTO,
  NIResponseParamsDTO,
  ResponseParamsDTO,
  TileDTO,
} from "../../model/types";
import { Filter, TransformedTiles, DataSeries } from "../../types/types";
import { getFilterMapper } from "../../utils";

export function transformFilters(
  params: ResponseParamsDTO | NIResponseParamsDTO | CaseOptionsDTO
): Filter[] {
  if (!params) return [];
  const filterMapper = getFilterMapper();

  const filteredParamsKeys = Object.keys(params).filter(
    (p) => filterMapper[p] && params[p].visible
  );
  return filteredParamsKeys.map((fk) => {
    const selection = findSelection(params[fk]);

    const rawOptions = params[fk].options;

    const options = isRawOptionsWithHeaders(rawOptions)
      ? parseOptionsHeaders(rawOptions)
      : parseOptions(rawOptions);

    return {
      name: filterMapper[fk],
      selection,
      isDirty: false,
      reloadingUrl: params[fk].reloading.url,
      peerFilters: params[fk].reloading.peer_filters,
      options: options,
    };
  });
}

// sanitize a DataSeries for HTML tags
// TODO: Create a type for DataSeries
export function filterHtmlTags(array) {
  return array
    .map((series) => {
      if (
        /(<[^>]+>|<[^>]>|<\/[^>]>)/g.test(series.name) &&
        series.data?.length === 0
      ) {
        return {};
      } else if (
        /(<[^>]+>|<[^>]>|<\/[^>]>)/g.test(series.name) &&
        series.data?.length !== 0
      ) {
        series.name = series.name.replace(/(<[^>]+>|<[^>]>|<\/[^>]>)/g, "");
      }
      return series;
    })
    .filter((series) => Object.keys(series).length > 0);
}

export function transformTiles(
  tilesDto: TileDTO[] | undefined
): TransformedTiles {
  let transformedTiles: TransformedTiles = {
    total: null,
    performant: null,
    performance: null,
    percentChangedFormatted: null,
  };

  if (tilesDto) {
    tilesDto.forEach((tile) => {
      switch (tile._component_class) {
        case "EventCounts":
          if (tile.opportunities)
            transformedTiles["total"] = tile.opportunities;
          if (tile.events) transformedTiles["performant"] = tile.events;
          break;
        case "PerformanceOverall":
          if (tile.performance)
            transformedTiles["performance"] = tile.performance;
          break;
        case "PerformanceChanged":
          if (tile.percentChangedFormatted)
            transformedTiles["percentChangedFormatted"] =
              tile.percentChangedFormatted;
          break;
        default:
          break;
      }
    });
  }
  return transformedTiles;
}

export function findBestTimePeriod(dataseries: DataSeries[]) {
  const visibleGroups = dataseries.filter((group) => {
    return group.visible;
  });

  if (visibleGroups.length !== 1) {
    return null;
  }

  const visibleGroup = visibleGroups[0];
  let largestPercentage = -Infinity;
  let bestTimePeriod = "";

  for (let i = 0; i < visibleGroup.data.length; i++) {
    const currentPercentage = visibleGroup.data[i].percentage;

    if (currentPercentage > largestPercentage) {
      largestPercentage = currentPercentage;
      bestTimePeriod = visibleGroup.data[i].name;
    }
  }

  return {
    date: bestTimePeriod.split("<br>")[0],
    performance: largestPercentage,
  };
}

export function formatTimeWithDate(
  timeSeen: string | number | Date,
  timeZone: string,
  event: string
): string {
  if (!timeSeen || !timeZone) return "No " + event + " Recorded";

  const date = new Date(timeSeen);

  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
    timeZone: timeZone,
  };

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    timeZone: timeZone,
  };

  // Get the time part and date part separately in order to format it how we want it
  const formattedTime = new Intl.DateTimeFormat("en-US", options).format(date);
  const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(
    date
  );

  return `${formattedTime}\n${formattedDate}`;
}

export function styleWordInText({ textToStyle, text }) {
  const splitText = text.split(" ");
  const textToStyleIndex = splitText.indexOf(textToStyle);

  const styledText = splitText.map((word: string, index: number) => {
    // Include a space before the word if it's not the first word
    const spaceBefore = index > 0 ? " " : "";

    if (index === textToStyleIndex) {
      return (
        <b>
          <i>{word}</i>
        </b>
      );
    }
    return spaceBefore + word;
  });

  return styledText;
}
