import Backwards from "@untitled-ui/icons-react/build/cjs/FlipBackward";

type BackwardsButtonProps = {
  click: () => void;
  text: string;
};

function BackwardsButton(props: BackwardsButtonProps) {
  return (
    <div>
      <button className="themed" onClick={() => props.click()}>
        {" "}
        <Backwards /> {props.text}
      </button>
    </div>
  );
}

export default BackwardsButton;
