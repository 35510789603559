import useCasesViewController from "../../viewcontroller/Cases/useCasesViewController";

import Filters from "../../Components/Filters/Filters";
import Cards from "../../Components/UI/Cards/Cards";
import TabsList from "../../Components/UI/Tabs/Tabs";
import { tooltipContent } from "../../content";
import Table from "../../Components/Table/Table";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
import { useUUIDWithLocation } from "../../Hooks/useUuid";
import { useEffect } from "react";

function Cases() {
  const {
    filters,
    onFilterChange,
    applyFilters,
    toggleButton,
    tableColumnsPositiveRooms,
    tableColumnsPositiveStaff,
    positivePatients,
    positiveStaff,
    tableOptionsRooms,
    tableOptionsStaff,
    disableExcelDoc,
    excelUrl,
    uuid,
  } = useCasesViewController();

  const { updateUUID } = useUUIDWithLocation();

  useEffect(() => {
    updateUUID(uuid);
  }, [uuid, updateUUID]);

  return (
    <>
      <div className="page-header">
        <h1>Existing Cases</h1>
        <div className="buttons-container">
          <ExcelDownload link={excelUrl} disabled={disableExcelDoc} />
        </div>
      </div>
      <div className="margin-bottom">
        <Cards>
          <div className="filters filters-margin-top">
            <Filters
              filters={filters}
              onChange={onFilterChange}
              toggleButton={toggleButton}
              applyFilters={applyFilters}
              singleSelect
            />
          </div>
        </Cards>
        <TabsList
          className="pills-tabs"
          tabs={["Positive Staff", "Positive Room"]}
          tooltipContent={tooltipContent.cases["en-US"]}
          tooltipId="cases-tooltip"
          content={[
            <div className="ct-rooms-history-table">
              <Table
                columns={tableColumnsPositiveStaff}
                optionOverrides={tableOptionsStaff}
                data={positiveStaff}
                title={"Positive Staff"}
              ></Table>
            </div>,
            <div>
              <div className="ct-rooms-history-table">
                <Table
                  columns={tableColumnsPositiveRooms}
                  optionOverrides={tableOptionsRooms}
                  data={positivePatients}
                  title={"Positive Room"}
                ></Table>
              </div>
            </div>,
          ]}
        />
      </div>
    </>
  );
}

export default Cases;
