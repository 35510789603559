import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FileHeart from "@untitled-ui/icons-react/build/cjs/FileHeart02";

import ApiError from "../../Components/ApiError/ApiError";
import Filters from "../../Components/Filters/Filters";
import Cards from "../../Components/UI/Cards/Cards";
import TabsList from "../../Components/UI/Tabs/Tabs";
import useNewCaseViewController from "../../viewcontroller/NewCase/useNewCaseViewController";

function NewCase() {
  const {
    onFilterChange,
    topLevelFiltersRooms,
    topLevelFiltersUsers,
    handleSelect,
    apiError,
    caseSubmitHandler,
    submitDisabled,
    setExposureStart,
    setExposureEnd,
  } = useNewCaseViewController();

  return (
    <>
      <div className="page-header">
        <h1>New Case</h1>
      </div>
      <div>
        <Cards>
          <div className="form-field-width">
            <div>
              <TabsList
                className="pills-tabs"
                tabs={["Positive Staff", "Positive Room"]}
                content={[
                  <div className="margin-top-2x margin-bottom form-field">
                    <Filters
                      filters={topLevelFiltersUsers}
                      onChange={onFilterChange}
                      singleSelect
                    />
                  </div>,
                  <div className="margin-top-2x margin-bottom form-field">
                    <Filters
                      filters={topLevelFiltersRooms}
                      onChange={onFilterChange}
                      singleSelect
                    />
                  </div>,
                ]}
                select={handleSelect}
              />
            </div>
            <div className="label-container contact-tracing">
              <label>Exposure Dates</label>
            </div>

            <div className="display-flex-gap ct-form">
              {/* LocalizationProvider should be at the top level of your app. If we end up using this library in place
            of the other datepicker, we should add it to the App.tsx file. */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  disableFuture
                  className="new-case-date-picker"
                  onChange={setExposureStart}
                />
                <DateTimePicker
                  disableFuture
                  className="new-case-date-picker"
                  onChange={setExposureEnd}
                />
              </LocalizationProvider>
            </div>

            <form method="post">
              <div className="margin-top">
                <button
                  onClick={caseSubmitHandler}
                  className={`themed ${submitDisabled ? "disabled" : ""}`}
                  type="submit"
                  disabled={submitDisabled}
                >
                  <FileHeart />
                  Open Case
                </button>
              </div>
            </form>
          </div>

          {apiError && <ApiError message={apiError} />}
        </Cards>
      </div>
    </>
  );
}

export default NewCase;
