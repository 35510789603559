import useIndividualAssetsModel from "../../model/IndividualAssets/useIndividualAssetsModel";
import { transformIndividualAssets } from "../../transformers/individualAssets/transformIndividualAssets";

const useIndividualAssetsViewModel = () => {
  const { individualAssetData } = useIndividualAssetsModel();
  const transformedIndividualAssetsData =
    transformIndividualAssets(individualAssetData);

  return {
    individualAssetData,
    transformedIndividualAssetsData,
  };
};

export default useIndividualAssetsViewModel;
