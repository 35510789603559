import { formatCalendarDate } from "../../utils";
import Dropdown from "./../UI/Dropdown/Dropdown";

interface DropdownProps {
  onChange: (value) => void;
  startDateLabel: Date;
  endDateLabel: Date;
}

const DatePickerDropdown = ({
  onChange,
  startDateLabel,
  endDateLabel,
}: DropdownProps) => {
  const handleDropdownChange = (value) => {
    onChange(value);
  };

  const datePickerOptions = [
    {
      value: "defaultView",
      label:
        formatCalendarDate(startDateLabel) +
        " - " +
        formatCalendarDate(endDateLabel),
    },
    {
      value: "sevenDays",
      label: "Past 7 Days",
    },
    {
      value: "fourteenDays",
      label: "Past 14 Days",
    },
    {
      value: "thirtyDays",
      label: "Past 30 Days",
    },
    {
      value: "lastWeek",
      label: "Last Week",
    },
    {
      value: "lastMonth",
      label: "Last Month",
    },
    {
      value: "lastThreeMonths",
      label: "Last 3 Months",
    },
    {
      value: "lastSixMonths",
      label: "Last 6 Months",
    },
    {
      value: "custom",
      label: "Custom",
    },
  ];

  return (
    <>
      <Dropdown
        title={"Date Picker"}
        options={datePickerOptions}
        onChange={handleDropdownChange}
      />
    </>
  );
};

export default DatePickerDropdown;
