import { get } from "../../api/laravelApi";
import { positiveRoomCaseEndpoint } from "../../constants";

export const PositiveRoomCaseLoader = async ({ params }) => {
  const positiveCaseData = await get(
    `${positiveRoomCaseEndpoint}/${params.id}`
  );

  return positiveCaseData;
};
