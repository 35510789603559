import { Await, useLoaderData } from "react-router";
import { Suspense, useEffect } from "react";
import Loading from "../../Components/UI/Loading/Loading";
import Cards from "../../Components/UI/Cards/Cards";
import FiltersWithDatePicker from "../../CompositeComponents/FiltersWithDatePicker";
import StaffOverviewSummary from "../../Components/VisitOverview/StaffOverviewSummary";
import { StaffOverviewDTO } from "../../model/types";
import StaffOverviewTable from "../../Components/VisitOverview/StaffOverviewTable";
import { useOutletContext } from "react-router-dom";
import { VisitOverviewContext } from "../VisitOverview/contexts/VisitOverviewContext";

function StaffOverviewView() {
  const { filters, summary, staffTable } = useLoaderData() as StaffOverviewDTO;
  const { setExcelDownloadUrl, setPdfProps } =
    useOutletContext<VisitOverviewContext>();
  // handle excel download url
  useEffect(() => {
    const resolveData = async () => {
      setExcelDownloadUrl((await filters).export);
    };
    resolveData().then();
  }, [filters, setExcelDownloadUrl]);

  // handle pdf attributes
  useEffect(() => {
    const resolveData = async () => {
      const [summaryData, staffTableData] = await Promise.all([
        summary,
        staffTable,
      ]);
      setPdfProps({
        type: "staff",
        staffProps: {
          summary: summaryData,
          users: staffTableData,
        },
        roomsProps: null,
      });
    };
    resolveData().then();
  }, [summary, staffTable, setPdfProps]);
  return (
    <>
      <div className="margin-bottom">
        <Cards>
          <div className="filters filters-margin-top">
            <Suspense
              fallback={
                <div className="fallback-loader">
                  <Loading />
                </div>
              }
            >
              <Await resolve={filters}>
                <FiltersWithDatePicker />
              </Await>
            </Suspense>
          </div>
        </Cards>
      </div>
      <div className="display-flex-visit-summary">
        <Suspense
          fallback={
            <div className="fallback-loader">
              <Loading />
            </div>
          }
        >
          <Await resolve={summary}>
            <StaffOverviewSummary />
          </Await>
        </Suspense>
      </div>

      <div className="display-flex-visit-table">
        <div>
          <Suspense>
            <Await resolve={staffTable}>
              <StaffOverviewTable />
            </Await>
          </Suspense>
        </div>
      </div>
    </>
  );
}

export default StaffOverviewView;
