import Cards from "../UI/Cards/Cards";
import MissingData from "../MissingData/MissingData";
import { missingDataMessage, missingDataAction } from "../../constants";
import Award from "@untitled-ui/icons-react/build/cjs/Award01";
import Tooltips from "../UI/Tooltip/Tooltip";
import { tooltipContent } from "../../content";
import { convertDateRangeFromString } from "../../utils";

type BestDayProps = {
  bestTimePeriod: { performance: number; date: string } | null;
  aggregateLabel: string | null;
};

function BestDay(props: BestDayProps) {
  const { bestTimePeriod, aggregateLabel } = props;

  let missingData = (
    <MissingData
      className="margin-top"
      message={missingDataMessage}
      action={missingDataAction}
    />
  );

  return (
    <>
      {!bestTimePeriod ? (
        <Cards>{missingData}</Cards>
      ) : (
        <Cards
          height="100%"
          headerLeft={<h2>Best {aggregateLabel}</h2>}
          headerRight={
            <Tooltips
              id="bestDateTooltip"
              content={tooltipContent.bestDate["en-US"]}
            />
          }
          verticalAlign={true}
        >
          <div className="best-date">
            <div className="title-container">
              <div>
                {aggregateLabel === "Week" ? (
                  <h2>{convertDateRangeFromString(bestTimePeriod?.date)}</h2>
                ) : (
                  <h2>{bestTimePeriod?.date}</h2>
                )}
              </div>
            </div>

            <div className="center-content display-flex-row">
              <div className="metrics-svg">
                {" "}
                <Award />
              </div>
              <h1>{bestTimePeriod?.performance}%</h1>
            </div>
          </div>
        </Cards>
      )}
    </>
  );
}

export default BestDay;
