import { useAsyncValue } from "react-router";
import { tooltipContent } from "../../content";
import SummaryCard from "../SummaryCard/SummaryCard";
import { StaffOverviewSummaryDTO } from "../../model/types";
import { convertSecondsToHoursMinutesSeconds } from "../../utils";

function Summary() {
  const summary = useAsyncValue() as StaffOverviewSummaryDTO;

  return (
    <>
      <div className="ni-visit-overview-summary-card" key={0}>
        <SummaryCard
          index={0}
          title="Average Length of Visit"
          data={convertSecondsToHoursMinutesSeconds(summary.averageVisitLength)}
          tooltipContent={
            tooltipContent.staffOverview.averageVisitLength["en-US"]
          }
        />
      </div>
      <div className="ni-visit-overview-summary-card" key={1}>
        <SummaryCard
          index={1}
          title="Total Staff Visits"
          data={summary.visitCount.toString()}
          tooltipContent={tooltipContent.staffOverview.visitCount["en-US"]}
        />
      </div>
    </>
  );
}

export default Summary;
