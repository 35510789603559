import { defaultDateRange } from "../../constants";
import useCaseOptionsModel from "../../model/CaseOptions/useCaseOptionsModel";
import { transformFilters } from "../../transformers/utils";

const useCaseOptionsViewModel = () => {
  const { caseOptionsData } = useCaseOptionsModel();

  const startDate = defaultDateRange.startDate;
  const endDate = defaultDateRange.endDate;

  const transformedCaseOptionsData = {
    filters: transformFilters(caseOptionsData),
    startDate: startDate ? new Date(startDate) : null,
    endDate: endDate ? new Date(endDate) : null,
  };

  return {
    transformedCaseOptionsData,
  };
};

export default useCaseOptionsViewModel;
