import { useModuleTheme } from "../../contexts/ModuleTheme/moduleThemes";
import { useUserInfo } from "../../contexts/UserInfo/userInfo";
import LogoutButton from "../LogoutButton/LogoutButton";
import LoginButton from "../LoginButton/LoginButton";
import HubApp from "@untitled-ui/icons-react/build/cjs/LayoutAlt02";
import { Link } from "react-router-dom";
import HamburgerButton from "../HamburgerButton/HamburgerButton";

type TopNavigationProps = {
  navigation?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  displayHamburgerButton?: boolean;
};

function TopNavigation(props: TopNavigationProps) {
  const { theme } = useModuleTheme();

  let className;
  if (window.location.pathname !== "/") {
    className = theme;
  } else {
    className = "overview";
  }

  const { userInfo } = useUserInfo();

  return (
    <div className={className}>
      <div className={"top-nav flex-container"}>
        <Link to="/">
          <img
            className="top-nav-image"
            src="/ss-logo-large-white.svg"
            alt="swipesense logo"
          />
        </Link>

        <div className="right-content">
          <a
            data-testid="hidden-mobile-class"
            href={`${process.env.REACT_APP_AUTH_DOMAIN}`}
          >
            <button className="navigation-button">
              <HubApp />
              Hub App
            </button>
          </a>
          <div>
            {JSON.stringify(userInfo) === "{}" ? (
              <LoginButton />
            ) : (
              <LogoutButton />
            )}
          </div>
          {props.displayHamburgerButton ? (
            <HamburgerButton
              onClick={props.onClick}
              navigation={props.navigation}
            ></HamburgerButton>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default TopNavigation;
