import { convertDecimalHoursToHoursMinutes } from "../../utils";

const baseShiftAnalysisOptions = {
  indexAxis: "y" as const,
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {},
  maintainAspectRatio: false,
};
export const averageTimeBetweenRounds = {
  ...baseShiftAnalysisOptions,
  plugins: {
    ...baseShiftAnalysisOptions.plugins,
    tooltip: {
      callbacks: {
        label: function (context: { parsed: { x: number } }) {
          return convertDecimalHoursToHoursMinutes(context.parsed.x);
        },
      },
    },
  },
  scales: {
    ...baseShiftAnalysisOptions.scales,
    x: {
      x: {
        grace: 0.5,
        min: 0,
      },
      ticks: {
        stepSize: 1,
        callback: function (value: string) {
          return value + " Hours";
        },
      },
    },
  },
};

export const averageRoundDuration = {
  ...baseShiftAnalysisOptions,
  plugins: {
    ...baseShiftAnalysisOptions.plugins,
    tooltip: {
      callbacks: {
        label: function (context: { parsed: { x: number } }) {
          return convertDecimalHoursToHoursMinutes(context.parsed.x);
        },
      },
    },
  },
  scales: {
    ...baseShiftAnalysisOptions.scales,
    x: {
      x: {
        grace: 0.5,
        min: 0,
      },
      ticks: {
        // every 3 minutes or 0.05 hours
        stepSize: 0.05,
        callback: function (value: number) {
          return convertDecimalHoursToHoursMinutes(value);
        },
      },
    },
  },
};

export const nurseRoomTimePercent = {
  ...baseShiftAnalysisOptions,
  plugins: {
    ...baseShiftAnalysisOptions.plugins,
    tooltip: {
      callbacks: {
        label: function (context: { parsed: { x: number } }) {
          return context.parsed.x + "%";
        },
      },
    },
  },
  scales: {
    ...baseShiftAnalysisOptions.scales,
    x: {
      x: {
        grace: 1,
        min: 0,
      },
      ticks: {
        stepSize: 1,
        callback: function (value: string) {
          return value + "%";
        },
      },
    },
  },
};

export const zeroPlaceholderPlugin = {
  id: "zeroPlaceholderPlugin",
  afterDatasetsDraw(chart, _args, _options) {
    const { ctx } = chart;

    chart.data.datasets.forEach(
      (dataset: { data: { [x: string]: null } }, datasetIndex: any) => {
        chart
          .getDatasetMeta(datasetIndex)
          .data.forEach(
            (bar: { x: string; y: string }, index: string | number) => {
              if (dataset.data[index] === null) {
                const xCoord = bar.x;
                const yCoord = bar.y;
                ctx.save();
                ctx.beginPath();
                ctx.arc(xCoord, yCoord, 5, 0, 2 * Math.PI);
                ctx.fillStyle = "#d52316";
                ctx.fill();
                ctx.restore();
              }
            }
          );
      }
    );
  },
};
