import Filters from "../../Components/Filters/Filters";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
import Table from "../../Components/Table/Table";
import Cards from "../../Components/UI/Cards/Cards";
import { formatTimeWithDate, styleWordInText } from "../../transformers/utils";
import useAssetsViewController from "../../viewcontroller/Assets/useAssetsViewController";
import { missingDataAction, missingDataMessageAT } from "../../constants";
import MissingData from "../../Components/MissingData/MissingData";
import { useUUIDWithLocation } from "../../Hooks/useUuid";
import { useEffect } from "react";

function AssetsView() {
  const {
    transformedAssetsData,
    renderImg,
    onFilterChange,
    toggleButton,
    applyFilters,
    filters,
    disableExcelDoc,
  } = useAssetsViewController();

  const { updateUUID } = useUUIDWithLocation();

  useEffect(() => {
    updateUUID(transformedAssetsData?.uuid);
  }, [transformedAssetsData?.uuid, updateUUID]);

  return (
    <>
      <div className="page-header">
        <h1>Assets Catalog</h1>
        <div className="buttons-container">
          <ExcelDownload
            link={transformedAssetsData.excelUrl}
            disabled={disableExcelDoc}
          />
        </div>
      </div>
      <div className="margin-bottom">
        <Cards>
          <div className="filters filters-margin-top">
            <Filters
              filters={filters}
              onChange={onFilterChange}
              toggleButton={toggleButton}
              applyFilters={applyFilters}
            />
          </div>
        </Cards>
      </div>
      <div className="assets-table">
        <Table
          columns={[
            {
              name: "image",
              label: "Image",
              options: {
                customBodyRenderLite: (dataIndex) => {
                  return renderImg(
                    transformedAssetsData.assetsTable[dataIndex].image
                  );
                },
              },
            },
            { name: "type", label: "Type" },
            { name: "model", label: "Model" },
            {
              name: "room",
              label: "Room",
              options: {
                customBodyRenderLite: (dataIndex) => {
                  return styleWordInText({
                    textToStyle: "Near",
                    text: transformedAssetsData.assetsTable[dataIndex].room,
                  });
                },
              },
            },
            { name: "unitLocation", label: "Unit Location" },
            { name: "facilityLocation", label: "Facility Location" },
            { name: "status", label: "Status" },
            { name: "ownership", label: "Ownership" },
            { name: "serialNumber", label: "Serial #" },
            { name: "id", label: "ID" },
            { name: "ownershipStatus", label: "Ownership Status" },
            {
              name: "timeLastSeen",
              label: "Time Last Seen",
              options: {
                customBodyRenderLite: (dataIndex) => {
                  return formatTimeWithDate(
                    transformedAssetsData.assetsTable[dataIndex].timeLastSeen,
                    transformedAssetsData.assetsTable[dataIndex].timezone,
                    "Time"
                  );
                },
              },
            },
          ]}
          data={transformedAssetsData.assetsTable}
          optionOverrides={{
            onRowClick: (rowData: any[]) => {
              const id = rowData[9];
              window.location.href = `/AT/assets/${id}`;
            },
            searchOpen: true,
            responsive: "vertical",
            rowHover: true,
            sortOrder: {
              name: "timeLastSeen",
              direction: "asc",
            },
            textLabels: {
              body: {
                noMatch: (
                  <MissingData
                    message={missingDataMessageAT}
                    action={missingDataAction}
                  />
                ),
              },
            },
          }}
        />
      </div>
    </>
  );
}

export default AssetsView;
