import useRtibModel from "../../model/Rtib/useRtibModel";
import { transformRtib } from "../../transformers/rtibs/transformRtibs";

const useRtibViewModel = () => {
  const { rtibData } = useRtibModel();

  const transformedRtib = transformRtib(rtibData);

  return {
    transformedRtib,
  };
};

export default useRtibViewModel;
