export default function mockExecutiveDashboardData() {
  return {
    _error: [],
    groupTypeData: [
      {
        name: "Administration",
        groupType_id: 11,
        href: "https://local.swipesense.test/report/group/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22group_type_ids%22%3A%5B11%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=572ef2bee42cf09ff49292976cc7faff71249b6018da2f785eb1633c6c6fe86c",
        currentM: null,
        previousM: {
          performance: "0.6746",
          total_opportunities: "35154",
          year_month: 202303,
          groupType_id: 11,
        },
      },
      {
        name: "Environmental Services (EVS)",
        groupType_id: 5,
        href: "https://local.swipesense.test/report/group/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22group_type_ids%22%3A%5B5%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=427b92f4475a22907fba65dad8d2b7f1320d040fdbfa4cf3dcd5521fbf15843c",
        currentM: {
          performance: "0.2634",
          total_opportunities: "22652",
          year_month: 202304,
          groupType_id: 5,
        },
        previousM: {
          performance: "0.2713",
          total_opportunities: "60784",
          year_month: 202303,
          groupType_id: 5,
        },
      },
      {
        name: "Food Services",
        groupType_id: 10,
        href: "https://local.swipesense.test/report/group/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22group_type_ids%22%3A%5B10%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=f4d22fa8ecceb62c7d0e2dcaedba1838f8e5b7b7464f238b692ab167c55ff077",
        currentM: {
          performance: "0.6745",
          total_opportunities: "2842",
          year_month: 202304,
          groupType_id: 10,
        },
        previousM: {
          performance: "0.6690",
          total_opportunities: "7670",
          year_month: 202303,
          groupType_id: 10,
        },
      },
      {
        name: "Nurses (RN)",
        groupType_id: 0,
        href: "https://local.swipesense.test/report/group/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22group_type_ids%22%3A%5B0%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=75d7abd1504e9fd2dffbcef91b770e359d349a87cae28c8e66ff29b9ae04ee5f",
        currentM: {
          performance: "0.5836",
          total_opportunities: "261149",
          year_month: 202304,
          groupType_id: 0,
        },
        previousM: {
          performance: "0.5639",
          total_opportunities: "628502",
          year_month: 202303,
          groupType_id: 0,
        },
      },
      {
        name: "Pharmacists",
        groupType_id: 12,
        href: "https://local.swipesense.test/report/group/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22group_type_ids%22%3A%5B12%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=392e7e59b5f1bd2897430a0cb816f4ca03564d0d0e33cf20e2271efe16dda8b5",
        currentM: {
          performance: "0.5805",
          total_opportunities: "348",
          year_month: 202304,
          groupType_id: 12,
        },
        previousM: {
          performance: "0.5296",
          total_opportunities: "776",
          year_month: 202303,
          groupType_id: 12,
        },
      },
      {
        name: "Phlebotomy",
        groupType_id: 14,
        href: "https://local.swipesense.test/report/group/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22group_type_ids%22%3A%5B14%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=3a51d834b46de2df553f9c67c44d04f5d761a333780a6838a128fd1ea6c4a9f7",
        currentM: {
          performance: "0.4565",
          total_opportunities: "276",
          year_month: 202304,
          groupType_id: 14,
        },
        previousM: {
          performance: "0.4404",
          total_opportunities: "872",
          year_month: 202303,
          groupType_id: 14,
        },
      },
      {
        name: "Physicians",
        groupType_id: 1,
        href: "https://local.swipesense.test/report/group/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22group_type_ids%22%3A%5B1%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=66608ef7dad95b592c6a02aea85b8b70b3e0249660e1e77a2e25425e44b74ed8",
        currentM: {
          performance: "0.6464",
          total_opportunities: "5178",
          year_month: 202304,
          groupType_id: 1,
        },
        previousM: {
          performance: "0.6284",
          total_opportunities: "14307",
          year_month: 202303,
          groupType_id: 1,
        },
      },
      {
        name: "Radiology",
        groupType_id: 13,
        href: "https://local.swipesense.test/report/group/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22group_type_ids%22%3A%5B13%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=f8a57a71c61f8cf82de2955ed6d0a2bba21daa8f68d446852d9c585c60fd5e32",
        currentM: {
          performance: "0.4339",
          total_opportunities: "6213",
          year_month: 202304,
          groupType_id: 13,
        },
        previousM: {
          performance: "0.4311",
          total_opportunities: "15550",
          year_month: 202303,
          groupType_id: 13,
        },
      },
      {
        name: "Respiratory Therapists (RT)",
        groupType_id: 9,
        href: "https://local.swipesense.test/report/group/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22group_type_ids%22%3A%5B9%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=490bb4811ec1ec61940d688de48a3406bb2de00ca72f3655307cbcdf59d7635d",
        currentM: {
          performance: "0.4805",
          total_opportunities: "1330",
          year_month: 202304,
          groupType_id: 9,
        },
        previousM: {
          performance: "0.4737",
          total_opportunities: "3276",
          year_month: 202303,
          groupType_id: 9,
        },
      },
      {
        name: "Techs",
        groupType_id: 2,
        href: "https://local.swipesense.test/report/group/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22group_type_ids%22%3A%5B2%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=19a416ac4918b7adbcf727407b391d1771e4209ad5d1f4ecb93d2c120e67b265",
        currentM: {
          performance: "0.4807",
          total_opportunities: "126815",
          year_month: 202304,
          groupType_id: 2,
        },
        previousM: {
          performance: "0.4558",
          total_opportunities: "324668",
          year_month: 202303,
          groupType_id: 2,
        },
      },
      {
        name: "Therapists",
        groupType_id: 7,
        href: "https://local.swipesense.test/report/group/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22group_type_ids%22%3A%5B7%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=d379b03b9213ce2f72007de845cfda5c3618ec79c2068d8c39b1b561d125a906",
        currentM: {
          performance: "0.5876",
          total_opportunities: "22038",
          year_month: 202304,
          groupType_id: 7,
        },
        previousM: {
          performance: "0.5573",
          total_opportunities: "55367",
          year_month: 202303,
          groupType_id: 7,
        },
      },
    ],
    unitTypeData: [
      {
        name: "Cardiac",
        tag_id: 5,
        href: "https://local.swipesense.test/report/unit/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22tag_ids%22%3A%5B5%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=f3810d4fc1fca5787d9cad061572f51085adfb3eb03cafb5d7e05acf17931f9d",
        currentM: {
          total_opportunities: "60448",
          performance: "0.5252",
          year_month: "202304",
          tag_id: 5,
        },
        previousM: {
          total_opportunities: "141077",
          performance: "0.5076",
          year_month: "202303",
          tag_id: 5,
        },
      },
      {
        name: "Critical Care",
        tag_id: 1,
        href: "https://local.swipesense.test/report/unit/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22tag_ids%22%3A%5B1%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=fe69ee8c8b7cd96ce6bb5cbfbb52234c2ca035577c28b0e7b25d9ee4ba846258",
        currentM: {
          total_opportunities: "29301",
          performance: "0.4506",
          year_month: "202304",
          tag_id: 1,
        },
        previousM: {
          total_opportunities: "77976",
          performance: "0.4487",
          year_month: "202303",
          tag_id: 1,
        },
      },
      {
        name: "Emergency",
        tag_id: 6,
        href: "https://local.swipesense.test/report/unit/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22tag_ids%22%3A%5B6%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=0d09d2858411306c2a6c902bf997ede911aa37264be40695788958a8512f08f0",
        currentM: {
          total_opportunities: "60558",
          performance: "0.3708",
          year_month: "202304",
          tag_id: 6,
        },
        previousM: {
          total_opportunities: "142293",
          performance: "0.3560",
          year_month: "202303",
          tag_id: 6,
        },
      },
      {
        name: "Med/Surg",
        tag_id: 2,
        href: "https://local.swipesense.test/report/unit/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22tag_ids%22%3A%5B2%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=680148a3c0a781017c34ae6ff8d8624a5d83047a9efc6fbfcbf5d7f71e84dc19",
        currentM: {
          total_opportunities: "162996",
          performance: "0.5541",
          year_month: "202304",
          tag_id: 2,
        },
        previousM: {
          total_opportunities: "427541",
          performance: "0.5289",
          year_month: "202303",
          tag_id: 2,
        },
      },
      {
        name: "Neuro",
        tag_id: 7,
        href: "https://local.swipesense.test/report/unit/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22tag_ids%22%3A%5B7%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=84859d571ac75f0e2dbcf54ee61da4ae68858c838bf75322b208ac0c8ef65acb",
        currentM: {
          total_opportunities: "7480",
          performance: "0.6727",
          year_month: "202304",
          tag_id: 7,
        },
        previousM: {
          total_opportunities: "16978",
          performance: "0.6252",
          year_month: "202303",
          tag_id: 7,
        },
      },
      {
        name: "Oncology",
        tag_id: 8,
        href: "https://local.swipesense.test/report/unit/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22tag_ids%22%3A%5B8%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=9fee3904f94c15961ce948a67af5f40761f0b6295fe0eddb962123abfbdd5042",
        currentM: {
          total_opportunities: "20190",
          performance: "0.5549",
          year_month: "202304",
          tag_id: 8,
        },
        previousM: {
          total_opportunities: "49154",
          performance: "0.5357",
          year_month: "202303",
          tag_id: 8,
        },
      },
      {
        name: "Orthopedics",
        tag_id: 9,
        href: "https://local.swipesense.test/report/unit/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22tag_ids%22%3A%5B9%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=ece24bc15a8ec3d9474ccdb2d963916b9507c93964ba432310b2620d31a6aaee",
        currentM: null,
        previousM: null,
      },
      {
        name: "Peds/NICU",
        tag_id: 10,
        href: "https://local.swipesense.test/report/unit/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22tag_ids%22%3A%5B10%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=d40930aecbb3d5a63b3f0152aaf9027e376efc0567266f174fe87ac99257defa",
        currentM: {
          total_opportunities: "38939",
          performance: "0.7006",
          year_month: "202304",
          tag_id: 10,
        },
        previousM: {
          total_opportunities: "83038",
          performance: "0.6958",
          year_month: "202303",
          tag_id: 10,
        },
      },
      {
        name: "Procedural",
        tag_id: 12,
        href: "https://local.swipesense.test/report/unit/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22tag_ids%22%3A%5B12%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=28749c3a1cd8ffc1d44bba68326d19620f9daf0c093c836661303f771b99d561",
        currentM: {
          total_opportunities: "11245",
          performance: "0.3886",
          year_month: "202304",
          tag_id: 12,
        },
        previousM: {
          total_opportunities: "26188",
          performance: "0.4020",
          year_month: "202303",
          tag_id: 12,
        },
      },
      {
        name: "Step-down/Intermediate Care",
        tag_id: 13,
        href: "https://local.swipesense.test/report/unit/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22tag_ids%22%3A%5B13%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=03804898c70fd009d87b2e3bc78b58868c855dacc683aae942bea79a07b4f003",
        currentM: {
          total_opportunities: "85958",
          performance: "0.5456",
          year_month: "202304",
          tag_id: 13,
        },
        previousM: {
          total_opportunities: "211883",
          performance: "0.5343",
          year_month: "202303",
          tag_id: 13,
        },
      },
      {
        name: "Women's Services",
        tag_id: 3,
        href: "https://local.swipesense.test/report/unit/sp/95229/%7B%22customer_ids%22%3A%5B32%2C19%2C28%5D%2C%22facility_ids%22%3A%5B2005%2C10467%2C12585%2C12586%2C12587%2C12588%2C12589%2C12590%2C12591%2C12592%2C14473%5D%2C%22tag_ids%22%3A%5B3%5D%2C%22aggregation%22%3A%22monthly%22%2C%22date_range%22%3A%22DR%7Coffset_month%2A6%7C%22%7D?expires=1681928610&redirectAddress=https%3A%2F%2Flocal.swipesense.test%2Fspa%2Fdashboard%2Fexecutive%3F_fe%3D98f6709c-47ba-44ea-ab56-5cc0c1285a7c&signature=eca33e812f799296c095c971bfb6768dee9b000e0cbd6d6f818a3140a8945a38",
        currentM: {
          total_opportunities: "37819",
          performance: "0.5766",
          year_month: "202304",
          tag_id: 3,
        },
        previousM: {
          total_opportunities: "76244",
          performance: "0.5337",
          year_month: "202303",
          tag_id: 3,
        },
      },
    ],
    handHygienePlot: {
      dataSeries: [
        {
          name: "System-1889 Health System",
          data: [],
          showInLegend: true,
          visible: false,
        },
        {
          name: "Facility-1 Hospital",
          data: [],
          showInLegend: true,
          visible: false,
        },
        {
          name: "CHSH Office",
          data: [],
          showInLegend: true,
          visible: false,
        },
        {
          name: "S11517 Health System",
          data: [
            {
              x: 1672531200000,
              y: 0.3656,
              percentage: 37,
              name: "January 2023",
            },
            {
              x: 1675209600000,
              y: 0.3718,
              percentage: 37,
              name: "February 2023",
            },
            {
              x: 1677628800000,
              y: 0.3598,
              percentage: 36,
              name: "March 2023",
            },
            {
              x: 1680307200000,
              y: 0.3969,
              percentage: 40,
              name: "April 2023 (Partial Month)",
            },
          ],
          showInLegend: true,
          visible: true,
        },
        {
          name: "Another Mysterious Place",
          data: [
            {
              x: 1675209600000,
              y: 0.3718,
              percentage: 60,
              name: "February 2023",
            },
            {
              x: 1677628800000,
              y: 0.3598,
              percentage: 36,
              name: "March 2023",
            },
            {
              x: 1680307200000,
              y: 0.3969,
              percentage: 50,
              name: "April 2023 (Partial Month)",
            },
          ],
          showInLegend: true,
          visible: true,
        },
        {
          name: "Testing Later Hospital",
          data: [
            {
              x: 1680307200000,
              y: 0.3293,
              percentage: 22,
              name: "April 2023 (Partial Month)",
            },
          ],
          showInLegend: true,
          visible: true,
        },
        {
          name: "Offboarding Hospital",
          data: [
            {
              x: 1672531200000,
              y: 0.3656,
              percentage: 68,
              name: "January 2023",
            },
            {
              x: 1675209600000,
              y: 0.3718,
              percentage: 37,
              name: "February 2023",
            },
          ],
          showInLegend: true,
          visible: true,
        },
        {
          name: "F11755 Hospital",
          data: [
            {
              x: 1672531200000,
              y: 0.3656,
              percentage: 37,
              name: "January 2023",
            },
            {
              x: 1675209600000,
              y: 0.3718,
              percentage: 37,
              name: "February 2023",
            },
            {
              x: 1677628800000,
              y: 0.3598,
              percentage: 99,
              name: "March 2023",
            },
            {
              x: 1680307200000,
              y: 0.3969,
              percentage: 1,
              name: "April 2023 (Partial Month)",
            },
          ],
          showInLegend: true,
          visible: true,
        },
        {
          name: "ICU",
          data: [
            {
              x: 1672531200000,
              y: 0.3684,
              percentage: 88,
              name: "January 2023",
            },
            {
              x: 1675209600000,
              y: 0.3689,
              percentage: 20,
              name: "February 2023",
            },
            {
              x: 1677628800000,
              y: 0.3775,
              percentage: 44,
              name: "March 2023",
            },
            {
              x: 1680307200000,
              y: 0.4224,
              percentage: 13,
              name: "April 2023 (Partial Month)",
            },
          ],
          showInLegend: true,
          visible: true,
        },
      ],
    },
  };
}
