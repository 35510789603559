import { useEffect } from "react";
import ApiError from "../../Components/ApiError/ApiError";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
import HardwareButton from "../../Components/HardwareButton/HardwareButton";
import LineGraph from "../../Components/LineGraph/LineGraph";
import MetricsCard from "../../Components/MetricsCard/MetricsCard";
import Performance from "../../Components/Performance/Performance";
import { useUUIDWithLocation } from "../../Hooks/useUuid";
import useMyReportViewController from "../../viewcontroller/MyReport/useMyReportViewController";
import DownloadButton from "../../Components/DownloadButton/DownloadButton";
import usePdfDownload from "../../Hooks/usePdfDownload";
import { myReportElementIds, myReportTitles } from "../../constants/pdfs";

function MyReportView() {
  const { transformedMyReportData: myReportData, pdfTables } =
    useMyReportViewController();

  const apiError = myReportData?.error?.message;

  const { updateUUID } = useUUIDWithLocation();

  useEffect(() => {
    updateUUID(myReportData?.uuid);
  }, [myReportData?.uuid, updateUUID]);

  const { downloadPdf, loading } = usePdfDownload(
    myReportElementIds,
    myReportTitles,
    pdfTables,
    "My-Report.pdf",
    "My Report PDF"
  );

  return (
    <>
      <div className="page-header">
        <div>
          <h1>My Report</h1>
          <p data-testid="date-range" className="no-margin">
            {myReportData?.tiles.dateRange}
          </p>
        </div>
        <div className="buttons-container">
          <ExcelDownload
            link={myReportData.excelUrl}
            disabled={myReportData.disabled}
          />
          <DownloadButton click={downloadPdf} loading={loading} cta={"PDF"} />
          <HardwareButton
            link={process.env.REACT_APP_AUTH_DOMAIN + "/report/myBadge"}
          />
        </div>
      </div>
      {apiError && <ApiError message={apiError} />}
      <div className="staff-pages-grid">
        <div className="performance-card">
          <Performance reportData={myReportData.tiles} />
        </div>
        <div className="metrics-card">
          <MetricsCard
            total={myReportData.tiles.totalOpportunities}
            performant={myReportData.tiles.totalHygieneEvents}
          />
        </div>
        <div className="graph-card spacing-cards-gap">
          <LineGraph
            title={`${
              myReportData.name || "My Report"
            }'s Performance Over Time`}
            dataSeries={myReportData.dataSeries}
            yAxisPlotlines={myReportData.yAxisPlotlines}
          />
        </div>
      </div>
    </>
  );
}

export default MyReportView;
