import { AverageCompliance } from "../../types/types";
import { getColor } from "../../utils";

type BubbleData = {
  x: number;
  y: number;
  r: number;
};

export const getBubbleChartData = (data: AverageCompliance[]) => {
  if (!data || data.length === 0) {
    return {
      labels: [],
      backgroundColors: [],
      mappedAndFilteredData: [],
    };
  }

  const MIN_BUBBLE_RADIUS = 3;
  const MAX_BUBBLE_RADIUS = 33;

  const filterZeroOpportunities = (data: AverageCompliance[]) => {
    return data.filter((item) => {
      return item.opportunities !== 0;
    });
  };

  const filteredData = filterZeroOpportunities(data);

  const labels = filteredData.map(
    (item) => item.name || item.nodeName + ": " + item.shiftName
  );

  const mapComplianceToBackgroundColor = (data: AverageCompliance[]) => {
    return data.map((item) => {
      return getColor(item.percentage);
    });
  };

  const backgroundColors = mapComplianceToBackgroundColor(filteredData);

  const findDivisor = (data: AverageCompliance[]) => {
    const highestNumOpps = Math.max(...data.map((item) => item.opportunities));
    let divisor = Math.floor(highestNumOpps / MAX_BUBBLE_RADIUS);

    return divisor;
  };

  const divisor = findDivisor(filteredData);

  const mappedAndFilteredData: BubbleData[] = filteredData.map((item) => {
    let rValue = Math.round(item.opportunities / divisor);
    if (rValue < MIN_BUBBLE_RADIUS) {
      rValue = MIN_BUBBLE_RADIUS;
    }
    return {
      x: item.opportunities,
      y: item.percentage,
      r: rValue,
    };
  });

  return {
    labels,
    backgroundColors,
    mappedAndFilteredData,
  };
};
