import { useState } from "react";

export const useUUIDWithLocation = () => {
  const [uuid, setUUID] = useState("");

  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.delete("uuid");
  currentUrl.searchParams.append("uuid", uuid);
  window.history.replaceState({}, "", currentUrl.toString());

  const updateUUID = (newUUID: string) => {
    setUUID(newUUID);
  };

  return { uuid, updateUUID };
};
