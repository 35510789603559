import { useAsyncValue } from "react-router";
import DatePickerDropdown from "../Components/DatePickerDropdown/DatePickerDropdown";
import DatePicker from "../Components/DatePicker/DatePicker";
import Filters from "../Components/Filters/Filters";
import { oneWeekDateRange } from "../constants";
import useFiltersViewController from "../viewcontroller/RoomsOverview/useFiltersViewController";

function FiltersWithDatePicker() {
  const resolvedFilters = useAsyncValue() as any;
  const {
    startDate,
    endDate,
    onFilterChange,
    handleDateChange,
    applyFilters,
    toggleButton,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
    filters,
  } = useFiltersViewController(resolvedFilters);

  return (
    <>
      <div>
        <DatePickerDropdown
          startDateLabel={startDate ?? oneWeekDateRange.startDate}
          endDateLabel={endDate ?? oneWeekDateRange.endDate}
          onChange={handleDatePickerChange}
        />
        {isDatePickerVisible && (
          <DatePicker
            handleDatePickerChange={() => handleDatePickerChange}
            visible={isDatePickerVisible}
            maxNumDays={7}
            maxNumWeeks={1}
            startDate={startDate ?? oneWeekDateRange.startDate}
            endDate={endDate ?? oneWeekDateRange.endDate}
            onChange={handleDateChange}
            handleClick={handleDatePickerButtonClick}
          />
        )}
      </div>
      <Filters
        filters={filters}
        onChange={onFilterChange}
        toggleButton={toggleButton}
        applyFilters={applyFilters}
      />
    </>
  );
}

export default FiltersWithDatePicker;
